import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { API } from 'aws-amplify';

import { TokenService } from './auth/token.service';
import { SnackBarService } from './snackbar.service';

import { Email } from '@models/email.model';
import { W9FormRequest } from '@models/w9-from-request.model';

import awsConfig from '../../../../amplify-src/aws-exports';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private endpoint: string = awsConfig.aws_cloud_logic_custom[0].name;

  constructor(
    private readonly http: HttpClient,
    private readonly snackbar: SnackBarService,
    private readonly tokenService: TokenService
  ) {}

  public async validateReCaptcha(answer: string): Promise<{ success: boolean }> {
    return this.get<{ success: boolean }>('/recaptcha-validation', { queryStringParameters: { answer } });
  }

  public async hasConnection(): Promise<boolean> {
    return await API.get(this.endpoint, '/healthcheck', { headers: await this.headers() });
  }

  public async sendEmail(body: Email): Promise<{ message: string }> {
    return this.post(body, '/send-email');
  }

  public async generateW9Form(body: W9FormRequest): Promise<{ key: string }> {
    return this.post(body, environment.w9Endpoint);
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
    }
    return throwError(
        'Something bad happened; please try again later.');
  }

  public getIPAddress(): Observable<string> {
    return this.http.get<{ ip: string }>('https://api.ipify.org/?format=json').pipe(map((res) => res?.ip));
  }

  private async get<T>(path: string, params: { [key: string]: unknown } = {}): Promise<T> {
    try {
      return await API.get(this.endpoint, path, { headers: await this.headers(), ...params });
    } catch (error) {
      this.snackbar.error(error?.message);
    }
  }

  private async post<T, K>(body: T, path: string): Promise<K> {
    try {
      return await API.post(this.endpoint, path, {
        body,
        headers: await this.headers(),
      });
    } catch (error) {
      this.snackbar.error(error?.message);
    }
  }

  private async headers(): Promise<{ Authorization: string }> {
    return {
      Authorization: await this.tokenService.getToken(),
    };
  }
}
