import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { IDBSurveyObject } from '@interfaces/indexed-db/idb-survey-object.interface';

export class SaveSurveyDataInIndexedDBRequestAction implements Action {
  readonly type = ActionTypes.SAVE_SURVEY_DATA_IN_INDEXED_DB_REQUEST;

  constructor(public payload: IDBSurveyObject) {}
}

export class SaveSurveyDataInIndexedDBSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_SURVEY_DATA_IN_INDEXED_DB_SUCCESS;

  constructor(public payload: IDBSurveyObject[]) {}
}

export class SaveSurveyDataInIndexedDBFailureAction implements Action {
  readonly type = ActionTypes.SAVE_SURVEY_DATA_IN_INDEXED_DB_FAILURE;

  constructor(public payload: Error) {}
}
