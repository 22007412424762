export enum StorageFileKeyPrefixEnum {
  CompanyInsurance = 'company-insurance',
  CompanyAdditionalFiles = 'company-additional-files',
  UserFiles = 'user-files',
  SurveyFiles = 'survey-files',
  CaseFiles = 'case-files',
  SurveyTemplateLogo = 'survey-template-logos',
  SurveyLogo = 'survey-logos',
  UserLogo = 'user-logos',
}
