import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';

export class SaveFileKeysInIndexedDBRequestAction implements Action {
  readonly type = ActionTypes.SAVE_FILE_KEYS_IN_INDEXED_DB_REQUEST;

  constructor(public payload: { id: string; key: string }) {}
}

export class SaveFileKeysInIndexedDBSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_FILE_KEYS_IN_INDEXED_DB_SUCCESS;
}

export class SaveFileKeysInIndexedDBFailureAction implements Action {
  readonly type = ActionTypes.SAVE_FILE_KEYS_IN_INDEXED_DB_FAILURE;

  constructor(public payload: Error) {}
}
