import { GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { User, UserView, Technician } from '@models/user.model';
import { CognitoUserData } from '@models/cognito-user-data.model';

class GetUserById extends GraphQLQueryById<{ id: string }, { getUser: UserView }> {
  protected query = `query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      status
      role
      firstname
      lastname
      country
      Region
      deletedAt
      deleteStatus
      phone
      dateOfBirth
      salesforceId
      address1
      address2
      city
      state
      zipCode
      serviceArea
      tools
      services
      notificationTypes
      cognitoId
      teamId
      agreementDate
      ipAddress
      registrationStatus
      backgroundCheckDate
      drugScreenCheckDate
      userLogo {
        bucket
        region
        key
        name
      }
      backgroundCheck {
        bucket
        region
        key
        name
      }
      drugScreenCheck {
        bucket
        region
        key
        name
      }
      comments(filter: {isActive: {eq: true}}) {
        items {
          id
          commentBody
          contactId
          createdBy
          createdDate
          updatedDate
          isActive
          isPublished
          parentId
          salesforceId
          salesforceContactId
          salesforceCreatedBy
          salesforceParentId
          contactID {
            firstname
            lastname
          }
        }
      }
      additionalFiles {
        items {
          image {
            region
            name
            key
            bucket
          }
        }
      }
      teamID {
        id
        name
        salesforceId
      }
      orgId
      createdAt
      updatedAt
      clientId
    }
  }`;
}

class GetUserList extends GraphQLQueryList<GraphQLQueryRequest<User>, { listUsers: { items: UserView[] } }> {
  protected query = `query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        salesforceId
        status
        role
        firstname
        lastname
        country
        Region
        orgId
        createdAt
        updatedAt
        cognitoId
        deletedAt
        deleteStatus
        phone
        dateOfBirth
        address1
        address2
        city
        state
        zipCode
        serviceArea
        tools
        services
        notificationTypes
        teamId
        agreementDate
        ipAddress
        registrationStatus
        backgroundCheckDate
        drugScreenCheckDate
        userLogo {
          bucket
          region
          key
          name
        }
        backgroundCheck {
          bucket
          region
          key
          name
        }
        drugScreenCheck {
          bucket
          region
          key
          name
        }
        comments(filter: {isActive: {eq: true}}) {
          items {
            id
            commentBody
            contactId
            createdBy
            createdDate
            updatedDate
            isActive
            isPublished
            parentId
            salesforceId
            salesforceContactId
            salesforceCreatedBy
            salesforceParentId
            contactID {
              firstname
              lastname
            }
          }
        }
        additionalFiles {
          items {
            image {
              region
              name
              key
              bucket
            }
          }
        }
        teamID {
          name
          salesforceId
        }
        clientId
      }
      nextToken
    }
  }`;
}

class SearchTechnicians extends GraphQLQueryList<GraphQLQueryRequest<UserView>, { searchUsers: { items: Technician[] } }> {
  protected query = `query SearchUsers(
    $filter: SearchableUserFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableUserSortInput
  ) {
    searchUsers(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        salesforceId
        teamId
        firstname
        lastname
        phone
        email
        role
        status
      }
      nextToken
    }
  }`;
}

class SearchUsers extends GraphQLQueryList<GraphQLQueryRequest<UserView>, { searchUsers: { items: UserView[] } }> {
  protected query = `query SearchUsers(
    $filter: SearchableUserFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableUserSortInput
  ) {
    searchUsers(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        email
        salesforceId
        status
        role
        firstname
        lastname
        country
        Region
        orgId
        createdAt
        inviteDatetime
        updatedAt
        cognitoId
        deletedAt
        deleteStatus
        phone
        dateOfBirth
        address1
        address2
        city
        state
        zipCode
        serviceArea
        tools
        services
        notificationTypes
        teamId
        agreementDate
        ipAddress
        registrationStatus
        backgroundCheckDate
        drugScreenCheckDate
        userLogo {
          bucket
          region
          key
          name
        }
        teamID {
          name
          salesforceId
        }
        comments(filter: {isActive: {eq: true}}) {
          items {
            id
            commentBody
            contactId
            createdBy
            createdDate
            updatedDate
            isActive
            isPublished
            parentId
            salesforceId
            salesforceContactId
            salesforceCreatedBy
            salesforceParentId
            contactID {
              firstname
              lastname
            }
          }
        }
        clientId
      }
      nextToken
    }
  }`;
}

class SearchUsersCustom extends GraphQLQueryList<any, { searchUsersCustom: { items: UserView[], total: number } }> {
  protected query = `query SearchUsersCustom(
    $filter: searchUsersByEmailFilter
    $from: Int!
    $limit: Int!
    $sort: searchUsersByEmailSort
  ) {
    searchUsersCustom(filter: $filter, from: $from, limit: $limit, sort: $sort) {
      items {
        id
        email
        salesforceId
        status
        role
        firstname
        lastname
        country
        Region
        orgId
        createdAt
        inviteDatetime
        updatedAt
        cognitoId
        deletedAt
        deleteStatus
        phone
        dateOfBirth
        address1
        address2
        city
        state
        zipCode
        serviceArea
        tools
        services
        notificationTypes
        teamId
        agreementDate
        ipAddress
        registrationStatus
        backgroundCheckDate
        drugScreenCheckDate
        teamID {
          name
          salesforceId
        }
      }
      total
    }
  }`;
}

class GetUserListComment extends GraphQLQueryList<GraphQLQueryRequest<User>, { listUsers: { items: UserView[] } }> {
  protected query = `query ListUsers(
    $filter: ModelUserFilterInput
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: 1000, nextToken: $nextToken) {
      items {
        id
        email
        salesforceId
        status
        role
        firstname
        lastname
        country
        orgId
        createdAt
        updatedAt
        cognitoId
        deletedAt
        deleteStatus
        phone
        dateOfBirth
        address1
        address2
        city
        state
        zipCode
        serviceArea
        tools
        services
        notificationTypes
        teamId
        agreementDate
        ipAddress
        registrationStatus
        backgroundCheckDate
        drugScreenCheckDate
        userLogo {
          bucket
          region
          key
          name
        }
        backgroundCheck {
          bucket
          region
          key
          name
        }
        drugScreenCheck {
          bucket
          region
          key
          name
        }
        comments(filter: {isActive: {eq: true}}) {
          items {
            id
            commentBody
            contactId
            createdBy
            createdDate
            updatedDate
            isActive
            isPublished
            parentId
            salesforceId
            salesforceContactId
            salesforceCreatedBy
            salesforceParentId
            contactID {
              firstname
              lastname
            }
          }
        }
        additionalFiles {
          items {
            image {
              region
              name
              key
              bucket
            }
          }
        }
        teamID {
          name
          salesforceId
        }
      }
      nextToken
    }
  }`;
}

class GetCognitoUser extends GraphQLQueryById<{ cognitoId: string }, { getCognitoUser: CognitoUserData }> {
  protected query = `query GetCognitoUser($cognitoId: ID) {
    getCognitoUser(cognitoId: $cognitoId) {
      UserStatus
      customRole
      emailVerified
      Username
    }
  }`;
}

export const getUserById = new GetUserById();
export const getUserList = new GetUserList();
export const getUserListComment = new GetUserListComment();
export const searchUsers = new SearchUsers();
export const searchUsersCustom = new SearchUsersCustom();
export const searchTechnicians = new SearchTechnicians();
export const getCognitoUser = new GetCognitoUser();
