import {Component, enableProdMode} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { materialIcons } from './constants';
import { VersionCheckService } from "@services/version-check.service";
import { environment } from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly versionCheckService: VersionCheckService,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer
  ) {
    materialIcons.forEach((icon) =>
      iconRegistry.addSvgIcon(
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(icon.path)
      )
    );
  }

  ngOnInit() {
    if (environment.production) {
      this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    }

  }
}
