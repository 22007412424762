import { GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { AdditionalExpense } from '@models/case/additional-expense.model';

class GetAdditionalExpensesList extends GraphQLQueryList<GraphQLQueryRequest<AdditionalExpense>, { searchAdditionalExpenses: { items: AdditionalExpense[] } }> {
  protected query = `query searchAdditionalExpenses(
    $filter: SearchableAdditionalExpenseFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableAdditionalExpenseSortInput
  ) {
    searchAdditionalExpenses(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        caseId
        category
        createdAt
        createdBy
        createdDate
        description
        expenses
        updatedDate
        updatedBy
        updatedAt
        salesforceId
        isActive
        attachment {
          region
          name
          key
          bucket
        }
      }
      nextToken
    }
  }`;
}

export const getAdditionalExpensesList = new GetAdditionalExpensesList();

