export enum ActionTypes {
  LOAD_COMPANY_REQUEST = '[COMPANY] Load Company Request',
  LOAD_COMPANY_FAILURE = '[COMPANY] Load Company Failure',
  LOAD_COMPANY_SUCCESS = '[COMPANY] Load Company Success',

  LOAD_COMPANY_LIST_REQUEST = '[COMPANY] Load Company List Request',
  LOAD_COMPANY_LIST_FAILURE = '[COMPANY] Load Company List Failure',
  LOAD_COMPANY_LIST_SUCCESS = '[COMPANY] Load Company List Success',

  CREATE_COMPANY_REQUEST = '[COMPANY] Create Company Request',
  CREATE_COMPANY_FAILURE = '[COMPANY] Create Company Failure',
  CREATE_COMPANY_SUCCESS = '[COMPANY] Create Company Success',

  UPDATE_COMPANY_REQUEST = '[COMPANY] Update Company Request',
  UPDATE_COMPANY_FAILURE = '[COMPANY] Update Company Failure',
  UPDATE_COMPANY_SUCCESS = '[COMPANY] Update Company Success',

  INVITE_USER_REQUEST = '[COMPANY] Invite User Request',
  INVITE_USER_FAILURE = '[COMPANY] Invite User Failure',
  INVITE_USER_SUCCESS = '[COMPANY] Invite User Success',

  LOAD_COMPANY_ADDITIONAL_FILE_REQUEST = '[COMPANY] Load Company Additional File Request',
  LOAD_COMPANY_ADDITIONAL_FILE_FAILURE = '[COMPANY] Load Company Additional File Failure',
  LOAD_COMPANY_ADDITIONAL_FILE_SUCCESS = '[COMPANY] Load Company Additional File Success',

  SAVE_COMPANY_ADDITIONAL_FILE_REQUEST = '[COMPANY] Save Company Additional File Request',
  SAVE_COMPANY_ADDITIONAL_FILE_FAILURE = '[COMPANY] Save Company Additional File Failure',
  SAVE_COMPANY_ADDITIONAL_FILE_SUCCESS = '[COMPANY] Save Company Additional File Success',

  REMOVE_COMPANY_ADDITIONAL_FILE_REQUEST = '[COMPANY] Remove Company Additional File Request',
  REMOVE_COMPANY_ADDITIONAL_FILE_FAILURE = '[COMPANY] Remove Company Additional File Failure',
  REMOVE_COMPANY_ADDITIONAL_FILE_SUCCESS = '[COMPANY] Remove Company Additional File Success',

  LOAD_COMPANY_INSURANCE_REQUEST = '[COMPANY] Load Company Insurance Request',
  LOAD_COMPANY_INSURANCE_FAILURE = '[COMPANY] Load Company Insurance Failure',
  LOAD_COMPANY_INSURANCE_SUCCESS = '[COMPANY] Load Company Insurance Success',

  SAVE_COMPANY_INSURANCE_REQUEST = '[COMPANY] Save Company Insurance Request',
  SAVE_COMPANY_INSURANCE_FAILURE = '[COMPANY] Save Company Insurance Failure',
  SAVE_COMPANY_INSURANCE_SUCCESS = '[COMPANY] Save Company Insurance Success',

  GENERATE_W9_FORM_REQUEST = '[COMPANY] Generate W9 Form Request',
  GENERATE_W9_FORM_FAILURE = '[COMPANY] Generate W9 Form Failure',
  GENERATE_W9_FORM_SUCCESS = '[COMPANY] Generate W9 Form Success',

  REMOVE_W9_FORM_REQUEST = '[COMPANY] Remove W9 Form Request',
  REMOVE_W9_FORM_FAILURE = '[COMPANY] Remove W9 Form Failure',
  REMOVE_W9_FORM_SUCCESS = '[COMPANY] Remove W9 Form Success',

  LOAD_COMPANY_INVITATIONS_REQUEST = '[COMPANY] Load Company Invitations Request',
  LOAD_COMPANY_INVITATIONS_FAILURE = '[COMPANY] Load Company Invitations Failure',
  LOAD_COMPANY_INVITATIONS_SUCCESS = '[COMPANY] Load Company Invitations Success',

  ACTIVATE_COMPANY_REQUEST = '[COMPANY] Activate Company Request',
  ACTIVATE_COMPANY_FAILURE = '[COMPANY] Activate Company Failure',
  ACTIVATE_COMPANY_SUCCESS = '[COMPANY] Activate Company Success',

  DEACTIVATE_COMPANY_REQUEST = '[COMPANY] Deactivate Company Request',
  DEACTIVATE_COMPANY_FAILURE = '[COMPANY] Deactivate Company Failure',
  DEACTIVATE_COMPANY_SUCCESS = '[COMPANY] Deactivate Company Success',

  COMPANY_PAGINATION_REQUEST = '[COMPANY] Company Pagination Request',
}
