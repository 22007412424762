export enum ActionTypes {
  LOAD_OBJECT_BANK_REQUEST = '[SURVEY] Load Object Bank Request',
  LOAD_OBJECT_BANK_FAILURE = '[SURVEY] Load Object Bank Failure',
  LOAD_OBJECT_BANK_SUCCESS = '[SURVEY] Load Object Bank Success',

  CREATE_SURVEY_OBJECT_REQUEST = '[SURVEY] Create Survey Object Request',
  CREATE_SURVEY_OBJECT_FAILURE = '[SURVEY] Create Survey Object Failure',
  CREATE_SURVEY_OBJECT_SUCCESS = '[SURVEY] Create Survey Object Success',

  UPDATE_SURVEY_OBJECT_REQUEST = '[SURVEY] Update Survey Object Request',
  UPDATE_SURVEY_OBJECT_FAILURE = '[SURVEY] Update Survey Object Failure',
  UPDATE_SURVEY_OBJECT_SUCCESS = '[SURVEY] Update Survey Object Success',

  DELETE_SURVEY_OBJECT_REQUEST = '[SURVEY] Delete Survey Object Request',
  DELETE_SURVEY_OBJECT_FAILURE = '[SURVEY] Delete Survey Object Failure',
  DELETE_SURVEY_OBJECT_SUCCESS = '[SURVEY] Delete Survey Object Success',

  LOAD_QUESTION_BANK_REQUEST = '[SURVEY] Load Question Bank Request',
  LOAD_QUESTION_BANK_FAILURE = '[SURVEY] Load Question Bank Failure',
  LOAD_QUESTION_BANK_SUCCESS = '[SURVEY] Load Question Bank Success',

  CREATE_SURVEY_QUESTION_REQUEST = '[SURVEY] Create Survey Question Request',
  CREATE_SURVEY_QUESTION_FAILURE = '[SURVEY] Create Survey Question Failure',
  CREATE_SURVEY_QUESTION_SUCCESS = '[SURVEY] Create Survey Question Success',

  UPDATE_SURVEY_QUESTION_REQUEST = '[SURVEY] Update Survey Question Request',
  UPDATE_SURVEY_QUESTION_FAILURE = '[SURVEY] Update Survey Question Failure',
  UPDATE_SURVEY_QUESTION_SUCCESS = '[SURVEY] Update Survey Question Success',

  DELETE_SURVEY_QUESTION_REQUEST = '[SURVEY] Delete Survey Question Request',
  DELETE_SURVEY_QUESTION_FAILURE = '[SURVEY] Delete Survey Question Failure',
  DELETE_SURVEY_QUESTION_SUCCESS = '[SURVEY] Delete Survey Question Success',

  CREATE_SURVEY_TEMPLATE_REQUEST = '[SURVEY] Create Survey Template Request',
  CREATE_SURVEY_TEMPLATE_FAILURE = '[SURVEY] Create Survey Template Failure',
  CREATE_SURVEY_TEMPLATE_SUCCESS = '[SURVEY] Create Survey Template Success',

  UPDATE_SURVEY_TEMPLATE_REQUEST = '[SURVEY] Update Survey Template Request',
  UPDATE_SURVEY_TEMPLATE_FAILURE = '[SURVEY] Update Survey Template Failure',
  UPDATE_SURVEY_TEMPLATE_SUCCESS = '[SURVEY] Update Survey Template Success',

  DELETE_SURVEY_TEMPLATE_REQUEST = '[SURVEY] Delete Survey Template Request',
  DELETE_SURVEY_TEMPLATE_FAILURE = '[SURVEY] Delete Survey Template Failure',
  DELETE_SURVEY_TEMPLATE_SUCCESS = '[SURVEY] Delete Survey Template Success',

  LOAD_SITE_SURVEY_LIST_REQUEST = '[SURVEY] Site Survey List Bank Request',
  LOAD_SITE_SURVEY_LIST_FAILURE = '[SURVEY] Site Survey List Bank Failure',
  LOAD_SITE_SURVEY_LIST_SUCCESS = '[SURVEY] Site Survey List Bank Success',

  CREATE_SITE_SURVEY_REQUEST = '[SURVEY] Create Site Survey Request',
  CREATE_SITE_SURVEY_FAILURE = '[SURVEY] Create Site Survey Failure',
  CREATE_SITE_SURVEY_SUCCESS = '[SURVEY] Create Site Survey Success',

  UPDATE_SITE_SURVEY_REQUEST = '[SURVEY] Update Site Survey Request',
  UPDATE_SITE_SURVEY_FAILURE = '[SURVEY] Update Site Survey Failure',
  UPDATE_SITE_SURVEY_SUCCESS = '[SURVEY] Update Site Survey Success',

  DELETE_SITE_SURVEY_REQUEST = '[SURVEY] Delete Site Survey Request',
  DELETE_SITE_SURVEY_FAILURE = '[SURVEY] Delete Site Survey Failure',
  DELETE_SITE_SURVEY_SUCCESS = '[SURVEY] Delete Site Survey Success',

  LOAD_SURVEY_DATA_FROM_INDEXED_DB_REQUEST = '[SURVEY] Load Survey Data From Indexed DB Request',
  LOAD_SURVEY_DATA_FROM_INDEXED_DB_FAILURE = '[SURVEY] Load Survey Data From Indexed DB Failure',
  LOAD_SURVEY_DATA_FROM_INDEXED_DB_SUCCESS = '[SURVEY] Load Survey Data From Indexed DB Success',

  SAVE_SURVEY_DATA_IN_INDEXED_DB_REQUEST = '[SURVEY] Save Survey Data In Indexed DB Request',
  SAVE_SURVEY_DATA_IN_INDEXED_DB_FAILURE = '[SURVEY] Save Survey Data In Indexed DB Failure',
  SAVE_SURVEY_DATA_IN_INDEXED_DB_SUCCESS = '[SURVEY] Save Survey Data In Indexed DB Success',

  DELETE_SURVEY_DATA_FROM_INDEXED_DB_REQUEST = '[SURVEY] Delete Survey Data From Indexed DB Request',
  DELETE_SURVEY_DATA_FROM_INDEXED_DB_FAILURE = '[SURVEY] Delete Survey Data From Indexed DB Failure',
  DELETE_SURVEY_DATA_FROM_INDEXED_DB_SUCCESS = '[SURVEY] Delete Survey Data From Indexed DB Success',

  LOAD_EXTRA_OBJECTS_FROM_INDEXED_DB_REQUEST = '[SURVEY] Load Extra Objects From Indexed DB Request',
  LOAD_EXTRA_OBJECTS_FROM_INDEXED_DB_FAILURE = '[SURVEY] Load Extra Objects From Indexed DB Failure',
  LOAD_EXTRA_OBJECTS_FROM_INDEXED_DB_SUCCESS = '[SURVEY] Load Extra Objects From Indexed DB Success',

  SAVE_EXTRA_OBJECTS_IN_INDEXED_DB_REQUEST = '[SURVEY] Save Extra Objects In Indexed DB Request',
  SAVE_EXTRA_OBJECTS_IN_INDEXED_DB_FAILURE = '[SURVEY] Save Extra Objects In Indexed DB Failure',
  SAVE_EXTRA_OBJECTS_IN_INDEXED_DB_SUCCESS = '[SURVEY] Save Extra Objects In Indexed DB Success',

  DELETE_EXTRA_OBJECTS_FROM_INDEXED_DB_REQUEST = '[SURVEY] Delete Extra Objects From Indexed DB Request',
  DELETE_EXTRA_OBJECTS_FROM_INDEXED_DB_FAILURE = '[SURVEY] Delete Extra Objects From Indexed DB Failure',
  DELETE_EXTRA_OBJECTS_FROM_INDEXED_DB_SUCCESS = '[SURVEY] Delete Extra Objects From Indexed DB Success',

  SAVE_FILE_KEYS_IN_INDEXED_DB_REQUEST = '[SURVEY] Save File Keys In Indexed DB Request',
  SAVE_FILE_KEYS_IN_INDEXED_DB_FAILURE = '[SURVEY] Save File Keys In Indexed DB Failure',
  SAVE_FILE_KEYS_IN_INDEXED_DB_SUCCESS = '[SURVEY] Save File Keys In Indexed DB Success',

  DELETE_FILE_KEYS_FROM_INDEXED_DB_REQUEST = '[SURVEY] Delete File Keys From Indexed DB Request',
  DELETE_FILE_KEYS_FROM_INDEXED_DB_FAILURE = '[SURVEY] Delete File Keys From Indexed DB Failure',
  DELETE_FILE_KEYS_FROM_INDEXED_DB_SUCCESS = '[SURVEY] Delete File Keys From Indexed DB Success',

  GENERATE_SURVEY_REPORT_REQUEST = '[SURVEY] Generate Survey Report Request',
  GENERATE_SURVEY_REPORT_FAILURE = '[SURVEY] Generate Survey Report Failure',
  GENERATE_SURVEY_REPORT_SUCCESS = '[SURVEY] Generate Survey Report Success',
  GENERATE_SURVEY_REPORT_COMPLETE = '[SURVEY] Generate Survey Report Complete',

  DOWNLOAD_SURVEY_REPORT_REQUEST = '[SURVEY] Download Survey Report Request',
  DOWNLOAD_SURVEY_REPORT_FAILURE = '[SURVEY] Download Survey Report Failure',
  DOWNLOAD_SURVEY_REPORT_SUCCESS = '[SURVEY] Download Survey Report Success',

  QUESTIONS_PAGINATION_REQUEST = '[SURVEY] Questions Pagination Request',

  SURVEYS_PAGINATION_REQUEST = '[SURVEY] Surveys Pagination Request',
  SURVEYS_PAGINATION_SUCCESS = '[SURVEY] Surveys Pagination Success',

  ARCHIVED_SURVEYS_PAGINATION_REQUEST = '[SURVEY] Archived Surveys Pagination Request',
  ARCHIVED_SURVEYS_PAGINATION_SUCCESS = '[SURVEY] Archived Surveys Pagination Success',
  ARCHIVED_SURVEYS_PAGINATION_FAILURE = '[SURVEY] Archived Surveys Pagination Failure',

  SURVEYS_MANAGEMENT_PAGINATION_REQUEST = '[SURVEY] Surveys Management Pagination Request',
  SURVEYS_MANAGEMENT_PAGINATION_SUCCESS = '[SURVEY] Surveys Management Pagination Success',
  SURVEYS_MANAGEMENT_PAGINATION_FAILURE = '[SURVEY] Surveys Management Pagination Failure',
  
  SURVEY_TEMPLATES_PAGINATION_REQUEST = '[SURVEY] Survey Templates Pagination Request',
  SURVEY_TEMPLATES_PAGINATION_FAILURE = '[SURVEY] Survey Templates Pagination Failure',
  SURVEY_TEMPLATES_PAGINATION_SUCCESS = '[SURVEY] Survey Templates Pagination Success',

  OBJECTS_PAGINATION_REQUEST = '[SURVEY] Objects Pagination Request',
  OBJECTS_PAGINATION_SUCCESS = '[SURVEY] Objects Pagination Success',
  OBJECTS_PAGINATION_FAILURE = '[SURVEY] Objects Pagination Failure',

  OBJECTS_BANK_PAGINATION_REQUEST = '[SURVEY] Objects Bank Pagination Request',
  OBJECTS_BANK_PAGINATION_SUCCESS = '[SURVEY] Objects Bank Pagination Success',
  OBJECTS_BANK_PAGINATION_FAILURE = '[SURVEY] Objects Bank Pagination Failure',

  QUESTIONS_BANK_PAGINATION_REQUEST = '[SURVEY] Questions Bank Pagination Request',
  QUESTIONS_BANK_PAGINATION_SUCCESS = '[SURVEY] Questions Bank Pagination Success',
  QUESTIONS_BANK_PAGINATION_FAILURE = '[SURVEY] Questions Bank Pagination Failure'
}
