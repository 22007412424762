export enum SurveyTemplateStatusEnum {
  Pending = 'Pending',
  Active = 'Active',
  Draft = 'Draft',
  RequestToDelete = 'RequestToDelete',
}

export enum SurveyTemplateStatusTitleEnum {
  Pending = 'Pending',
  Active = 'Active',
  Draft = 'Draft',
  RequestToDelete = 'Request To Delete',
}
