export enum ActionTypes {
  LOAD_EMPTY_ROLE_TEMPLATE_REQUEST = '[ROLE] Load Empty Role Template Request',
  LOAD_EMPTY_ROLE_TEMPLATE_FAILURE = '[ROLE] Load Empty Role Template Failure',
  LOAD_EMPTY_ROLE_TEMPLATE_SUCCESS = '[ROLE] Load Empty Role Template Success',

  LOAD_BACKEND_ROLE_LIST_REQUEST = '[ROLE] Load BackEnd Role List Request',
  LOAD_BACKEND_ROLE_LIST_FAILURE = '[ROLE] Load BackEnd Role List Failure',
  LOAD_BACKEND_ROLE_LIST_SUCCESS = '[ROLE] Load BackEnd Role List Success',

  LOAD_FRONTEND_ROLE_LIST_REQUEST = '[ROLE] Load FrontEnd Role List Request',
  LOAD_FRONTEND_ROLE_LIST_FAILURE = '[ROLE] Load FrontEnd Role List Failure',
  LOAD_FRONTEND_ROLE_LIST_SUCCESS = '[ROLE] Load FrontEnd Role List Success',

  LOAD_USER_PERMISSIONS_REQUEST = '[ROLE] Load User Permissions Request',
  LOAD_USER_PERMISSIONS_FAILURE = '[ROLE] Load User Permissions Failure',
  LOAD_USER_PERMISSIONS_SUCCESS = '[ROLE] Load User Permissions Success',

  CREATE_ROLE_REQUEST = '[ROLE] Create Role Request',
  CREATE_ROLE_FAILURE = '[ROLE] Create Role Failure',
  CREATE_ROLE_SUCCESS = '[ROLE] Create Role Success',

  UPDATE_ROLE_REQUEST = '[ROLE] Update Role Request',
  UPDATE_ROLE_FAILURE = '[ROLE] Update Role Failure',
  UPDATE_ROLE_SUCCESS = '[ROLE] Update Role Success',

  ROLE_PAGINATION_REQUEST = '[ROLE] Role Pagination Request',
}
