export const environment = {
  production: true,
  reCaptchaSiteKey: '6LcsdcoZAAAAAD5ZMBGNazuXoxeN-rxX7Jsz1eTb',
  googleMapApiKey: 'AIzaSyAh2uC6tgcZF6B0Eb35tWuiCYCVfHcgKck',
  superAdminEmail: 'superadmin@pivital.com',
  superAdminPassword: 'pivital1234',
  linkExpirationTime: 3600000, // 1 hour in milliseconds
  versionCheckURL : '/version.json',
  w9Endpoint: '/generate-w9'
};
