import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';

export class DeleteExtraObjectsFromIndexedDBRequestAction implements Action {
  readonly type = ActionTypes.DELETE_EXTRA_OBJECTS_FROM_INDEXED_DB_REQUEST;

  constructor(public payload: string) {}
}

export class DeleteExtraObjectsFromIndexedDBSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_EXTRA_OBJECTS_FROM_INDEXED_DB_SUCCESS;

  constructor(public payload: string) {}
}

export class DeleteExtraObjectsFromIndexedDBFailureAction implements Action {
  readonly type = ActionTypes.DELETE_EXTRA_OBJECTS_FROM_INDEXED_DB_FAILURE;

  constructor(public payload: Error) {}
}
