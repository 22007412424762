import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { AdditionalExpense } from '@models/case/additional-expense.model';


class CreateAdditionalExpense extends GraphQLMutation<AdditionalExpense, { createAdditionalExpense: Partial<AdditionalExpense> }> {
  protected query = `mutation CreateAdditionalExpense(
    $input: CreateAdditionalExpenseInput!
    $condition: ModelAdditionalExpenseConditionInput
  ) {
    createAdditionalExpense(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateAdditionalExpense extends GraphQLMutation<Required<Partial<AdditionalExpense>, 'id'>, { updateAdditionalExpense: Partial<AdditionalExpense> }> {
  protected query = `mutation UpdateAdditionalExpense(
    $input: UpdateAdditionalExpenseInput!
    $condition: ModelAdditionalExpenseConditionInput
  ) {
    updateAdditionalExpense(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createAdditionalExpense = new CreateAdditionalExpense();
export const updateAdditionalExpense = new UpdateAdditionalExpense();
