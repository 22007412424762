import { CaseEvent } from '@models/case/case-event.model';
import { CaseTimeLog } from '@models/case/case-timelog.model';
import { Case, CaseView } from '@models/case/case.model';
import { AdditionalExpense} from '@models/case/additional-expense.model';

export interface CaseState {
  isLoading: boolean;
  isExpensesLoading: boolean;
  isHistoryLoading: boolean;
  isTimeLogsLoading: boolean;
  casePagination: { items: Case[], total: number };
  casePaginationLoading: boolean;
  singleCase: CaseView;
  caseEvents: CaseEvent[];
  caseTimeLogs: CaseTimeLog[];
  additionalExpenses: AdditionalExpense[];
}

export const initialState: CaseState = {
  isLoading: false,
  isExpensesLoading: false,
  isHistoryLoading: false,
  isTimeLogsLoading: false,
  casePagination: { items: [], total: 0 },
  casePaginationLoading: false,
  singleCase: null,
  caseEvents: [],
  caseTimeLogs: [],
  additionalExpenses: []
};
