import { Action } from '@ngrx/store';
import { ActionTypes } from '@store/survey-store/action-types.enum';

import { Pagination } from '@models/pagination.model';
import { SurveyQuestion } from '@models/survey/survey-question.model';

export class QuestionsBankPaginationRequestAction implements Action {
  readonly type = ActionTypes.QUESTIONS_BANK_PAGINATION_REQUEST;

  constructor(public payload: Pagination, public staticFilter?: { [key: string]: any} ) {}
}
  
export class QuestionsBankPaginationSuccessAction implements Action {
  readonly type = ActionTypes.QUESTIONS_BANK_PAGINATION_SUCCESS;

  constructor(public payload: { items: SurveyQuestion[], total: number }) {}
}

export class QuestionsBankPaginationFailureAction implements Action {
  readonly type = ActionTypes.QUESTIONS_BANK_PAGINATION_FAILURE;

  constructor(public payload: Error) {}
}
