import { GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { BellNotification } from '@models/bell-notification.model';

class GetBellNotificationsList extends GraphQLQueryList<GraphQLQueryRequest<BellNotification>, { searchProfileNotifications: { items: BellNotification[] } }> {
  protected query = `query ListBellNotifications(
    $filter: SearchableProfileNotificationFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableProfileNotificationSortInput
  ) {
    searchProfileNotifications(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        createdDate
        updatedDate
        type
        title
        message
        link
        isViewed
        isActive
        id
      }
      nextToken
    }
  }`;
}

export const getBellNotificationsList = new GetBellNotificationsList();
