import { BackEndRole } from '@models/roles/backend-role.model';
import { FrontEndRole } from '@models/roles/frontend-role.model';

export interface RoleState {
  isLoading: boolean;
  userPermissions: FrontEndRole[];
  renderingRoleSchema: FrontEndRole[];
  backEndRoleList: BackEndRole[];
  frontEndRoleList: FrontEndRole[];
}

export const initialState: RoleState = {
  isLoading: false,
  userPermissions: [],
  renderingRoleSchema: [],
  backEndRoleList: [],
  frontEndRoleList: [],
};
