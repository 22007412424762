import { GraphQLCustomQueryList, GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { SiteSurvey } from '@models/survey/site-survey.model';

class GetSiteSurveyById extends GraphQLQueryById<{ id: string }, { getSiteSurvey: SiteSurvey }> {
  protected query = `query GetSiteSurvey($id: ID!) {
    getSiteSurvey(id: $id) {
      id
      name
      client
      project
      instructions
      status
      caseId
      caseNumber
      clientId
      projectId
      surveyBody
      onMyWayDate
      onMyWayLocation
      beginDate
      beginLocation
      endDate
      endLocation
      createdAt
      updatedAt
      createdDate
      createdBy
      updatedDate
      updatedBy
      scheduleDate
      address
      createdByID {
        id
        email
        firstname
        lastname
      }
      clientID {
        id
        name
      }
      projectID {
        id
        name
      }
      caseID {
        id
        name
      }
      surveyLogo {
        bucket
        region
        key
        name
      }
    }
  }
`;
}

class GetSiteSurveyList extends GraphQLQueryList<GraphQLQueryRequest<SiteSurvey>, { listSiteSurveys: { items: SiteSurvey[] } }> {
  protected query = `query ListSiteSurveys(
    $filter: ModelSiteSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        client
        project
        instructions
        status
        caseId
        clientId
        projectId
        onMyWayDate
        onMyWayLocation
        beginDate
        beginLocation
        endDate
        endLocation
        createdAt
        updatedAt
        createdDate
        createdBy
        updatedDate
        updatedBy
        reportGenerated
        reportKeyPdfAttachment
        reportKeyPdfPageBreaks
        reportKeyPdfTables
        reportKeyDoc
        reportKeyCsv
        reportKeyImgNotFullyNamed
        reportKeyImgFullyNamed
        scheduleDate
        address
        createdByID {
          id
          email
          firstname
          lastname
        }
        clientID {
          id
          name
        }
        projectID {
          id
          name
        }
        caseID {
          id
          name
        }
      }
      nextToken
    }
  }`;
}

class SearchSiteSurvey extends GraphQLQueryList<GraphQLQueryRequest<SiteSurvey>, { searchSiteSurveys: { items: SiteSurvey[] } }> {
  protected query = `query SearchSiteSurveys(
    $filter: SearchableSiteSurveyFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableSiteSurveySortInput
  ) {
    searchSiteSurveys(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        name
        client
        project
        instructions
        status
        case
        caseId
        caseNumber
        clientId
        projectId
        onMyWayDate
        onMyWayLocation
        beginDate
        beginLocation
        endDate
        endLocation
        createdAt
        updatedAt
        createdDate
        createdBy
        updatedDate
        updatedBy
        reportGenerated
        reportKeyPdfAttachment
        reportKeyPdfAttachmentUpdatedDate
        reportKeyPdfPageBreaks
        reportKeyPdfPageBreaksUpdatedDate
        reportKeyPdfTables
        reportKeyPdfTablesUpdatedDate
        reportKeyDoc
        reportKeyDocUpdatedDate
        reportKeyCsv
        reportKeyCsvUpdatedDate
        reportKeyImgNotFullyNamed
        reportKeyImgNotFullyNamedUpdatedDate
        reportKeyImgFullyNamed
        reportKeyImgFullyNamedUpdatedDate
        scheduleDate
        address
        createdByID {
          id
          email
          firstname
          lastname
        }
        clientID {
          id
          name
        }
        projectID {
          id
          name
        }
        caseID {
          id
          name
          address1
          address2
          caseNumber
          city
          country
          state
          zipCode
        }
      }
      nextToken
    }
  }`;
}

class CustomSiteSurveysSearch extends GraphQLCustomQueryList<any, {
  customSiteSurveysSearch: { items: SiteSurvey[], total: number }
}> {
  protected query = `query CustomSiteSurveysSearch(
    $filter: customSiteSurveysSearchFilter
    $from: Int!
    $limit: Int!
    $sort: customSiteSurveysSearchSort
  ) {
    customSiteSurveysSearch(filter: $filter, from: $from, limit: $limit, sort: $sort) {
      items {
        id
        name
        client
        project
        instructions
        status
        case
        caseId
        caseNumber
        clientId
        projectId
        onMyWayDate
        onMyWayLocation
        beginDate
        beginLocation
        endDate
        endLocation
        createdAt
        updatedAt
        createdDate
        createdBy
        createdByName
        updatedDate
        updatedBy
        reportGenerated
        reportKeyPdfAttachment
        reportKeyPdfAttachmentUpdatedDate
        reportKeyPdfPageBreaks
        reportKeyPdfPageBreaksUpdatedDate
        reportKeyPdfTables
        reportKeyPdfTablesUpdatedDate
        reportKeyDoc
        reportKeyDocUpdatedDate
        reportKeyCsv
        reportKeyCsvUpdatedDate
        reportKeyImgNotFullyNamed
        reportKeyImgNotFullyNamedUpdatedDate
        reportKeyImgFullyNamed
        reportKeyImgFullyNamedUpdatedDate
        scheduleDate
        address
      }
      total
    }
  }`;
}

export const getSiteSurveyById = new GetSiteSurveyById();
export const getSiteSurveyList = new GetSiteSurveyList();
export const searchSiteSurvey = new SearchSiteSurvey();
export const customSiteSurveysSearch = new CustomSiteSurveysSearch();
