import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginGuard } from './core/guards/login.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'auth',
    canActivate: [LoginGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'applink',
    loadChildren: () => {
      return new Promise(() => {
        if (window.location.href.match(/android/)) {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.post.pivital';
        } else if (window.location.href.match(/apple-ios/)) {
          window.location.href = 'https://apps.apple.com/us/app/post-pivital/id1568085831';
        } else {
          window.location.href = window.location.origin;
        }
      });
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, LoginGuard],
})
export class AppRoutingModule {}
