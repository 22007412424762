import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';

export class DeleteFileKeysFromIndexedDBRequestAction implements Action {
  readonly type = ActionTypes.DELETE_FILE_KEYS_FROM_INDEXED_DB_REQUEST;

  constructor(public payload: string) {}
}

export class DeleteFileKeysFromIndexedDBSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_FILE_KEYS_FROM_INDEXED_DB_SUCCESS;
}

export class DeleteFileKeysFromIndexedDBFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FILE_KEYS_FROM_INDEXED_DB_FAILURE;

  constructor(public payload: Error) {}
}
