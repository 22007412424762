import { Action } from '@ngrx/store';
import { Required } from 'utility-types';

import { ActionTypes } from '../action-types.enum';
import { Case } from '@models/case/case.model';
import { ActionParams } from '@models/action-params.model';

export class UpdateCaseRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_REQUEST;

  constructor(public payload: { model: Required<Partial<Case>, 'id'>; params?: ActionParams }) {}
}

export class UpdateCaseSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_SUCCESS;
}

export class UpdateCaseFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_FAILURE;

  constructor(public payload: Error) {}
}
