export enum ActionTypes {
  LOGIN_REQUEST = '[AUTH] Login Request',
  LOGIN_FAILURE = '[AUTH] Login Failure',
  LOGIN_SUCCESS = '[AUTH] Login Success',

  REGISTRATION_REQUEST = '[AUTH] Registration Request',
  REGISTRATION_FAILURE = '[AUTH] Registration Failure',
  REGISTRATION_SUCCESS = '[AUTH] Registration Success',

  RESET_PASSWORD_REQUEST = '[AUTH] Reset Password Request',
  RESET_PASSWORD_FAILURE = '[AUTH] Reset Password Failure',
  RESET_PASSWORD_SUCCESS = '[AUTH] Reset Password Success',

  CHANGE_PASSWORD_REQUEST = '[AUTH] Change Password Request',
  CHANGE_PASSWORD_FAILURE = '[AUTH] Change Password Failure',
  CHANGE_PASSWORD_SUCCESS = '[AUTH] Change Password Success',

  SET_NEW_PASSWORD_REQUEST = '[AUTH] Set New Password Request',
  SET_NEW_PASSWORD_FAILURE = '[AUTH] Set New Password Failure',
  SET_NEW_PASSWORD_SUCCESS = '[AUTH] Set New Password Success',

  SET_PASSWORD_AS_ADMIN_REQUEST = '[AUTH] Set Password As Admin Request',
  SET_PASSWORD_AS_ADMIN_FAILURE = '[AUTH] Set Password As Admin Failure',
  SET_PASSWORD_AS_ADMIN_SUCCESS = '[AUTH] Set Password As Admin Success',

  REQUEST_RESEND_SIGN_UP_REQUEST = '[AUTH] Request Re-Send Sign Up Request',
  REQUEST_RESEND_SIGN_UP_FAILURE = '[AUTH] Request Re-Send Sign Up Failure',
  REQUEST_RESEND_SIGN_UP_SUCCESS = '[AUTH] Request Re-Send Sign Up Success',

  RESEND_SIGN_UP_REQUEST = '[AUTH] Re-Send Sign Up Request',
  RESEND_SIGN_UP_FAILURE = '[AUTH] Re-Send Sign Up Failure',
  RESEND_SIGN_UP_SUCCESS = '[AUTH] Re-Send Sign Up Success',

  SEND_REQUEST_REQUEST = '[AUTH] Send Request Request',
  SEND_REQUEST_FAILURE = '[AUTH] Send Request Failure',
  SEND_REQUEST_SUCCESS = '[AUTH] Send Request Success',

  LOGOUT_REQUEST = '[AUTH] Logout Request',
  LOGOUT_FAILURE = '[AUTH] Logout Failure',
  LOGOUT_SUCCESS = '[AUTH] Logout Success',

  EMAIL_FORM_SUBMIT_REQUEST = '[AUTH] Email Form Submit Request',
  EMAIL_FORM_SUBMIT_FAILURE = '[AUTH] Email Form Submit Failure',
}
