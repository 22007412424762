import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { Pagination } from '@models/pagination.model';

export class CaseTimeLogsPaginationRequestAction implements Action {
  readonly type = ActionTypes.CASE_TIME_LOGS_PAGINATION_REQUEST;

  constructor(public payload: Pagination) {}
}
