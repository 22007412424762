import { Action } from '@ngrx/store';
import { Required } from 'utility-types';

import { ActionParams } from './../../../core/models/action-params.model';
import { FileField } from './../../../core/interfaces/file-field.interface';
import { VMOCompany } from './../../../core/models/vmo-company.model';
import { ActionTypes } from '../action-types.enum';

export class UpdateCompanyRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPANY_REQUEST;

  constructor(
    public payload: {
      company: Required<Partial<VMOCompany>, 'id'>;
      files: FileField<File>[];
      params?: ActionParams;
      type?;
    }
  ) {}
}

export class UpdateCompanySuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPANY_SUCCESS;
}

export class UpdateCompanyFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPANY_FAILURE;

  constructor(public payload: Error) {}
}
