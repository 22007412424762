import { UserView } from '@models/user.model';
import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';

export class ChangeUserRoleRequestAction implements Action {
  readonly type = ActionTypes.CHANGE_USER_ROLE_REQUEST;

  constructor(public payload: { user: Partial<UserView>; newRole: string; type?; }) {}
}

export class ChangeUserRoleSuccessAction implements Action {
  readonly type = ActionTypes.CHANGE_USER_ROLE_SUCCESS;
}

export class ChangeUserRoleFailureAction implements Action {
  readonly type = ActionTypes.CHANGE_USER_ROLE_FAILURE;

  constructor(public payload: Error) {}
}
