import { Action } from '@ngrx/store';

import { ActionParams } from './../../../core/models/action-params.model';
import { UserUpdateRequest } from './../../../core/interfaces/user/user-update-request.interface';
import { ActionTypes } from '../action-types.enum';

export class UpdateUserRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_USER_REQUEST;

  constructor(public payload: { model: UserUpdateRequest; userPhoto?; params?: ActionParams }) {}
}

export class UpdateUserSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_USER_SUCCESS;
}

export class UpdateUserFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_USER_FAILURE;

  constructor(public payload: Error) {}
}
