import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { FrontEndRole } from '@models/roles/frontend-role.model';

class CreateFrontEndRole extends GraphQLMutation<FrontEndRole<string>, { createRoleFrontend: Partial<FrontEndRole<string>> }> {
  protected query = `mutation CreateRoleFrontend(
    $input: CreateRoleFrontendInput!
    $condition: ModelRoleFrontendConditionInput
  ) {
    createRoleFrontend(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateFrontEndRole extends GraphQLMutation<Required<Partial<FrontEndRole<string>>, 'id'>, { updateRoleFrontend: Partial<FrontEndRole<string>> }> {
  protected query = `mutation UpdateRoleFrontend(
    $input: UpdateRoleFrontendInput!
    $condition: ModelRoleFrontendConditionInput
  ) {
    updateRoleFrontend(input: $input, condition: $condition) {
      id
    }
  }`;
}

class RemoveFrontEndRole extends GraphQLMutation<Required<Partial<FrontEndRole<string>>, 'id'>, { deleteRoleFrontend: Partial<FrontEndRole<string>> }> {
  protected query = `mutation DeleteRoleFrontend(
    $input: DeleteRoleFrontendInput!
    $condition: ModelRoleFrontendConditionInput
  ) {
    deleteRoleFrontend(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createFrontEndRole = new CreateFrontEndRole();
export const updateFrontEndRole = new UpdateFrontEndRole();
export const removeFrontEndRole = new RemoveFrontEndRole();
