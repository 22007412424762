export interface AuthState {
  isLoading: boolean;
  isAuthenticated: boolean;
  resetSuperAdminPassword: boolean;
}

export const initialState: AuthState = {
  isLoading: false,
  isAuthenticated: false,
  resetSuperAdminPassword: false,
};
