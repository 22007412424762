import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SurveyObject } from '@models/survey/survey-object.model';

export class DeleteSurveyObjectRequestAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_OBJECT_REQUEST;

  constructor(public payload: string) {}
}

export class DeleteSurveyObjectSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_OBJECT_SUCCESS;

  constructor(public payload: SurveyObject[]) {}
}

export class DeleteSurveyObjectFailureAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_OBJECT_FAILURE;

  constructor(public payload: Error) {}
}
