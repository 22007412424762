import {
  createFeatureSelector,
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector,
  MemoizedSelectorWithProps,
} from '@ngrx/store';

import { RoleState } from './state';
import { BackEndRole } from '@models/roles/backend-role.model';
import { FrontEndRole } from '@models/roles/frontend-role.model';

const getIsLoading = (state: RoleState): boolean => state.isLoading;

const getUserPermissions = (state: RoleState): FrontEndRole[] => state.userPermissions;

const getRoleSchema = (state: RoleState): FrontEndRole[] => state.renderingRoleSchema;

const getUserAvailableModules = (state: RoleState): string[] =>
  state.userPermissions.map((permission) => permission.viewName);

const getBackEndRoleList = (state: RoleState): BackEndRole[] => state.backEndRoleList;

const getFrontEndRoleList = (state: RoleState): FrontEndRole[] => state.frontEndRoleList;

const getFrontEndRoleByName = (state: RoleState, roleName: string): FrontEndRole[] =>
  state.frontEndRoleList.filter((role) => role.roleName === roleName);

export const selectUserState: MemoizedSelector<object, RoleState> = createFeatureSelector<RoleState>(
  'role-management'
);

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectUserState,
  getIsLoading
);

export const selectUserPermissions: MemoizedSelector<object, FrontEndRole[]> = createSelector(
  selectUserState,
  getUserPermissions
);

export const selectUserAvailableModules: MemoizedSelector<object, string[]> = createSelector(
  selectUserState,
  getUserAvailableModules
);

export const selectRoleSchema: MemoizedSelector<object, FrontEndRole[]> = createSelector(
  selectUserState,
  getRoleSchema
);

export const selectBackEndRoleList: MemoizedSelector<object, BackEndRole[]> = createSelector(
  selectUserState,
  getBackEndRoleList
);

export const selectFrontEndRoleList: MemoizedSelector<object, FrontEndRole[]> = createSelector(
  selectUserState,
  getFrontEndRoleList
);

export const selectFrontEndRoleByName: MemoizedSelectorWithProps<
  object,
  string,
  FrontEndRole[],
  DefaultProjectorFn<FrontEndRole[]>
> = createSelector(selectUserState, getFrontEndRoleByName);
