import { GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { SalesforceWebConnection } from '@models/salesforce/salesforce-web-connection.model';

class GetSalesForceConnectionWebList extends GraphQLQueryList<GraphQLQueryRequest<SalesforceWebConnection>, { listSalesForceConnectionWebs: { items: SalesforceWebConnection[] } }> {
  protected query = `query ListSalesForceConnectionWebs(
    $filter: ModelSalesForceConnectionWebFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesForceConnectionWebs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
       clientId
       clientSecret
       dynamicBasicToken
       dynamicBasicUrl
       grandType
       id
       password
       token
       urlForAttachment
       urlForAuth
       urlForUpdate
       username
       createdAt
       updatedAt
      }
      nextToken
    }
  }`;
}

export const getSalesForceConnectionWebList = new GetSalesForceConnectionWebList();

