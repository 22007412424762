import { Required } from 'utility-types';

import { UserDTO } from '@models/user.model';
import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { UserInvite } from '@interfaces/user-invite.interface';

class CreateUser extends GraphQLMutation<UserDTO, { createUser: Partial<UserDTO> }> {
  protected query = `mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateUser extends GraphQLMutation<Required<Partial<UserDTO>, 'id'>, { updateUser: Partial<UserDTO> }> {
  protected query = `mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
    }
  }`;
}

class RemoveUser extends GraphQLMutation<Required<Partial<UserDTO>, 'id'>, { deleteUser: Partial<UserDTO> }> {
  protected query = `mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
    }
  }`;
}

class CognitoDisableUser extends GraphQLMutation<Required<{ cognitoUserId: string }, 'cognitoUserId'>, {
  cognitoDisableUser: {
    success: boolean,
    errors: string
  }
}> {
  protected query = `mutation cognitoDisableUser(
    $input: cognitoDisableOrEnableUserInput!
  ) {
    cognitoDisableUser(input: $input) {
      success
      errors
    }
  }`;
}

class CognitoEnableUser extends GraphQLMutation<Required<{ cognitoUserId: string }, 'cognitoUserId'>, {
  cognitoEnableUser: {
    success: boolean,
    errors: string
  }
}> {
  protected query = `mutation cognitoEnableUser(
    $input: cognitoDisableOrEnableUserInput!
  ) {
    cognitoEnableUser(input: $input) {
      success
      errors
    }
  }`;
}

class CognitoSetPassword extends GraphQLMutation<Required<{ cognitoUserId: string, password: string }, 'cognitoUserId'>, {
  cognitoSetPassword: {
    success: boolean,
    errors: string
  }
}> {
  protected query = `mutation CognitoSetPassword(
    $input: cognitoSetPasswordInput!
  ) {
    cognitoSetPassword(input: $input) {
      success
      errors
    }
  }`;
}

class CognitoUpdateUserAttribute extends GraphQLMutation<Required<{
    cognitoUserId: string,
    attributeName: string,
    attributeValue: string
  }, 'cognitoUserId'>,
  {
    cognitoUpdateUserAttribute: {
      success: boolean,
      errors: string
    }
  }
> {
  protected query = `mutation CognitoUpdateUserAttribute(
    $input: cognitoUpdateUserAttributeInput!
  ) {
    cognitoUpdateUserAttribute(input: $input) {
      success
      errors
    }
  }`;
}

class CognitoAddToGroup extends GraphQLMutation<Required<{ cognitoUserId: string, group: string }, 'cognitoUserId'>, {
  cognitoAddToGroup: {
    success: boolean,
    errors: string
  }
}> {
  protected query = `mutation CognitoAddToGroup(
    $input: cognitoAddRemoveFromGroupInput!
  ) {
    cognitoAddToGroup(input: $input) {
      success
      errors
    }
  }`;
}

class CognitoRemoveFromGroup extends GraphQLMutation<Required<{ cognitoUserId: string, group: string }, 'cognitoUserId'>, {
  cognitoRemoveFromGroup: {
    success: boolean,
    errors: string
  }
}> {
  protected query = `mutation CognitoRemoveFromGroup(
    $input: cognitoAddRemoveFromGroupInput!
  ) {
    cognitoRemoveFromGroup(input: $input) {
      success
      errors
    }
  }`;
}

export const createUser = new CreateUser();
export const updateUser = new UpdateUser();
export const removeUser = new RemoveUser();
export const cognitoDisableUser = new CognitoDisableUser();
export const cognitoEnableUser = new CognitoEnableUser();
export const cognitoSetPassword = new CognitoSetPassword();
export const cognitoUpdateUserAttribute = new CognitoUpdateUserAttribute();
export const cognitoAddToGroup = new CognitoAddToGroup();
export const cognitoRemoveFromGroup = new CognitoRemoveFromGroup();
