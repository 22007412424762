import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { Case } from '@models/case/case.model';

class UpdateCase extends GraphQLMutation<Required<Partial<Case>, 'id'>, { updateCase: Partial<Case> }> {
  protected query = `mutation updateCase(
    $input: UpdateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    updateCase(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const updateCase = new UpdateCase();

