export enum CaseStatusEnum {
  draft = 'draft',
  new = 'new',
  onHold = 'onHold',
  cancelled = 'cancelled',
  routed = 'routed',
  published = 'published',
  assigned = 'assigned',
  atRisk = 'atRisk',
  confirmed = 'confirmed',
  declined = 'declined',
  onMyWay = 'onMyWay',
  checkedIn = 'checkedIn',
  checkedOut = 'checkedOut',
  workCompleted = 'workCompleted',
  approved = 'approved',
  paid = 'paid',
  deleted = 'deleted',
  delayed = 'delayed',
  rescheduling = 'rescheduling'
}

export enum CaseStatusLabelEnum {
  draft = 'Draft',
  new = 'New',
  onHold = 'On Hold',
  cancelled = 'Cancelled',
  routed = 'Routed',
  published = 'Published',
  assigned = 'Assigned',
  atRisk = 'At Risk',
  confirmed = 'Confirmed',
  declined = 'Declined',
  onMyWay = 'On My Way',
  checkedIn = 'Checked In',
  checkedOut = 'Checked Out',
  workCompleted = 'Work Completed',
  approved = 'Approved',
  paid = 'Paid',
  deleted = 'Deleted',
  delayed = 'Delayed',
  rescheduling = 'Rescheduling'
}

export enum CaseStatusFiltersEnum {
  new = 'New',
  cancelled = 'Cancelled',
  assigned = 'Assigned',
  confirmed = 'Confirmed',
  declined = 'Declined',
  onMyWay = 'On My Way',
  checkedIn = 'Checked In',
  checkedOut = 'Checked Out',
  workCompleted = 'Work Completed',
  approved = 'Approved',
  paid = 'Paid'
}
