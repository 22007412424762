import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { BellNotification } from '@models/bell-notification.model';
import { Pagination } from '@models/pagination.model';

export class NotificationsPaginationRequestAction implements Action {
  readonly type = ActionTypes.NOTIFICATION_PAGINATION_REQUEST;

  constructor(public payload: Pagination) {}
}

export class NotificationsPaginationSuccessAction implements Action {
  readonly type = ActionTypes.NOTIFICATION_PAGINATION_SUCCESS;

  constructor(public payload: BellNotification[]) {}
}

export class NotificationsPaginationFailureAction implements Action {
  readonly type = ActionTypes.NOTIFICATION_PAGINATION_FAILURE;

  constructor(public payload: Error) {}
}
