export enum UserInviteStatusEnum {
  InvitePending = 'InvitePending',
  InviteAccepted = 'InviteAccepted',
  InviteRejected = 'InviteRejected',
  InviteResent = 'InviteResent',
  RegistrationComplete = 'RegistrationComplete',
  RegistrationPending = 'RegistrationPending',
  Expired = 'Expired',
  Disabled = 'Disabled',
  Reactivated = 'Reactivated',
}

export enum UserInviteStatusTitleEnum {
  InvitePending = 'Invite pending',
  InviteAccepted = 'Invite accepted',
  InviteRejected = 'Invite rejected',
  InviteResent = 'Invite re-sent',
  RegistrationComplete = 'Registration complete',
  RegistrationPending = 'Registration pending',
  Expired = 'Expired',
  Disabled = 'Disabled',
  Reactivated = 'Reactivated',
}
