import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { SurveyQuestion } from '@models/survey/survey-question.model';

class CreateSiteSurveyQuestion extends GraphQLMutation<SurveyQuestion, { createSiteSurveyQuestion: Partial<SurveyQuestion> }> {
  protected query = `mutation CreateSiteSurveyQuestion(
    $input: CreateSiteSurveyQuestionInput!
    $condition: ModelSiteSurveyQuestionConditionInput
  ) {
    createSiteSurveyQuestion(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateSiteSurveyQuestion extends GraphQLMutation<Required<Partial<SurveyQuestion>, 'id'>, { updateSiteSurveyQuestion: Partial<SurveyQuestion> }> {
  protected query = `mutation UpdateSiteSurveyQuestion(
    $input: UpdateSiteSurveyQuestionInput!
    $condition: ModelSiteSurveyQuestionConditionInput
  ) {
    updateSiteSurveyQuestion(input: $input, condition: $condition) {
      id
    }
  }`;
}

class DeleteSiteSurveyQuestion extends GraphQLMutation<Required<Partial<SurveyQuestion>, 'id'>, { deleteSiteSurveyQuestion: Partial<SurveyQuestion> }> {
  protected query = `mutation DeleteSiteSurveyQuestion(
    $input: DeleteSiteSurveyQuestionInput!
    $condition: ModelSiteSurveyQuestionConditionInput
  ) {
    deleteSiteSurveyQuestion(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createSiteSurveyQuestion = new CreateSiteSurveyQuestion();
export const updateSiteSurveyQuestion = new UpdateSiteSurveyQuestion();
export const deleteSiteSurveyQuestion = new DeleteSiteSurveyQuestion();
