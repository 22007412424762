import { Actions } from './actions';
import { ActionTypes } from './action-types.enum';
import { initialState, UserState} from './state';

export function userReducer(state = initialState, action: Actions): UserState {
  switch (action.type) {
    case ActionTypes.USER_PAGINATION_REQUEST:
      return {
        ...state,
        usersPaginationLoading: true
      };

    case ActionTypes.USER_PAGINATION_SUCCESS:
      return {
        ...state,
        usersPaginationLoading: false,
        usersPagination: action.payload
      };

    case ActionTypes.UPDATE_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersPaginationLoading: false,
        usersPagination: { items: action.payload, total: state.usersPagination.total }
      }

    case ActionTypes.USER_AUTHLOG_PAGINATION_REQUEST:
      return {
        ...state,
        userAuthLogPaginationLoading: true
      }

    case ActionTypes.USER_AUTHLOG_PAGINATION_SUCCESS:
      return {
        ...state,
        userAuthLogPaginationLoading: false,
        userAuthLogPagination: action.payload
      }

    case ActionTypes.LOAD_ALL_USERS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUsers: [...action.payload]
      };

    case ActionTypes.LOAD_ALL_INVITES_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        invitedUsers: [...action.payload]
      };

    case ActionTypes.LOAD_CURRENT_USER_SUCCESS:
      return {
        ...state,
        activeUser: action.payload,
        isLoading: false
      };

    case ActionTypes.INVITE_NEW_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userInvites: { ...action.payload }
      };

    case ActionTypes.UPDATE_USER_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.LOAD_USER_VIEW_SUCCESS:
    case ActionTypes.LOAD_USER_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentUserView: action.payload
      };

    case ActionTypes.INVITE_NEW_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        userInvites: { existingUsers: [], invitationsSent: false }
      };

    case ActionTypes.USER_INVITE_PAGINATION_REQUEST:
      return {
        ...state,
        invitesPaginationLoading: true
      }

    case ActionTypes.USER_INVITE_PAGINATION_SUCCESS:
      return {
        ...state,
        invitesPaginationLoading: false,
        invitesPagination: action.payload
      }

    case ActionTypes.SAVE_USER_ADDITIONAL_FILE_SUCCESS:
    case ActionTypes.REMOVE_USER_ADDITIONAL_FILE_SUCCESS:
    case ActionTypes.SAVE_USER_CHECKS_FILES_REQUEST:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.USER_REQUEST_PAGINATION_REQUEST:
      return {
        ...state,
        userRequestsLoading: true
      };

    case ActionTypes.USER_REQUEST_PAGINATION_SUCCESS:
      return {
        ...state,
        userRequestsLoading: false,
        userRequests: action.payload
      };

    case ActionTypes.LOAD_ALL_USERS_LIST_REQUEST:
    case ActionTypes.LOAD_ALL_INVITES_LIST_REQUEST:
    case ActionTypes.LOAD_CURRENT_USER_REQUEST:
    case ActionTypes.CREATE_USER_REQUEST:
    case ActionTypes.UPDATE_USER_REQUEST:
    case ActionTypes.UPDATE_USER_REQUEST_REQUEST:
    case ActionTypes.CREATE_USER_ADDITIONAL_FILE_REQUEST:
    case ActionTypes.DISABLE_USER_REQUEST:
    case ActionTypes.REACTIVATE_USER_REQUEST:
    case ActionTypes.CONFIRM_USER_INVITE_REQUEST:
    case ActionTypes.CHANGE_USER_EMAIL_REQUEST:
    case ActionTypes.SAVE_USER_ADDITIONAL_FILE_REQUEST:
    case ActionTypes.REMOVE_USER_ADDITIONAL_FILE_REQUEST:
    case ActionTypes.SAVE_USER_CHECKS_FILES_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.LOAD_CURRENT_USER_FAILURE:
    case ActionTypes.LOAD_ALL_USERS_LIST_FAILURE:
    case ActionTypes.LOAD_ALL_INVITES_LIST_FAILURE:
    case ActionTypes.CREATE_USER_FAILURE:
    case ActionTypes.CREATE_USER_SUCCESS:
    case ActionTypes.UPDATE_USER_REQUEST_FAILURE:
    case ActionTypes.UPDATE_USER_FAILURE:
    case ActionTypes.UPDATE_USER_SUCCESS:
    case ActionTypes.CREATE_USER_ADDITIONAL_FILE_SUCCESS:
    case ActionTypes.CREATE_USER_ADDITIONAL_FILE_FAILURE:
    case ActionTypes.INVITE_NEW_USER_FAILURE:
    case ActionTypes.DISABLE_USER_SUCCESS:
    case ActionTypes.DISABLE_USER_FAILURE:
    case ActionTypes.REACTIVATE_USER_SUCCESS:
    case ActionTypes.REACTIVATE_USER_FAILURE:
    case ActionTypes.CONFIRM_USER_INVITE_SUCCESS:
    case ActionTypes.CONFIRM_USER_INVITE_FAILURE:
    case ActionTypes.CHANGE_USER_EMAIL_SUCCESS:
    case ActionTypes.CHANGE_USER_EMAIL_FAILURE:
    case ActionTypes.SAVE_USER_CHECKS_FILES_FAILURE:
    case ActionTypes.USER_REQUEST_PAGINATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        invitesPaginationLoading: false,
        userRequestsLoading: false
      };

    default: {
      return state;
    }
  }
}
