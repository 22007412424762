import { FileView } from '@interfaces/file-view.interface';
import { UserInvite } from '@interfaces/user-invite.interface';
import { VMOCompany, VMOCompanyView } from '@models/vmo-company.model';

export interface CompanyState {
  companies: VMOCompany[];
  shownCompany: ShownCompany;
  isLoading: boolean;
  w9IsLoading: boolean;
  createNewUser: boolean;
}

export const initialState: CompanyState = {
  companies: [],
  shownCompany: {
    companyInfo: null,
    insurance: { CertificateInsurance: null },
    w9Form: null,
    additionalFiles: [],
    invitedUsers: [],
  },
  isLoading: false,
  w9IsLoading: false,
  createNewUser: false,
};

export interface ShownCompany {
  companyInfo: VMOCompanyView | null;
  insurance: { CertificateInsurance: FileView };
  w9Form: FileView;
  additionalFiles: FileView[];
  invitedUsers: UserInvite[];
}
