export enum ActionTypes {
  LOAD_BELL_NOTIFICATIONS_REQUEST = '[NOTIFICATION] Load Bell Notifications Request',
  LOAD_BELL_NOTIFICATIONS_SUCCESS = '[NOTIFICATION] Load Bell Notifications Success',
  LOAD_BELL_NOTIFICATIONS_FAILURE = '[NOTIFICATION] Load Bell Notifications Failure',

  NOTIFICATION_PAGINATION_REQUEST = '[NOTIFICATION] Notification Pagination Request',
  NOTIFICATION_PAGINATION_SUCCESS = '[NOTIFICATION] Notification Pagination Success',
  NOTIFICATION_PAGINATION_FAILURE = '[NOTIFICATION] Notification Pagination Failure',

  NOTIFICATION_UPDATE_REQUEST = '[NOTIFICATION] Notification Update Request',
  NOTIFICATION_UPDATE_SUCCESS = '[NOTIFICATION] Notification Update Success',
  NOTIFICATION_UPDATE_FAILURE = '[NOTIFICATION] Notification Update Failure'
}
