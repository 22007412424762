import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CompanyEffects } from './effects';
import { companyReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('vmo-company', companyReducer),
    EffectsModule.forFeature([CompanyEffects]),
  ],
  providers: [CompanyEffects],
})
export class CompanyStoreModule {}
