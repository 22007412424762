import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { AmplifyAuthError } from './../../../core/interfaces/amplify-auth-error.interface';

export class ResetPasswordRequestAction implements Action {
  readonly type = ActionTypes.RESET_PASSWORD_REQUEST;

  constructor(public payload: string) {}
}

export class ResetPasswordSuccessAction implements Action {
  readonly type = ActionTypes.RESET_PASSWORD_SUCCESS;
}

export class ResetPasswordFailureAction implements Action {
  readonly type = ActionTypes.RESET_PASSWORD_FAILURE;

  constructor(public payload: AmplifyAuthError) {}
}
