import { Action } from '@ngrx/store';
import { Required } from 'utility-types';

import { ActionTypes } from '../../action-types.enum';
import { SurveyObject } from '@models/survey/survey-object.model';
import { ActionParams } from '@models/action-params.model';

export class UpdateSurveyObjectRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_SURVEY_OBJECT_REQUEST;

  constructor(public payload: { model: Required<Partial<SurveyObject>, 'id'>; params?: ActionParams }) {}
}

export class UpdateSurveyObjectSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SURVEY_OBJECT_SUCCESS;
}

export class UpdateSurveyObjectFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_SURVEY_OBJECT_FAILURE;

  constructor(public payload: Error) {}
}
