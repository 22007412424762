import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of, combineLatest } from 'rxjs';

import { SnackBarService } from '@services/snackbar.service';
import { RoleHelperService } from '@services/helpers/role-helper.service';

import { searchBackEndRoles } from '@graphql/backend-roles/queries';
import { getFrontEndRoleList } from '@graphql/frontend-roles/queries';
import { FrontEndRole } from '@models/roles/frontend-role.model';
import { defaultRoleTemplateName } from '@app/constants';
import { ActionTypes } from './action-types.enum';
import * as RoleActions from './role-actions';
import * as RoleSelectors from './selectors';
import { RootStore } from '@store/index';

@Injectable()
export class RoleEffects {
  constructor(
    private readonly store$: Store<RootStore.AppState>,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly snackbar: SnackBarService,
    private readonly roleHelper: RoleHelperService,
  ) {}

  @Effect()
  loadBackendRoleListEffect$: Observable<Action> = this.actions$.pipe(
    ofType<RoleActions.LoadBackEndRoleListRequestAction>(ActionTypes.LOAD_BACKEND_ROLE_LIST_REQUEST),
    switchMap(() =>
      searchBackEndRoles.execute({ filter: { frontRoleName: { ne: defaultRoleTemplateName } } })
      .then(res => res?.data?.searchRoleBackends?.items)
    ),
    map((roles) => new RoleActions.LoadBackEndRoleListSuccessAction(roles)),
    catchError((error) => of(new RoleActions.LoadBackEndRoleListFailureAction(error)))
  );

  @Effect()
  loadFrontendRoleListEffect$: Observable<Action> = this.actions$.pipe(
    ofType<RoleActions.LoadFrontEndRoleListRequestAction>(ActionTypes.LOAD_FRONTEND_ROLE_LIST_REQUEST),
    switchMap(() => getFrontEndRoleList.execute()),
    map((response) => {
      const roles: FrontEndRole[] = response?.data?.listRoleFrontends?.items?.map((item) => ({
        ...item,
        roleBlock: JSON.parse(item.roleBlock),
        id: item.roleName === defaultRoleTemplateName ? null : item.id,
      }));
      return new RoleActions.LoadFrontEndRoleListSuccessAction(roles);
    }),
    catchError((error) => of(new RoleActions.LoadFrontEndRoleListFailureAction(error)))
  );

  @Effect()
  createEmptyRoleTemplateEffect$: Observable<Action> = this.actions$.pipe(
    ofType<RoleActions.LoadEmptyRoleTemplateRequestAction>(ActionTypes.LOAD_EMPTY_ROLE_TEMPLATE_REQUEST),
    switchMap(() => this.roleHelper.fetchFrontEndRole(defaultRoleTemplateName)),
    map((template) => new RoleActions.LoadEmptyRoleTemplateSuccessAction(template)),
    catchError((error) => of(new RoleActions.LoadEmptyRoleTemplateFailureAction(error)))
  );

  @Effect()
  loadUserPermissionsEffect$: Observable<Action> = this.actions$.pipe(
    ofType<RoleActions.LoadUserPermissionsRequestAction>(ActionTypes.LOAD_USER_PERMISSIONS_REQUEST),
    // switchMap(() => this.store$.select(UserSelectors.selectUser)),
    // switchMap((user) => this.roleHelper.fetchFrontEndRole(user.role)),
    // map((permissions) => new RoleActions.LoadUserPermissionsSuccessAction(permissions)),
    catchError((error) => of(new RoleActions.LoadUserPermissionsFailureAction(error)))
  );

  @Effect()
  createRoleEffect$: Observable<Action> = this.actions$.pipe(
    ofType<RoleActions.CreateRoleRequestAction>(ActionTypes.CREATE_ROLE_REQUEST),
    switchMap(({ payload }) => this.roleHelper.createNewRole(payload.roleName, payload.model)),
    map(() => {
      this.snackbar.success('New role has been created successfully');
      this.router.navigate(['role-management']);
      return new RoleActions.CreateRoleSuccessAction();
    }),
    catchError((error) => of(new RoleActions.CreateRoleFailureAction(error)))
  );

  @Effect()
  updateRoleEffect$: Observable<Action> = this.actions$.pipe(
    ofType<RoleActions.UpdateRoleRequestAction>(ActionTypes.UPDATE_ROLE_REQUEST),
    withLatestFrom(this.store$.select(RoleSelectors.selectFrontEndRoleList)),
    switchMap(([{ payload }, frontEndRoles]) => {
      const toCreate: FrontEndRole[] = [];
      const toUpdate: FrontEndRole[] = [];
      const toDelete: FrontEndRole[] = [];

      const oldRoleName = payload.backEndRole.frontRoleName;
      let existedFrontEndRoles = frontEndRoles.filter((role) => role.roleName === oldRoleName);

      payload.frontendRoles.forEach((role) => {
        if (!role.id) {
          toCreate.push(role);
        }

        if (existedFrontEndRoles.some((item) => item.id === role.id)) {
          toUpdate.push(role);
          existedFrontEndRoles = existedFrontEndRoles.filter((item) => item.id !== role.id);
        }
      });
      toDelete.push(...existedFrontEndRoles);

      const requests = [];

      if (toCreate.length) {
        requests.push(this.roleHelper.createFrontEndRole(toCreate, payload.roleName, payload.backEndRole.id));
      }
      if (toUpdate.length) {
        requests.push(this.roleHelper.updateFrontEndRole(toUpdate, payload.roleName, payload.backEndRole.id));
      }
      if (toDelete.length) {
        requests.push(this.roleHelper.deleteFrontEndRole(toDelete));
      }

      return combineLatest([
        ...requests,
        this.roleHelper.updateBackendRole(payload.roleName, payload.frontendRoles, payload.backEndRole),
      ]);
    }),
    map(() => {
      this.snackbar.success('Role has been updated successfully');
      this.router.navigate(['role-management']);
      return new RoleActions.UpdateRoleSuccessAction();
    }),
    catchError((error) => of(new RoleActions.UpdateRoleFailureAction(error)))
  );

  @Effect()
  rolePaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<RoleActions.RolePaginationRequestAction>(ActionTypes.ROLE_PAGINATION_REQUEST),
    switchMap(({ payload }) =>
      searchBackEndRoles
        .execute({ ...payload, filter: { frontRoleName: { ne: defaultRoleTemplateName } } })
        .then((res) => res?.data?.searchRoleBackends?.items)
    ),
    map((roles) => new RoleActions.LoadBackEndRoleListSuccessAction(roles)),
    catchError((error) => of(new RoleActions.LoadBackEndRoleListFailureAction(error)))
  );

  @Effect({ dispatch: false })
  roleErrorsEffect$: Observable<void> = this.actions$.pipe(
    ofType<Action & { payload: Error }>(
      ActionTypes.LOAD_EMPTY_ROLE_TEMPLATE_FAILURE,
      ActionTypes.LOAD_USER_PERMISSIONS_FAILURE,
      ActionTypes.CREATE_ROLE_FAILURE,
      ActionTypes.UPDATE_ROLE_FAILURE
    ),
    map((action) => {
      console.log('action: ', action);
      this.snackbar.error(action.payload.message);
    })
  );
}
