import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { SurveyTemplate } from '@models/survey/survey-template.model';

class CreateSiteSurveyTemplate extends GraphQLMutation<SurveyTemplate, { createSiteSurveyTemplate: Partial<SurveyTemplate> }> {
  protected query = `mutation CreateSiteSurveyTemplate(
    $input: CreateSiteSurveyTemplateInput!
    $condition: ModelSiteSurveyTemplateConditionInput
  ) {
    createSiteSurveyTemplate(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateSiteSurveyTemplate extends GraphQLMutation<Required<Partial<SurveyTemplate>, 'id'>, { updateSiteSurveyTemplate: Partial<SurveyTemplate> }> {
  protected query = `mutation UpdateSiteSurveyTemplate(
    $input: UpdateSiteSurveyTemplateInput!
    $condition: ModelSiteSurveyTemplateConditionInput
  ) {
    updateSiteSurveyTemplate(input: $input, condition: $condition) {
      id
    }
  }`;
}

class DeleteSiteSurveyTemplate extends GraphQLMutation<Required<Partial<SurveyTemplate>, 'id'>, { deleteSiteSurveyTemplate: Partial<SurveyTemplate> }> {
  protected query = `mutation DeleteSiteSurveyTemplate(
    $input: DeleteSiteSurveyTemplateInput!
    $condition: ModelSiteSurveyTemplateConditionInput
  ) {
    deleteSiteSurveyTemplate(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createSurveyTemplate = new CreateSiteSurveyTemplate();
export const updateSurveyTemplate = new UpdateSiteSurveyTemplate();
export const deleteSurveyTemplate = new DeleteSiteSurveyTemplate();
