import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CaseEffects } from './effects';
import { caseReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('case-store', caseReducer),
    EffectsModule.forFeature([CaseEffects]),
  ],
  providers: [CaseEffects],
})
export class CaseStoreModule {}
