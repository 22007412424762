import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { FrontEndRole } from '@models/roles/frontend-role.model';

export class LoadFrontEndRoleListRequestAction implements Action {
  readonly type = ActionTypes.LOAD_FRONTEND_ROLE_LIST_REQUEST;
}

export class LoadFrontEndRoleListSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_FRONTEND_ROLE_LIST_SUCCESS;

  constructor(public payload: FrontEndRole[]) {}
}

export class LoadFrontEndRoleListFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FRONTEND_ROLE_LIST_FAILURE;

  constructor(public payload: Error) {}
}
