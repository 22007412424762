import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { AdditionalExpense } from '@models/case/additional-expense.model';

export class LoadAdditionalExpensesListRequestAction implements Action {
  readonly type = ActionTypes.LOAD_ADDITIONAL_EXPENSES_LIST_REQUEST;
}

export class LoadAdditionalExpensesListSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ADDITIONAL_EXPENSES_LIST_SUCCESS;

  constructor(public payload: AdditionalExpense[]) {}
}

export class LoadAdditionalExpensesListFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ADDITIONAL_EXPENSES_LIST_FAILURE;

  constructor(public payload: Error) {}
}
