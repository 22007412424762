import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { BellNotification } from '@models/bell-notification.model';
import { ActionParams } from '@models/action-params.model';

export class NotificationsUpdateRequestAction implements Action {
  readonly type = ActionTypes.NOTIFICATION_UPDATE_REQUEST;

  constructor(public payload: { updateData: BellNotification[], params?: ActionParams }) {}
}

export class NotificationsUpdateSuccessAction implements Action {
  readonly type = ActionTypes.NOTIFICATION_UPDATE_SUCCESS;

  constructor(public payload: BellNotification[]) {}
}

export class NotificationsUpdateFailureAction implements Action {
  readonly type = ActionTypes.NOTIFICATION_UPDATE_FAILURE;

  constructor(public payload: Error) {}
}
