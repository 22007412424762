import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { TeamAdditionalFile } from '@models/additional-file.model';

class CreateTeamFile extends GraphQLMutation<TeamAdditionalFile, { createTeamAdditionalFiles: Partial<TeamAdditionalFile> }> {
  protected query = `mutation CreateTeamAdditionalFiles(
    $input: CreateTeamAdditionalFilesInput!
    $condition: ModelTeamAdditionalFilesConditionInput
  ) {
    createTeamAdditionalFiles(input: $input, condition: $condition) {
      id
      image {
        bucket
        key
        name
        region
      }
    }
  }
  `;
}

class UpdateTeamFile extends GraphQLMutation<Required<Partial<TeamAdditionalFile>, 'id'>, { updateTeamAdditionalFiles: Partial<TeamAdditionalFile> }> {
  protected query = `mutation UpdateTeamAdditionalFiles(
    $input: UpdateTeamAdditionalFilesInput!
    $condition: ModelTeamAdditionalFilesConditionInput
  ) {
    updateTeamAdditionalFiles(input: $input, condition: $condition) {
      id
    }
  }`;
}

class RemoveTeamFile extends GraphQLMutation<Required<Partial<TeamAdditionalFile>, 'id'>, { deleteTeamAdditionalFiles: Partial<TeamAdditionalFile> }> {
  protected query = `mutation DeleteTeamAdditionalFiles(
    $input: DeleteTeamAdditionalFilesInput!
    $condition: ModelTeamAdditionalFilesConditionInput
  ) {
    deleteTeamAdditionalFiles(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createTeamFile = new CreateTeamFile();
export const updateTeamFile = new UpdateTeamFile();
export const removeTeamFile = new RemoveTeamFile();
