import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SiteSurvey } from '@models/survey/site-survey.model';
import { GraphQLFilter } from '@models/graphql/graphql-filter.model';

export class LoadSiteSurveyListRequestAction implements Action {
  readonly type = ActionTypes.LOAD_SITE_SURVEY_LIST_REQUEST;

  constructor(public payload?: { filter?: GraphQLFilter; searchLoading?: boolean }) {}
}

export class LoadSiteSurveyListSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SITE_SURVEY_LIST_SUCCESS;

  constructor(public payload: SiteSurvey[]) {}
}

export class LoadSiteSurveyListFailureAction implements Action {
  readonly type = ActionTypes.LOAD_SITE_SURVEY_LIST_FAILURE;

  constructor(public payload: Error) {}
}
