import { Required } from 'utility-types';
import { UserRequest } from '@interfaces/user-request.interface';
import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';

class CreateUserRequest extends GraphQLMutation<UserRequest, { createUserRequest: Partial<UserRequest> }> {
  protected query = `mutation CreateUserRequest(
    $input: CreateUserRequestInput!
    $condition: ModelUserRequestConditionInput
  ) {
    createUserRequest(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateUserRequest extends GraphQLMutation<Required<Partial<UserRequest>, 'id'>, { updateUserRequest: Partial<UserRequest> }> {
  protected query = `mutation UpdateUserRequest(
    $input: UpdateUserRequestInput!
    $condition: ModelUserRequestConditionInput
  ) {
    updateUserRequest(input: $input, condition: $condition) {
      id
    }
  }`;
}

class DeleteUserRequest extends GraphQLMutation<Required<Partial<UserRequest>, 'id'>, { deleteUserRequest: Partial<UserRequest> }> {
  protected query = `mutation DeleteUserRequest(
    $input: DeleteUserRequestInput!
    $condition: ModelUserRequestConditionInput
  ) {
    deleteUserRequest(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createUserRequest = new CreateUserRequest();
export const updateUserRequest = new UpdateUserRequest();
export const deleteUserRequest = new DeleteUserRequest();
