import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CookieService {
  public getCookie(name: string) {
    const matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  public setCookie(name: string, value: string): void {
    const cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    document.cookie = cookie;
  }

  public deleteCookie(name: string): void {
    this.setCookie(name, '');
  }
}
