import { VMOCompany } from './vmo-company.model';
import { W9VMOCompanyTypeOrderEnum } from './../enums/w9-vmo-company-type-order.enum';

export class W9FormRequest {
  'topmostSubform[0].Page1[0].f1_1[0]': string;
  'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[0]': boolean = false;
  'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[1]': boolean = false;
  'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[2]': boolean = false;
  'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[3]': boolean = false;
  'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[4]': boolean = false;
  'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[5]': boolean = false;
  'topmostSubform[0].Page1[0].FederalClassification[0].f1_3[0]': string;
  'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[6]': boolean = false;
  'topmostSubform[0].Page1[0].FederalClassification[0].f1_4[0]': string;
  'topmostSubform[0].Page1[0].Address[0].f1_7[0]': string;
  'topmostSubform[0].Page1[0].Address[0].f1_8[0]': string;
  'topmostSubform[0].Page1[0].SSN[0].f1_11[0]': string;
  'topmostSubform[0].Page1[0].SSN[0].f1_12[0]': string;
  'topmostSubform[0].Page1[0].SSN[0].f1_13[0]': string;
  'topmostSubform[0].Page1[0].EmployerID[0].f1_14[0]': string;
  'topmostSubform[0].Page1[0].EmployerID[0].f1_15[0]': string;
  'signature': { name: string; date: string };

  constructor(source: Partial<VMOCompany>) {
    if (!source) return this;

    this.setMainData(source);
    this.setCompanyType(source.type);
    this.setTINNumber(source);
  }

  private setMainData(source: Partial<VMOCompany>): void {
    this['topmostSubform[0].Page1[0].f1_1[0]'] = source.name || '';
    this['topmostSubform[0].Page1[0].Address[0].f1_7[0]'] = [source.Address1, source.Address2].filter(i => !!i).join(', ');
    this['topmostSubform[0].Page1[0].Address[0].f1_8[0]'] = [source.City, source.State, source.ZIPCode].filter(i => !!i).join(', ');
    if (source.signed) {
      this.signature = {
        name: source.signValue,
        date: source.signDate
      };
    }
  }

  private setCompanyType(type: string): void {
    if (!type) return;
    const LLCCompanyTypes = ['LLC C', 'LLC S', 'LLC P'];

    if (LLCCompanyTypes.includes(type)) {
      this['topmostSubform[0].Page1[0].FederalClassification[0].c1_1[5]'] = true;
      this['topmostSubform[0].Page1[0].FederalClassification[0].f1_3[0]'] = type.split(' ').pop();
    } else  {
      this[`topmostSubform[0].Page1[0].FederalClassification[0].c1_1[${W9VMOCompanyTypeOrderEnum[type]}]`] = true;
    }
  }

  private setTINNumber(source: Partial<VMOCompany>): void {
    const value = String(source.TaxID).replace(/-/g, '');
    if (source.taxType === 'socialId') {
        this['topmostSubform[0].Page1[0].SSN[0].f1_11[0]'] = value.slice(0, 3);
        this['topmostSubform[0].Page1[0].SSN[0].f1_12[0]'] = value.slice(3, 5);
        this['topmostSubform[0].Page1[0].SSN[0].f1_13[0]'] = value.slice(5);
    }

    if (source.taxType === 'employerId') {
        this['topmostSubform[0].Page1[0].EmployerID[0].f1_14[0]'] = value.slice(0, 2);
        this['topmostSubform[0].Page1[0].EmployerID[0].f1_15[0]'] = value.slice(2);
    }
  }
}
