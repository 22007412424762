import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { AdditionalExpense } from '@models/case/additional-expense.model';
import { ActionParams } from '@models/action-params.model';
import { Required } from 'utility-types';


export class DeleteAdditionalExpenseObjectRequestAction implements Action {
  readonly type = ActionTypes.DELETE_ADDITIONAL_EXPENSES_OBJECT_REQUEST;

  constructor(public payload: { id: string, params?: ActionParams }) {}
}

export class DeleteAdditionalExpenseObjectSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_ADDITIONAL_EXPENSES_OBJECT_SUCCESS;

  constructor(public payload: AdditionalExpense[]) {}
}

export class DeleteAdditionalExpenseObjectFailureAction implements Action {
  readonly type = ActionTypes.DELETE_ADDITIONAL_EXPENSES_OBJECT_FAILURE;

  constructor(public payload: Error) {}
}

export class CreateAdditionalExpenseItemRequestAction implements Action {
  readonly type = ActionTypes.CREATE_CASE_ADDITIONAL_EXPENSE_ITEM_REQUEST;

  constructor(public payload: { model: AdditionalExpense, params?: ActionParams }) {}
}

export class CreateAdditionalExpenseItemSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_CASE_ADDITIONAL_EXPENSE_ITEM_SUCCESS;
}

export class CreateAdditionalExpenseItemFailureAction implements Action {
  readonly type = ActionTypes.CREATE_CASE_ADDITIONAL_EXPENSE_ITEM_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateAdditionalExpenseItemRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_ADDITIONAL_EXPENSE_ITEM_REQUEST;

  constructor(public payload: { model: Required<Partial<AdditionalExpense>, 'id'>, params?: ActionParams }) {}
}

export class UpdateAdditionalExpenseItemSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_ADDITIONAL_EXPENSE_ITEM_SUCCESS;
}

export class UpdateAdditionalExpenseItemFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_ADDITIONAL_EXPENSE_ITEM_FAILURE;

  constructor(public payload: Error) {}
}
