import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { FileView } from './../../../core/interfaces/file-view.interface';
import { ActionParams } from '@models/action-params.model';

export class SaveUserAdditionalFileRequestAction implements Action {
  readonly type = ActionTypes.SAVE_USER_ADDITIONAL_FILE_REQUEST;

  constructor(public payload: { files: File[]; id: string; salesforceId?; params?: ActionParams }) {}
}

export class SaveUserAdditionalFileSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_USER_ADDITIONAL_FILE_SUCCESS;

  constructor(public payload: FileView[]) {}
}

export class SaveUserAdditionalFileFailureAction implements Action {
  readonly type = ActionTypes.SAVE_USER_ADDITIONAL_FILE_FAILURE;

  constructor(public payload: Error) {}
}
