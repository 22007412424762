import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { ActionParams } from '@models/action-params.model';

export class ChangeUserEmailRequestAction implements Action {
  readonly type = ActionTypes.CHANGE_USER_EMAIL_REQUEST;

  constructor(public payload: { cognitoId: string, oldEmail: string, newEmail: string, params?: ActionParams }) {}
}

export class ChangeUserEmailSuccessAction implements Action {
  readonly type = ActionTypes.CHANGE_USER_EMAIL_SUCCESS;
}

export class ChangeUserEmailFailureAction implements Action {
  readonly type = ActionTypes.CHANGE_USER_EMAIL_FAILURE;

  constructor(public payload: Error) {}
}
