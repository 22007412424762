import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';

export class SaveCaseAdditionalFileRequestAction implements Action {
  readonly type = ActionTypes.SAVE_CASE_ADDITIONAL_FILE_REQUEST;

  constructor(public payload: { files: File[]; id: string; salesforceId: string; }) {}
}

export class SaveCaseAdditionalFileSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_CASE_ADDITIONAL_FILE_SUCCESS;
}

export class SaveCaseAdditionalFileFailureAction implements Action {
  readonly type = ActionTypes.SAVE_CASE_ADDITIONAL_FILE_FAILURE;

  constructor(public payload: Error) {}
}
