import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';

export class InviteNewUserRequestAction implements Action {
  readonly type = ActionTypes.INVITE_NEW_USER_REQUEST;

  constructor(public payload: {
    email: string;
    role: string,
    firstname?: string,
    lastname?: string,
    phone?: string,
    client?: string
  }[]) {}
}

export class InviteNewUserSuccessAction implements Action {
  readonly type = ActionTypes.INVITE_NEW_USER_SUCCESS;

  constructor(public payload: { existingUsers: string[]; invitationsSent: boolean }) {}
}

export class InviteNewUserFailureAction implements Action {
  readonly type = ActionTypes.INVITE_NEW_USER_FAILURE;

  constructor(public payload: Error) {}
}
