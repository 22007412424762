import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  horizontalPosition,
  verticalPosition,
  snackBarDuration
} from '../../constants';
import { SnackBarTypeEnum } from '../enums/snackbar-type.enum';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  constructor(private readonly snackBar: MatSnackBar) {}

  public success(message: string): void {
    this.open(message, SnackBarTypeEnum.Success);
  }

  public reportGenerated(message: string, cb: () => void) {
    this.snackBar.open(message, 'Download', {
      duration: 15000,
      horizontalPosition,
      verticalPosition,
      panelClass: SnackBarTypeEnum.Success
    }).onAction().subscribe(() => cb());
  }

  public warning(message: string): void {
    this.open(message, SnackBarTypeEnum.Warning);
  }

  public error(message: string): void {
    this.open(message, SnackBarTypeEnum.Error);
  }

  private open(message: string, panelClass: string): void {
    this.snackBar.open(message, 'Close', {
      duration: snackBarDuration,
      horizontalPosition,
      verticalPosition,
      panelClass
    });
  }
}
