import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { IDBSurveyObject } from '@interfaces/indexed-db/idb-survey-object.interface';

export class DeleteSurveyDataFromIndexedDBRequestAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_DATA_FROM_INDEXED_DB_REQUEST;

  constructor(public payload: string[]) {}
}

export class DeleteSurveyDataFromIndexedDBSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_DATA_FROM_INDEXED_DB_SUCCESS;

  constructor(public payload: IDBSurveyObject[]) {}
}

export class DeleteSurveyDataFromIndexedDBFailureAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_DATA_FROM_INDEXED_DB_FAILURE;

  constructor(public payload: Error) {}
}
