import { Required } from 'utility-types';

import { BellNotification } from '@models/bell-notification.model';
import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';

class UpdateProfileNotification extends GraphQLMutation<Required<Partial<BellNotification>, 'id'>, { updateProfileNotification: Partial<BellNotification> }> {
  protected query = `mutation UpdateProfileNotification(
    $input: UpdateProfileNotificationInput!,
    $condition: ModelProfileNotificationConditionInput
  ) {
    updateProfileNotification(input: $input, condition: $condition) {
      id
      targetUserId
    }
  }`;
}

export const updateProfileNotification = new UpdateProfileNotification();
