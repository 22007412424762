import { Action } from '@ngrx/store';

import { AmplifyAuthError } from '@interfaces/amplify-auth-error.interface';
import { ActionTypes } from '../action-types.enum';

export class RequestResendSignUpRequestAction implements Action {
  readonly type = ActionTypes.REQUEST_RESEND_SIGN_UP_REQUEST;

  constructor(public payload: { email: string; cognitoId: string }) {}
}

export class RequestResendSignUpSuccessAction implements Action {
  readonly type = ActionTypes.REQUEST_RESEND_SIGN_UP_SUCCESS;
}

export class RequestResendSignUpFailureAction implements Action {
  readonly type = ActionTypes.REQUEST_RESEND_SIGN_UP_FAILURE;

  constructor(public payload: AmplifyAuthError) {}
}
