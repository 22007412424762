import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { CaseComment } from '@models/case/case-comment.model';
import { Required } from 'utility-types';

class CreateCaseComment extends GraphQLMutation<CaseComment, { createCaseComment: Partial<CaseComment> }> {
  protected query = `mutation CreateCaseComment(
    $input: CreateCaseCommentInput!
    $condition: ModelCaseCommentConditionInput
  ) {
    createCaseComment(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateCaseComment extends GraphQLMutation<Required<Partial<CaseComment>, 'id'>, { updateCaseComment: Partial<CaseComment> }> {
  protected query = `mutation updateCaseComment(
    $input: UpdateCaseCommentInput!
    $condition: ModelCaseCommentConditionInput
  ) {
    updateCaseComment(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const updateCaseComment = new UpdateCaseComment();
export const createCaseComment = new CreateCaseComment();

