import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { UserRequest } from './../../../core/interfaces/user-request.interface';
import { AmplifyAuthError } from './../../../core/interfaces/amplify-auth-error.interface';

export class SendRequestRequestAction implements Action {
  readonly type = ActionTypes.SEND_REQUEST_REQUEST;

  constructor(public payload: UserRequest) {}
}

export class SendRequestSuccessAction implements Action {
  readonly type = ActionTypes.SEND_REQUEST_SUCCESS;
}

export class SendRequestFailureAction implements Action {
  readonly type = ActionTypes.SEND_REQUEST_FAILURE;

  constructor(public payload: AmplifyAuthError) {}
}
