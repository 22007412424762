export class GraphQLFilter {
  filter: {
    [key: string]: {
      [key: string]: any;
    };
  };

  constructor(
    filterBy: string,
    value: unknown,
    filterType: GraphQLFilterType = 'eq',
    public nextToken: string = null,
    public limit: number = 10,
    public from: number = 0
  ) {
    this.filter = {
      [filterBy]: {
        [filterType]: value
      }
    };
  }
}

declare type GraphQLFilterType =
  | 'ne'
  | 'eq'
  | 'le'
  | 'lt'
  | 'ge'
  | 'gt'
  | 'contains'
  | 'notContains'
  | 'between'
  | 'beginsWith'
  | 'match'
  | 'matchPhrase'
  | 'matchPhrasePrefix'
  | 'multiMatch'
  | 'exists'
  | 'wildcard'
  | 'regexp'
  | 'range';
