import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { VMOCompany, VMOCompanyView } from '@models/vmo-company.model';

class GetCompanyById extends GraphQLQueryById<{ id: string }, { getTeam: VMOCompanyView }> {
  protected query = `query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      type
      status
      Address1
      Address2
      City
      State
      ZIPCode
      Country
      Region
      IsUnion
      Website
      salesforceId
      TaxID
      AgreementDate
      AgreementUser
      CreatedByName
      CreatedByEmail
      IP
      taxType
      ProvideInsurance
      CertificateInsurance {
        bucket
        region
        key
      }
      W9Form {
        bucket
        region
        key
        name
      }
      InsuranceRenewalDate
      MainPhone
      FirstName
      LastName
      Phone
      MainEmail
      email
      ESignUserAgreement
      ESignServicesAgreement
      AccountsPayableContactFirstName
      AccountsPayableContactLastName
      AccountsPayableContactPhone
      AccountsPayableContactEmail
      PaymentMethod
      BankName
      RoutingNumber
      AccountNumber
      AccountName
      InsuranceCoverage
      SWIFTCode
      IBANNumber
      BankAddress
      SortCode
      signed
      signValue
      signDate
      tools
      services
      ServiceArea
      users {
        items {
          id
          email
          role
          inviteDatetime
        }
        nextToken
      }
      teamAdditionalFiles {
        items {
          salesforceId
          id
          image {
            region
            name
            key
            bucket
          }
        }
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
      createdDate
    }
  }`;
}

class GetCompanyList extends GraphQLQueryList<GraphQLQueryRequest<Partial<VMOCompany>>, { listTeams: { items: VMOCompany[] } }> {
  protected query = `query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        salesforceId
        status
        Address1
        Address2
        City
        State
        ZIPCode
        Country
        IsUnion
        Website
        TaxID
        AgreementDate
        AgreementUser
        CreatedByName
        CreatedByEmail
        IP
        taxType
        ProvideInsurance
        CertificateInsurance {
          bucket
          region
          key
        }
        W9Form {
          bucket
          region
          key
          name
        }
        InsuranceRenewalDate
        MainPhone
        FirstName
        LastName
        Phone
        MainEmail
        email
        ESignUserAgreement
        ESignServicesAgreement
        AccountsPayableContactFirstName
        AccountsPayableContactLastName
        AccountsPayableContactPhone
        AccountsPayableContactEmail
        PaymentMethod
        BankName
        RoutingNumber
        AccountNumber
        AccountName
        InsuranceCoverage
        SWIFTCode
        IBANNumber
        BankAddress
        SortCode
        tools
        services
        ServiceArea
        users {
          items {
            id
            email
            role
            inviteDatetime
          }
          nextToken
        }
        teamAdditionalFiles {
          items {
            image {
              region
              name
              key
              bucket
            }
          }
        }
        createdAt
        updatedAt
        createdBy
        updatedBy
        createdDate
      }
      nextToken
    }
  }`;
}

class GetExistingCompanyNames extends GraphQLQueryList<GraphQLQueryRequest<Partial<VMOCompany>>, { listTeams: { items: Partial<VMOCompany>[] } }> {
  protected query = `query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }`;
}

class SearchCompanies extends GraphQLQueryList<GraphQLQueryRequest<VMOCompany>, { searchTeams: { items: VMOCompany[] } }> {
  protected query = `query SearchTeams(
    $filter: SearchableTeamFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableTeamSortInput
  ) {
    searchTeams(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        name
        type
        status
        Address1
        Address2
        City
        salesforceId
        State
        ZIPCode
        Country
        IsUnion
        Website
        TaxID
        AgreementDate
        AgreementUser
        CreatedByName
        CreatedByEmail
        IP
        taxType
        ProvideInsurance
        CertificateInsurance {
          bucket
          region
          key
        }
        W9Form {
          bucket
          region
          key
          name
        }
        InsuranceRenewalDate
        MainPhone
        FirstName
        LastName
        Phone
        MainEmail
        email
        ESignUserAgreement
        ESignServicesAgreement
        AccountsPayableContactFirstName
        AccountsPayableContactLastName
        AccountsPayableContactPhone
        AccountsPayableContactEmail
        PaymentMethod
        BankName
        RoutingNumber
        AccountNumber
        AccountName
        InsuranceCoverage
        SWIFTCode
        IBANNumber
        BankAddress
        SortCode
        tools
        services
        ServiceArea
        users {
          items {
            id
            email
            role
            inviteDatetime
          }
          nextToken
        }
        teamAdditionalFiles {
          items {
            image {
              region
              name
              key
              bucket
            }
          }
        }
        createdAt
        updatedAt
        createdBy
        updatedBy
        createdDate
      }
      nextToken
    }
  }`;
}

export const getCompanyById = new GetCompanyById();
export const getCompanyList = new GetCompanyList();
export const getExistingCompanyNames = new GetExistingCompanyNames();
export const searchCompanies = new SearchCompanies();
