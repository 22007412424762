import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { FileView } from './../../../core/interfaces/file-view.interface';
import { ActionParams } from '@models/action-params.model';

export class SaveUserChecksFilesRequestAction implements Action {
  readonly type = ActionTypes.SAVE_USER_CHECKS_FILES_REQUEST;

  constructor(public payload: { files: File[]; userInfo; salesforceId?; type?; params?: ActionParams;  }) {}
}

export class SaveUserChecksFilesSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_USER_CHECKS_FILES_SUCCESS;

  constructor(public payload: FileView[]) {}
}

export class SaveUserChecksFilesFailureAction implements Action {
  readonly type = ActionTypes.SAVE_USER_CHECKS_FILES_FAILURE;

  constructor(public payload: Error) {}
}
