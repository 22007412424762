import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, repeat, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';

import { SnackBarService } from '@services/snackbar.service';
import { IndexedDBSyncService } from '@services/indexed-db/indexedDB-sync.service';
import { FileService } from '@services/file.service';
import { HelperService } from '@services/helpers/helper.service';
import { SurveyHelperService } from '@services/helpers/survey-helper.service';
import { FilterHelperService } from '@services/helpers/filter-helper.service';
import { SalesForceService } from '@services/sales-force.service';

import { SiteSurvey } from '@models/survey/site-survey.model';
import { SurveyObject } from '@models/survey/survey-object.model';
import { SurveyQuestion } from '@models/survey/survey-question.model';
import { SurveyTemplate } from '@models/survey/survey-template.model';
import { S3FileObject } from '@models/s3-file-object.model';
import { AdditionalFile } from '@models/additional-file.model';
import { IDBSurveyObject } from '@interfaces/indexed-db/idb-survey-object.interface';
import { SurveyTemplateStatusEnum } from '@enums/survey/survey-template-status.enum';
import { IndexedDBStorageEnum } from '@enums/survey/indexed-db-storage.enum';
import { StorageFileKeyPrefixEnum } from '@enums/storage-file-key-prefix.enum';
import { SurveyStatusEnum } from '@enums/survey/survey-status.enum';
import { UserRolesEnum } from '@enums/user-roles.enum';
import { ActionTypes } from './action-types.enum';
import { SalesForceActionsEnum } from '@enums/sales-force-actions.enum';
import { indexedDBConfig } from '@core/configs/indexed-db.config';

import { createSiteSurveyQuestion, deleteSiteSurveyQuestion, updateSiteSurveyQuestion } from '@graphql/survey-questions/mutations';
import { createSiteSurveyObject, deleteSiteSurveyObject, updateSiteSurveyObject } from '@graphql/survey-object/mutations';
import { createSurveyTemplate, deleteSurveyTemplate, updateSurveyTemplate } from '@graphql/survey-templates/mutations';
import { createSiteSurvey, deleteSiteSurvey, generateSurveyReport, updateSiteSurvey } from '@graphql/site-survey/mutations';
import { customSearchSurveyTemplates } from '@graphql/survey-templates/queries';
import { customSearchSurveyQuestions, searchSurveyQuestions } from '@graphql/survey-questions/queries';
import { customSiteSurveyObjectsSearch, searchSurveyObjects } from '@graphql/survey-object/queries';
import { customSiteSurveysSearch, searchSiteSurvey } from '@graphql/site-survey/queries';

import { RootStore } from '@store/index';
import { UserSelectors } from '@store/user-store';
import { CaseActions } from '@store/case-store';
import * as SurveySelectors from './selectors';
import * as SurveyActions from './survey-actions';

@Injectable()
export class RoleEffects {
  constructor(
    private readonly salesForceService: SalesForceService,
    private readonly store$: Store<RootStore.AppState>,
    private readonly actions$: Actions,
    private readonly snackbar: SnackBarService,
    private readonly router: Router,
    private readonly idbSync: IndexedDBSyncService,
    private readonly fileService: FileService,
    private readonly filterHelper: FilterHelperService,
    private readonly surveyHelper: SurveyHelperService
  ) {}

  // Objects
  @Effect()
  loadObjectBankEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.LoadObjectBankRequestAction>(ActionTypes.LOAD_OBJECT_BANK_REQUEST),
    switchMap(() => searchSurveyObjects.execute().then(res => res?.data?.searchSiteSurveyObjects?.items)),
    map((items) => {
      const objects = items.map((object) => ({ ...object, numberOfQuestions: JSON.parse(object.questionsBody)?.length || 0 }));
      return new SurveyActions.LoadObjectBankSuccessAction(objects);
    }),
    catchError((error) => of(new SurveyActions.LoadObjectBankFailureAction(error))),
    repeat()
  );

  @Effect()
  createSurveyObjectEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.CreateSurveyObjectRequestAction>(ActionTypes.CREATE_SURVEY_OBJECT_REQUEST),
    switchMap(({ payload }) =>
      createSiteSurveyObject
        .execute({ input: payload.model })
        .then(() => payload.params?.callback?.())
    ),
    map(() => {
      this.snackbar.success('Object created successfully');
      this.store$.dispatch(new SurveyActions.LoadObjectBankRequestAction());
      return new SurveyActions.CreateSurveyObjectSuccessAction();
    }),
    catchError((error) => of(new SurveyActions.CreateSurveyObjectFailureAction(error))),
    repeat()
  );

  @Effect()
  updateSurveyObjectEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.UpdateSurveyObjectRequestAction>(ActionTypes.UPDATE_SURVEY_OBJECT_REQUEST),
    switchMap(({ payload }) =>
      updateSiteSurveyObject
        .execute({ input: payload.model })
        .then(() => payload.params?.callback?.())
    ),
    map(() => {
      this.snackbar.success('Object updated successfully');
      this.store$.dispatch(new SurveyActions.LoadObjectBankRequestAction());
      return new SurveyActions.UpdateSurveyObjectSuccessAction();
    }),
    catchError((error) => of(new SurveyActions.UpdateSurveyObjectFailureAction(error))),
    repeat()
  );

  @Effect()
  deleteSurveyObjectEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.DeleteSurveyObjectRequestAction>(ActionTypes.DELETE_SURVEY_OBJECT_REQUEST),
    switchMap(({ payload }) => deleteSiteSurveyObject.execute({ input: { id: payload } })),
    withLatestFrom(this.store$.select(SurveySelectors.selectObjectBank)),
    map(([response, objectBank]) => {
      this.snackbar.success('Object deleted successfully');
      const objects = objectBank.filter((obj) => obj.id !== response?.data?.deleteSiteSurveyObject?.id);
      return new SurveyActions.DeleteSurveyObjectSuccessAction(objects);
    }),
    catchError((error) => of(new SurveyActions.DeleteSurveyObjectFailureAction(error))),
    repeat()
  );

  // Questions
  @Effect()
  loadQuestionBankEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.LoadQuestionBankRequestAction>(ActionTypes.LOAD_QUESTION_BANK_REQUEST),
    switchMap(() => searchSurveyQuestions.execute().then(res => res?.data?.searchSiteSurveyQuestions?.items)),
    map((questions) => new SurveyActions.LoadQuestionBankSuccessAction(questions)),
    catchError((error) => of(new SurveyActions.LoadQuestionBankFailureAction(error))),
    repeat()
  );

  @Effect()
  createSurveyQuestionEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.CreateSurveyQuestionRequestAction>(ActionTypes.CREATE_SURVEY_QUESTION_REQUEST),
    switchMap(({ payload }) =>
      createSiteSurveyQuestion
        .execute({ input: payload.model })
        .then(() => payload.params?.callback?.())
    ),
    map(() => {
      this.snackbar.success('Question created successfully');
      this.store$.dispatch(new SurveyActions.LoadQuestionBankRequestAction());
      return new SurveyActions.CreateSurveyQuestionSuccessAction();
    }),
    catchError((error) => of(new SurveyActions.CreateSurveyQuestionFailureAction(error))),
    repeat()
  );

  @Effect()
  updateSurveyQuestionEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.UpdateSurveyQuestionRequestAction>(ActionTypes.UPDATE_SURVEY_QUESTION_REQUEST),
    switchMap(({ payload }) =>
      updateSiteSurveyQuestion
        .execute({ input: payload.model })
        .then(() => payload.params?.callback?.())
    ),
    map(() => {
      this.snackbar.success('Question updated successfully');
      this.store$.dispatch(new SurveyActions.LoadQuestionBankRequestAction());
      return new SurveyActions.UpdateSurveyQuestionSuccessAction();
    }),
    catchError((error) => of(new SurveyActions.UpdateSurveyQuestionFailureAction(error))),
    repeat()
  );

  @Effect()
  deleteSurveyQuestionEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.DeleteSurveyQuestionRequestAction>(ActionTypes.DELETE_SURVEY_QUESTION_REQUEST),
    switchMap(({ payload }) => deleteSiteSurveyQuestion.execute({ input: { id: payload } })),
    withLatestFrom(this.store$.select(SurveySelectors.selectQuestionBank)),
    map(([response, questionsBank]) => {
      this.snackbar.success('Question deleted successfully');
      const questions = questionsBank.filter(
        (obj) => obj.id !== response?.data?.deleteSiteSurveyQuestion?.id
      );
      return new SurveyActions.DeleteSurveyQuestionSuccessAction(questions);
    }),
    catchError((error) => of(new SurveyActions.DeleteSurveyQuestionFailureAction(error))),
    repeat()
  );

  // Survey Templates
  @Effect()
  surveyTemplatesPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.SurveyTemplatesPaginationRequestAction>(ActionTypes.SURVEY_TEMPLATES_PAGINATION_REQUEST),
    switchMap(({ payload }) => {
      const queryParams = {
        from: payload.from,
        limit: payload.limit,
        filter: payload.filter,
        sort: payload.sort || {}
      };

      return combineLatest([
        customSearchSurveyTemplates.execute(queryParams).then((res) => res?.data?.customSiteSurveyTemplateSearch),
        payload.reset
          ? of({ items: [] as SurveyTemplate[], total: 0 })
          : this.store$.select(SurveySelectors.selectSurveyTemplatePagination).pipe(take(1))
      ]);
    }),
    map(([response, templates]) => new SurveyActions.SurveyTemplatesPaginationSuccessAction({ ...templates, ...response })),
    catchError((error) => of(new SurveyActions.SurveyTemplatesPaginationFailureAction(error))),
    repeat()
  );

  @Effect()
  createSurveyTemplateEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.CreateSurveyTemplateRequestAction>(ActionTypes.CREATE_SURVEY_TEMPLATE_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) =>
      combineLatest([
      createSurveyTemplate.execute({ input: { ...payload.template, createdBy: user.id } }).then(
        (response) =>
          ({
            ...payload.template,
            id: response?.data?.createSiteSurveyTemplate?.id
          } as SurveyTemplate)
      ),
      of(payload)
    ])),
    map(([template, payload]) => {
      const surveyTemplateId = template.id;
      const keyTo = `${StorageFileKeyPrefixEnum.SurveyTemplateLogo}/${surveyTemplateId}`;
      const keyFrom = `${StorageFileKeyPrefixEnum.SurveyTemplateLogo}/${payload.logoInfo.copiedSurveyId}/${payload.logoInfo.templateLogoName}`;
      const sfUpdateModel = {
        Name: payload.template.name,
        postId__c: template.id
      };
      if (payload.file) {
        const file = payload.file;
        const s3Object = new S3FileObject(`${keyTo}/${file[0].name}`, file[0].name);
        const attachment: AdditionalFile = { image: s3Object };

        this.fileService
          .saveFile(file[0], keyTo)
          .then(() => {
            const model = { id: surveyTemplateId, surveyLogo: attachment.image };
            updateSurveyTemplate.execute({ input: model }).then(() => payload);
          })
          .then(() => {
            this.updateSurveyTemplateAfterCreation(surveyTemplateId, sfUpdateModel, template.status);
          });
      } else {
        if (payload.type !== 'new' && payload.logoInfo.templateLogoName ) {
          this.fileService
            .copyFile(keyFrom)
            .then((file) => {
              this.fileService
                .saveFile(file, keyTo)
                .then(() => {
                  const s3Object = new S3FileObject(`${keyTo}/${file.name}`, file.name);
                  const attachment: AdditionalFile = { image: s3Object };
                  const model = { id: surveyTemplateId, surveyLogo: attachment.image };

                  updateSurveyTemplate.execute({input: model}).then(() => payload);
                });
            })
            .then(() => {
              this.updateSurveyTemplateAfterCreation(surveyTemplateId, sfUpdateModel, template.status);
            });
        } else {
          this.updateSurveyTemplateAfterCreation(surveyTemplateId, sfUpdateModel, template.status);
        }
      }
      return new SurveyActions.CreateSurveyTemplateSuccessAction();
    }),
    catchError((error) => of(new SurveyActions.CreateSurveyTemplateFailureAction(error))),
    repeat()
  );

  @Effect()
  updateSurveyTemplateEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.UpdateSurveyTemplateRequestAction>(ActionTypes.UPDATE_SURVEY_TEMPLATE_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      const surveyTemplateId = payload.model.id;
      const key = `${StorageFileKeyPrefixEnum.SurveyTemplateLogo}/${surveyTemplateId}`;
      let model;
      const sfUpdateModel = {
        Name: payload.model.name
      };
      this.salesForceService.patchSFInfo(SalesForceActionsEnum.surveyTemplate, payload.model.salesforceId, sfUpdateModel);
      if (payload.file && payload.type === 'new') {
        const file = payload.file;
        const s3Object = new S3FileObject(`${key}/${file[0].name}`, file[0].name);
        const attachment: AdditionalFile = {
          image: s3Object
        };
        model = Object.assign({}, payload.model, { surveyLogo: attachment.image, updatedBy: user.id });
        return this.fileService
          .saveFile(file[0], key)
          .then(() => this.fileService.removeFile(payload.model.surveyLogo?.key))
          .then(() =>
            updateSurveyTemplate.execute({ input: model }).then(() => payload)
          );
      } else {
        if (payload.type === 'empty') {
          model = Object.assign({}, payload.model, { surveyLogo: null, updatedBy: user.id });
          this.fileService.removeFile(payload.model.surveyLogo?.key);
          return updateSurveyTemplate.execute({ input: model }).then(() => payload);
        }
      }
      return updateSurveyTemplate.execute({ input: { ...payload.model, updatedBy: user.id } }).then(() => payload);
    }),
    map((payload) => {
      payload?.params?.callback && payload.params.callback();
      this.snackbar.success('Survey template updated successfully');
      return new SurveyActions.UpdateSurveyTemplateSuccessAction();
    }),
    catchError((error) => of(new SurveyActions.UpdateSurveyTemplateFailureAction(error))),
    repeat()
  );

  @Effect()
  deleteSurveyTemplateEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.DeleteSurveyTemplateRequestAction>(ActionTypes.DELETE_SURVEY_TEMPLATE_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      const keyToRemove = payload.data.surveyLogo?.key ? payload.data.surveyLogo?.key : '';

      if (user.role === UserRolesEnum.ProjectCoordinator && payload.data.status !== SurveyTemplateStatusEnum.Draft) {
        return updateSurveyTemplate
          .execute(
            { input: {
              id: payload.data.id,
              status: SurveyTemplateStatusEnum.RequestToDelete,
              statusSearch: SurveyTemplateStatusEnum.RequestToDelete
            }}
          )
          .then((res) => {
            payload.params?.callback?.();
            return [res?.data?.updateSiteSurveyTemplate?.id, 'Removal request sent successfully']
          });
      }

      return combineLatest([
        deleteSurveyTemplate
          .execute({ input: { id: payload.data.id } })
          .then((res) => {
            payload.params?.callback?.();
            return [res?.data?.deleteSiteSurveyTemplate?.id, 'Survey template deleted successfully']
          }),
        this.fileService.removeFile(keyToRemove),
        this.salesForceService.deleteSFInfo(SalesForceActionsEnum.surveyTemplate, payload.data.salesforceId)
      ]);
    }),
    withLatestFrom(this.store$.select(SurveySelectors.selectSurveyTemplatesList)),
    map(([[response], surveys]) => {
      this.snackbar.success(response[1]);
      const objects = surveys.filter((obj) => obj.id !== response[0]);
      return new SurveyActions.DeleteSurveyTemplateSuccessAction(objects);
    }),
    catchError((error) => of(new SurveyActions.DeleteSurveyTemplateFailureAction(error))),
    repeat()
  );

  // Site Survey
  @Effect()
  loadSiteSurveyListEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.LoadSiteSurveyListRequestAction>(ActionTypes.LOAD_SITE_SURVEY_LIST_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      const filter = this.filterHelper.getSurveySearchFilter(user, payload?.filter);
      return searchSiteSurvey.execute({ filter })
        .then(res => res?.data?.searchSiteSurveys?.items.map(item => ({...item, address: HelperService.caseLocation(item.caseID)})));
    }),
    map((surveys) => new SurveyActions.LoadSiteSurveyListSuccessAction(this.surveyHelper.mapSurveyFields(surveys))),
    catchError((error) => of(new SurveyActions.LoadSiteSurveyListFailureAction(error))),
    repeat()
  );

  @Effect()
  createSiteSurveyEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.CreateSiteSurveyRequestAction>(ActionTypes.CREATE_SITE_SURVEY_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) =>
      combineLatest([
        createSiteSurvey.execute({  input: { ...payload.survey, createdBy: user.id } })
          .then((res) => Object.assign({}, payload, { id: res.data.createSiteSurvey.id })),
        of(payload)
      ])
    ),
    map(([survey, payload]) => {
      const surveyId = survey.id;
      const keyTo = `${StorageFileKeyPrefixEnum.SurveyLogo}/${surveyId}`;
      const keyFrom = `${StorageFileKeyPrefixEnum.SurveyTemplateLogo}/${payload.logoInfo.copiedSurveyId}/${payload.logoInfo.templateLogoName}`;
      if (payload.file) {
        if (typeof payload.file === 'object') {
          const file = payload.file;
          const s3Object = new S3FileObject(`${keyTo}/${file[0].name}`, file[0].name);
          const attachment: AdditionalFile = {
            image: s3Object
          };
          this.fileService
            .saveFile(file[0], keyTo)
            .then(() => {
              const model = { id: surveyId, surveyLogo: attachment.image };
              updateSiteSurvey.execute({ input: model }).then(() => payload);
            }).then(() => {
            this.router.navigate(['survey-management']);
            this.snackbar.success('Site survey created successfully');
          });
        } else {
          this.fileService
            .copyFile(keyFrom)
            .then((file) => {
              this.fileService
                .saveFile(file, keyTo)
                .then(() => {
                  const s3Object = new S3FileObject(`${keyTo}/${file.name}`, file.name);
                  const attachment: AdditionalFile = {
                    image: s3Object
                  };
                  const model = { id: surveyId, surveyLogo: attachment.image };
                  updateSiteSurvey.execute({ input: model }).then(() => payload);
                });
            })
            .then(() => {
              this.router.navigate(['survey-management']);
              this.snackbar.success('Site survey created successfully');
            });
        }
      } else {
        this.router.navigate(['survey-management']);
        this.snackbar.success('Site survey created successfully');
      }
      const updateModelSF = {
        postSurveyId__c: survey.id
      };
      this.salesForceService.patchSFInfo(SalesForceActionsEnum.case, payload.caseSalesforceId, updateModelSF);
      this.store$.dispatch(new CaseActions.UpdateCaseRequestAction({ model: { id: payload.survey.caseId, surveyId: survey.id }}));

      return new SurveyActions.CreateSiteSurveySuccessAction();
    }),
    catchError((error) => of(new SurveyActions.CreateSiteSurveyFailureAction(error))),
    repeat()
  );

  @Effect()
  updateSiteSurveyEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.UpdateSiteSurveyRequestAction>(ActionTypes.UPDATE_SITE_SURVEY_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) =>
      updateSiteSurvey
        .execute({
          input: {
            ...payload.model,
            updatedDate: HelperService.timestamp,
            reportGenerated: false,
            updatedBy: user.id
          }
        })
        .then(() => payload)
    ),
    map((payload) => {
      payload.params?.callback?.();
      this.snackbar.success('Site survey updated successfully');

      return new SurveyActions.UpdateSiteSurveySuccessAction();
    }),
    catchError((error) => of(new SurveyActions.UpdateSiteSurveyFailureAction(error))),
    repeat()
  );

  @Effect()
  deleteSiteSurveyEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.DeleteSiteSurveyRequestAction>(ActionTypes.DELETE_SITE_SURVEY_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      if (user.role === UserRolesEnum.ProjectCoordinator) {
        return updateSiteSurvey
          .execute({ input: {
            id: payload,
            status: SurveyStatusEnum.RequestToDelete,
            statusSearch: SurveyStatusEnum.RequestToDelete,
            reportGenerated: false
          }})
          .then((res) => [res?.data?.updateSiteSurvey?.id, 'Removal request sent successfully']);
      }

      return deleteSiteSurvey
        .execute({ input: { id: payload } })
        .then((res) => [res?.data?.deleteSiteSurvey?.id, 'Survey deleted successfully']);
    }),
    withLatestFrom(this.store$.select(SurveySelectors.selectSurveyList)),
    map(([[responseId, message], surveys]) => {
      this.snackbar.success(message);
      const objects = surveys.filter((obj) => obj.id !== responseId);

      return new SurveyActions.DeleteSiteSurveySuccessAction(objects);
    }),
    catchError((error) => of(new SurveyActions.DeleteSurveyObjectFailureAction(error))),
    repeat()
  );

  @Effect()
  loadSurveyDataFromIndexedDBEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.LoadSurveyDataFromIndexedDBRequestAction>(
      ActionTypes.LOAD_SURVEY_DATA_FROM_INDEXED_DB_REQUEST
    ),
    switchMap(({ payload }) => this.idbSync.connectToDB().then(() => payload)),
    switchMap((payload) => combineLatest([
      this.idbSync.loadObjects(indexedDBConfig.objectKeyPath, payload),
      this.idbSync.loadTemplate(payload)
    ])),
    map(([answers, template]) => {
      this.store$.dispatch(new SurveyActions.LoadExtraObjectsFromIndexedDBSuccessAction(template?.surveyBody));
      return new SurveyActions.LoadSurveyDataFromIndexedDBSuccessAction(answers);
    }),
    catchError((error) => of(new SurveyActions.LoadSurveyDataFromIndexedDBFailureAction(error))),
    repeat()
  );

  @Effect()
  saveSurveyDataInIndexedDBEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.SaveSurveyDataInIndexedDBRequestAction>(
      ActionTypes.SAVE_SURVEY_DATA_IN_INDEXED_DB_REQUEST
    ),
    switchMap(({ payload }) => this.idbSync.putItemToDB(payload, IndexedDBStorageEnum.ObjectStore).then(() => payload)),
    withLatestFrom(this.store$.select(SurveySelectors.selectSurveyAnswers)),
    map(([payload, surveyAnswers]) => {
      const answers: IDBSurveyObject[] = surveyAnswers.map(item => ({ ...item, answers: Object.assign({}, item.answers) }));
      const idx = answers.findIndex((item) => item.id === payload.id);

      if (idx > -1) {
        answers.splice(idx, 1, payload);
      } else {
        answers.push(payload);
      }

      return new SurveyActions.SaveSurveyDataInIndexedDBSuccessAction(answers);
    }),
    catchError((error) => of(new SurveyActions.SaveSurveyDataInIndexedDBFailureAction(error))),
    repeat()
  );

  @Effect()
  removeSurveyDataFromIndexedDBEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.DeleteSurveyDataFromIndexedDBRequestAction>(
      ActionTypes.DELETE_SURVEY_DATA_FROM_INDEXED_DB_REQUEST
    ),
    switchMap(({ payload }) =>
      Promise.all(payload.map((id) => this.idbSync.delete(IndexedDBStorageEnum.ObjectStore, id))).then(
        () => payload
      )
    ),
    withLatestFrom(this.store$.select(SurveySelectors.selectSurveyAnswers)),
    map(([payload, surveyAnswers]) => {
      const answers: IDBSurveyObject[] = surveyAnswers.map(item => ({ ...item, answers: Object.assign({}, item.answers) }));
      const filtered = answers.filter((answer) => !payload.includes(answer.surveyKeyPath));

      return new SurveyActions.DeleteSurveyDataFromIndexedDBSuccessAction(filtered);
    }),
    catchError((error) => of(new SurveyActions.DeleteSurveyDataFromIndexedDBFailureAction(error))),
    repeat()
  );

  @Effect()
  generateSurveyReportEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.GenerateSurveyReportRequestAction>(ActionTypes.GENERATE_SURVEY_REPORT_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      const input = { id: payload.id, reportGenerated: true, ...payload.reportsToReset };

      return combineLatest([
        updateSiteSurvey.execute({ input }).then(
          () => generateSurveyReport.execute({ siteSurveyId: payload.id, reportType: payload.reportType, userId: user.id })
            .then(() => payload.params?.callback?.())
        ).then(() => payload)
      ]);
    }),
    map(([payload]) => {
      this.snackbar.warning('Generating report...');
      return new SurveyActions.GenerateSurveyReportSuccessAction({ id: payload.id, reportType: payload.reportType });
    }),
    catchError((error) => of(new SurveyActions.GenerateSurveyReportFailureAction(error))),
    repeat()
  );

  @Effect()
  downloadSurveyReportEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.DownloadSurveyReportRequestAction>(ActionTypes.DOWNLOAD_SURVEY_REPORT_REQUEST),
    switchMap(({ payload }) => this.fileService.downloadFile(payload.key).then(() => payload)),
    map((payload) => {
      payload.params?.callback?.();

      return new SurveyActions.DownloadSurveyReportSuccessAction(payload.surveyId);
    }),
    catchError((error) => of(new SurveyActions.DownloadSurveyReportFailureAction(error))),
    repeat()
  );

  @Effect()
  saveExtraObjectsInIndexedDBEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.SaveExtraObjectsInIndexedDBRequestAction>(ActionTypes.SAVE_EXTRA_OBJECTS_IN_INDEXED_DB_REQUEST),
    switchMap(({ payload }) => this.idbSync.putItemToDB(payload, IndexedDBStorageEnum.TemplateStore).then(() => payload)),
    map((payload) => new SurveyActions.SaveExtraObjectsInIndexedDBSuccessAction(payload.surveyBody)),
    catchError((error) => of(new SurveyActions.SaveExtraObjectsInIndexedDBFailureAction(error))),
    repeat()
  );

  @Effect()
  deleteExtraObjectsFromIndexedDBEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.DeleteExtraObjectsFromIndexedDBRequestAction>(ActionTypes.DELETE_EXTRA_OBJECTS_FROM_INDEXED_DB_REQUEST),
    switchMap(({ payload }) => this.idbSync.delete(IndexedDBStorageEnum.TemplateStore, payload)),
    map(() => new SurveyActions.DeleteExtraObjectsFromIndexedDBSuccessAction(null)),
    catchError((error) => of(new SurveyActions.DeleteExtraObjectsFromIndexedDBFailureAction(error))),
    repeat()
  );

  @Effect()
  saveFileKeyInIndexedDBEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.SaveFileKeysInIndexedDBRequestAction>(ActionTypes.SAVE_FILE_KEYS_IN_INDEXED_DB_REQUEST),
    switchMap(({ payload }) => {
      return this.idbSync.get<IDBSurveyObject>(IndexedDBStorageEnum.FileKeysStore, payload.id).then((res) => {
        const model: IDBSurveyObject = { id: payload.id, fileKeys: [] };
        if (res?.fileKeys?.length) {
          model.fileKeys = [...res.fileKeys];
        }
        model.fileKeys.push(payload.key);

        return this.idbSync.put(IndexedDBStorageEnum.FileKeysStore, model);
      });
    }),
    map(() => new SurveyActions.SaveFileKeysInIndexedDBSuccessAction()),
    catchError((error) => of(new SurveyActions.SaveFileKeysInIndexedDBFailureAction(error))),
    repeat()
  );

  @Effect()
  removeFileKeysEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.DeleteFileKeysFromIndexedDBRequestAction>(ActionTypes.DELETE_FILE_KEYS_FROM_INDEXED_DB_REQUEST),
    switchMap(({ payload }) => this.idbSync.connectToDB().then(() => payload)),
    switchMap((payload) => this.idbSync.get<IDBSurveyObject>(IndexedDBStorageEnum.FileKeysStore, payload)),
    switchMap((object) => combineLatest((object?.fileKeys || []).map(key => this.fileService.removeFile(key))).pipe(map(() => object?.id))),
    switchMap((id) => {
      if (id) {
        return this.idbSync.delete(IndexedDBStorageEnum.FileKeysStore, id);
      }

      return of (null);
    }),
    map((data) => new SurveyActions.DeleteFileKeysFromIndexedDBSuccessAction()),
    catchError((error) => of(new SurveyActions.DeleteFileKeysFromIndexedDBFailureAction(error))),
    repeat()
  );

  @Effect()
  objectsPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.ObjectsPaginationRequestAction>(ActionTypes.OBJECTS_PAGINATION_REQUEST),
    switchMap(({ payload, staticFilter }) => {
      const queryParams = {
        from: payload.from,
        limit: payload.limit
      };
      if (!!payload.filter) {
        queryParams['filter'] = payload.filter;
      }
      if (!!staticFilter) {
        queryParams['filter'] = Object.assign({}, queryParams['filter'] || {}, staticFilter);
      }
      if (!!payload.sort) {
        queryParams['sort'] = payload.sort;
      }

      return combineLatest([
        customSiteSurveyObjectsSearch.execute(queryParams).then((res) => res?.data?.customSiteSurveyObjectsSearch),
        payload.reset
          ? of({ items: [] as SurveyObject[], total: 0 })
          : this.store$.select(SurveySelectors.selectObjectBank).pipe(take(1))
      ]);
    }),
    map(([response, objectsPagination]) =>  new SurveyActions.ObjectsPaginationSuccessAction({ ...objectsPagination, ...response})),
    catchError((error) => of(new SurveyActions.ObjectsPaginationFailureAction(error))),
    repeat()
  );

  @Effect()
  objectsBankPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.ObjectsBankPaginationRequestAction>(ActionTypes.OBJECTS_BANK_PAGINATION_REQUEST),
    switchMap(({ payload }) => {
      const queryParams = {
        from: payload.from,
        limit: payload.limit
      };
      if (!!payload.filter) {
        queryParams['filter'] = payload.filter;
      }
      if (!!payload.sort) {
        queryParams['sort'] = payload.sort;
      }

      return combineLatest([
        customSiteSurveyObjectsSearch.execute(queryParams).then((res) => res?.data?.customSiteSurveyObjectsSearch),
        payload.reset
          ? of({ items: [] as SurveyObject[], total: 0 })
          : this.store$.select(SurveySelectors.selectObjectBank).pipe(take(1))
      ]);
    }),
    map(([response, objectsPagination]) =>  new SurveyActions.ObjectsBankPaginationSuccessAction({ ...objectsPagination, ...response })),
    catchError((error) => of(new SurveyActions.ObjectsBankPaginationFailureAction(error))),
    repeat()
  );

  @Effect()
  questionsPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.QuestionsPaginationRequestAction>(ActionTypes.QUESTIONS_PAGINATION_REQUEST),
    switchMap(({ payload }) => {
      return combineLatest([
        searchSurveyQuestions.execute(payload).then((res) => res?.data?.searchSiteSurveyQuestions?.items),
        payload.reset ? of([] as SurveyQuestion[]) : this.store$.select(SurveySelectors.selectQuestionBank).pipe(take(1))
      ]);
    }),
    map(([response, questions]) => new SurveyActions.LoadQuestionBankSuccessAction([...questions, ...response])),
    catchError((error) => of(new SurveyActions.LoadQuestionBankFailureAction(error))),
    repeat()
  );

  @Effect()
  questionsBankPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.QuestionsBankPaginationRequestAction>(ActionTypes.QUESTIONS_BANK_PAGINATION_REQUEST),
    switchMap(({ payload }) => {
      const queryParams = {
        from: payload.from,
        limit: payload.limit
      };
      if (!!payload.filter) {
        queryParams['filter'] = payload.filter;
      }
      if (!!payload.sort) {
        queryParams['sort'] = payload.sort;
      }

      return combineLatest([
        customSearchSurveyQuestions.execute(queryParams).then((res) => res?.data?.customSiteSurveyQuestionsSearch),
        payload.reset ? of({ items: [] as SurveyQuestion[], total: 0 }) : this.store$.select(SurveySelectors.selectQuestionsBankPagination).pipe(take(1))
      ]);
    }),
    map(([response, questionsPagination]) => new SurveyActions.QuestionsBankPaginationSuccessAction({...questionsPagination, ...response})),
    catchError((error) => of(new SurveyActions.QuestionsBankPaginationFailureAction(error))),
    repeat()
  );

  @Effect()
  surveyPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.SurveysPaginationRequestAction>(ActionTypes.SURVEYS_PAGINATION_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload, staticFilter }, user]) => {
      const queryParams = {
        from: payload.from,
        limit: payload.limit
      };
      if (!!payload.filter) {
        queryParams['filter'] = payload.filter;
      }
      if (!!payload.sort) {
        queryParams['sort'] = payload.sort;
      }

      queryParams['filter'] = this.filterHelper.getCustomSurveySearchFilter(user, queryParams['filter'], staticFilter);

      return customSiteSurveysSearch.execute(queryParams)
        .then((res) => {
          return {
            ...res?.data?.customSiteSurveysSearch,
            items: res?.data?.customSiteSurveysSearch?.items.map((item) => (
              {
                ...item,
                address: HelperService.caseLocation(item.caseID),
                updatedDate: item.updatedDate * 1000
              }
            ))
          };
        });
    }),
    map((response) => {
      return new SurveyActions.SurveysPaginationSuccessAction({ ...response });
    }),
    catchError((error) => of(new SurveyActions.LoadSiteSurveyListFailureAction(error))),
    repeat()
  );

  @Effect()
  surveyManagementPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.SurveysManagementPaginationRequestAction>(ActionTypes.SURVEYS_MANAGEMENT_PAGINATION_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      const queryParams = {
        from: payload.from,
        limit: payload.limit
      };
      if (!!payload.filter) {
        queryParams['filter'] = payload.filter;
      }
      if (!!payload.sort) {
        queryParams['sort'] = payload.sort;
      }

      queryParams['filter'] = this.filterHelper.getCustomSurveySearchFilter(user, queryParams['filter']);

      return customSiteSurveysSearch.execute(queryParams)
        .then((res) => {
          return {
            ...res?.data?.customSiteSurveysSearch,
            items: res?.data?.customSiteSurveysSearch?.items.map((item) => (
              { ...item, address: HelperService.caseLocation(item.caseID) }
            ))
          };
        });
    }),
    map((response) => {
      return new SurveyActions.SurveysManagementPaginationSuccessAction({ ...response });
    }),
    catchError((error) => of(new SurveyActions.LoadSiteSurveyListFailureAction(error))),
    repeat()
  );

  @Effect()
  ArchivedSurveyPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<SurveyActions.ArchivedSurveysPaginationRequestAction>(ActionTypes.ARCHIVED_SURVEYS_PAGINATION_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload, staticFilter }, user]) => {
      const queryParams = {
        from: payload.from,
        limit: payload.limit
      };
      if (!!payload.filter) {
        queryParams['filter'] = payload.filter;
      }
      if (!!payload.sort) {
        queryParams['sort'] = payload.sort;
      }

      queryParams['filter'] = this.filterHelper.getCustomSurveySearchFilter(user, queryParams['filter'], staticFilter);

      return customSiteSurveysSearch.execute(queryParams)
        .then((res) => {
          return {
            ...res?.data?.customSiteSurveysSearch,
            items: res?.data?.customSiteSurveysSearch?.items.map((item) => (
              { ...item, address: HelperService.caseLocation(item.caseID) }
            ))
          };
        });
    }),
    map((response) => {
      return new SurveyActions.ArchivedSurveysPaginationSuccessAction(response);
    }),
    catchError((error) => of(new SurveyActions.LoadSiteSurveyListFailureAction(error))),
    repeat()
  );

  @Effect({ dispatch: false })
  surveyErrorsEffect$: Observable<void> = this.actions$.pipe(
    ofType<Action & { payload: Error }>(
      ActionTypes.LOAD_OBJECT_BANK_FAILURE,
      ActionTypes.CREATE_SURVEY_OBJECT_FAILURE,
      ActionTypes.UPDATE_SURVEY_OBJECT_FAILURE,
      ActionTypes.DELETE_SURVEY_OBJECT_FAILURE,
      ActionTypes.LOAD_QUESTION_BANK_FAILURE,
      ActionTypes.CREATE_SURVEY_QUESTION_FAILURE,
      ActionTypes.UPDATE_SURVEY_QUESTION_FAILURE,
      ActionTypes.DELETE_SURVEY_QUESTION_FAILURE,
      ActionTypes.CREATE_SURVEY_TEMPLATE_FAILURE,
      ActionTypes.UPDATE_SURVEY_TEMPLATE_FAILURE,
      ActionTypes.DELETE_SURVEY_TEMPLATE_FAILURE,
      ActionTypes.LOAD_SITE_SURVEY_LIST_FAILURE,
      ActionTypes.CREATE_SITE_SURVEY_FAILURE,
      ActionTypes.UPDATE_SITE_SURVEY_FAILURE,
      ActionTypes.DELETE_SITE_SURVEY_FAILURE,
      ActionTypes.LOAD_SURVEY_DATA_FROM_INDEXED_DB_FAILURE,
      ActionTypes.SAVE_SURVEY_DATA_IN_INDEXED_DB_FAILURE,
      ActionTypes.DOWNLOAD_SURVEY_REPORT_FAILURE,
      ActionTypes.SAVE_SURVEY_DATA_IN_INDEXED_DB_FAILURE,
      ActionTypes.DOWNLOAD_SURVEY_REPORT_FAILURE,
      ActionTypes.LOAD_EXTRA_OBJECTS_FROM_INDEXED_DB_FAILURE,
      ActionTypes.SAVE_EXTRA_OBJECTS_IN_INDEXED_DB_FAILURE,
      ActionTypes.DELETE_EXTRA_OBJECTS_FROM_INDEXED_DB_FAILURE
    ),
    map((action) => {
      console.log('action: ', action);
      this.snackbar.error(action.payload.message);
    })
  );

  private updateSurveyTemplateAfterCreation(surveyTemplateId, sfUpdateModel, templateStatus: SurveyTemplateStatusEnum) {
    if (templateStatus !== SurveyTemplateStatusEnum.Draft) {
      this.salesForceService.postSFInfo(SalesForceActionsEnum.surveyTemplate, sfUpdateModel).then((res) => {
        const model = { id: surveyTemplateId, salesforceId: res.id };
        updateSurveyTemplate.execute({input: model});
        this.router.navigate(['survey-management/surveys-templates']);
        this.snackbar.success('Survey template created successfully');
      });
    } else {
      this.router.navigate(['survey-management/surveys-templates']);
      this.snackbar.success('Survey template created successfully');
    }
  }
}
