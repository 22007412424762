export enum QuestionResponseEnum {
  Instruction = 'Instruction',
  MultiSelectCheckbox = 'MultiSelectCheckbox',
  Text = 'Text',
  Select = 'Select',
  Checkbox = 'Checkbox',
  FloorPlan = 'FloorPlan',
  SelectFromGallery = 'SelectFromGallery',
  FileUpload = 'FileUpload',
  GeoLocation = 'GeoLocation',
}

export enum QuestionResponseTitleEnum {
  Instruction = 'Instruction',
  MultiSelectCheckbox = 'Multi-select',
  Text = 'Text',
  Select = 'Select',
  Checkbox = 'Checkbox',
  FloorPlan = 'Floor Plan',
  SelectFromGallery = 'Photo Capture',
  FileUpload = 'File Upload',
  GeoLocation = 'Geo-location',
}
