import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { FrontEndRole } from '@models/roles/frontend-role.model';

export class LoadUserPermissionsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_USER_PERMISSIONS_REQUEST;
}

export class LoadUserPermissionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_USER_PERMISSIONS_SUCCESS;

  constructor(public payload: FrontEndRole[]) {}
}

export class LoadUserPermissionsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_USER_PERMISSIONS_FAILURE;

  constructor(public payload: Error) {}
}
