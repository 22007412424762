import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SurveyTemplate } from '@models/survey/survey-template.model';

export class CreateSurveyTemplateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_SURVEY_TEMPLATE_REQUEST;

  constructor(public payload: { type?; logoInfo?; file: File; template: SurveyTemplate }) {}
}

export class CreateSurveyTemplateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SURVEY_TEMPLATE_SUCCESS;
}

export class CreateSurveyTemplateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_SURVEY_TEMPLATE_FAILURE;

  constructor(public payload: Error) {}
}
