import { GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { FrontEndRole } from '@models/roles/frontend-role.model';

class GetFrontEndRoleById extends GraphQLQueryById<{ id: string }, { getRoleFrontend: FrontEndRole<string> }> {
  protected query = `query GetRoleFrontend($id: ID!) {
    getRoleFrontend(id: $id) {
      id
      roleName
      viewName
      route
      backendRoleType
      roleBlock
      createdAt
      updatedAt
    }
  }`;
}

class GetFrontEndRoleList extends GraphQLQueryList<GraphQLQueryRequest<FrontEndRole<string>>, { listRoleFrontends: { items: FrontEndRole<string>[] } }> {
  protected query = `query ListRoleFrontends(
    $filter: ModelRoleFrontendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleFrontends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleName
        viewName
        route
        backendRoleType
        roleBlock
        createdAt
        updatedAt
      }
      nextToken
    }
  }`;
}

export const getFrontEndRoleById = new GetFrontEndRoleById();
export const getFrontEndRoleList = new GetFrontEndRoleList();
