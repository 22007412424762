import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { ActionParams } from '@models/action-params.model';

export class DownloadSurveyReportRequestAction implements Action {
  readonly type = ActionTypes.DOWNLOAD_SURVEY_REPORT_REQUEST;

  constructor(public payload: { key: string; surveyId: string; params?: ActionParams }) {}
}

export class DownloadSurveyReportSuccessAction implements Action {
  readonly type = ActionTypes.DOWNLOAD_SURVEY_REPORT_SUCCESS;

  constructor(public payload: string) {}
}

export class DownloadSurveyReportFailureAction implements Action {
  readonly type = ActionTypes.DOWNLOAD_SURVEY_REPORT_FAILURE;

  constructor(public payload: Error) {}
}
