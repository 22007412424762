import { BellNotification } from '@models/bell-notification.model';

export interface NotificationState {
  isLoading: boolean;
  bellNotifications: BellNotification[];
  notifications: BellNotification[];
}

export const initialState: NotificationState = {
  isLoading: false,
  bellNotifications: [],
  notifications: []
};
