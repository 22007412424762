import { Action } from '@ngrx/store';

import { VMOCompany } from '../../../core/models/vmo-company.model';
import { ActionTypes } from '../action-types.enum';

export class LoadCompanyRequestAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_REQUEST;

  constructor(public payload: string) {}
}

export class LoadCompanySuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_SUCCESS;

  constructor(public payload: VMOCompany) {}
}

export class LoadCompanyFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_FAILURE;

  constructor(public payload: Error) {}
}
