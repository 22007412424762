import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SiteSurvey } from '@models/survey/site-survey.model';

export class DeleteSiteSurveyRequestAction implements Action {
  readonly type = ActionTypes.DELETE_SITE_SURVEY_REQUEST;

  constructor(public payload: string) {}
}

export class DeleteSiteSurveySuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SITE_SURVEY_SUCCESS;

  constructor(public payload: SiteSurvey[]) {}
}

export class DeleteSiteSurveyFailureAction implements Action {
  readonly type = ActionTypes.DELETE_SITE_SURVEY_FAILURE;

  constructor(public payload: Error) {}
}
