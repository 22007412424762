import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { Case, CaseView } from '@models/case/case.model';
import { CaseEvent } from '@models/case/case-event.model';
import { CaseState } from './state';
import { CaseTimeLog } from '@models/case/case-timelog.model';
import { AdditionalExpense} from '@models/case/additional-expense.model';


const getIsLoading = (state: CaseState): boolean => state.isLoading;

const getIsExpensesLoading = (state: CaseState): boolean => state.isExpensesLoading;

const getIsHistoryLoading = (state: CaseState): boolean => state.isHistoryLoading;

const getIsTimeLogsLoading = (state: CaseState): boolean => state.isTimeLogsLoading;

const getCasePagination = (state: CaseState): { items: Case[], total: number } => state.casePagination;

const getCasePaginationLoading = (state: CaseState): boolean => state.casePaginationLoading;

const getCaseEventsList = (state: CaseState): CaseEvent[] => state.caseEvents;

const getCaseTimeLogsList = (state: CaseState): CaseTimeLog[] => state.caseTimeLogs;

const getAdditionalExpenses = (state: CaseState): AdditionalExpense[] => state.additionalExpenses;

const getCaseInfo = (state: CaseState): CaseView => state.singleCase;


export const selectCaseState: MemoizedSelector<object, CaseState> = createFeatureSelector<CaseState>(
  'case-store'
);

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectCaseState,
  getIsLoading
);

export const selectIsExpensesLoading: MemoizedSelector<object, boolean> = createSelector(
  selectCaseState,
  getIsExpensesLoading
);

export const selectIsHistoryLoading: MemoizedSelector<object, boolean> = createSelector(
  selectCaseState,
  getIsHistoryLoading
);

export const selectIsTimeLogsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectCaseState,
  getIsTimeLogsLoading
);

export const selectCaseInfo: MemoizedSelector<object, CaseView> = createSelector(
  selectCaseState,
  getCaseInfo
);

export const selectCasePagination: MemoizedSelector<object, { items: Case[], total: number }> = createSelector(
  selectCaseState,
  getCasePagination
);

export const selectCasePaginationLoading: MemoizedSelector<object, boolean> = createSelector(
  selectCaseState,
  getCasePaginationLoading
);

export const selectCaseEventsList: MemoizedSelector<object, CaseEvent[]> = createSelector(
  selectCaseState, getCaseEventsList
);

export const selectCaseTimeLogsList: MemoizedSelector<object, CaseTimeLog[]> = createSelector(
  selectCaseState, getCaseTimeLogsList
);

export const selectAdditionalExpensesList: MemoizedSelector<object, AdditionalExpense[]> = createSelector(
  selectCaseState, getAdditionalExpenses
);
