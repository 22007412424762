import { Action } from '@ngrx/store';
import { Required } from 'utility-types';
import { ActionTypes } from '../../action-types.enum';
import { CaseComment } from '@models/case/case-comment.model';

export class UpdateCaseCommentRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_COMMENT_REQUEST;

  constructor(public payload: { model: Required<Partial<CaseComment>, 'id'>; }) {}
}

export class UpdateCaseCommentSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_COMMENT_SUCCESS;
}

export class UpdateCaseCommentFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_COMMENT_FAILURE;

  constructor(public payload: Error) {}
}
