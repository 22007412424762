import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SurveyQuestion } from '@models/survey/survey-question.model';

export class DeleteSurveyQuestionRequestAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_QUESTION_REQUEST;

  constructor(public payload: string) {}
}

export class DeleteSurveyQuestionSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_QUESTION_SUCCESS;

  constructor(public payload: SurveyQuestion[]) {}
}

export class DeleteSurveyQuestionFailureAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_QUESTION_FAILURE;

  constructor(public payload: Error) {}
}
