import { Required } from 'utility-types';

import { SurveyObject } from '@models/survey/survey-object.model';
import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';

class CreateSiteSurveyObject extends GraphQLMutation<SurveyObject, { createSiteSurveyObject: Partial<SurveyObject> }> {
  protected query = `mutation CreateSiteSurveyObject(
    $input: CreateSiteSurveyObjectInput!
    $condition: ModelSiteSurveyObjectConditionInput
  ) {
    createSiteSurveyObject(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateSiteSurveyObject extends GraphQLMutation<Required<Partial<SurveyObject>, 'id'>, { updateSiteSurveyObject: Partial<SurveyObject> }> {
  protected query = `mutation UpdateSiteSurveyObject(
    $input: UpdateSiteSurveyObjectInput!
    $condition: ModelSiteSurveyObjectConditionInput
  ) {
    updateSiteSurveyObject(input: $input, condition: $condition) {
      id
    }
  }`;
}

class DeleteSiteSurveyObject extends GraphQLMutation<Required<Partial<SurveyObject>, 'id'>, { deleteSiteSurveyObject: Partial<SurveyObject> }> {
  protected query = `mutation DeleteSiteSurveyObject(
    $input: DeleteSiteSurveyObjectInput!
    $condition: ModelSiteSurveyObjectConditionInput
  ) {
    deleteSiteSurveyObject(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createSiteSurveyObject = new CreateSiteSurveyObject();
export const updateSiteSurveyObject = new UpdateSiteSurveyObject();
export const deleteSiteSurveyObject = new DeleteSiteSurveyObject();
