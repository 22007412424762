import { SurveyQuestion } from '@models/survey/survey-question.model';
import { SurveyObject } from '@models/survey/survey-object.model';
import { SurveyTemplate } from '@models/survey/survey-template.model';
import { SiteSurvey } from '@models/survey/site-survey.model';
import { IDBSurveyObject } from '@interfaces/indexed-db/idb-survey-object.interface';
import { ReportGeneratingObject } from '@models/survey/report-generating-object.model';

export interface SurveyState {
  isLoading: boolean;
  searchLoading: boolean;
  questionBank: SurveyQuestion[];
  objectBank: SurveyObject[];
  objectsPagination: { items: SurveyObject[], total: number };
  objectsPaginationLoading: boolean;
  objectsBankPagination: { items: SurveyObject[], total: number };
  objectsBankPaginationLoading: boolean;
  questionsBankPagination: { items: SurveyQuestion[], total: number };
  questionsBankPaginationLoading: boolean;
  surveyTemplates: SurveyTemplate[];
  surveys: SiteSurvey[];
  surveysPagination: { items: SiteSurvey[], total: number };
  surveysPaginationLoading: boolean;
  surveysManagementPagination: { items: SiteSurvey[], total: number };
  surveysManagementPaginationLoading: boolean;
  archivedSurveysPagination: { items: SiteSurvey[], total: number };
  archivedSurveysPaginationLoading: boolean;
  surveyAnswers: IDBSurveyObject[];
  extraObjectsTemplate: string;
  downloadReportProgressIds: string[];
  reportsGenerating: ReportGeneratingObject[];
  surveyTemplatePaginationLoading: boolean;
  surveyTemplatePagination: { items: SurveyTemplate[], total: number };
}

export const initialState: SurveyState = {
  isLoading: false,
  searchLoading: false,
  questionBank: [],
  objectBank: [],
  objectsPagination: { items: [], total: 0 },
  objectsPaginationLoading: false,
  objectsBankPagination: { items: [], total: 0 },
  objectsBankPaginationLoading: false,
  questionsBankPagination: { items: [], total: 0 },
  questionsBankPaginationLoading: false,
  surveyTemplates: [],
  surveys: [],
  surveysPagination: { items: [], total: 0 },
  surveysPaginationLoading: false,
  surveysManagementPagination: { items: [], total: 0 },
  surveysManagementPaginationLoading: false,
  archivedSurveysPagination: { items: [], total: 0 },
  archivedSurveysPaginationLoading: false,
  surveyAnswers: [],
  extraObjectsTemplate: null,
  downloadReportProgressIds: [],
  reportsGenerating: [],
  surveyTemplatePaginationLoading: false,
  surveyTemplatePagination: { items: [], total: 0 }
};
