import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { FrontEndRole } from '@models/roles/frontend-role.model';

export class CreateRoleRequestAction implements Action {
  readonly type = ActionTypes.CREATE_ROLE_REQUEST;

  constructor(public payload: { model: FrontEndRole[]; roleName: string }) {}
}

export class CreateRoleSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_ROLE_SUCCESS;
}

export class CreateRoleFailureAction implements Action {
  readonly type = ActionTypes.CREATE_ROLE_FAILURE;

  constructor(public payload: Error) {}
}
