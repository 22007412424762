import { GraphQLCustomQueryList, GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { SurveyObject } from '@models/survey/survey-object.model';

class GetSiteSurveyObjectById extends GraphQLQueryById<{ id: string }, { getSiteSurveyObject: SurveyObject }> {
  protected query = `query GetSiteSurveyObject($id: ID!) {
    getSiteSurveyObject(id: $id) {
      id
      parentId
      name
      createdBy
      questionsBody
      level
      label
      createdAt
      updatedAt
    }
  }
`;
}

class GetSiteSurveyObjectsList extends GraphQLQueryList<
  GraphQLQueryRequest<SurveyObject>,
  { listSiteSurveyObjects: { items: SurveyObject[] } }
> {
  protected query = `query ListSiteSurveyObjects(
    $filter: ModelSiteSurveyObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteSurveyObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentId
        name
        createdBy
        questionsBody
        level
        label
        createdAt
        updatedAt
      }
      nextToken
    }
  }`;
}

class SearchSurveyObjects extends GraphQLQueryList<GraphQLQueryRequest<SurveyObject>, { searchSiteSurveyObjects: { items: SurveyObject[] } }> {
  protected query = `query SearchSiteSurveyObjects(
    $filter: SearchableSiteSurveyObjectFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableSiteSurveyObjectSortInput
  ) {
    searchSiteSurveyObjects(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        parentId
        name
        createdBy
        questionsBody
        level
        label
        createdAt
        updatedAt
      }
      nextToken
    }
  }`;
}

class CustomSiteSurveyObjectsSearch extends GraphQLCustomQueryList<any, {
  customSiteSurveyObjectsSearch: { items: SurveyObject[], total: number }
}> {
  protected query = `query CustomSiteSurveyObjectsSearch(
    $filter: customSiteSurveyObjectsSearchFilter
    $from: Int!
    $limit: Int!
    $sort: customSiteSurveyObjectsSearchSort
  ) {
    customSiteSurveyObjectsSearch(filter: $filter, from: $from, limit: $limit, sort: $sort) {
      items {
        name
        numberOfQuestions
        createdAt
        createdBy
        createdDate
        id
        label
        level
        parentId
        questionsBody
        secondName
        updatedAt
        updatedDate
      }
      total
    }
  }`;
}

export const getSiteSurveyObjectById = new GetSiteSurveyObjectById();
export const getSiteSurveyObjectsList = new GetSiteSurveyObjectsList();
export const searchSurveyObjects = new SearchSurveyObjects();
export const customSiteSurveyObjectsSearch = new CustomSiteSurveyObjectsSearch();
