import { Action } from '@ngrx/store';

import { AmplifyAuthError } from '@interfaces/amplify-auth-error.interface';
import { ActionTypes } from '../action-types.enum';
import { UserInvite } from '@interfaces/user-invite.interface';

export class ResendSignUpRequestAction implements Action {
  readonly type = ActionTypes.RESEND_SIGN_UP_REQUEST;

  constructor(public payload: UserInvite) {}
}

export class ResendSignUpSuccessAction implements Action {
  readonly type = ActionTypes.RESEND_SIGN_UP_SUCCESS;
}

export class ResendSignUpFailureAction implements Action {
  readonly type = ActionTypes.RESEND_SIGN_UP_FAILURE;

  constructor(public payload: AmplifyAuthError) {}
}
