export enum SurveyStatusEnum {
  new = 'new',
  onHold = 'onHold',
  inProgress = 'inProgress',
  complete = 'complete',
  RequestToDelete = 'RequestToDelete',
  archived = 'archived',
}

export enum SurveyStatusEnumLabels {
  new = 'New',
  onHold = 'On hold',
  inProgress = 'In progress',
  complete = 'Complete',
  RequestToDelete = 'Request to delete',
  archived = 'Archived',
}
