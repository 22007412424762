import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { VMOCompany } from '@models/vmo-company.model';

class CreateCompany extends GraphQLMutation<VMOCompany, { createTeam: Partial<VMOCompany> }> {
  protected query = `mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
    }
  }`;
}

class UpdateCompany extends GraphQLMutation<Required<Partial<VMOCompany>, 'id'>, { updateTeam: Partial<VMOCompany> }> {
  protected query = `mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
    }
  }`;
}

class RemoveCompany extends GraphQLMutation<Required<Partial<VMOCompany>, 'id'>, { deleteTeam: Partial<VMOCompany> }> {
  protected query = `mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createCompany = new CreateCompany();
export const updateCompany = new UpdateCompany();
export const removeCompany = new RemoveCompany();
