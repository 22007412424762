import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { AmplifyAuthError } from './../../../core/interfaces/amplify-auth-error.interface';

export class LogoutRequestAction implements Action {
  readonly type = ActionTypes.LOGOUT_REQUEST;

  constructor(public payload: boolean = true) {}
}

export class LogoutSuccessAction implements Action {
  readonly type = ActionTypes.LOGOUT_SUCCESS;
}

export class LogoutFailureAction implements Action {
  readonly type = ActionTypes.LOGOUT_FAILURE;

  constructor(public payload: AmplifyAuthError) {}
}
