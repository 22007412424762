import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { ActionParams } from '@models/action-params.model';
import { AmplifyAuthError } from '@interfaces/amplify-auth-error.interface';

export class ChangePasswordRequestAction implements Action {
  readonly type = ActionTypes.CHANGE_PASSWORD_REQUEST;

  constructor(public payload: { oldPassword: string; newPassword: string, params?: ActionParams }) {}
}

export class ChangePasswordSuccessAction implements Action {
  readonly type = ActionTypes.CHANGE_PASSWORD_SUCCESS;
}

export class ChangePasswordFailureAction implements Action {
  readonly type = ActionTypes.CHANGE_PASSWORD_FAILURE;

  constructor(public payload: AmplifyAuthError) {}
}
