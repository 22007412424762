import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { Pagination } from '@models/pagination.model';
import { SurveyObject } from '@models/survey/survey-object.model';

export class ObjectsPaginationRequestAction implements Action {
  readonly type = ActionTypes.OBJECTS_PAGINATION_REQUEST;

  constructor(public payload: Pagination, public staticFilter?: { [key: string]: any} ) {}
}

export class ObjectsPaginationSuccessAction implements Action {
  readonly type = ActionTypes.OBJECTS_PAGINATION_SUCCESS;

  constructor(public payload: { items: SurveyObject[], total: number }) {}
}

export class ObjectsPaginationFailureAction implements Action {
  readonly type = ActionTypes.OBJECTS_PAGINATION_FAILURE;

  constructor(public payload: Error) {}
}

export class ObjectsBankPaginationRequestAction implements Action {
  readonly type = ActionTypes.OBJECTS_BANK_PAGINATION_REQUEST;

  constructor(public payload: Pagination, public staticFilter?: { [key: string]: any} ) {}
}

export class ObjectsBankPaginationSuccessAction implements Action {
  readonly type = ActionTypes.OBJECTS_BANK_PAGINATION_SUCCESS;

  constructor(public payload: { items: SurveyObject[], total: number }) {}
}

export class ObjectsBankPaginationFailureAction implements Action {
  readonly type = ActionTypes.OBJECTS_BANK_PAGINATION_FAILURE;

  constructor(public payload: Error) {}
}
