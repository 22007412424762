import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { ActionParams } from '@models/action-params.model';
import { SurveyReportKeysEnum } from '@enums/survey/survey-report-type.enum';

export class GenerateSurveyReportRequestAction implements Action {
  readonly type = ActionTypes.GENERATE_SURVEY_REPORT_REQUEST;

  constructor(
    public payload: {
      id: string;
      reportType: SurveyReportKeysEnum;
      reportsToReset: { [key: string]: null };
      params?: ActionParams
    }
  ) {}
}

export class GenerateSurveyReportSuccessAction implements Action {
  readonly type = ActionTypes.GENERATE_SURVEY_REPORT_SUCCESS;

  constructor(
    public payload: {
      id: string;
      reportType: SurveyReportKeysEnum;
    }
  ) {}
}

export class GenerateSurveyReportCompleteAction implements Action {
  readonly type = ActionTypes.GENERATE_SURVEY_REPORT_COMPLETE;

  constructor(
    public payload: {
      id: string;
      reportType: SurveyReportKeysEnum;
    }
  ) {}
}

export class GenerateSurveyReportFailureAction implements Action {
  readonly type = ActionTypes.GENERATE_SURVEY_REPORT_FAILURE;

  constructor(public payload: Error) {}
}
