import { Action } from '@ngrx/store';

import { VMOCompany } from '@models/vmo-company.model';
import { ActionParams } from '@models/action-params.model';
import { ActionTypes } from './../action-types.enum';

export class InviteUserRequestAction implements Action {
  readonly type = ActionTypes.INVITE_USER_REQUEST;

  constructor(public payload: { company: Partial<VMOCompany>; user: { email: string; role: string }; params?: ActionParams }) {}
}

export class InviteUserSuccessAction implements Action {
  readonly type = ActionTypes.INVITE_USER_SUCCESS;

  constructor(public payload?: boolean) {}
}

export class InviteUserFailureAction implements Action {
  readonly type = ActionTypes.INVITE_USER_FAILURE;

  constructor(public payload: Error) {}
}
