import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { UserAdditionalFile } from '@models/additional-file.model';

class CreateUserFile extends GraphQLMutation<UserAdditionalFile, { createUserAdditionalFiles: Partial<UserAdditionalFile> }> {
  protected query = `mutation CreateUserAdditionalFiles(
    $input: CreateUserAdditionalFilesInput!
    $condition: ModelUserAdditionalFilesConditionInput
  ) {
    createUserAdditionalFiles(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateUserFile extends GraphQLMutation<Required<Partial<UserAdditionalFile>, 'id'>, { updateUserAdditionalFiles: Partial<UserAdditionalFile> }> {
  protected query = `mutation UpdateUserAdditionalFiles(
    $input: UpdateUserAdditionalFilesInput!
    $condition: ModelUserAdditionalFilesConditionInput
  ) {
    updateUserAdditionalFiles(input: $input, condition: $condition) {
      id
    }
  }`;
}

class RemoveUserFile extends GraphQLMutation<Required<Partial<UserAdditionalFile>, 'id'>, { deleteUserAdditionalFiles: Partial<UserAdditionalFile> }> {
  protected query = `mutation DeleteUserAdditionalFiles(
    $input: DeleteUserAdditionalFilesInput!
    $condition: ModelUserAdditionalFilesConditionInput
  ) {
    deleteUserAdditionalFiles(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createUserFile = new CreateUserFile();
export const updateUserFile = new UpdateUserFile();
export const removeUserFile = new RemoveUserFile();
