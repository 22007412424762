import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';

export class ActivateCompanyRequestAction implements Action {
  readonly type = ActionTypes.ACTIVATE_COMPANY_REQUEST;

  constructor(public payload: string) {}
}

export class ActivateCompanySuccessAction implements Action {
  readonly type = ActionTypes.ACTIVATE_COMPANY_SUCCESS;
}

export class ActivateCompanyFailureAction implements Action {
  readonly type = ActionTypes.ACTIVATE_COMPANY_FAILURE;

  constructor(public payload: Error) {}
}
