import { UserAuthLog } from "@interfaces/user-authlog.interface";
import { GraphQLQueryList } from "@models/graphql/graphql-query.model";


class ListUsersAuthEvents extends GraphQLQueryList<any, { listUsersAuthEvents: { items: UserAuthLog[], total: number } }> {
  protected query = `query listUsersAuthEvents(
    $cognitoId: ID!
    $from: Int!
    $limit: Int!
  ) {
      listUsersAuthEvents(cognitoId: $cognitoId, from: $from, limit: $limit) {
      items {
        lastIPAddress
        deviceStatus
        deviceName
        deviceLastAuthenticatedDate
        deviceKey
      }
      total
    }
  }`;
}

export const listUsersAuthEvents = new ListUsersAuthEvents();
