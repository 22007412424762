import { Action } from '@ngrx/store';
import { ActionTypes } from '../../action-types.enum';
import { CaseComment } from '@models/case/case-comment.model';
import { ActionParams } from '@models/action-params.model';

export class CreateCommentRequestAction implements Action {
  readonly type = ActionTypes.CREATE_COMMENT_REQUEST;
  constructor(public payload: { model: CaseComment, params?: ActionParams}) {}
}

export class CreateCommentSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_COMMENT_SUCCESS;
}

export class CreateCommentFailureAction implements Action {
  readonly type = ActionTypes.CREATE_COMMENT_FAILURE;

  constructor(public payload: Error) {}
}
