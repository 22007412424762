import { GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { BackEndRole } from '@models/roles/backend-role.model';

class GetBackEndRoleById extends GraphQLQueryById<{ id: string }, { getRoleBackend: BackEndRole }> {
  protected query = `query GetRoleBackend($id: ID!) {
    getRoleBackend(id: $id) {
      id
      frontRoleName
      roleCognito
      crudRole
      createdAt
      updatedAt
    }
  }`;
}

class GetBackEndRoleList extends GraphQLQueryList<GraphQLQueryRequest<BackEndRole>, { listRoleBackends: { items: BackEndRole[] } }> {
  protected query = `query ListRoleBackends(
    $filter: ModelRoleBackendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleBackends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        frontRoleName
        roleCognito
        crudRole
        createdAt
        updatedAt
      }
      nextToken
    }
  }`;
}

class SearchBackEndRoles extends GraphQLQueryList<GraphQLQueryRequest<BackEndRole>, { searchRoleBackends: { items: BackEndRole[] } }> {
  protected query = `query SearchRoleBackends(
    $filter: SearchableRoleBackendFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableRoleBackendSortInput
  ) {
    searchRoleBackends(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        frontRoleName
        roleCognito
        crudRole
        createdAt
        updatedAt
      }
      nextToken
    }
  }`;
}

export const getBackEndRoleById = new GetBackEndRoleById();
export const getBackEndRoleList = new GetBackEndRoleList();
export const searchBackEndRoles = new SearchBackEndRoles();
