import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SurveyQuestion } from '@models/survey/survey-question.model';

export class LoadQuestionBankRequestAction implements Action {
  readonly type = ActionTypes.LOAD_QUESTION_BANK_REQUEST;
}

export class LoadQuestionBankSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_QUESTION_BANK_SUCCESS;

  constructor(public payload: SurveyQuestion[]) {}
}

export class LoadQuestionBankFailureAction implements Action {
  readonly type = ActionTypes.LOAD_QUESTION_BANK_FAILURE;

  constructor(public payload: Error) {}
}
