import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { RoleEffects } from './effects';
import { roleReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('survey-management', roleReducer),
    EffectsModule.forFeature([RoleEffects]),
  ],
  providers: [RoleEffects],
})
export class SurveyStoreModule {}
