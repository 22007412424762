import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { SiteSurvey } from '@models/survey/site-survey.model';
import { GraphQLQueryById } from '@models/graphql/graphql-query.model';

class CreateSiteSurvey extends GraphQLMutation<SiteSurvey, { createSiteSurvey: Partial<SiteSurvey> }> {
  protected query = `mutation CreateSiteSurvey(
    $input: CreateSiteSurveyInput!
    $condition: ModelSiteSurveyConditionInput
  ) {
    createSiteSurvey(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateSiteSurvey extends GraphQLMutation<Required<Partial<SiteSurvey>, 'id'>, { updateSiteSurvey: Partial<SiteSurvey> }> {
  protected query = `mutation UpdateSiteSurvey(
    $input: UpdateSiteSurveyInput!
    $condition: ModelSiteSurveyConditionInput
  ) {
    updateSiteSurvey(input: $input, condition: $condition) {
      id
      reportGenerated
    }
  }`;
}

class DeleteSiteSurvey extends GraphQLMutation<Required<Partial<SiteSurvey>, 'id'>, { deleteSiteSurvey: Partial<SiteSurvey> }> {
  protected query = `mutation DeleteSiteSurvey(
    $input: DeleteSiteSurveyInput!
    $condition: ModelSiteSurveyConditionInput
  ) {
    deleteSiteSurvey(input: $input, condition: $condition) {
      id
    }
  }`;
}

class GenerateSurveyReport extends GraphQLQueryById<{ siteSurveyId: string, reportType: string, userId: string }, never> {
  protected query = `mutation CreateSiteSurveyReport($siteSurveyId: String!, $reportType: String!, $userId: String) {
    createSiteSurveyReport(siteSurveyId: $siteSurveyId, reportType: $reportType, userId: $userId)
  }`;
}

export const createSiteSurvey = new CreateSiteSurvey();
export const updateSiteSurvey = new UpdateSiteSurvey();
export const deleteSiteSurvey = new DeleteSiteSurvey();
export const generateSurveyReport = new GenerateSurveyReport();
