import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { FileView } from '../../../core/interfaces/file-view.interface';

export class RemoveCompanyAdditionalFileRequestAction implements Action {
  readonly type = ActionTypes.REMOVE_COMPANY_ADDITIONAL_FILE_REQUEST;

  constructor(public payload: { file: FileView; salesforceId? }) {}
}

export class RemoveCompanyAdditionalFileSuccessAction implements Action {
  readonly type = ActionTypes.REMOVE_COMPANY_ADDITIONAL_FILE_SUCCESS;

  constructor(public payload: FileView[]) {}
}

export class RemoveCompanyAdditionalFileFailureAction implements Action {
  readonly type = ActionTypes.REMOVE_COMPANY_ADDITIONAL_FILE_FAILURE;

  constructor(public payload: Error) {}
}
