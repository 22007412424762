import { Actions } from './actions';
import { initialState, NotificationState } from './state';
import { ActionTypes } from './action-types.enum';

export function notificationReducer(state = initialState, action: Actions): NotificationState {
  switch (action.type) {
    case ActionTypes.LOAD_BELL_NOTIFICATIONS_REQUEST:
    case ActionTypes.NOTIFICATION_PAGINATION_REQUEST:
    case ActionTypes.NOTIFICATION_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true
      };

      case ActionTypes.LOAD_BELL_NOTIFICATIONS_FAILURE:
      case ActionTypes.NOTIFICATION_PAGINATION_FAILURE:
      case ActionTypes.NOTIFICATION_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.LOAD_BELL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        bellNotifications: [...action.payload],
        isLoading: false
      };

    case ActionTypes.NOTIFICATION_PAGINATION_SUCCESS:
    case ActionTypes.NOTIFICATION_UPDATE_SUCCESS:
      return {
        ...state,
        notifications: [...action.payload],
        isLoading: false
      };

    default: {
      return state;
    }
  }
}
