import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { CaseView } from "@models/case/case.model";

export class LoadCaseRequestAction implements Action {
  readonly type = ActionTypes.LOAD_CASE_REQUEST;

  constructor(public payload: string) {}
}

export class LoadCaseSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CASE_SUCCESS;

  constructor(public payload: CaseView) {}
}

export class LoadCaseFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CASE_FAILURE;

  constructor(public payload: Error) {}
}
