import { GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { CaseTimeLog } from '@models/case/case-timelog.model';

class GetCaseTimeLogsList extends GraphQLQueryList<GraphQLQueryRequest<CaseTimeLog>, { searchTimeLogs: { items: CaseTimeLog[] } }> {
  protected query = `query searchTimeLogs(
    $filter: SearchableTimeLogFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableTimeLogSortInput
  ) {
    searchTimeLogs(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        caseId
        checkInDate
        checkInLocation
        checkInTimestamp
        checkOutDate
        checkOutLocation
        checkOutTimestamp
        createdAt
        createdBy
        createdDate
        isActive
        updatedAt
        updatedBy
        updatedDate
        workedSeconds
        workedHours
      }
      nextToken
    }
  }`;
}

export const getCaseTimeLogsList = new GetCaseTimeLogsList();

