import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { IDBSurveyObject } from '@interfaces/indexed-db/idb-survey-object.interface';
import { SurveyQuestion } from '@models/survey/survey-question.model';
import { SurveyObject } from '@models/survey/survey-object.model';
import { SurveyTemplate } from '@models/survey/survey-template.model';
import { SiteSurvey } from '@models/survey/site-survey.model';
import { SurveyState } from './state';
import { ReportGeneratingObject } from '@models/survey/report-generating-object.model';

const getIsLoading = (state: SurveyState): boolean => state.isLoading;

const getSurveyTemplatePaginationLoading = (state: SurveyState): boolean => state.surveyTemplatePaginationLoading;

const getSurveyTemplatePagination = (state: SurveyState): { items: SurveyTemplate[], total: number } => state.surveyTemplatePagination;

const getSurveyManagementPagination = (state: SurveyState): { items: SiteSurvey[], total: number } => state.surveysManagementPagination;

const getSurveyManagementPaginationLoading = (state: SurveyState): boolean => state.surveysManagementPaginationLoading;

const getSearchLoading = (state: SurveyState): boolean => state.searchLoading;

const getObjectBank = (state: SurveyState): SurveyObject[] => state.objectBank;

const getObjectsPagination = (state: SurveyState): { items: SurveyObject[], total: number } => state.objectsPagination;

const getObjectsPaginationLoading = (state: SurveyState): boolean => state.objectsPaginationLoading;

const getObjectsBankPagination = (state: SurveyState): { items: SurveyObject[], total: number } => state.objectsBankPagination;

const getObjectsBankPaginationLoading = (state: SurveyState): boolean => state.objectsBankPaginationLoading;

const getQuestionsBankPagination = (state: SurveyState): { items: SurveyQuestion[], total: number } => state.questionsBankPagination;

const getQuestionsBankPaginationLoading = (state: SurveyState): boolean => state.questionsBankPaginationLoading;

const getQuestionBank = (state: SurveyState): SurveyQuestion[] => state.questionBank;

const getSurveyTemplatesList = (state: SurveyState): SurveyTemplate[] => state.surveyTemplates;

const getSurveyList = (state: SurveyState): SiteSurvey[] => state.surveys;

const getSurveyPagination = (state: SurveyState): { items: SiteSurvey[], total: number } => state.surveysPagination;

const getSurveyPaginationLoading = (state: SurveyState): boolean => state.surveysPaginationLoading;

const getArchivedSurveyPagination = (state: SurveyState): { items: SiteSurvey[], total: number } => state.archivedSurveysPagination;

const getArchivedSurveyPaginationLoading = (state: SurveyState): boolean => state.archivedSurveysPaginationLoading;

const getSurveyAnswers = (state: SurveyState): IDBSurveyObject[] => state.surveyAnswers;

const getExtraObjectsTemplate = (state: SurveyState): string => state.extraObjectsTemplate;

const getDownloadReportProgressIds = (state: SurveyState): string[] => state.downloadReportProgressIds;

const getReportsGenerating = (state: SurveyState): ReportGeneratingObject[] => state.reportsGenerating;

export const selectSurveyState: MemoizedSelector<object, SurveyState> = createFeatureSelector<SurveyState>(
  'survey-management'
);

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSurveyState,
  getIsLoading
);

export const selectSearchLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSurveyState,
  getSearchLoading
);

export const selectObjectBank: MemoizedSelector<object, SurveyObject[]> = createSelector(
  selectSurveyState,
  getObjectBank
);

export const selectObjectsPagination: MemoizedSelector<object, { items: SurveyObject[], total: number }> = createSelector(
  selectSurveyState,
  getObjectsPagination
);

export const selectObjectsPaginationLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSurveyState,
  getObjectsPaginationLoading
);

export const selectObjectsBankPagination: MemoizedSelector<object, { items: SurveyObject[], total: number }> = createSelector(
  selectSurveyState,
  getObjectsBankPagination
);

export const selectObjectsBankPaginationLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSurveyState,
  getObjectsBankPaginationLoading
);

export const selectQuestionsBankPagination: MemoizedSelector<object, { items: SurveyQuestion[], total: number }> = createSelector(
  selectSurveyState,
  getQuestionsBankPagination
);

export const selectQuestionsBankPaginationLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSurveyState,
  getQuestionsBankPaginationLoading
);

export const selectQuestionBank: MemoizedSelector<object, SurveyQuestion[]> = createSelector(
  selectSurveyState,
  getQuestionBank
);

export const selectSurveyTemplatesList: MemoizedSelector<object, SurveyTemplate[]> = createSelector(
  selectSurveyState,
  getSurveyTemplatesList
);

export const selectSurveyList: MemoizedSelector<object, SiteSurvey[]> = createSelector(
  selectSurveyState,
  getSurveyList
);

export const selectSurveyPagination: MemoizedSelector<object, { items: SiteSurvey[], total: number }> = createSelector(
  selectSurveyState,
  getSurveyPagination
);

export const selectSurveyPaginationLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSurveyState,
  getSurveyPaginationLoading
);

export const selectArchivedSurveyPagination: MemoizedSelector<object, { items: SiteSurvey[], total: number }> = createSelector(
  selectSurveyState,
  getArchivedSurveyPagination
);

export const selectArchivedSurveyPaginationLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSurveyState,
  getArchivedSurveyPaginationLoading
);

export const selectSurveyAnswers: MemoizedSelector<object, IDBSurveyObject[]> = createSelector(
  selectSurveyState,
  getSurveyAnswers
);

export const selectExtraObjectsTemplate: MemoizedSelector<object, string> = createSelector(
  selectSurveyState,
  getExtraObjectsTemplate
);

export const selectDownloadReportProgressIds: MemoizedSelector<object, string[]> = createSelector(
  selectSurveyState,
  getDownloadReportProgressIds
);

export const selectReportsGenerating: MemoizedSelector<object, ReportGeneratingObject[]> = createSelector(
  selectSurveyState,
  getReportsGenerating
);

export const selectSurveyTemplatePaginationLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSurveyState,
  getSurveyTemplatePaginationLoading
);

export const selectSurveyTemplatePagination: MemoizedSelector<object, { items: SurveyTemplate[], total: number }> = createSelector(
  selectSurveyState,
  getSurveyTemplatePagination
);

export const selectSurveyManagementPagination: MemoizedSelector<object, { items: SiteSurvey[], total: number }> = createSelector(
  selectSurveyState,
  getSurveyManagementPagination
);

export const selectSurveyManagementPaginationLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSurveyState,
  getSurveyManagementPaginationLoading
);
