import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AgreementContentConfig } from '@interfaces/agreement-content-config.interface';
import { AgreementModalComponent } from '@shared/components/agreement/agreement-modal/agreement-modal.component';
import { ConfirmationComponent } from '@shared/components/confirmation/confirmation.component';
import { CopyConfirmationComponent } from '@shared/components/copy-confirmation/copy-confirmation.component';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  public open<T, K = any>(component: Type<T>, params: { [key: string]: unknown } = {}): MatDialogRef<T, K> {
    return this.dialog.open(component, { ...params, autoFocus: false });
  }

  public confirm(title?: string, params: { [key: string]: unknown } = {}): MatDialogRef<ConfirmationComponent, boolean> {
    return this.dialog.open<ConfirmationComponent, { title: string }, boolean>(ConfirmationComponent, {
      ...params,
      data: { title },
    });
  }

  public copy(
    objectName: string,
    hasNestedObjects: boolean,
    params: { [key: string]: unknown } = {}
  ): MatDialogRef<CopyConfirmationComponent, { confirm: boolean, includeNested: boolean }> {
    return this.dialog.open<CopyConfirmationComponent, { objectName: string, hasNestedObjects: boolean }, { confirm: boolean, includeNested: boolean }>
    (CopyConfirmationComponent, {
      ...params,
      data: { objectName, hasNestedObjects }
    });
  }

  public openAgreement(data: AgreementContentConfig, params: { [key: string]: unknown } = {}): MatDialogRef<AgreementModalComponent, boolean> {
    return this.open<AgreementModalComponent, boolean>(AgreementModalComponent, {
      ...params,
      height: '75vh',
      width: '600px',
      data,
    });
  }
}
