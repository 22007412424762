import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SiteSurvey } from '@models/survey/site-survey.model';

export class CreateSiteSurveyRequestAction implements Action {
  readonly type = ActionTypes.CREATE_SITE_SURVEY_REQUEST;

  constructor(public payload: { logoInfo?; file?: File; survey: SiteSurvey, caseSalesforceId?: string }) {}
}

export class CreateSiteSurveySuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SITE_SURVEY_SUCCESS;
}

export class CreateSiteSurveyFailureAction implements Action {
  readonly type = ActionTypes.CREATE_SITE_SURVEY_FAILURE;

  constructor(public payload: Error) {}
}
