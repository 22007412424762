import { Action } from '@ngrx/store';

import { FileView } from './../../../core/interfaces/file-view.interface';
import { S3FileObject } from './../../../core/models/s3-file-object.model';
import { ActionTypes } from '../action-types.enum';

export class SaveCompanyInsuranceRequestAction implements Action {
  readonly type = ActionTypes.SAVE_COMPANY_INSURANCE_REQUEST;

  constructor(public payload: S3FileObject) {}
}

export class SaveCompanyInsuranceSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_COMPANY_INSURANCE_SUCCESS;

  constructor(public payload: FileView) {}
}

export class SaveCompanyInsuranceFailureAction implements Action {
  readonly type = ActionTypes.SAVE_COMPANY_INSURANCE_FAILURE;

  constructor(public payload: Error) {}
}
