import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, repeat, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';

import { HelperService } from '@services/helpers/helper.service';
import { FileService } from '@services/file.service';
import { SnackBarService } from '@services/snackbar.service';

import { ActionTypes } from './action-types.enum';
import { StorageFileKeyPrefixEnum } from '@enums/storage-file-key-prefix.enum';
import { CaseAdditionalFile } from '@models/additional-file.model';
import { S3FileObject } from '@models/s3-file-object.model';
import { CaseComment } from '@models/case/case-comment.model';
import { CaseEvent } from '@models/case/case-event.model';
import { CaseTimeLog } from '@models/case/case-timelog.model';
import { AdditionalExpense } from '@models/case/additional-expense.model';

import { createCaseAttachment, removeCaseAttachment } from '@graphql/case-files/mutations';
import { customCasesSearch, getCaseById } from '@graphql/case/queries';
import { createCaseComment, updateCaseComment } from '@graphql/case-comments/mutations';
import { updateCase } from '@graphql/case/mutations';
import { updateTask } from '@graphql/task/mutations';
import { getCaseEventsList } from '@graphql/case-events/queries';
import { getCaseTimeLogsList } from '@graphql/case-time-logs/queries';
import { getAdditionalExpensesList } from '@graphql/additional-expenses/queries';
import { createCaseTimeLog, updateCaseTimeLog } from '@graphql/case-time-logs/mutations';
import { createAdditionalExpense, updateAdditionalExpense } from '@graphql/additional-expenses/mutations';

import * as CaseActions from './case-actions';
import * as CaseSelectors from './selectors';
import { UserSelectors } from '@store/user-store';
import { RootStore } from '@store/index';

@Injectable()
export class CaseEffects {
  constructor(
    private readonly store$: Store<RootStore.AppState>,
    private readonly actions$: Actions,
    private readonly snackbar: SnackBarService,
    private readonly fileService: FileService
  ) {}

  @Effect()
  casePaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.CasePaginationRequestAction>(ActionTypes.CASE_PAGINATION_REQUEST),
    switchMap(({ payload }) => {
      const queryParams = {
        from: payload.from,
        limit: payload.limit
      };

      if (payload.filter) {
        queryParams['filter'] = payload.filter;
      }
      if (payload.sort) {
        queryParams['sort'] = payload.sort;
      }

      return customCasesSearch.execute(queryParams).then((res) => res?.data?.customCasesSearch);
    }),
    map((response) => new CaseActions.CasePaginationSuccessAction(response)),
    catchError((error) => of(new CaseActions.CasePaginationFailureAction(error)))
  );

  @Effect()
  caseEventsPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.CaseEventsPaginationRequestAction>(ActionTypes.CASE_EVENTS_PAGINATION_REQUEST),
    withLatestFrom(this.store$.select(CaseSelectors.selectCaseInfo)),
    switchMap(([{ payload }, singleCase]) => {
      let filter = { caseId: { eq: singleCase.id } };
      if (payload.filter) {
        filter = {...filter, ...payload.filter};
      }
      return combineLatest([
        getCaseEventsList
          .execute({ ...payload, filter, sort: { direction: 'desc', field: 'eventDate' } })
          .then((res) => res?.data?.searchCaseEvents?.items),
        payload.reset ? of([] as CaseEvent[]) : this.store$.select(CaseSelectors.selectCaseEventsList).pipe(take(1))
      ]);
    }),
    map(([response, caseEventsList]) => {
      response.forEach((caseEvent) => {
        caseEvent.createdDate = caseEvent.createdDate * 1000;
        caseEvent.byUserIDName = `${caseEvent.byUserID.firstname || ''} ${caseEvent.byUserID.lastname || ''}`;
      });

      return new CaseActions.LoadCaseEventsListSuccessAction([...caseEventsList, ...response]);
    }),
    catchError((error) => of(new CaseActions.LoadCaseEventsListFailureAction(error)))
  );

  @Effect()
  caseTimeLogsPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.CaseTimeLogsPaginationRequestAction>(ActionTypes.CASE_TIME_LOGS_PAGINATION_REQUEST),
    withLatestFrom(this.store$.select(CaseSelectors.selectCaseInfo)),
    switchMap(([{ payload }, singleCase]) => {
      let filter = { caseId: { eq: singleCase.id } };
      if (payload.filter) {
        filter = {...filter, ...payload.filter};
      }
      return combineLatest([
        getCaseTimeLogsList
          .execute({ ...payload, filter, sort: { direction: 'desc', field: 'updatedDate' } })
          .then((res) => res?.data?.searchTimeLogs?.items),
        payload.reset ? of([] as CaseTimeLog[]) : this.store$.select(CaseSelectors.selectCaseTimeLogsList).pipe(take(1))
      ]);
    }),
    map(([response, CaseTimeLogsList]) => {
      return new CaseActions.LoadCaseTimeLogsListSuccessAction([...CaseTimeLogsList, ...response]);
    }),
    catchError((error) => of(new CaseActions.CreateTimeLogObjectFailureAction(error)))
  );

  @Effect()
  loadCaseEventsListEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.LoadCaseEventsListRequestAction>(ActionTypes.LOAD_CASE_EVENTS_LIST_REQUEST),
    withLatestFrom(this.store$.select(CaseSelectors.selectCaseInfo)),
    switchMap(([_, singleCase]) => {
      const filter = { caseId: { eq: singleCase.id } };
      return getCaseEventsList.execute({ filter, sort: { direction: 'desc', field: 'eventDate' } }).then(res => res?.data?.searchCaseEvents?.items);
    }),
    map((events) => {
      events?.forEach((caseEvent) => {
        caseEvent.createdDate = caseEvent.createdDate * 1000;
        caseEvent.byUserIDName = `${caseEvent.byUserID.firstname || ''} ${caseEvent.byUserID.lastname || ''}`;
      });

      return new CaseActions.LoadCaseEventsListSuccessAction(events);
    }),
    catchError((error) => of(new CaseActions.LoadCaseEventsListFailureAction(error)))
  );

  @Effect()
  loadCaseTimeLogsListEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.LoadCaseTimeLogsListRequestAction>(ActionTypes.LOAD_CASE_TIME_LOGS_LIST_REQUEST),
    withLatestFrom(this.store$.select(CaseSelectors.selectCaseInfo)),
    switchMap(([_, singleCase]) => {
      const filter = { caseId: { eq: singleCase.id } };
      return getCaseTimeLogsList.execute(
        { filter, sort: { direction: 'desc', field: 'updatedDate' } }
      ).then(res => res?.data?.searchTimeLogs?.items);
    }),
    map((timeLogs) => {
      return new CaseActions.LoadCaseTimeLogsListSuccessAction(timeLogs);
    }),
    catchError((error) => of(new CaseActions.LoadCaseEventsListFailureAction(error)))
  );

  @Effect()
  loadCaseEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.LoadCaseRequestAction>(ActionTypes.LOAD_CASE_REQUEST),
    switchMap(({ payload }) => getCaseById.execute({ id: payload }).then((res) => res?.data?.getCase)),
    switchMap((singleCase) => {
      const attachments = singleCase.additionalFiles?.items || [];
      const files: Promise<CaseAdditionalFile>[] = [];
      Object.keys(singleCase).forEach((key) => {
        if (singleCase[key] === 'NULL') {
          singleCase[key] = null;
        }
      });

      attachments.forEach((item) => {
        if (item.link) {
          files.push(Promise.resolve(item));
        } else {
          files.push(this.fileService.getFile(item.image.key).then((link) => ({ ...item, link })));
        }
      });

      singleCase?.comments?.items.forEach((comment) => {
        comment.commentBody = comment.commentBody.replace(/\<br\>/g, '\n').replace(/<[^>]*>/g, '');
      });

      return Promise.all(files).then((items) => ({ ...singleCase, additionalFiles: { items } }));
    }),
    map((singleCase) => new CaseActions.LoadCaseSuccessAction(singleCase)),
    catchError((error) => of(new CaseActions.LoadCaseFailureAction(error))),
    repeat()
  );

  @Effect()
  updateCaseEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.UpdateCaseRequestAction>(ActionTypes.UPDATE_CASE_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(
      ([{ payload }, user]) => {
        const model = {
          ...payload.model,
          updatedBy: user.id
        };
        return updateCase
          .execute({ input: model })
          .then(() => {
            if (payload.model?.lastCheckInDate) {
              this.store$.dispatch(new CaseActions.CreateTimeLogObjectRequestAction({
                model: {
                  caseId: payload.model.id,
                  timeLogCaseIDId: payload.model.id,
                  checkInDate: payload.model.lastCheckInDate,
                  createdDate: HelperService.timestamp
                }
              }));
            } else if (payload.model?.lastCheckOutDate) {
              this.store$.dispatch(new CaseActions.UpdateTimeLogObjectRequestAction({
                model: {
                  id: payload.model.lastTimeLogId,
                  caseId: payload.model.id,
                  checkOutDate: payload.model.lastCheckOutDate,
                  updatedDate: HelperService.timestamp
                }
              }));
            }
            
            return payload;
          });
      }
    ),
    map((payload) => {
      payload.params?.callback?.();
      return new CaseActions.UpdateCaseSuccessAction();
    }),
    catchError((error) => of(new CaseActions.UpdateCaseFailureAction(error))),
    repeat()
  );

  @Effect()
  updateTaskEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.UpdateTaskRequestAction>(ActionTypes.UPDATE_TASK_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      const model = { ...payload.model, updatedBy: user.id };

      return updateTask.execute({ input: model }).then(() => payload.params?.callback?.());
    }),
    map(() => new CaseActions.UpdateTaskSuccessAction()),
    catchError((error) => of(new CaseActions.UpdateTaskFailureAction(error))),
    repeat()
  );

  @Effect()
  updateCaseCommentEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.UpdateCaseCommentRequestAction>(ActionTypes.UPDATE_CASE_COMMENT_REQUEST),
    switchMap(({ payload }) => updateCaseComment.execute({ input: payload.model }).then(() => payload)),
    map((payload) => {
      return new CaseActions.UpdateCaseCommentSuccessAction();
    }),
    catchError((error) => of(new CaseActions.UpdateCaseCommentFailureAction(error))),
    repeat()
  );

  @Effect()
  saveCaseAttachmentEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.SaveCaseAdditionalFileRequestAction>(ActionTypes.SAVE_CASE_ADDITIONAL_FILE_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      const requests = payload.files.map((file) => {
        const key = `${StorageFileKeyPrefixEnum.CaseFiles}/${payload.id}`;
        const s3Object = new S3FileObject(`${key}/${file.name}`, file.name);
        const attachment: CaseAdditionalFile = {
          caseId: payload.id,
          caseAdditionalFilesCaseIDId: payload.id,
          image: s3Object,
          createdBy: user.id
        };

        return this.fileService
          .saveFile(file, key)
          .then(() => this.fileService.getFile(`${key}/${file.name}`))
          .then((link) => {
            return createCaseAttachment.execute({ input: attachment }).then(
              (res) => {
                return {
                  ...attachment,
                  link,
                  id: res?.data?.createCaseAdditionalFiles?.id
                } as CaseAdditionalFile;
              });
          });
      });
      return Promise.all(requests);
    }),
    withLatestFrom(this.store$.select(CaseSelectors.selectCaseInfo)),
    map(([items, activeCase]) => {
      const singleCase = {
        ...activeCase,
        additionalFiles: { items: [...activeCase.additionalFiles.items, ...items] }
      };
      this.store$.dispatch(new CaseActions.LoadCaseSuccessAction(singleCase));
      return new CaseActions.SaveCaseAdditionalFileSuccessAction();
    }),
    catchError((error) => of(new CaseActions.SaveCaseAdditionalFileFailureAction(error))),
    repeat()
  );

  @Effect()
  removeCaseAttachmentEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.RemoveCaseAdditionalFileRequestAction>(
      ActionTypes.REMOVE_CASE_ADDITIONAL_FILE_REQUEST
    ),
    withLatestFrom(this.store$.select(CaseSelectors.selectCaseInfo)),
    switchMap(([{ payload }, activeCase]) => {
      const files = activeCase.additionalFiles.items;
      const filtered = files.filter((item) => item.id !== payload.file.id);
      const singleCase = { ...activeCase, additionalFiles: { items: filtered } };

      return combineLatest([
        removeCaseAttachment.execute({ input: { id: payload.file.id } }),
        this.fileService.removeFile(payload.file.image.key)
      ]).pipe(tap(() => this.store$.dispatch(new CaseActions.LoadCaseSuccessAction(singleCase))));
    }),
    map(() => new CaseActions.RemoveCaseAdditionalFileSuccessAction()),
    catchError((error) => of(new CaseActions.RemoveCaseAdditionalFileFailureAction(error))),
    repeat()
  );

  @Effect()
  createCommentEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.CreateCommentRequestAction>(ActionTypes.CREATE_COMMENT_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) =>
      createCaseComment.execute({
        input: {
          ...payload.model,
          createdById: user.id,
          updatedBy: user.id,
          caseCommentCreatedByIDId: user.id
        }
      }).then(
        (response) =>
          ({
            ...payload.model,
            id: response?.data?.createCaseComment?.id
          } as CaseComment)
      )
    ),
    map((payload) => {
      this.snackbar.success('Comment has been sent');
      this.store$.dispatch(new CaseActions.LoadCaseRequestAction(payload.caseId));
      return new CaseActions.CreateCommentSuccessAction();
    }),
    catchError((error) => of(new CaseActions.CreateCommentFailureAction(error))),
    repeat()
  );

  @Effect({ dispatch: false })
  caseErrorsEffect$: Observable<void> = this.actions$.pipe(
    ofType<Action & { payload: Error }>(
      ActionTypes.CASE_PAGINATION_FAILURE,
      ActionTypes.CREATE_COMMENT_FAILURE,
      ActionTypes.UPDATE_CASE_COMMENT_FAILURE,
      ActionTypes.LOAD_CASE_FAILURE,
      ActionTypes.UPDATE_TASK_FAILURE,
      ActionTypes.UPDATE_CASE_FAILURE
    ),
    map((action) => {
      console.log('action: ', action);
      this.snackbar.error(action.payload.message);
    })
  );

  @Effect()
  deleteCaseTimeLogEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.DeleteTimeLogObjectRequestAction>(ActionTypes.DELETE_CASE_TIME_LOG_OBJECT_REQUEST),
    withLatestFrom(this.store$.select(CaseSelectors.selectCaseTimeLogsList), this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, timeLogs, user]) => updateCaseTimeLog
      .execute({ input: { id: payload.id, isActive: false, updatedBy: user.id } })
      .then(() => {
        payload.params?.callback?.();

        return timeLogs.filter((item) => item.id !== payload.id);
      })
    ),
    map((items) => {
      setTimeout(() => this.snackbar.success('Time Log deleted successfully'), 2000);

      return new CaseActions.DeleteTimeLogObjectSuccessAction(items);
    }),
    catchError((error) => of(new CaseActions.DeleteTimeLogObjectFailureAction(error))),
    repeat()
  );

  @Effect()
  createTimeLogEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.CreateTimeLogObjectRequestAction>(ActionTypes.CREATE_CASE_TIME_LOG_OBJECT_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => createCaseTimeLog
      .execute({ input: { ...payload.model, createdBy: user.id, updatedBy: user.id } })
      .then((response) => {
        if (payload.model?.checkInDate && !payload.model.checkOutDate) {
          this.store$.dispatch(new CaseActions.UpdateCaseRequestAction({
            model: {
              id: payload.model.caseId,
              lastTimeLogId: response.data.createTimeLog.id
            }
          }));
        }
        payload.params?.callback?.();
      })
    ),
    map(() => {
      setTimeout(
        () => {
          this.snackbar.success('Time Log object created successfully');
        },
        2000
      );
      return new CaseActions.CreateTimeLogObjectSuccessAction();
    }),
    catchError((error) => of(new CaseActions.CreateTimeLogObjectFailureAction(error))),
    repeat()
  );

  @Effect()
  updateTimeLogEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.UpdateTimeLogObjectRequestAction>(ActionTypes.UPDATE_CASE_TIME_LOG_OBJECT_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => updateCaseTimeLog
      .execute({ input: { ...payload.model, updatedBy: user.id } })
      .then(() => {
        if (!payload.model?.checkInDate && payload.model.checkOutDate) {
          this.store$.dispatch(new CaseActions.UpdateCaseRequestAction({
            model: {
              id: payload.model.caseId,
              lastTimeLogId: null
            }
          }));
        }
        payload.params?.callback?.();
      })
    ),
    map(() => {
      setTimeout(
        () => {
          this.snackbar.success('Time Log object updated successfully');
        },
        2000
      );
      return new CaseActions.UpdateTimeLogObjectSuccessAction();
    }),
    catchError((error) => of(new CaseActions.UpdateTimeLogObjectFailureAction(error))),
    repeat()
  );

  @Effect()
  additionalExpensesPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.AdditionalExpensesPaginationRequestAction>(ActionTypes.ADDITIONAL_EXPENSES_PAGINATION_REQUEST),
    withLatestFrom(this.store$.select(CaseSelectors.selectCaseInfo)),
    switchMap(([{ payload }, singleCase]) => {
      let filter = { caseId: { eq: singleCase.id } };
      if (payload.filter) {
        filter = {...filter, ...payload.filter};
      }
      return combineLatest([
        getAdditionalExpensesList
          .execute({ ...payload, filter, sort: { direction: 'desc', field: 'updatedDate' } })
          .then((res) => res?.data?.searchAdditionalExpenses?.items),
        payload.reset ? of([] as AdditionalExpense[]) : this.store$.select(CaseSelectors.selectAdditionalExpensesList).pipe(take(1))
      ]);
    }),
    map(([response, additionalExpenses]) => {
      return new CaseActions.LoadAdditionalExpensesListSuccessAction([...additionalExpenses, ...response]);
    }),
    catchError((error) => of(new CaseActions.LoadAdditionalExpensesListFailureAction(error)))
  );

  @Effect()
  loadAdditionalExpensesListEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.LoadAdditionalExpensesListRequestAction>(ActionTypes.LOAD_ADDITIONAL_EXPENSES_LIST_REQUEST),
    withLatestFrom(this.store$.select(CaseSelectors.selectCaseInfo)),
    switchMap(([_, singleCase]) => {
      const filter = { caseId: { eq: singleCase.id } };
      return getAdditionalExpensesList.execute(
        { filter, sort: { direction: 'desc', field: 'updatedDate' } }
      ).then((res) => res?.data?.searchAdditionalExpenses?.items);
    }),
    map((additionalExpenses) => {
      return new CaseActions.LoadAdditionalExpensesListSuccessAction(additionalExpenses || []);
    }),
    catchError((error) => of(new CaseActions.LoadAdditionalExpensesListFailureAction(error))),
    repeat()
  );

  @Effect()
  createAdditionalExpenseEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.CreateAdditionalExpenseItemRequestAction>(
      ActionTypes.CREATE_CASE_ADDITIONAL_EXPENSE_ITEM_REQUEST
    ),
    switchMap(({payload}) => createAdditionalExpense
      .execute({ input: payload.model })
      .then((res) => {
        payload.params?.callback?.();
        return res?.data?.createAdditionalExpense;
      })
    ),
    map((item) => {
      setTimeout(
        () => {
          this.snackbar.success('Additional Expenses item added successfully');
        },
        2000
      );
      return new CaseActions.CreateAdditionalExpenseItemSuccessAction();
    }),
    catchError((error) => of(new CaseActions.CreateAdditionalExpenseItemFailureAction(error))),
    repeat()
  );

  @Effect()
  updateAdditionalExpenseEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.UpdateAdditionalExpenseItemRequestAction>(ActionTypes.UPDATE_CASE_ADDITIONAL_EXPENSE_ITEM_REQUEST),
    switchMap(({payload}) => updateAdditionalExpense
      .execute({ input: payload.model })
      .then((res) => {
        payload.params?.callback?.();

        return res?.data?.updateAdditionalExpense;
      })
    ),
    map(() => {
      setTimeout(() => this.snackbar.success('Additional Expenses item updated successfully'), 2000);

      return new CaseActions.CreateAdditionalExpenseItemSuccessAction();
    }),
    catchError((error) => of(new CaseActions.DeleteAdditionalExpenseObjectFailureAction(error))),
    repeat()
  );

  @Effect()
  deleteAdditionalExpenseEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CaseActions.DeleteAdditionalExpenseObjectRequestAction>(ActionTypes.DELETE_ADDITIONAL_EXPENSES_OBJECT_REQUEST),
    withLatestFrom(
      this.store$.select(CaseSelectors.selectAdditionalExpensesList),
      this.store$.select(UserSelectors.selectUser)
    ),
    switchMap(([{ payload }, additionalExpenses, user]) => updateAdditionalExpense
      .execute({ input: { id: payload.id, isActive: false, updatedBy: user.id } })
      .then(() => {
        payload.params?.callback?.();

        return additionalExpenses.filter((item) => item.id !== payload.id);
      })
    ),
    map((items) => {
      setTimeout(() => this.snackbar.success('Additional Expenses item deleted successfully'), 2000);

      return new CaseActions.DeleteAdditionalExpenseObjectSuccessAction(items || []);
    }),
    catchError((error) => of(new CaseActions.DeleteAdditionalExpenseObjectFailureAction(error))),
    repeat()
  );
}
