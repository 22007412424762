import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AuthStoreModule } from './auth-store';
import { UserStoreModule } from './user-store';
import { CompanyStoreModule } from './company-store';
import { SurveyStoreModule } from './survey-store';
import { RoleStoreModule } from './role-store';
import { CaseStoreModule } from './case-store';
import { NotificationStoreModule } from './notification-store';
import { RootEffects } from './effects';

import { environment } from '../../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([RootEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    AuthStoreModule,
    UserStoreModule,
    CompanyStoreModule,
    SurveyStoreModule,
    RoleStoreModule,
    CaseStoreModule,
    NotificationStoreModule
  ]
})
export class RootStoreModule {}
