import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SurveyTemplate } from '@models/survey/survey-template.model';
import { Pagination } from '@models/pagination.model';

export class SurveyTemplatesPaginationRequestAction implements Action {
  readonly type = ActionTypes.SURVEY_TEMPLATES_PAGINATION_REQUEST;

  constructor(public payload: Pagination) {}
}

export class SurveyTemplatesPaginationSuccessAction implements Action {
  readonly type = ActionTypes.SURVEY_TEMPLATES_PAGINATION_SUCCESS;

  constructor(public payload: { items: SurveyTemplate[], total: number }) {}
}

export class SurveyTemplatesPaginationFailureAction implements Action {
  readonly type = ActionTypes.SURVEY_TEMPLATES_PAGINATION_FAILURE;

  constructor(public payload: Error) {}
}