import { Action } from '@ngrx/store';

import { VMOCompany } from './../../../core/models/vmo-company.model';
import { FileField } from './../../../core/interfaces/file-field.interface';
import { ActionParams } from '../../../core/models/action-params.model';
import { ActionTypes } from '../action-types.enum';

export class CreateCompanyRequestAction implements Action {
  readonly type = ActionTypes.CREATE_COMPANY_REQUEST;

  constructor(public payload: { model: VMOCompany; files: FileField<File>[]; additionalFiles?: File[], params?: ActionParams }) {}
}

export class CreateCompanySuccessAction implements Action {
  readonly type = ActionTypes.CREATE_COMPANY_SUCCESS;
}

export class CreateCompanyFailureAction implements Action {
  readonly type = ActionTypes.CREATE_COMPANY_FAILURE;

  constructor(public payload: Error) {}
}
