export class Email {
  public text: string;
  public to: string | string[];

  public subject?: string = 'POST';

  constructor(source: Email) {
    Object.assign(this, source);
  }
}
