import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { AuthState } from './state';

const getIsLoading = (state: AuthState): boolean => state.isLoading;

const getResetSuperAdminPassword = (state: AuthState): boolean => state.resetSuperAdminPassword;

const getUserIsAuthenticated = (state: AuthState): boolean => state.isAuthenticated;

export const selectAuthState: MemoizedSelector<object, AuthState> = createFeatureSelector<AuthState>('auth');

export const selectAuthIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAuthState,
  getIsLoading
);

export const selectResetSuperAdminPassword: MemoizedSelector<object, boolean> = createSelector(
  selectAuthState,
  getResetSuperAdminPassword
);

export const selectIsUserAuthenticated: MemoizedSelector<object, boolean> = createSelector(
  selectAuthState,
  getUserIsAuthenticated
);
