import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { UserInvite } from '@interfaces/user-invite.interface';

export class LoadAllInvitesListRequestAction implements Action {
  readonly type = ActionTypes.LOAD_ALL_INVITES_LIST_REQUEST;
  }

export class LoadAllInvitesListSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ALL_INVITES_LIST_SUCCESS;

  constructor(public payload: UserInvite[]) {}
}

export class LoadAllInvitesListFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ALL_INVITES_LIST_FAILURE;

  constructor(public payload: Error) {}
}
