import { GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { SurveyTemplate } from '@models/survey/survey-template.model';

class GetSurveyTemplateById extends GraphQLQueryById<{ id: string }, { getSiteSurveyTemplate: SurveyTemplate }> {
  protected query = `query GetSiteSurveyTemplate($id: ID!) {
    getSiteSurveyTemplate(id: $id) {
      id
      name
      salesforceId
      templateBody
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      surveyLogo {
        bucket
        region
        key
        name
      }
    }
  }
`;
}

class SearchSurveyTemplates extends GraphQLQueryList<GraphQLQueryRequest<SurveyTemplate>, {
  searchSiteSurveyTemplates: { items: SurveyTemplate[] }
}> {
  protected query = `query SearchSiteSurveyTemplates(
    $filter: SearchableSiteSurveyTemplateFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableSiteSurveyTemplateSortInput
  ) {
    searchSiteSurveyTemplates(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        name
        templateBody
        salesforceId
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        surveyLogo {
          bucket
          region
          key
          name
        }
      }
      nextToken
    }
  }`;
}

class CustomSiteSurveyTemplateSearch extends GraphQLQueryList<any, {
  customSiteSurveyTemplateSearch: { items: SurveyTemplate[], total: number }
}> {
  protected query = `query customSiteSurveyTemplateSearch(
    $filter: customSiteSurveyTemplateSearchFilter
    $from: Int!
    $limit: Int!
    $sort: customSiteSurveyTemplateSearchSort
  ) {
    customSiteSurveyTemplateSearch(filter: $filter, from: $from, limit: $limit, sort: $sort) {
      items {
        name
        createdAt
        createdBy
        createdDate
        id
        isActive
        salesforceId
        sequence
        status
        statusSearch
        templateBody
        updatedAt
        updatedBy
        updatedDate
      }
      total
    }
  }`;
}

export const getSurveyTemplateById = new GetSurveyTemplateById();
export const searchSurveyTemplates = new SearchSurveyTemplates();
export const customSearchSurveyTemplates = new CustomSiteSurveyTemplateSearch();
