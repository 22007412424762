import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { BackEndRole } from '@models/roles/backend-role.model';

class CreateBackEndRole extends GraphQLMutation<BackEndRole, { createRoleBackend: Partial<BackEndRole> }> {
  protected query = `mutation CreateRoleBackend(
    $input: CreateRoleBackendInput!
    $condition: ModelRoleBackendConditionInput
  ) {
    createRoleBackend(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateBackEndRole extends GraphQLMutation<Required<Partial<BackEndRole>, 'id'>, { updateRoleBackend: Partial<BackEndRole> }> {
  protected query = `mutation UpdateRoleBackend(
    $input: UpdateRoleBackendInput!
    $condition: ModelRoleBackendConditionInput
  ) {
    updateRoleBackend(input: $input, condition: $condition) {
      id
    }
  }`;
}

class RemoveBackEndRole extends GraphQLMutation<Required<Partial<BackEndRole>, 'id'>, { deleteRoleBackend: Partial<BackEndRole> }> {
  protected query = `mutation DeleteRoleBackend(
    $input: DeleteRoleBackendInput!
    $condition: ModelRoleBackendConditionInput
  ) {
    deleteRoleBackend(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createBackEndRole = new CreateBackEndRole();
export const updateBackEndRole = new UpdateBackEndRole();
export const removeBackEndRole = new RemoveBackEndRole();
