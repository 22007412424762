import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of, combineLatest, from } from 'rxjs';

import { HelperService } from '@services/helpers/helper.service';
import { defaultRoleTemplateName } from '@app/constants';
import { BackEndRole } from '@models/roles/backend-role.model';
import { FrontEndRole } from '@models/roles/frontend-role.model';
import { BackEndRoles } from '@enums/roles/backend-role.enum';

import { getBackEndRoleList } from '@graphql/backend-roles/queries';
import { getFrontEndRoleList } from '@graphql/frontend-roles/queries';
import { createBackEndRole, updateBackEndRole } from '@graphql/backend-roles/mutations';
import { createFrontEndRole, removeFrontEndRole, updateFrontEndRole } from '@graphql/frontend-roles/mutations';

@Injectable({ providedIn: 'root' })
export class RoleHelperService {
  public fetchBackendRoles(): Observable<BackEndRole[]> {
    return from(
      getBackEndRoleList.execute({
        filter: {
          frontRoleName: {
            ne: defaultRoleTemplateName,
          },
        },
      })
    ).pipe(
      map((roles) => roles?.data?.listRoleBackends?.items || []),
      catchError(this.errorHandler)
    );
  }

  public fetchFrontEndRole(roleName: string): Observable<FrontEndRole[]> {
    return from(
      getFrontEndRoleList.execute({
        filter: {
          roleName: {
            eq: roleName,
          },
        },
      })
    ).pipe(
      map((res) => {
        const roles: FrontEndRole[] = res?.data?.listRoleFrontends?.items?.map((item) => ({
          ...item,
          roleBlock: JSON.parse(item.roleBlock),
        }));

        return roles;
      }),
      catchError(this.errorHandler)
    );
  }

  public createNewRole(roleName: string, model: FrontEndRole[]): Observable<Partial<FrontEndRole>[]> {
    return this.createBackEndRole(roleName, model).pipe(
      switchMap((backRole) => this.createFrontEndRole(model, roleName, backRole?.id))
    );
  }

  public updateBackendRole(roleName: string, frontEndRoles: FrontEndRole[], backendRole: BackEndRole): Observable<Partial<BackEndRole>> {
    const crudRole = this.createBackEndCrudRole(frontEndRoles);
    const roleCognito = (roleName as string)
      .split(' ')
      .map((value) => value.trim())
      .filter((value) => !!value)
      .join('');

    return from(
      updateBackEndRole.execute({
        input: { id: backendRole.id, frontRoleName: roleName, crudRole, roleCognito },
      })
    ).pipe(
      map((response) => response?.data?.updateRoleBackend),
      catchError(this.errorHandler)
    );
  }

  private createBackEndRole(roleName: string, model: FrontEndRole[]): Observable<Partial<BackEndRole>> {
    const crudRole = this.createBackEndCrudRole(model);
    const roleCognito = roleName
      .split(' ')
      .map((value) => value.trim())
      .filter((value) => !!value)
      .join('');

    return from(
      createBackEndRole.execute({ input: { frontRoleName: roleName, crudRole, roleCognito, createdDate: HelperService.timestamp } })
    ).pipe(
      map((response) => response?.data?.createRoleBackend),
      catchError(this.errorHandler)
    );
  }

  public createFrontEndRole(roles: FrontEndRole[], roleName: string, backRoleId: string): Observable<Partial<FrontEndRole>[]> {
    const frontEndRoles = this.createFrontEndRoleModels(roles, roleName, backRoleId);
    const requests = frontEndRoles.map((input) => createFrontEndRole.execute({ input }));

    return combineLatest(requests).pipe(
      map((response) => response.map((res) => res?.data?.createRoleFrontend)),
      catchError(this.errorHandler)
    );
  }

  public updateFrontEndRole(roles: FrontEndRole[], roleName: string, backRoleId: string): Observable<Partial<FrontEndRole>[]> {
    const frontEndRoles = this.createFrontEndRoleModels(roles, roleName, backRoleId);
    const requests = frontEndRoles.map((input) => updateFrontEndRole.execute({ input: { ...input, id: input.id } }));

    return combineLatest(requests).pipe(
      map((response) => response.map((res) => res?.data?.updateRoleFrontend)),
      catchError(this.errorHandler)
    );
  }

  public deleteFrontEndRole(roles: FrontEndRole[]): Observable<Partial<FrontEndRole>[]> {
    const requests = roles.map((input) => removeFrontEndRole.execute({ input: { id: input.id } }));

    return combineLatest(requests).pipe(
      map((response) => response.map((res) => res?.data?.deleteRoleFrontend)),
      catchError(this.errorHandler)
    );
  }

  private errorHandler<T>(error: T): Observable<T> {
    console.log('error: ', error);
    return of(error);
  }

  private createBackEndCrudRole(model: FrontEndRole[]): string {
    const roles = model.map((role) => {
      const blocks = role.roleBlock.map((block) => {
        const permissions = block.permissions.filter((permission) => permission.value);
        return permissions.map((item) => BackEndRoles[role.backendRoleType][item.name]);
      });

      return [].concat.apply([], blocks);
    });

    const merged = [].concat.apply([], roles);

    return [...new Set(merged)].join(',');
  }

  private createFrontEndRoleModels(model: FrontEndRole[], roleName: string, backRoleId: string): FrontEndRole<string>[] {
    return model.map((role) => {
      const roleModel: FrontEndRole<string> = {
        roleName,
        viewName: role.viewName,
        route: role.route,
        roleBlock: JSON.stringify(role.roleBlock),
        backendRoleType: role.backendRoleType,
        roleFrontendRoleBackId: backRoleId,
        id: role.id,
        createdDate: HelperService.timestamp
      };

      return roleModel;
    });
  }
}
