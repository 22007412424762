import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { CaseAdditionalFile } from '@models/additional-file.model';

export class RemoveCaseAdditionalFileRequestAction implements Action {
  readonly type = ActionTypes.REMOVE_CASE_ADDITIONAL_FILE_REQUEST;

  constructor(public payload: { file: CaseAdditionalFile; salesforceId: string; }) {}
}

export class RemoveCaseAdditionalFileSuccessAction implements Action {
  readonly type = ActionTypes.REMOVE_CASE_ADDITIONAL_FILE_SUCCESS;
}

export class RemoveCaseAdditionalFileFailureAction implements Action {
  readonly type = ActionTypes.REMOVE_CASE_ADDITIONAL_FILE_FAILURE;

  constructor(public payload: Error) {}
}
