import { UserRequest } from '@interfaces/user-request.interface';
import { GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';

class GetUserRequestById extends GraphQLQueryById<{ id: string }, { getUserRequest: UserRequest }> {
  protected query = `query GetUserRequest($id: ID!) {
    getUserRequest(id: $id) {
      id
      email
      firstName
      lastName
      phone
      status
      approvedBy
      rejectedBy
      createdAt
      updatedAt
    }
  }
`;
}

class GetUserRequestsList extends GraphQLQueryList<GraphQLQueryRequest<UserRequest>, { listUserRequests: { items: UserRequest[] } }> {
  protected query = `query ListUsersRequests(
    $filter: ModelUserRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        phone
        status
        approvedBy {
          id
          firstname
          lastname
        }
        rejectedBy {
          id
          firstname
          lastname
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }`;
}

class SearchUserRequests extends GraphQLQueryList<GraphQLQueryRequest<UserRequest>, { searchUserRequests: { items: UserRequest[] } }> {
  protected query = `query SearchUserRequests(
    $filter: SearchableUserRequestFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableUserRequestSortInput
  ) {
    searchUserRequests(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        email
        firstName
        lastName
        phone
        status
        approvedBy {
          id
          firstname
          lastname
        }
        rejectedBy {
          id
          firstname
          lastname
        }
        createdAt
        createdDate
        updatedAt
      }
      nextToken
    }
  }`;
}

class SearchUserRequestsByEmail extends GraphQLQueryList<any, { searchUserRequestsByEmail: { items: UserRequest[], total: number } }> {
  protected query = `query SearchUserRequestsByEmail(
    $filter: searchUserRequestsByEmailFilter
    $from: Int!
    $limit: Int!
    $sort: searchUserRequestsByEmailSort
  ) {
    searchUserRequestsByEmail(filter: $filter, from: $from, limit: $limit, sort: $sort) {
      items {
        id
        email
        firstName
        lastName
        phone
        status
        approvedBy {
          id
          firstname
          lastname
        }
        rejectedBy {
          id
          firstname
          lastname
        }
        createdAt
        createdDate
        updatedAt
      }
      total
    }
  }`;
}

export const getUserRequestById = new GetUserRequestById();
export const getUserRequestsList = new GetUserRequestsList();
export const searchUserRequests = new SearchUserRequests();
export const searchUserRequestsByEmail = new SearchUserRequestsByEmail();
