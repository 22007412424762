import { GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { Case, CaseView } from '@models/case/case.model';

class GetCaseById extends GraphQLQueryById<{ id: string }, { getCase: CaseView }> {
  protected query = `query GetCase($id: ID!) {
    getCase(id: $id) {
      approvedExpenses
      name
      salesforceId
      status
      id
      subject
      typeOfWork
      template
      clientName
      projectName
      workorderId
      serviceDateTime
      locationName
      contactName
      pricingMethod
      pricingBaseRate
      totalPayment
      pricingAdditionalRate
      contactPhone
      managerEmail
      managerName
      managerPhone
      maxHours
      contactEmail
      description
      specificInstruction
      customerSiteId
      customCustTicket
      CV_Case
      CV_Assigned_dt
      serviceContract
      serviceLevel
      UPS_District
      CSC_WO_Number
      PO_NBR
      units1Installed
      units2Installed
      units3Installed
      techId
      techID {
        id
        salesforceId
        firstname
        lastname
        teamId
        role
        status
        phone
        email
      }
      techName
      techMobilePhoneNumber
      techEmailAddress
      autoAssigned
      createdBy
      caseNumber
      approvedBy
      approvedDateTime
      canceledBy
      paidDateTime
      canceledDateTime
      paidBy
      firstCheckInDate
      lastCheckInDate
      lastCheckOutDate
      lastTimeLogId
      hoursWorked
      techClosingNote
      insuranceFee
      isActive
      createdAt
      updatedAt
      createdDate
      updatedDate
      address1
      address2
      city
      country
      state
      zipCode
      workDoneDateTime
      surveyId
      surveyTemplateId
      payStructure
      projectId
      projectID {
        clientId
        id
        name
        salesforceClientId
        salesforceId
      }
      clientId
      clientID {
        id
        name
        salesforceId
      }
      onMyWayDate
      actionRequired
      tasks(filter: {isActive: {eq: true}}) {
        items {
          id
          subject
          description
          type
          isActive
          isCompleted
          priority
          subject
          updatedDate
          activityDate
          caseId
          salesforceId
        }
      }
      comments(filter: {isActive: {eq: true}}) {
        items {
          id
          commentBody
          createdAt
          updatedAt
          updatedDate
          isActive
          parentCommentId
          createdByID {
            firstname
            lastname
          }
          salesforceCreatedBy
        }
      }
      additionalFiles(filter: {isActive: {ne: false}}) {
        items {
          caseId
          createdAt
          salesforceId
          updatedAt
          id
          isExternal
          image {
            bucket
            key
            region
            name
          }
        }
      }
      sites {
        items {
          name
          id
        }
      }
      timeLogs(filter: {isActive: {eq: true}}) {
        items {
          id
          caseId
          createdAt
          updatedAt
          createdDate
          updatedDate
          checkInDate
          checkOutDate
          checkInLocation
          checkInTimestamp
          checkOutLocation
          checkOutTimestamp
          workedSeconds
          workedHours
        }
      }
    }
  }`;
}

class CustomCasesSearch extends GraphQLQueryList<any, { customCasesSearch: { items: Case[], total: number } }> {
  protected query = `query CustomCasesSearch(
    $filter: customCasesSearchFilter
    $from: Int!
    $limit: Int!
    $sort: customCasesSearchSort
  ) {
    customCasesSearch(filter: $filter, from: $from, limit: $limit, sort: $sort) {
      items {
        id
        serviceDateTime,
        techName,
        status,
        actionRequired,
        pricingBaseRate,
        totalPayment,
        clientName,
        location,
        caseNumber,
        name
      }
      total
    }
  }`;
}

class SearchCases extends GraphQLQueryList<GraphQLQueryRequest<Case>, { searchCases: { items: Case[] } }> {
  protected query = `query SearchCases(
    $filter: SearchableCaseFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableCaseSortInput
  ) {
    searchCases(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        name
        salesforceId
        status
        id
        subject
        typeOfWork
        template
        caseNumber
        clientName
        projectName
        workorderId
        serviceDateTime
        locationName
        contactName
        pricingMethod
        pricingBaseRate
        totalPayment
        pricingAdditionalRate
        contactPhone
        managerEmail
        managerName
        managerPhone
        contactEmail
        description
        specificInstruction
        customerSiteId
        customCustTicket
        CV_Case
        CV_Assigned_dt
        serviceContract
        serviceLevel
        UPS_District
        CSC_WO_Number
        PO_NBR
        units1Installed
        units2Installed
        units3Installed
        techId
        techName
        techMobilePhoneNumber
        techEmailAddress
        autoAssigned
        createdBy
        approvedBy
        approvedDateTime
        canceledBy
        paidDateTime
        canceledDateTime
        paidBy
        firstCheckInDate
        lastCheckInDate
        lastCheckOutDate
        lastTimeLogId
        hoursWorked
        techClosingNote
        isActive
        createdAt
        updatedAt
        createdDate
        updatedDate
        onMyWayDate
        address1
        address2
        city
        country
        state
        zipCode
        workDoneDateTime
        actionRequired
        surveyId
      }
      nextToken
    }
  }`;
}

export const getCaseById = new GetCaseById();
export const customCasesSearch = new CustomCasesSearch();
export const searchCases = new SearchCases();
