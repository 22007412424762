import { Action } from '@ngrx/store';
import { Required } from 'utility-types';

import { ActionTypes } from '../action-types.enum';
import { UserInvite } from '@interfaces/user-invite.interface';

export class UpdateUserInviteRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_USER_INVITE_REQUEST;

  constructor(public payload: Required<Partial<UserInvite>, 'email'>) {}
}

export class UpdateUserInviteSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_USER_INVITE_SUCCESS;
}

export class UpdateUserInviteFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_USER_INVITE_FAILURE;

  constructor(public payload: Error) {}
}
