<div class="wrapper position-relative">
  <ng-container *ngIf="agreements; else loader">
    <h2 class="text-center">
      {{ config.label || config.type === agreementTypes.UserAgreement ? 'Terms of Use' : 'Independent Contractor Agreement' }}
    </h2>
    <div id="print-content" class="content" (scroll)="onScroll($event)">
      <h2 class="print-only" style="text-align: center;">
        {{ config.label || config.type === agreementTypes.UserAgreement ? 'Terms of Use' : 'Independent Contractor Agreement' }}
      </h2>
      <div [innerHTML]="agreements | safeHtml"></div>
    </div>

    <div *ngIf="config.type === agreementTypes.ServicePartnerAgreementUSA" class="company-agreement-explanation">
      Both parties consent to the use of an electronic signature in lieu of a written signature.
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button
        *ngIf="agreements"
        type="button"
        class="print-btn mr-1"
        mat-raised-button
        printSectionId="print-content"
        printTitle="{{ config.label || config.type === agreementTypes.UserAgreement ? 'Terms of Use' : 'Independent Contractor Agreement' }}"
        ngxPrint
      >Print</button>
      <button type="button" class="close-btn" mat-raised-button mat-dialog-close>Close</button>
      <button
        *ngIf="!config.readonly"
        [disabled]="!wasScrolledDown"
        [mat-dialog-close]="true"
        type="button"
        class="accept-btn ml-2"
        mat-raised-button
      >
        <span *ngIf="config.type === agreementTypes.UserAgreement || config.type === agreementTypes.ServicePartnerAgreement">Accept</span>
        <span *ngIf="config.type === agreementTypes.ServicePartnerAgreementUSA">eSign Agreement</span>
      </button>
    </div>
  </ng-container>
</div>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>
