import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

import { LoginModel } from '@models/login.model';
import { SnackBarService } from '@services/snackbar.service';
import { CognitoUser } from '@interfaces/cognito.user.interface';
import { SignUpParams } from '@interfaces/sign-up-params.interface';
import { SetNewPassword } from '@interfaces/set-new-password.interface';
import { AmplifyErrorCodesEnum } from '@enums/amplify-error-codes.enum';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AmplifyService {
  constructor(private readonly snackbar: SnackBarService) {}

  public async signUp(model: SignUpParams) {
    try {
      return await Auth.signUp(model);
    } catch (error) {
      this.snackbar.error(error?.message);
      throw new Error(error?.message);
    }
  }

  public async confirmSignUp(username: string, code: string) {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      this.snackbar.error(error?.message);
      throw new Error(error?.message);
    }
  }

  public singIn(model: LoginModel): Promise<CognitoUser> {
    try {
      return Auth.signIn(model);
    } catch (error) {
      throw new Error(error?.message);
    }
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      await Auth.changePassword(await Auth.currentAuthenticatedUser(), oldPassword, newPassword);
      this.snackbar.success('Your password has been successfully changed');
    } catch (error) {
      let message = error?.message;

      if (error.code === AmplifyErrorCodesEnum.NotAuthorizedException) {
        message = 'Invalid old password';
      } else if (error.code === AmplifyErrorCodesEnum.InvalidParameterException) {
        message = 'New password should be at least 8 characters and include at least 1 lowercase, 1 uppercase and 1 number';
      }

      this.snackbar.error(message);
      throw new Error(message);
    }
  }

  public async resetPassword(username: string): Promise<void> {
    try {
      await Auth.forgotPassword(username);
      this.snackbar.success('Verification code was sent to your email');
    } catch (error) {
      this.snackbar.error(error?.message);
      throw new Error(error?.message);
    }
  }

  public async confirmForgotPassword({ username, confirmCode, password }: SetNewPassword): Promise<void> {
    try {
      await Auth.forgotPasswordSubmit(username, confirmCode, password);
    } catch (error) {
      this.snackbar.error(error?.message);
      throw new Error(error?.message);
    }
  }

  public async resendSignUp(email: string): Promise<void> {
    try {
      await Auth.resendSignUp(email);
    } catch (error) {
      this.snackbar.error(error?.message);
      throw new Error(error?.message);
    }
  }

  public async logout(): Promise<void> {
    try {
      await Auth.signOut();
    } catch (error) {
      this.snackbar.error(error?.message);
      throw new Error(error?.message);
    }
  }

  public loginAsAdmin(): Promise<CognitoUser> {
    return this.singIn({
      username: environment.superAdminEmail,
      password: environment.superAdminPassword,
    });
  }

  public getCurrentUserInfo(): Promise<SignUpParams> {
    return Auth.currentUserInfo();
  }
}
