import { Action } from '@ngrx/store';

import { UserView } from '../../../core/models/user.model';
import { GraphQLFilter } from '../../../core/models/graphql/graphql-filter.model';
import { ActionTypes } from '../action-types.enum';

export class LoadCurrentUserRequestAction implements Action {
  readonly type = ActionTypes.LOAD_CURRENT_USER_REQUEST;

  constructor(public payload?: string | GraphQLFilter) {}
}

export class LoadCurrentUserSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CURRENT_USER_SUCCESS;

  constructor(public payload: UserView) {}
}

export class LoadCurrentUserFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CURRENT_USER_FAILURE;

  constructor(public payload: Error) {}
}
