import { GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { UserAdditionalFile } from '@models/additional-file.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';

class GetUserFileById extends GraphQLQueryById<{ id: string }, { getUserAdditionalFiles: UserAdditionalFile }> {
  protected query = `query GetUserAdditionalFiles($id: ID!) {
    getUserAdditionalFiles(id: $id) {
      createdAt
      id
      image {
        region
        name
        key
        bucket
      }
      updatedAt
      userId
    }
  }`;
}

class GetUserFilesList extends GraphQLQueryList<GraphQLQueryRequest<UserAdditionalFile>, { listUserAdditionalFiless: { items: UserAdditionalFile[] } }> {
  protected query = `query ListUserAdditionalFiless(
    $filter: ModelUserAdditionalFilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAdditionalFiless(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        image {
          region
          name
          key
          bucket
        }
        updatedAt
        userId
      }
      nextToken
    }
  }`;
}

export const getUserFileById = new GetUserFileById();
export const getUserFilesList = new GetUserFilesList();
