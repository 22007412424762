import { GraphQLResult } from '@aws-amplify/api/lib/types';

import { GraphQLMutationRequest } from '@interfaces/graphql/graphql-mutation-request.interface';
import { GraphQLApiService } from '@services/graphql/graphql-api.service';

export class GraphQLMutation<Req, Res> {
  protected query: string;

  public execute(params: GraphQLMutationRequest<Req>): Promise<GraphQLResult<Res>> {
    return GraphQLApiService.request<GraphQLMutationRequest<Req>, Res>(this.query, params);
  }
}
