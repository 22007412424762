<h2 mat-dialog-title>Copy '{{ data.objectName }}' object?</h2>

<div *ngIf="data.hasNestedObjects" class="d-flex justify-content-center mt-3">
  <mat-checkbox [(ngModel)]="includeNested">Include nested objects</mat-checkbox>
</div>

<mat-dialog-actions align="end" class="p-2 pb-3">
  <button mat-raised-button mat-dialog-close class="cancel-btn">Cancel</button>
  <button mat-raised-button (click)="onConfirm()" class="save-btn">Ok</button>
</mat-dialog-actions>
