import { Actions } from './actions';
import { initialState, RoleState } from './state';
import { ActionTypes } from './action-types.enum';

export function roleReducer(state = initialState, action: Actions): RoleState {
  switch (action.type) {
    case ActionTypes.LOAD_EMPTY_ROLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        renderingRoleSchema: [...action.payload],
        isLoading: false,
      };

    case ActionTypes.LOAD_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        userPermissions: [...action.payload],
        isLoading: false,
      };

    case ActionTypes.LOAD_BACKEND_ROLE_LIST_SUCCESS:
      return {
        ...state,
        backEndRoleList: [...action.payload],
        isLoading: false,
      };

    case ActionTypes.LOAD_FRONTEND_ROLE_LIST_SUCCESS:
      return {
        ...state,
        frontEndRoleList: [...action.payload],
        isLoading: false,
      };

    case ActionTypes.LOAD_EMPTY_ROLE_TEMPLATE_REQUEST:
    case ActionTypes.LOAD_USER_PERMISSIONS_REQUEST:
    case ActionTypes.CREATE_ROLE_REQUEST:
    case ActionTypes.UPDATE_ROLE_REQUEST:
    case ActionTypes.LOAD_BACKEND_ROLE_LIST_REQUEST:
    case ActionTypes.LOAD_FRONTEND_ROLE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.LOAD_EMPTY_ROLE_TEMPLATE_FAILURE:
    case ActionTypes.LOAD_USER_PERMISSIONS_FAILURE:
    case ActionTypes.CREATE_ROLE_SUCCESS:
    case ActionTypes.CREATE_ROLE_FAILURE:
    case ActionTypes.UPDATE_ROLE_SUCCESS:
    case ActionTypes.UPDATE_ROLE_FAILURE:
    case ActionTypes.LOAD_BACKEND_ROLE_LIST_FAILURE:
    case ActionTypes.LOAD_FRONTEND_ROLE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default: {
      return state;
    }
  }
}
