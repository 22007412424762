import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SurveyObject } from '@models/survey/survey-object.model';
import { ActionParams } from '@models/action-params.model';

export class CreateSurveyObjectRequestAction implements Action {
  readonly type = ActionTypes.CREATE_SURVEY_OBJECT_REQUEST;

  constructor(public payload: { model: SurveyObject; params?: ActionParams }) {}
}

export class CreateSurveyObjectSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SURVEY_OBJECT_SUCCESS;
}

export class CreateSurveyObjectFailureAction implements Action {
  readonly type = ActionTypes.CREATE_SURVEY_OBJECT_FAILURE;

  constructor(public payload: Error) {}
}
