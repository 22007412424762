import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { Pagination } from '@models/pagination.model';

export class RolePaginationRequestAction implements Action {
  readonly type = ActionTypes.ROLE_PAGINATION_REQUEST;

  constructor(public payload: Pagination) {}
}
