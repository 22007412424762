import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { UserInvite } from '@interfaces/user-invite.interface';

export class CreateUserInviteRequestAction implements Action {
  readonly type = ActionTypes.CREATE_USER_INVITE_REQUEST;

  constructor(public payload: Partial<UserInvite>) {}
}

export class CreateUserInviteSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_USER_INVITE_SUCCESS;
}

export class CreateUserInviteFailureAction implements Action {
  readonly type = ActionTypes.CREATE_USER_INVITE_FAILURE;

  constructor(public payload: Error) {}
}
