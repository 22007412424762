import { Actions } from './actions';
import { initialState, CompanyState } from './state';
import { ActionTypes } from './action-types.enum';

export function companyReducer(state = initialState, action: Actions): CompanyState {
  switch (action.type) {
    case ActionTypes.INVITE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createNewUser: !!action.payload,
      };

    case ActionTypes.LOAD_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companies: [...action.payload],
      };

    case ActionTypes.LOAD_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shownCompany: {
          ...state.shownCompany,
          companyInfo: action.payload,
        },
      };

    case ActionTypes.LOAD_COMPANY_INSURANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shownCompany: {
          ...state.shownCompany,
          insurance: {
            CertificateInsurance: action.payload || null,
          },
        },
      };

    case ActionTypes.LOAD_COMPANY_ADDITIONAL_FILE_SUCCESS:
    case ActionTypes.REMOVE_COMPANY_ADDITIONAL_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shownCompany: {
          ...state.shownCompany,
          additionalFiles: [...action.payload],
        },
      };

    case ActionTypes.SAVE_COMPANY_ADDITIONAL_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shownCompany: {
          ...state.shownCompany,
          additionalFiles: [...state.shownCompany.additionalFiles, ...action.payload],
        },
      };

    case ActionTypes.GENERATE_W9_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        w9IsLoading: action.payload?.loading ?? state.w9IsLoading,
        shownCompany: {
          ...state.shownCompany,
          w9Form: { ...action.payload?.file },
        },
      };

    case ActionTypes.REMOVE_W9_FORM_SUCCESS:
      return {
        ...state,
        shownCompany: {
          ...state.shownCompany,
          w9Form: null,
        },
      };

    case ActionTypes.LOAD_COMPANY_INVITATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shownCompany: {
          ...state.shownCompany,
          invitedUsers: [...action.payload],
        },
      };

    case ActionTypes.GENERATE_W9_FORM_REQUEST:
      return {
        ...state,
        isLoading: true,
        w9IsLoading: true,
      };

    case ActionTypes.LOAD_COMPANY_REQUEST:
    case ActionTypes.LOAD_COMPANY_LIST_REQUEST:
    case ActionTypes.CREATE_COMPANY_REQUEST:
    case ActionTypes.UPDATE_COMPANY_REQUEST:
    case ActionTypes.INVITE_USER_REQUEST:
    case ActionTypes.SAVE_COMPANY_ADDITIONAL_FILE_REQUEST:
    case ActionTypes.REMOVE_COMPANY_ADDITIONAL_FILE_REQUEST:
    case ActionTypes.ACTIVATE_COMPANY_REQUEST:
    case ActionTypes.DEACTIVATE_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.LOAD_COMPANY_FAILURE:
    case ActionTypes.LOAD_COMPANY_LIST_FAILURE:
    case ActionTypes.CREATE_COMPANY_SUCCESS:
    case ActionTypes.CREATE_COMPANY_FAILURE:
    case ActionTypes.UPDATE_COMPANY_SUCCESS:
    case ActionTypes.UPDATE_COMPANY_FAILURE:
    case ActionTypes.INVITE_USER_FAILURE:
    case ActionTypes.GENERATE_W9_FORM_FAILURE:
    case ActionTypes.ACTIVATE_COMPANY_SUCCESS:
    case ActionTypes.ACTIVATE_COMPANY_FAILURE:
    case ActionTypes.DEACTIVATE_COMPANY_SUCCESS:
    case ActionTypes.DEACTIVATE_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default: {
      return state;
    }
  }
}
