enum TeamPermissions {
  Create = 'TeamCreate',
  Read = 'TeamRead',
  Update = 'TeamUpdate',
  Delete = 'TeamDelete',
}

enum UserPermissions {
  Create = 'UserCreate',
  Read = 'UserRead',
  Update = 'UserUpdate',
  Delete = 'UserDelete',
}

enum SurveyPermissions {
  Create = 'SurveyCreate',
  Read = 'SurveyRead',
  Update = 'SurveyUpdate',
  Delete = 'SurveyDelete',
}

export const BackEndRoles: BackEndRole = {
  Team: TeamPermissions,
  User: UserPermissions,
  Survey: SurveyPermissions,
};

export interface BackEndRole {
  Team: typeof TeamPermissions;
  User: typeof UserPermissions;
  Survey: typeof SurveyPermissions;
}

export type BackEndRoleType = 'Team' | 'User' | 'Survey';
