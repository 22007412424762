import { Action } from '@ngrx/store';
import { ActionTypes } from '../action-types.enum';
import { ActionParams } from '@models/action-params.model';
import { ContactComment } from '@models/contact-comment.model';

export class CreateContactCommentRequestAction implements Action {
  readonly type = ActionTypes.CREATE_CONTACT_COMMENT_REQUEST;
  constructor(public payload: { model: ContactComment, params?: ActionParams}) {}
}

export class CreateContactCommentSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_CONTACT_COMMENT_SUCCESS;
}

export class CreateContactCommentFailureAction implements Action {
  readonly type = ActionTypes.CREATE_CONTACT_COMMENT_FAILURE;

  constructor(public payload: Error) {}
}
