import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { CaseTimeLog } from '@models/case/case-timelog.model';

export class LoadCaseTimeLogsListRequestAction implements Action {
  readonly type = ActionTypes.LOAD_CASE_TIME_LOGS_LIST_REQUEST;
}

export class LoadCaseTimeLogsListSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CASE_TIME_LOGS_LIST_SUCCESS;

  constructor(public payload: CaseTimeLog[]) {}
}

export class LoadCaseTimeLogsListFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CASE_EVENTS_LIST_FAILURE;

  constructor(public payload: Error) {}
}
