import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';

export class CreateUserAdditionalFileRequestAction implements Action {
  readonly type = ActionTypes.CREATE_USER_ADDITIONAL_FILE_REQUEST;

  constructor(public payload: { files: File[]; userId: string }) {}
}

export class CreateUserAdditionalFileSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_USER_ADDITIONAL_FILE_SUCCESS;
}

export class CreateUserAdditionalFileFailureAction implements Action {
  readonly type = ActionTypes.CREATE_USER_ADDITIONAL_FILE_FAILURE;

  constructor(public payload: Error) {}
}
