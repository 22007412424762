import { GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { Agreement } from '@interfaces/agreement.interface';

class GetAgreementById extends GraphQLQueryById<{ id: string }, { getAgreements: Agreement }> {
  protected query = `query GetAgreements($id: ID!) {
    getAgreements(id: $id) {
      id
      type
      body
      createdAt
      updatedAt
    }
  }`;
}

class GetAgreementsList extends GraphQLQueryList<GraphQLQueryRequest<Agreement>, { listAgreementss: { items: Agreement[] } }> {
  protected query = `query ListAgreementss(
    $filter: ModelAgreementsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgreementss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }`;
}

export const getAgreementById = new GetAgreementById();
export const getAgreementsList = new GetAgreementsList();
