import { Action } from '@ngrx/store';

import { AmplifyAuthError } from './../../../core/interfaces/amplify-auth-error.interface';
import { UserUpdateRequest } from './../../../core/interfaces/user/user-update-request.interface';
import { ActionTypes } from '../action-types.enum';

export class RegistrationRequestAction implements Action {
  readonly type = ActionTypes.REGISTRATION_REQUEST;

  constructor(public payload: UserUpdateRequest) {}
}

export class RegistrationSuccessAction implements Action {
  readonly type = ActionTypes.REGISTRATION_SUCCESS;
}

export class RegistrationFailureAction implements Action {
  readonly type = ActionTypes.REGISTRATION_FAILURE;

  constructor(public payload: AmplifyAuthError) {}
}
