import { Action } from '@ngrx/store';
import { Required } from 'utility-types';
import { ActionTypes } from '../action-types.enum';
import { CaseComment } from '@models/case/case-comment.model';

export class UpdateContactCommentRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_CONTACT_CASE_COMMENT_REQUEST;

  constructor(public payload: { model: Required<Partial<CaseComment>, 'id'>; }) {}
}

export class UpdateContactCommentSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_CONTACT_CASE_COMMENT_FAILURE;
}

export class UpdateContactCommentFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_CONTACT_CASE_COMMENT_SUCCESS;

  constructor(public payload: Error) {}
}
