import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { CaseEvent } from '@models/case/case-event.model';

export class LoadCaseEventsListRequestAction implements Action {
  readonly type = ActionTypes.LOAD_CASE_EVENTS_LIST_REQUEST;
}

export class LoadCaseEventsListSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CASE_EVENTS_LIST_SUCCESS;

  constructor(public payload: CaseEvent[]) {}
}

export class LoadCaseEventsListFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CASE_EVENTS_LIST_FAILURE;

  constructor(public payload: Error) {}
}
