import { Component, Inject, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UserInvite } from '@core/interfaces/user-invite.interface';

@Component({
  selector: 'app-table-of-reactivate-users',
  templateUrl: './table-of-reactivate-users.component.html',
  styleUrls: ['./table-of-reactivate-users.component.scss'],
})
export class TableOfReactivateUsersComponent implements OnInit {
  public displayedColumns: string[] = ['select', 'name', 'role'];
  public dataSource: MatTableDataSource<UserInvite>;
  public selection: SelectionModel<UserInvite> = new SelectionModel<UserInvite>(true, []);

  constructor(
    private readonly dialogRef: MatDialogRef<TableOfReactivateUsersComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: UserInvite[]
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<UserInvite>(this.data);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public save(): void {
    this.dialogRef.close(this.selection.selected);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
