/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = { "aws_project_region": "us-east-1",  "aws_cognito_identity_pool_id": "us-east-1:bb205262-e0fc-4282-9f31-5bcdfc43e129",  "aws_cognito_region": "us-east-1",  "aws_user_pools_id": "us-east-1_lA40ePkOW",  "aws_user_pools_web_client_id": "4j9rbq3f321hu826tqtd52aid",  "oauth": {},  "aws_appsync_graphqlEndpoint": "https://2s4u2rst7fg7hbb2jr4t3ogiaq.appsync-api.us-east-1.amazonaws.com/graphql",  "aws_appsync_region": "us-east-1",  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",  "aws_cloud_logic_custom": [ { "name": "api403a52ad",  "endpoint": "https://z5hix5pqm0.execute-api.us-east-1.amazonaws.com/staging",  "region": "us-east-1"  },  { "name": "salesforce",  "endpoint": "https://fvatuzm2t6.execute-api.us-east-1.amazonaws.com/staging",  "region": "us-east-1"  },  { "name": "core",  "endpoint": "https://1mf0wyczle.execute-api.us-east-1.amazonaws.com/staging",  "region": "us-east-1"  } ],  "aws_mobile_analytics_app_id": "b07b8610a09f42b6acc10fae41a2feee",  "aws_mobile_analytics_app_region": "us-east-1",  "aws_user_files_s3_bucket": "post7a4707d9a2894d24b1d29547c7a4ddab130138-staging",  "aws_user_files_s3_bucket_region": "us-east-1" };  export default awsmobile;

// const awsmobile = {
//     "aws_project_region": "us-east-1",
//     "aws_cognito_identity_pool_id": "us-east-1:ad125b65-ffb3-4ffc-bd87-bce705e06590",
//     "aws_cognito_region": "us-east-1",
//     "aws_user_pools_id": "us-east-1_N47aF4IND",
//     "aws_user_pools_web_client_id": "a4pjbnr89nus6vsjkqvv4f7lt",
//     "oauth": {},
//     "aws_appsync_graphqlEndpoint": "https://vinofmn7tnbc7ic64hmgkth3eq.appsync-api.us-east-1.amazonaws.com/graphql",
//     "aws_appsync_region": "us-east-1",
//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
//     "aws_cloud_logic_custom": [
//         {
//             "name": "api18da55d9",
//             "endpoint": "https://injmxuokcb.execute-api.us-east-1.amazonaws.com/stage",
//             "region": "us-east-1"
//         }
//     ],
//     "aws_mobile_analytics_app_id": "e5cc32a5a0894d529e7bb84a91fcd63f",
//     "aws_mobile_analytics_app_region": "us-east-1",
//     "aws_user_files_s3_bucket": "poste06245c772a947afb99c7dcee3489cd3194428-stage",
//     "aws_user_files_s3_bucket_region": "us-east-1"
// };
//
//
// export default awsmobile;


/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5cfa250f-a43d-4b4a-81a0-8bf7396f9c53",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vZYeKGwL0",
    "aws_user_pools_web_client_id": "5403isj8atmo29cg9qbal8ukpd",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://kq5tcyceoffvbdio7fjmsdu53u.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "api403a52ad",
            "endpoint": "https://4zwjhzoa4g.execute-api.us-east-1.amazonaws.com/develop",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "66f039e8d2a84be0ae2b39c81daaf5cc",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_user_files_s3_bucket": "post7a4707d9a2894d24b1d29547c7a4ddab102131-develop",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;


/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//     "aws_project_region": "us-east-1",
//     "aws_cognito_identity_pool_id": "us-east-1:f591886b-9045-42d5-9f84-8027e6bc50be",
//     "aws_cognito_region": "us-east-1",
//     "aws_user_pools_id": "us-east-1_vi4ZupMrE",
//     "aws_user_pools_web_client_id": "160koq3ibqqidkdg3b1g31ngl8",
//     "oauth": {},
//     "aws_appsync_graphqlEndpoint": "https://eruh7jmd65b2zlgu3fnsrljshu.appsync-api.us-east-1.amazonaws.com/graphql",
//     "aws_appsync_region": "us-east-1",
//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
//     "aws_cloud_logic_custom": [
//         {
//             "name": "api403a52ad",
//             "endpoint": "https://azejrv8x29.execute-api.us-east-1.amazonaws.com/test",
//             "region": "us-east-1"
//         }
//     ],
//     "aws_mobile_analytics_app_id": "09a9438e494b4558b3c9388c8550a066",
//     "aws_mobile_analytics_app_region": "us-east-1",
//     "aws_user_files_s3_bucket": "post7a4707d9a2894d24b1d29547c7a4ddab193609-test",
//     "aws_user_files_s3_bucket_region": "us-east-1"
// };


// export default awsmobile;


/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//   "aws_project_region": "us-east-1",
//   "aws_cognito_identity_pool_id": "us-east-1:11d9be23-fa80-4d5b-8a06-44de7fba4a77",
//   "aws_cognito_region": "us-east-1",
//   "aws_user_pools_id": "us-east-1_8Wqfeh3bc",
//   "aws_user_pools_web_client_id": "2046d17nkn7f09f1qjlbn1lr9a",
//   "oauth": {},
//   "aws_appsync_graphqlEndpoint": "https://bbjoapqzl5aypinscznq4if2iu.appsync-api.us-east-1.amazonaws.com/graphql",
//   "aws_appsync_region": "us-east-1",
//   "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
//   "aws_cloud_logic_custom": [
//       {
//           "name": "api403a52ad",
//           "endpoint": "https://o1r8n2qxbb.execute-api.us-east-1.amazonaws.com/prod",
//           "region": "us-east-1"
//       }
//   ],
//   "aws_mobile_analytics_app_id": "814fe39c662b4d02a24603620134c482",
//   "aws_mobile_analytics_app_region": "us-east-1",
//   "aws_user_files_s3_bucket": "post7a4707d9a2894d24b1d29547c7a4ddab164130-prod",
//   "aws_user_files_s3_bucket_region": "us-east-1"
// };
// export default awsmobile;
