import { AmplifyService } from './../services/amplify.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../services/auth/auth.service';
import { CookieService } from '../services/cookie.service';
import { CookieKeysEnum } from '../enums/cookie-keys.enum';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly amplifyService: AmplifyService,
    private readonly cookieService: CookieService
  ) {}

  async canActivate(): Promise<boolean> {
    try {
      const isAuthenticated = await this.authService.isAuthenticated();
      const isRemembered = this.authService.isRememberMe();

      if (!isAuthenticated && isRemembered) {
        await this.amplifyService.singIn({
          username: this.cookieService.getCookie(CookieKeysEnum.Username),
          password: atob(this.cookieService.getCookie(CookieKeysEnum.Password)),
        });

        this.router.navigate(['']);
        return false;
      } else if (!isAuthenticated) {
        return true;
      }

      this.router.navigate(['']);
      return false;
    } catch (error) {
      return true;
    }
  }
}
