import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';

export class RemoveW9FormRequestAction implements Action {
  readonly type = ActionTypes.REMOVE_W9_FORM_REQUEST;

  constructor(public payload: string) {}
}

export class RemoveW9FormSuccessAction implements Action {
  readonly type = ActionTypes.REMOVE_W9_FORM_SUCCESS;
}

export class RemoveW9FormFailureAction implements Action {
  readonly type = ActionTypes.REMOVE_W9_FORM_FAILURE;

  constructor(public payload: Error) {}
}
