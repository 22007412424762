import { Injectable } from '@angular/core';

import { IndexedDBConnectService } from './indexedDB-connect.service';
import { IDBOpenCursorEvent, IDBOpenEvent, IndexedDBEvent } from '@interfaces/indexed-db/indexed-db-event.interface';
import { IDBSurveyObject } from '@interfaces/indexed-db/idb-survey-object.interface';
import { IndexedDBStorageEnum } from '@enums/survey/indexed-db-storage.enum';
import { indexedDBConfig } from '@core/configs/indexed-db.config';

@Injectable({ providedIn: 'root' })
export class IndexedDBHandlersService {
  constructor(protected readonly dbConnect: IndexedDBConnectService) {}

  public openDB(): Promise<IDBDatabase> {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = this.dbConnect.openDB();

      request.onblocked = () => reject();

      request.onupgradeneeded = this.onDBUpgradeNeeded.bind(this);

      request.onsuccess = ({ target }: IndexedDBEvent) => {
        if (this.isDBValid(target.result)) {
          this.dbConnect.dataBase = target.result;
          resolve(target.result);
        } else {
          reject(new Error('DB is not valid'));
        }
      };

      request.onerror = (error) => {
        reject(new Error('IndexedDB opening was failed'));
      };
    });
  }

  public isDBValid(db: IDBDatabase): boolean {
    if (!db || db.version !== indexedDBConfig.version) {
      return false;
    }

    if (db.objectStoreNames.length !== indexedDBConfig.objectStoreNames.length) {
      return false;
    }

    for (const name of indexedDBConfig.objectStoreNames) {
      if (!db.objectStoreNames.contains(name)) {
        return false;
      }
    }

    return true;
  }

  public openDBRequest(): Promise<IDBDatabase> {
    return new Promise<IDBDatabase>((resolve, reject) => {
      this.openDB()
        .then(resolve)
        .catch(() => this.reloadDBRequest().then(resolve).catch(reject));
    });
  }

  public reloadDBRequest(): Promise<IDBDatabase> {
    return this.dbConnect.deleteDB().then(() => this.openDB());
  }

  public getAll<T>(tableName: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const request = this.dbConnect.getObjectStore('readonly', tableName).getAll();

      request.onsuccess = () => resolve(request.result);

      request.onerror = () => reject();
    });
  }

  public getAllByFilter<T>(tableName: string, indexName: string, keyId: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const index = this.dbConnect.getObjectStore('readonly', tableName).index(indexName);
      const request = index.openCursor();
      const result: T[] = [];

      request.onsuccess = (event: IDBOpenCursorEvent) => {
        const cursor = event.target.result;

        if (cursor) {
          const isValidObject = cursor.key.toString().split('&').shift() === keyId;
          isValidObject && result.push(cursor.value);

          cursor.continue();
        } else {
          resolve(result);
        }
      };

      request.onerror = () => reject();
    });
  }

  public get<T>(tableName: string, id: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const request = this.dbConnect.getObjectStore('readwrite', tableName).get(id);

      request.onsuccess = () => resolve(request.result);

      request.onerror = () => reject();
    });
  }

  public deleteAll(tableName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = this.dbConnect.getObjectStore('readwrite', tableName).clear();

      request.onsuccess = () => resolve();

      request.onerror = () => reject();
    });
  }

  public delete(tableName: string, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = this.dbConnect.getObjectStore('readwrite', tableName).delete(id);

      request.onsuccess = () => resolve();

      request.onerror = () => reject();
    });
  }

  public add<T>(tableName: string, item: IDBSurveyObject<T>): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = this.dbConnect.getObjectStore('readwrite', tableName).add(item);

      request.onsuccess = () => resolve();

      request.onerror = () => reject();
    });
  }

  public put<T>(tableName: string, item: IDBSurveyObject<T>): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = this.dbConnect.getObjectStore('readwrite', tableName).put(item);

      request.onsuccess = () => resolve();

      request.onerror = () => reject();
    });
  }

  private onDBUpgradeNeeded({ target }: IDBOpenEvent): void {
    const db: IDBDatabase = target.result;
    this.dbConnect.dataBase = db;

    const objectStore: IDBObjectStore = db.createObjectStore(IndexedDBStorageEnum.ObjectStore, {
      keyPath: indexedDBConfig.objectKeyPath,
    });

    objectStore.createIndex(indexedDBConfig.objectKeyPath, indexedDBConfig.objectKeyPath, {
      unique: true,
    });

    const templateStore: IDBObjectStore = db.createObjectStore(IndexedDBStorageEnum.TemplateStore, {
      keyPath: indexedDBConfig.defaultKey,
    });

    templateStore.createIndex(indexedDBConfig.defaultKey, indexedDBConfig.defaultKey, {
      unique: true,
    });

    const fileKeysStore: IDBObjectStore = db.createObjectStore(IndexedDBStorageEnum.FileKeysStore, {
      keyPath: indexedDBConfig.defaultKey,
    });

    fileKeysStore.createIndex(indexedDBConfig.defaultKey, indexedDBConfig.defaultKey, {
      unique: false,
    });
  }
}
