import { Action } from '@ngrx/store';
import { Required } from 'utility-types';

import { ActionTypes } from '../../action-types.enum';
import { Task } from '@models/case/task.model';
import { ActionParams } from '@models/action-params.model';

export class UpdateTaskRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_TASK_REQUEST;

  constructor(public payload: { model: Required<Partial<Task>, 'id'>, params?: ActionParams}) {}
}

export class UpdateTaskSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_TASK_SUCCESS;
}

export class UpdateTaskFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_TASK_FAILURE;

  constructor(public payload: Error) {}
}
