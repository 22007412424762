import { Action } from '@ngrx/store';

import { VMOCompany } from './../../../core/models/vmo-company.model';
import { GraphQLFilter } from '../../../core/models/graphql/graphql-filter.model';
import { ActionTypes } from '../action-types.enum';

export class LoadCompanyListRequestAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_LIST_REQUEST;

 // constructor(public payload?: GraphQLFilter) {}
}

export class LoadCompanyListSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_LIST_SUCCESS;

  constructor(public payload: VMOCompany[]) {}
}

export class LoadCompanyListFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_LIST_FAILURE;

  constructor(public payload: Error) {}
}
