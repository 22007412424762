import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { BellNotification } from '@models/bell-notification.model';
import { NotificationState } from './state';

const getIsLoading = (state: NotificationState): boolean => state.isLoading;

const getBellNotifications = (state: NotificationState): BellNotification[] => state.bellNotifications;

const getNotifications = (state: NotificationState): BellNotification[] => state.notifications;

export const selectNotificationState: MemoizedSelector<object, NotificationState> = createFeatureSelector<NotificationState>(
  'notification-store'
);

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectNotificationState,
  getIsLoading
);

export const selectBellNotifications: MemoizedSelector<object, BellNotification[]> = createSelector(
  selectNotificationState,
  getBellNotifications
);

export const selectNotifications: MemoizedSelector<object, BellNotification[]> = createSelector(
  selectNotificationState,
  getNotifications
);
