import { Actions } from './actions';
import { initialState, SurveyState } from './state';
import { ActionTypes } from './action-types.enum';

export function roleReducer(state = initialState, action: Actions): SurveyState {
  switch (action.type) {
    case ActionTypes.LOAD_OBJECT_BANK_SUCCESS:
    case ActionTypes.DELETE_SURVEY_OBJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        objectBank: [...action.payload]
      };

    case ActionTypes.OBJECTS_BANK_PAGINATION_SUCCESS:
      return {
        ...state,
        objectsBankPaginationLoading: false,
        objectsBankPagination: action.payload
      };

    case ActionTypes.QUESTIONS_BANK_PAGINATION_SUCCESS:
      return {
        ...state,
        questionsBankPaginationLoading: false,
        questionsBankPagination: action.payload
      };

    case ActionTypes.OBJECTS_PAGINATION_SUCCESS:
      return {
        ...state,
        objectsPaginationLoading: false,
        objectsPagination: action.payload
      };
    
    case ActionTypes.DELETE_SURVEY_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        surveyTemplates: [...action.payload]
      };

    case ActionTypes.SURVEYS_PAGINATION_REQUEST:
      return {
        ...state,
        surveysPaginationLoading: true
      };

    case ActionTypes.SURVEYS_PAGINATION_SUCCESS:
      return {
        ...state,
        surveysPaginationLoading: false,
        surveysPagination: action.payload
      };

    case ActionTypes.SURVEYS_MANAGEMENT_PAGINATION_REQUEST:
      return {
        ...state,
        surveysManagementPaginationLoading: true
      };

    case ActionTypes.SURVEYS_MANAGEMENT_PAGINATION_SUCCESS:
      return {
        ...state,
        surveysManagementPaginationLoading: false,
        surveysManagementPagination: action.payload
      };

    case ActionTypes.LOAD_SITE_SURVEY_LIST_SUCCESS:
    case ActionTypes.DELETE_SITE_SURVEY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchLoading: false,
        surveys: [...action.payload]
      };

    case ActionTypes.ARCHIVED_SURVEYS_PAGINATION_REQUEST:
      return {
        ...state,
        archivedSurveysPaginationLoading: true
      }

    case ActionTypes.ARCHIVED_SURVEYS_PAGINATION_SUCCESS:
      return {
        ...state,
        archivedSurveysPaginationLoading: false,
        archivedSurveysPagination: action.payload
      };

    case ActionTypes.LOAD_QUESTION_BANK_SUCCESS:
    case ActionTypes.DELETE_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionBank: [...action.payload]
      };

    case ActionTypes.LOAD_SURVEY_DATA_FROM_INDEXED_DB_SUCCESS:
    case ActionTypes.SAVE_SURVEY_DATA_IN_INDEXED_DB_SUCCESS:
    case ActionTypes.DELETE_SURVEY_DATA_FROM_INDEXED_DB_SUCCESS:
      return {
        ...state,
        surveyAnswers: [...action.payload]
      };

    case ActionTypes.LOAD_EXTRA_OBJECTS_FROM_INDEXED_DB_SUCCESS:
    case ActionTypes.SAVE_EXTRA_OBJECTS_IN_INDEXED_DB_SUCCESS:
    case ActionTypes.DELETE_EXTRA_OBJECTS_FROM_INDEXED_DB_SUCCESS:
      return {
        ...state,
        extraObjectsTemplate: action.payload
      };

    case ActionTypes.DOWNLOAD_SURVEY_REPORT_REQUEST:
      return {
        ...state,
        downloadReportProgressIds: [...state.downloadReportProgressIds, action.payload.surveyId]
      };

    case ActionTypes.DOWNLOAD_SURVEY_REPORT_SUCCESS:
      return {
        ...state,
        downloadReportProgressIds: state.downloadReportProgressIds.filter((id) => id !== action.payload)
      };

    case ActionTypes.GENERATE_SURVEY_REPORT_SUCCESS:
      return {
        ...state,
        reportsGenerating: [...state.reportsGenerating, { surveyId: action.payload.id, report: action.payload.reportType }]
      };

    case ActionTypes.GENERATE_SURVEY_REPORT_COMPLETE:
      return {
        ...state,
        reportsGenerating: state.reportsGenerating.filter((reportGenerating) => {
          return (reportGenerating.surveyId !== action.payload.id) && (reportGenerating.report !== action.payload.reportType);
        })
      };

    case ActionTypes.LOAD_SITE_SURVEY_LIST_REQUEST:
      return {
        ...state,
        isLoading: !action.payload?.searchLoading,
        searchLoading: !!action.payload?.searchLoading
      };

    case ActionTypes.LOAD_OBJECT_BANK_REQUEST:
    case ActionTypes.CREATE_SURVEY_OBJECT_REQUEST:
    case ActionTypes.UPDATE_SURVEY_OBJECT_REQUEST:
    case ActionTypes.DELETE_SURVEY_OBJECT_REQUEST:
    case ActionTypes.CREATE_SURVEY_TEMPLATE_REQUEST:
    case ActionTypes.UPDATE_SURVEY_TEMPLATE_REQUEST:
    case ActionTypes.DELETE_SURVEY_TEMPLATE_REQUEST:
    case ActionTypes.CREATE_SITE_SURVEY_REQUEST:
    case ActionTypes.UPDATE_SITE_SURVEY_REQUEST:
    case ActionTypes.DELETE_SITE_SURVEY_REQUEST:
    case ActionTypes.LOAD_QUESTION_BANK_REQUEST:
    case ActionTypes.CREATE_SURVEY_QUESTION_REQUEST:
    case ActionTypes.UPDATE_SURVEY_QUESTION_REQUEST:
    case ActionTypes.DELETE_SURVEY_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.OBJECTS_BANK_PAGINATION_REQUEST:
      return {
        ...state,
        objectsBankPaginationLoading: true
      };

    case ActionTypes.QUESTIONS_BANK_PAGINATION_REQUEST:
      return {
        ...state,
        questionsBankPaginationLoading: true
      };

    case ActionTypes.OBJECTS_PAGINATION_REQUEST:
      return {
        ...state,
        objectsPaginationLoading: true
      };

    case ActionTypes.SURVEY_TEMPLATES_PAGINATION_REQUEST:
      return {
        ...state,
        surveyTemplatePaginationLoading: true
      };

    case ActionTypes.SURVEY_TEMPLATES_PAGINATION_SUCCESS:
      return {
        ...state,
        surveyTemplatePagination: action.payload,
        surveyTemplatePaginationLoading: false
      };

    case ActionTypes.LOAD_OBJECT_BANK_FAILURE:
    case ActionTypes.CREATE_SURVEY_OBJECT_SUCCESS:
    case ActionTypes.UPDATE_SURVEY_OBJECT_SUCCESS:
    case ActionTypes.CREATE_SURVEY_OBJECT_FAILURE:
    case ActionTypes.UPDATE_SURVEY_OBJECT_FAILURE:
    case ActionTypes.DELETE_SURVEY_OBJECT_FAILURE:
    case ActionTypes.CREATE_SURVEY_TEMPLATE_SUCCESS:
    case ActionTypes.UPDATE_SURVEY_TEMPLATE_SUCCESS:
    case ActionTypes.CREATE_SURVEY_TEMPLATE_FAILURE:
    case ActionTypes.UPDATE_SURVEY_TEMPLATE_FAILURE:
    case ActionTypes.DELETE_SURVEY_TEMPLATE_FAILURE:
    case ActionTypes.CREATE_SITE_SURVEY_SUCCESS:
    case ActionTypes.UPDATE_SITE_SURVEY_SUCCESS:
    case ActionTypes.LOAD_SITE_SURVEY_LIST_FAILURE:
    case ActionTypes.CREATE_SITE_SURVEY_FAILURE:
    case ActionTypes.UPDATE_SITE_SURVEY_FAILURE:
    case ActionTypes.DELETE_SITE_SURVEY_FAILURE:
    case ActionTypes.LOAD_QUESTION_BANK_FAILURE:
    case ActionTypes.CREATE_SURVEY_QUESTION_SUCCESS:
    case ActionTypes.UPDATE_SURVEY_QUESTION_SUCCESS:
    case ActionTypes.CREATE_SURVEY_QUESTION_FAILURE:
    case ActionTypes.UPDATE_SURVEY_QUESTION_FAILURE:
    case ActionTypes.DELETE_SURVEY_QUESTION_FAILURE:
    case ActionTypes.SURVEY_TEMPLATES_PAGINATION_FAILURE:
    case ActionTypes.OBJECTS_PAGINATION_FAILURE:
    case ActionTypes.OBJECTS_BANK_PAGINATION_FAILURE:
    case ActionTypes.QUESTIONS_BANK_PAGINATION_FAILURE:
    case ActionTypes.SURVEYS_MANAGEMENT_PAGINATION_FAILURE:
    case ActionTypes.ARCHIVED_SURVEYS_PAGINATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        searchLoading: false,
        surveysPaginationLoading: false,
        surveyTemplatePaginationLoading: false,
        objectsBankPaginationLoading: false,
        questionsBankPaginationLoading: false,
        objectsPaginationLoading: false,
        surveysManagementPaginationLoading: false,
        archivedSurveysPaginationLoading: false
      };

    default: {
      return state;
    }
  }
}
