import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { TagInputModule } from 'ngx-chips';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

/* Add Amplify imports */
import { Amplify } from 'aws-amplify';
import amplifyConfig from '../../amplify-src/aws-exports';

import { RootStoreModule } from './root-store/root-store.module';
import { VersionCheckService } from '@services/version-check.service';

/* Configure Amplify resources */
Amplify.configure(amplifyConfig);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    HttpClientModule,
    MatDialogModule,
    RootStoreModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    TagInputModule
  ],
  providers: [
    VersionCheckService,
    DeviceDetectorService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
