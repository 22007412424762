import { Injectable } from '@angular/core';
import { IndexedDBStorageEnum } from '@enums/survey/indexed-db-storage.enum';
import { ExtraObjectsTemplate } from '@interfaces/indexed-db/extra-objects-template.interface';
import { IDBSurveyObject } from '@interfaces/indexed-db/idb-survey-object.interface';

import { IndexedDBConnectService } from './indexedDB-connect.service';
import { IndexedDBHandlersService } from './indexedDB-handlers.service';

@Injectable({ providedIn: 'root' })
export class IndexedDBSyncService extends IndexedDBHandlersService {
  constructor(protected readonly dbConnect: IndexedDBConnectService) {
    super(dbConnect);
  }

  public async putItemToDB<T>(item: T, store: IndexedDBStorageEnum): Promise<IDBRequest<IDBValidKey>> {
    const objectStore = this.dbConnect.getObjectStore('readwrite', store);
    return objectStore.put(item);
  }

  public connectToDB(): Promise<IDBDatabase> {
    return this.openDBRequest().catch(() => {
      throw new Error('Open db was failed');
    });
  }

  public async loadObjects(index?: string, keyId?: string): Promise<IDBSurveyObject[]> {
    if (keyId && index) {
      return this.getAllByFilter(IndexedDBStorageEnum.ObjectStore, index, keyId);
    }

    return this.getAll<IDBSurveyObject>(IndexedDBStorageEnum.ObjectStore);
  }

  public async loadTemplate(id: string): Promise<ExtraObjectsTemplate> {
    return this.get<ExtraObjectsTemplate>(IndexedDBStorageEnum.TemplateStore, id);
  }
}
