import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { FrontEndRole } from '@models/roles/frontend-role.model';

export class LoadEmptyRoleTemplateRequestAction implements Action {
  readonly type = ActionTypes.LOAD_EMPTY_ROLE_TEMPLATE_REQUEST;
}

export class LoadEmptyRoleTemplateSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_EMPTY_ROLE_TEMPLATE_SUCCESS;

  constructor(public payload: FrontEndRole[]) {}
}

export class LoadEmptyRoleTemplateFailureAction implements Action {
  readonly type = ActionTypes.LOAD_EMPTY_ROLE_TEMPLATE_FAILURE;

  constructor(public payload: Error) {}
}
