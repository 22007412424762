export class EmailTemplate {
  public text: string;
  public url?: string;
  public title?: string;

  static createEmailTemplate(text: string, url?: string, title?: string): string {
    return `
            <div>
                ${EmailTemplate.createTextTemplate(text)}
                ${EmailTemplate.createLinkTemplate(url, title)}
            </div>
        `;
  }

  static createTextTemplate(text: string): string {
    return `
            <div style="font-size:18px; margin-bottom: 20px">
                ${text}
            </div>
        `;
  }
  static createLinkTemplate(url: string, title: string): string {
    if (!url || !title) {
      return '';
    }

    return `
            <div style="text-align:center">
                <a style="
                        background-color: #20263f;
                        color: #fff;
                        text-decoration: none;
                        min-width: 100px;
                        padding: 2px 20px;
                        display: inline-block;
                        font-size: 16px;
                        line-height: 28px;
                        border-radius: 5px"
                   href="${url}">${title}</a>
            </div>
        `;
  }

  static highlight<T>(label: string, value: T): string {
    return `<div>${label}: <span style="background-color: #e3e3e3;display:inline-block; padding:1px 4px;">${value}</span></div>`;
  }
}
