import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { getSalesForceConnectionWebList } from '@graphql/sales-force-web-connection/queries';
import { Store } from '@ngrx/store';
import { RootStore } from '@store/index';
import { take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class SalesForceService {
  constructor(
    private readonly http: HttpClient,
    private readonly store$: Store<RootStore.AppState>,
  ) {}

  public async patchSFInfo(actionType, id, updateModel) {
    const connectionData = await this.getSalesForceConnection();
    const fullUrl = connectionData.dynamicBasicUrl + connectionData.urlForUpdate + '/' + actionType + '/' + id;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${connectionData.dynamicBasicToken}`
    });
    const options = {
      headers
    };
    this.http.patch<any>(fullUrl, JSON.stringify(updateModel), options).subscribe(data => {
      // console.log(actionType + ' with ' + id + ' in SF has been updated');
    });
  }

  public async deleteSFInfo(actionType, id) {
    const connectionData = await this.getSalesForceConnection();
    const fullUrl = connectionData.dynamicBasicUrl + connectionData.urlForUpdate + '/' + actionType + '/' + id;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${connectionData.dynamicBasicToken}`
    });
    const options = {
      headers
    };
    this.http.delete<any>(fullUrl, options).subscribe(data => {
     // console.log(actionType + ' with ' + id + ' in SF has been deleted');
    });
  }

  public async postSFInfo(actionType, updateModel): Promise<any> {
    const connectionData = await this.getSalesForceConnection();
    const fullUrl = connectionData.dynamicBasicUrl + connectionData.urlForUpdate + '/' + actionType + '/';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${connectionData.dynamicBasicToken}`
    });
    const options = {
      headers
    };
    return new Promise<any> (resolve => {
      this.http.post<any>(fullUrl, JSON.stringify(updateModel), options).pipe(take(1)).subscribe(
        (data: any) => {
         // console.log(data);
          resolve(data);
        });
    });
  }

  public async uploadSFAttachment(id, fileName, file): Promise<any> {
    const connectionData = await this.getSalesForceConnection();
    const fullUrl = connectionData.dynamicBasicUrl + connectionData.urlForAttachment + '?id=' + id + '&name=' + fileName;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${connectionData.dynamicBasicToken}`
    });
    const options = {
      headers
    };
    return new Promise<any>(resolve => {
      this.http.post<any>(fullUrl, file, options).pipe(take(1)).subscribe(
        (data: any) => {
          resolve(data);
        }
      );
    });
  }

  public async deleteSFAttachment(actionType, id): Promise<any> {
    const connectionData = await this.getSalesForceConnection();
    const fullUrl = connectionData.dynamicBasicUrl + connectionData.urlForUpdate + '/' + actionType + '/' + id;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${connectionData.dynamicBasicToken}`
    });
    const options = {
      headers
    };
    return new Promise<any> (resolve => {
      this.http.delete<any>(fullUrl, options).pipe(take(1)).subscribe(
        (data: any) => {
          resolve(data);
        });
    });
  }

  private async getSalesForceConnection(): Promise<any> {
    return getSalesForceConnectionWebList
      .execute()
      .then((res) => {
       return res?.data?.listSalesForceConnectionWebs?.items[0];
      })
      .catch((err) => console.log(err));
  }
}
