import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { filter, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../services/auth/auth.service';
import { UserActions, UserSelectors } from '../../root-store/user-store';
import { AuthActions, RootStore } from '../../root-store';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly store$: Store<RootStore.AppState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.authService.isAuthenticated()).pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
          return of(false);
        }

        return this.store$.select(UserSelectors.selectUser).pipe(
          map((user) => {
            if (
              !!this.authService.loggedInAsAnotherUser
              && (!user || user.id !== this.authService.loggedInAsAnotherUser)
            ) {
              this.store$.dispatch(new UserActions.LoadCurrentUserRequestAction(this.authService.loggedInAsAnotherUser));
            } else if (!user) {
              this.store$.dispatch(new UserActions.LoadCurrentUserRequestAction());
            }

            return user;
          }),
          filter((user) => !!user),
          mapTo(true),
          tap(() => this.store$.dispatch(new AuthActions.LoginSuccessAction()))
        );
      })
    );
  }
}
