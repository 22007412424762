import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SurveyQuestion } from '@models/survey/survey-question.model';
import { ActionParams } from '@models/action-params.model';

export class CreateSurveyQuestionRequestAction implements Action {
  readonly type = ActionTypes.CREATE_SURVEY_QUESTION_REQUEST;

  constructor(public payload: { model: SurveyQuestion; params?: ActionParams }) {}
}

export class CreateSurveyQuestionSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SURVEY_QUESTION_SUCCESS;
}

export class CreateSurveyQuestionFailureAction implements Action {
  readonly type = ActionTypes.CREATE_SURVEY_QUESTION_FAILURE;

  constructor(public payload: Error) {}
}
