import { GraphQLCustomQueryList, GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { UserInvite } from '@interfaces/user-invite.interface';

class GetUserInviteById extends GraphQLQueryById<{ id: string }, { getUsersInvite: UserInvite }> {
  protected query = `query GetUsersInvite($id: ID!) {
    getUsersInvite(id: $id) {
      id
      email
      firstName
      lastName
      status
      teamId
      userId
      role
      inviteDatetime
      createdBy
      updatedBy
      createdAt
      updatedAt
      invitedByName
      teamID {
        salesforceId
        name
        id
      }
      userID {
        firstname
        lastname
        registrationStatus
      }
    }
  }
`;
}

class GetUserInviteList extends GraphQLQueryList<
    GraphQLQueryRequest<UserInvite>,
    { listUsersInvites: { items: UserInvite[] } }
    > {
  protected query = `query ListUsersInvites(
    $filter: ModelUsersInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        status
        teamId
        userId
        role
        inviteDatetime
        createdBy
        updatedBy
        createdAt
        updatedAt
        invitedByName
        teamID {
          salesforceId
          name
          id
        }
        userID {
          firstname
          lastname
          registrationStatus
          cognitoId
        }
      }
      nextToken
    }
  }`;
}

class SearchUserInvites extends GraphQLQueryList<GraphQLQueryRequest<UserInvite>, { searchUsersInvites: { items: UserInvite[] } }> {
  protected query = `query SearchUsersInvites(
    $filter: SearchableUsersInviteFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableUsersInviteSortInput
  ) {
    searchUsersInvites(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        email
        firstName
        lastName
        status
        teamId
        userId
        role
        inviteDatetime
        createdBy
        updatedBy
        createdAt
        updatedAt
        invitedByName
        teamID {
          salesforceId
          name
          id
        }
        userID {
          firstname
          lastname
          registrationStatus
          cognitoId
        }
      }
      nextToken
    }
  }`;
}

class SearchUserInvitesByEmail extends GraphQLCustomQueryList<any, { searchUserInvitesByEmail: { items: UserInvite[], total: number } }> {
  protected query = `query SearchUsersInvitesByEmail(
    $filter: searchUserInvitesByEmailFilter
    $from: Int!
    $limit: Int!
    $sort: searchUserInvitesByEmailSort
  ) {
    searchUserInvitesByEmail(filter: $filter, from: $from, limit: $limit, sort: $sort) {
      items {
        id
        email
        companyName
        firstName
        lastName
        status
        statusSearch
        teamId
        userId
        role
        inviteDatetime
        createdBy
        updatedBy
        createdAt
        updatedAt
        invitedByName
        teamID {
          salesforceId
          name
          id
        }
        userID {
          firstname
          lastname
          registrationStatus
          cognitoId
        }
      }
      total
    }
  }`;
}

export const getUserInviteById = new GetUserInviteById();
export const getUserInviteList = new GetUserInviteList();
export const searchUserInvites = new SearchUserInvites();
export const searchUserInvitesByEmail = new SearchUserInvitesByEmail();
