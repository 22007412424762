import { Action } from '@ngrx/store';
import { Required } from 'utility-types';

import { ActionTypes } from '../../action-types.enum';
import { SurveyTemplate } from '@models/survey/survey-template.model';
import { ActionParams } from '@models/action-params.model';

export class UpdateSurveyTemplateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_SURVEY_TEMPLATE_REQUEST;

  constructor(public payload: { model: Required<Partial<SurveyTemplate>, 'id'>; type?; file?: File;  params?: ActionParams }) {}
}

export class UpdateSurveyTemplateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SURVEY_TEMPLATE_SUCCESS;
}

export class UpdateSurveyTemplateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_SURVEY_TEMPLATE_FAILURE;

  constructor(public payload: Error) {}
}
