import { Injectable } from '@angular/core';
import { indexedDBConfig } from '@core/configs/indexed-db.config';

@Injectable({ providedIn: 'root' })
export class IndexedDBConnectService {
  private readonly dbName = indexedDBConfig.dbStorageName;
  private db: IDBDatabase;

  constructor() {}

  get dataBase(): IDBDatabase {
    return this.db;
  }

  set dataBase(db: IDBDatabase) {
    this.db = db;
  }

  public openDB(): IDBOpenDBRequest {
    if (this.db && this.db.version) {
      return window.indexedDB.open(this.dbName, this.db.version);
    } else {
      return window.indexedDB.open(this.dbName, indexedDBConfig.version);
    }
  }

  public deleteDB(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.deleteDatabase(this.dbName);
      request.onsuccess = () => resolve(true);

      request.onerror = () => reject(false);
    });
  }

  public closeDB(): void {
    this.db.close();
  }

  public getObjectStore(transactionType: IDBTransactionMode, storeName: string): IDBObjectStore {
    return this.db.transaction([storeName], transactionType).objectStore(storeName);
  }
}
