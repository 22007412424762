import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { FileView } from '../../../core/interfaces/file-view.interface';
import { ActionParams } from '@models/action-params.model';

export class RemoveUserAdditionalFileRequestAction implements Action {
  readonly type = ActionTypes.REMOVE_USER_ADDITIONAL_FILE_REQUEST;

  constructor(public payload: { file: FileView; salesforceId?; params?: ActionParams }) {}
}

export class RemoveUserAdditionalFileSuccessAction implements Action {
  readonly type = ActionTypes.REMOVE_USER_ADDITIONAL_FILE_SUCCESS;

  constructor(public payload: FileView[]) {}
}

export class RemoveUserAdditionalFileFailureAction implements Action {
  readonly type = ActionTypes.REMOVE_USER_ADDITIONAL_FILE_FAILURE;

  constructor(public payload: Error) {}
}
