export enum UserRolesEnum {
  Admin = 'Admin',
  ServicePartnerAdmin = 'ServicePartnerAdmin',
  Customer = 'Customer',
  PivitalTechnician = 'OrgTech',
  PlatformTechnician = 'FnTech',
  ProjectCoordinator = 'ProjectCoordinator',
  VendorManager = 'VendorManager',
  ServicePartnerTechnician = 'Technician',
  SuperAdmin = 'SuperAdmin',
  'ServicePartnerAdmin, ServicePartnerTechnician' = 'ServicePartnerAdmin, ServicePartnerTechnician',
}

export enum UserRoleTitleEnum {
  Admin = 'Admin',
  ServicePartnerAdmin = 'Service Partner Admin',
  Customer = 'Customer',
  OrgTech = 'Pivital Technician',
  FnTech = 'Platform Technician',
  ProjectCoordinator = 'Project Coordinator',
  VendorManager = 'Vendor Manager',
  Technician = 'Service Partner Technician',
  SuperAdmin = 'Super Admin',
  'ServicePartnerAdmin, ServicePartnerTechnician' = 'Service Partner Admin, Service Partner Technician',
}
