import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { TokenService } from './token.service';
import { UserRolesEnum } from '@enums/user-roles.enum';
import { rememberMeLabel } from '@app/constants';
import { AmplifyService } from '@services/amplify.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userRole: string;
  private eventSubject: Subject<boolean> = new Subject<boolean>();
  private loggedIn = false;

  public readonly statusChanged$: Observable<boolean> = this.eventSubject.asObservable();

  public get loggedInAsAnotherUser(): string {
    return localStorage.getItem('logInAsAnotherUser');
  }

  get role(): string {
    return this.userRole;
  }

  set role(value: string) {
    this.userRole = value;
  }

  constructor(
    private readonly tokenService: TokenService,
    private readonly amplifyService: AmplifyService) {
    window.onstorage = () => {
      const userLoggedIn = !!this.amplifyService.getCurrentUserInfo();

      if (JSON.parse(localStorage.getItem('signOut'))) {
        this.eventSubject.next(false);
      }
      if (this.loggedIn !== userLoggedIn) {
        this.loggedIn = userLoggedIn;
        this.eventSubject.next(userLoggedIn);
      }
    };
  }

  public async isAuthenticated(): Promise<boolean> {
    const token = await this.tokenService.getToken();
    return !this.tokenService.isTokenExpired(token);
  }

  public isRememberMe(): boolean {
    return !!JSON.parse(localStorage.getItem(rememberMeLabel));
  }

  public notPermitted(restrictedRoles: UserRolesEnum[]): boolean {
    const userRoles = this.userRole?.split(',') as UserRolesEnum[];
    const isNotPermitted = this.userRole && userRoles.some((role) => restrictedRoles.includes(role));

    return isNotPermitted;
  }

  public hasPermission(roles: string[]): boolean {
    const userRoles = this.userRole?.split(',');

    return (
      this.userRole
      && (userRoles.includes(UserRolesEnum.SuperAdmin)
        || userRoles.some((role) => roles.includes(role))
        || roles.includes(this.userRole))
    );
  }
}
