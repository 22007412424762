import { UserView } from './../../core/models/user.model';
import { UserInvite } from '@interfaces/user-invite.interface';
import { UserRequest } from '@interfaces/user-request.interface';
import { UserAuthLog } from '@interfaces/user-authlog.interface';

export interface UserState {
  activeUser: UserView | null;
  invitedUsers: UserInvite[];
  isLoading: boolean;
  userRequests: { items: UserRequest[], total: number };
  userRequestsLoading: boolean;
  allUsers: UserView[];
  userInvites: {
    existingUsers: string[];
    invitationsSent: boolean;
  };
  invitesPagination: { items: UserInvite[], total: number };
  invitesPaginationLoading: boolean;
  currentUserView: UserView | null;
  getUserById: UserView | null;
  usersPagination: { items: UserView[], total: number };
  usersPaginationLoading: boolean;
  userAuthLogPagination: { items: UserAuthLog[], total: number };
  userAuthLogPaginationLoading: boolean;
}

export const initialState: UserState = {
  activeUser: null,
  invitedUsers: [],
  userRequests: { items: [], total: 0 },
  userRequestsLoading: false,
  allUsers: [],
  isLoading: false,
  userInvites: {
    existingUsers: [],
    invitationsSent: false
  },
  invitesPagination: { items: [], total: 0 },
  invitesPaginationLoading: false,
  currentUserView: null,
  getUserById: null,
  usersPagination: { items: [], total: 0 },
  usersPaginationLoading: false,
  userAuthLogPagination: { items: [], total: 0 },
  userAuthLogPaginationLoading: false
};
