import { Injectable } from '@angular/core';

import { TableConfig } from '@interfaces/table-config.interface';
import { Case } from '@models/case/case.model';
import { AuthService } from '@services/auth/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({ providedIn: 'root' })
export class HelperService {
  static get timestamp(): number {
    const utcMoment = Date.now() - (new Date().getTimezoneOffset() * 60000);

    return Math.floor(utcMoment / 1000);
  }

  constructor(private authService: AuthService, private deviceService: DeviceDetectorService) {}

  public get userAgent(): string {
    return this.deviceService.getDeviceInfo().userAgent;
  }

  public static caseLocation(item: Case): string {
    return `${item?.address1 || ''} ${item?.address2 || ''} ${item?.city || ''} ${item?.state || ''}
      ${ item?.zipCode || '' } ${item?.country || ''}`.trim();
  }

  public static getDateTimeUTCString(inputDate?: string | number): string {
    const timeOffset = new Date().getTimezoneOffset() * 60000;
    const date = new Date(new Date(inputDate || new Date()).getTime() - timeOffset);

    date.setSeconds(0, 0);

    return date.toISOString();
  }

  public sortByCreationDate<T extends { createdAt?: string }>(items: T[] = []): T[] {
    return items.sort((item1, item2) => +new Date(item2.createdAt) - +new Date(item1.createdAt));
  }

  public filterRestrictedColumns(tableConfig: TableConfig): TableConfig {
    const filteredCols = tableConfig.cols.filter(({ restrictedRoles }) => {
      return restrictedRoles ? !this.authService.notPermitted(restrictedRoles) : true;
    });

    return { ...tableConfig, cols: filteredCols };
  }
}
