import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { Pagination } from '@models/pagination.model';
import { UserView } from '@models/user.model';
import { UserAuthLog } from '@interfaces/user-authlog.interface';
import { UserInvite } from '@interfaces/user-invite.interface';
import { UserRequest } from '@interfaces/user-request.interface';

export class UserPaginationRequestAction implements Action {
  readonly type = ActionTypes.USER_PAGINATION_REQUEST;

  constructor(public payload: Pagination) {}
}
export class UserPaginationSuccessAction implements Action {
  readonly type = ActionTypes.USER_PAGINATION_SUCCESS;

  constructor(public payload: { items: UserView[], total: number }) {}
}

export class UserInvitePaginationRequestAction implements Action {
  readonly type = ActionTypes.USER_INVITE_PAGINATION_REQUEST;

  constructor(public payload: Pagination) {}
}

export class UserInvitePaginationSuccessAction implements Action {
  readonly type = ActionTypes.USER_INVITE_PAGINATION_SUCCESS;

  constructor(public payload: { items: UserInvite[], total: number }) {}
}

export class UserRequestPaginationRequestAction implements Action {
  readonly type = ActionTypes.USER_REQUEST_PAGINATION_REQUEST;

  constructor(public payload: Pagination) {}
}

export class UserRequestPaginationSuccessAction implements Action {
  readonly type = ActionTypes.USER_REQUEST_PAGINATION_SUCCESS;

  constructor(public payload: { items: UserRequest[], total: number }) {}
}

export class UserRequestPaginationFailureAction implements Action {
  readonly type = ActionTypes.USER_REQUEST_PAGINATION_FAILURE;

  constructor(public payload: Error) {}
}

export class UserAuthLogPaginationRequestAction implements Action {
  readonly type = ActionTypes.USER_AUTHLOG_PAGINATION_REQUEST;

  constructor(public payload: Pagination, public cognitoId: string) {}
}
export class UserAuthLogPaginationSuccessAction implements Action {
  readonly type = ActionTypes.USER_AUTHLOG_PAGINATION_SUCCESS;

  constructor(public payload: { items: UserAuthLog[], total: number }) {}
}
export class UpdateUsersInListSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_USERS_LIST_SUCCESS;

  constructor(public payload: UserView[]) {}
}
