import { UserInvite } from '@interfaces/user-invite.interface';
import { ResendUserInviteInterface} from '@interfaces/resend-user-invite.interface';
import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';

class CreateUserInvite extends GraphQLMutation<UserInvite, { createUsersInvite: Partial<UserInvite> }> {
  protected query = `mutation CreateUsersInvite(
    $input: CreateUsersInviteInput!
    $condition: ModelUsersInviteConditionInput
  ) {
    createUsersInvite(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateUserInvite extends GraphQLMutation<Required<Partial<UserInvite>, 'id'>, { updateUsersInvite: Partial<UserInvite> }> {
  protected query = `mutation UpdateUsersInvite(
    $input: UpdateUsersInviteInput!
    $condition: ModelUsersInviteConditionInput
  ) {
    updateUsersInvite(input: $input, condition: $condition) {
      id
    }
  }`;
}

class DeleteUserInvite extends GraphQLMutation<Required<Partial<UserInvite>, 'id'>, { deleteUsersInvite: Partial<UserInvite> }> {
  protected query = `mutation DeleteUsersInvite(
    $input: DeleteUsersInviteInput!
    $condition: ModelUsersInviteConditionInput
  ) {
    deleteUsersInvite(input: $input, condition: $condition) {
      id
    }
  }`;
}

class ResendUserInvite extends GraphQLMutation<Required<Partial<ResendUserInviteInterface>, 'email'>, { resendUserInvite: ResendUserInviteInterface }> {
    protected query = `mutation ResendUserInvite(
      $input: ResendUserInviteInput!
    ) {
      resendUserInvite(input: $input) {
        email requestType message success
      }
    }`;
}

export const createUserInvite = new CreateUserInvite();
export const updateUserInvite = new UpdateUserInvite();
export const deleteUserInvite = new DeleteUserInvite();
export const resendUserInvite = new ResendUserInvite();
