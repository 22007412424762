import { IndexedDBStorageEnum } from '@enums/survey/indexed-db-storage.enum';

export const indexedDBConfig = {
  version: 1,
  dbStorageName: 'POST',
  objectKeyPath: 'surveyKeyPath',
  defaultKey: 'id',
  objectStoreNames: [
    IndexedDBStorageEnum.ObjectStore.toString(),
    IndexedDBStorageEnum.TemplateStore.toString(),
    IndexedDBStorageEnum.FileKeysStore.toString(),
  ],
};
