import { Injectable } from '@angular/core';

import { CaseStatusEnum } from '@enums/case-status.enum';
import { EntityStatusEnum } from '@enums/entity-status.enum';
import { SurveyTemplateStatusEnum } from '@enums/survey/survey-template-status.enum';
import { UserInviteStatusEnum } from '@enums/user-invite-status.enum';
import { UserRolesEnum } from '@enums/user-roles.enum';
import { UserView } from '@models/user.model';

@Injectable({ providedIn: 'root' })
export class FilterHelperService {
  public getUserSearchFilter<T>(user: UserView, payloadFilter?: T) {
    const filter: { [key: string]: unknown } = {
      statusSearch: { ne: EntityStatusEnum.Pending }
    };

    if (user.role === UserRolesEnum.ProjectCoordinator || user.role === UserRolesEnum.VendorManager) {
      filter.not = {
        or: [
          UserRolesEnum.SuperAdmin,
          UserRolesEnum.Admin,
          UserRolesEnum.ProjectCoordinator,
          UserRolesEnum.VendorManager
        ].map((role) => ({ role: { eq: role } }))
      };
    }

    return Object.assign({}, filter, payloadFilter);
  }

  public getUserInvitesSearchFilter<T>(user: UserView, payloadFilter?: T) {
    const filter: { [key: string]: unknown } = {
      or: [
        UserInviteStatusEnum.InvitePending,
        UserInviteStatusEnum.InviteRejected,
        UserInviteStatusEnum.InviteResent,
        UserInviteStatusEnum.RegistrationPending,
        UserInviteStatusEnum.Expired
      ].map((status) => ({ statusSearch: { eq: status } }))
    };

    if (user.role === UserRolesEnum.ProjectCoordinator) {
      filter.and = {
        or: [
          UserRolesEnum.SuperAdmin,
          UserRolesEnum.Admin,
          UserRolesEnum.ProjectCoordinator,
          UserRolesEnum.VendorManager
        ].map((role) => ({ role: { eq: role } }))
      };
    }

    if (user.role === UserRolesEnum.VendorManager) {
      filter.and = {
        or: [
          UserRolesEnum.Customer,
          UserRolesEnum.ServicePartnerAdmin,
          UserRolesEnum.ServicePartnerTechnician,
          UserRolesEnum.PivitalTechnician,
          UserRolesEnum.PlatformTechnician
        ].map((role) => ({ role: { eq: role } }))
      };
    }

    return Object.assign({}, filter, payloadFilter);
  }

  public getSurveyTemplateSearchFilter(user: UserView) {
    const filter: { [key: string]: unknown } = {
      or: [
        { statusSearch: { ne: SurveyTemplateStatusEnum.Draft } },
        {
          createdBy: { eq: user.id },
          statusSearch: { eq: SurveyTemplateStatusEnum.Draft }
        }
      ]
    };

    if (user.role === UserRolesEnum.ProjectCoordinator) {
      filter.not = { statusSearch: { eq: SurveyTemplateStatusEnum.RequestToDelete } };
    }

    return filter;
  }

  public getSurveySearchFilter<T>(user: UserView, payloadFilter: T, staticFilter?: {[key: string]: any}) {
    let filter: { [key: string]: unknown } = {
      statusSearch: { ne: SurveyTemplateStatusEnum.RequestToDelete }
    };

    if (
      [
        UserRolesEnum.ServicePartnerTechnician,
        UserRolesEnum.PlatformTechnician,
        UserRolesEnum.PivitalTechnician
      ].includes(user.role as UserRolesEnum)
    ) {
      filter.techId = { eq: user.id };
    } else if (user.role.includes(UserRolesEnum.ServicePartnerAdmin)) {
      filter.accountId = { eq: user.teamId };
    } else if (user.role === UserRolesEnum.Customer) {
      filter.clientId = { eq: user.clientId};
    }

    if (payloadFilter || filter || !!staticFilter) {
      filter = Object.assign({}, payloadFilter, filter);
    }

    if (!!staticFilter) {
      filter = Object.assign({}, filter, staticFilter);
    }

    return filter;
  }

  public getCustomSurveySearchFilter<T>(user: UserView, payloadFilter: T, staticFilter?: {[key: string]: any}) {
    let filter: { [key: string]: unknown } = {};

    if (
      [
        UserRolesEnum.ServicePartnerTechnician,
        UserRolesEnum.PlatformTechnician,
        UserRolesEnum.PivitalTechnician
      ].includes(user.role as UserRolesEnum)
    ) {
      filter.techId = user.id;
    } else if (user.role.includes(UserRolesEnum.ServicePartnerAdmin)) {
      filter.accountId = user.teamId;
    } else if (user.role === UserRolesEnum.Customer) {
      filter.clientId = user.clientId;
    }

    if (payloadFilter || filter || !!staticFilter) {
      filter = Object.assign({}, payloadFilter, filter);
    }

    if (!!staticFilter) {
      filter = Object.assign({}, filter, staticFilter);
    }

    return filter;
  }

  public getCaseSearchFilter<T>(user: UserView, payloadFilter?: T) {
    let filter: { [key: string]: unknown };

    if (user.role.includes(UserRolesEnum.ServicePartnerAdmin)) {
      filter = { accountId: { eq: user.teamId } };
    } else if (
      user.role.includes(UserRolesEnum.ServicePartnerTechnician) ||
      user.role.includes(UserRolesEnum.PivitalTechnician)
    ) {
      filter = { techId: { eq: user.id } };
    }

    if ([
      UserRolesEnum.ServicePartnerAdmin,
      UserRolesEnum.ServicePartnerTechnician,
      UserRolesEnum['ServicePartnerAdmin, ServicePartnerTechnician']
    ].includes(user.role as UserRolesEnum)) {
      filter = filter || {};
      filter.statusSearch = { ne: CaseStatusEnum.declined};
    }

    if (payloadFilter || filter) {
      filter = Object.assign({}, payloadFilter, filter, { isActive: { ne: false } });
    }

    if (!filter) {
      filter = { isActive: { ne: false} };
    }

    return filter;
  }
}
