import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { Pagination } from '@models/pagination.model';
import { Case } from '@models/case/case.model';

export class CasePaginationRequestAction implements Action {
  readonly type = ActionTypes.CASE_PAGINATION_REQUEST;

  constructor(public payload: Pagination) {}
}

export class CasePaginationSuccessAction implements Action {
  readonly type = ActionTypes.CASE_PAGINATION_SUCCESS;

  constructor(public payload: { items: Case[], total: number }) {}
}

export class CasePaginationFailureAction implements Action {
  readonly type = ActionTypes.CASE_PAGINATION_FAILURE;

  constructor(public payload: Error) {}
}
