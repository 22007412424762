import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { Required } from 'utility-types';
import { ContactComment } from '@models/contact-comment.model';

class CreateContactComment extends GraphQLMutation<ContactComment, { createContactComment: Partial<ContactComment> }> {
  protected query = `mutation CreateContactComment(
    $input: CreateContactCommentInput!
    $condition: ModelContactCommentConditionInput
  ) {
    createContactComment(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateContactComment extends GraphQLMutation<Required<Partial<ContactComment>, 'id'>, { updateContactComment: Partial<CreateContactComment> }> {
  protected query = `mutation updateContactComment(
    $input: UpdateContactCommentInput!
    $condition: ModelContactCommentConditionInput
  ) {
    updateContactComment(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const updateContactComment = new UpdateContactComment();
export const createContactComment = new CreateContactComment();

