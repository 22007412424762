import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { BellNotification } from '@models/bell-notification.model';

export class LoadBellNotificationsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_BELL_NOTIFICATIONS_REQUEST;
}

export class LoadBellNotificationsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_BELL_NOTIFICATIONS_SUCCESS;

  constructor(public payload: BellNotification[]) {}
}

export class LoadBellNotificationsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_BELL_NOTIFICATIONS_FAILURE;

  constructor(public payload: Error) {}
}
