import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

@Injectable({ providedIn: 'root' })
export class TokenService {
  public async getToken(): Promise<string> {
    try {
      return (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (error) {
      return '';
    }
  }

  public isTokenExpired(token: string): boolean {
    if (!token) {
      return true;
    }
    const expirationTime = JSON.parse(atob(token.split('.')[1])).exp;
    const isExpired = Math.floor(new Date().getTime() / 1000) >= expirationTime;

    return isExpired;
  }
}
