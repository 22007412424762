import { GraphQLCustomQueryList, GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { SurveyQuestion } from '@models/survey/survey-question.model';

class GetSiteSurveyQuestionById extends GraphQLQueryById<{ id: string }, { getSiteSurveyQuestion: SurveyQuestion }> {
  protected query = `query GetSiteSurveyQuestion($id: ID!) {
    getSiteSurveyQuestion(id: $id) {
      id
      title
      objectId
      responseType
      responses
      createdBy
      createdAt
      updatedAt
      answer
    }
  }
`;
}

class GetSiteSurveyQuestionsList extends GraphQLQueryList<
  GraphQLQueryRequest<SurveyQuestion>,
  { listSiteSurveyQuestions: { items: SurveyQuestion[] } }
> {
  protected query = `query ListSiteSurveyQuestions(
    $filter: ModelSiteSurveyQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteSurveyQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        objectId
        responseType
        responses
        createdBy
        createdAt
        updatedAt
        answer
        inReport
      }
      nextToken
    }
  }`;
}

class SearchSurveyQuestions extends GraphQLQueryList<GraphQLQueryRequest<SurveyQuestion>, { searchSiteSurveyQuestions: { items: SurveyQuestion[] } }> {
  protected query = `query SearchSiteSurveyQuestions(
    $filter: SearchableSiteSurveyQuestionFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableSiteSurveyQuestionSortInput
  ) {
    searchSiteSurveyQuestions(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        title
        objectId
        responseType
        responses
        createdBy
        createdAt
        updatedAt
        answer
        inReport
      }
      nextToken
    }
  }`;
}

class CustomSiteSurveyQuestionsSearch extends GraphQLCustomQueryList<any, {
  customSiteSurveyQuestionsSearch: { items: SurveyQuestion[], total: number }
}> {
  protected query = `query CustomSiteSurveyQuestionsSearch(
    $filter: customSiteSurveyQuestionsSearchFilter
    $from: Int!
    $limit: Int!
    $sort: customSiteSurveyQuestionsSearchSort
  ) {
    customSiteSurveyQuestionsSearch(filter: $filter, from: $from, limit: $limit, sort: $sort) {
      items {
        answer
        createdAt
        createdBy
        createdDate
        id
        inReport
        objectId
        responseType
        responses
        title
        updatedAt
        updatedDate
      }
      total
    }
  }`;
}

export const getSiteSurveyQuestionById = new GetSiteSurveyQuestionById();
export const getSiteSurveyQuestionsList = new GetSiteSurveyQuestionsList();
export const searchSurveyQuestions = new SearchSurveyQuestions();
export const customSearchSurveyQuestions = new CustomSiteSurveyQuestionsSearch();
