import { Action } from '@ngrx/store';

import { FileView } from './../../../core/interfaces/file-view.interface';
import { ActionTypes } from './../action-types.enum';

export class LoadCompanyAdditionalFileRequestAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_ADDITIONAL_FILE_REQUEST;

  constructor(public payload: string) {}
}

export class LoadCompanyAdditionalFileSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_ADDITIONAL_FILE_SUCCESS;

  constructor(public payload: FileView[]) {}
}

export class LoadCompanyAdditionalFileFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_ADDITIONAL_FILE_FAILURE;

  constructor(public payload: Error) {}
}
