import { QuestionResponseEnum } from '@enums/survey/question-responses.enum';
import { QuestionAnswerTypeEnum } from '@enums/survey/question-answer-type.enum';

export interface SurveyBody {
  parent: null | string;
  children: SurveyBody[];
  questionsBody: string;
  name: string;
  id: string;
  label?: string;
  removable?: boolean;
  invalid?: boolean;
}

export interface QuestionsBody {
  responseType: QuestionResponseEnum;
  responses: string[] | null;
  answer: QuestionsAnswer[];
  id: string;
  title: string;
  optional?: boolean;
}

export class QuestionsAnswer {
  type: QuestionAnswerTypeEnum;
  value: AnswerValue | string[] | boolean[] | string | File;

  constructor(source: QuestionsAnswer) {
    Object.assign(this, source);
  }
}

export interface AnswerValue {
  name: string;
  url?: string;
  key?: string;
}
