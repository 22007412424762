import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { BackEndRole } from '@models/roles/backend-role.model';
import { FrontEndRole } from '@models/roles/frontend-role.model';

export class UpdateRoleRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_ROLE_REQUEST;

  constructor(
    public payload: { roleName: string; frontendRoles: FrontEndRole[]; backEndRole: BackEndRole }
  ) {}
}

export class UpdateRoleSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_ROLE_SUCCESS;
}

export class UpdateRoleFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_ROLE_FAILURE;

  constructor(public payload: Error) {}
}
