import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { UserInvite } from '@interfaces/user-invite.interface';

export class LoadCompanyInvitationsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_INVITATIONS_REQUEST;

  constructor(public payload: string) {}
}

export class LoadCompanyInvitationsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_INVITATIONS_SUCCESS;

  constructor(public payload: UserInvite[]) {}
}

export class LoadCompanyInvitationsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_INVITATIONS_FAILURE;

  constructor(public payload: Error) {}
}
