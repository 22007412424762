export enum ActionTypes {
  LOAD_CASE_EVENTS_LIST_REQUEST = '[CASE] Load Case Events List Request',
  LOAD_CASE_EVENTS_LIST_FAILURE = '[CASE] Load Case Events List Failure',
  LOAD_CASE_EVENTS_LIST_SUCCESS = '[CASE] Load Case Events List Success',

  LOAD_CASE_TIME_LOGS_LIST_REQUEST = '[CASE] Load Case Time Logs List Request',
  LOAD_CASE_TIME_LOGS_LIST_SUCCESS = '[CASE] Load Case Time Logs List Success',

  DELETE_CASE_TIME_LOG_OBJECT_REQUEST = '[CASE] Delete Time Log Object Request',
  DELETE_CASE_TIME_LOG_OBJECT_SUCCESS = '[CASE] Delete Time Log Object Success',
  DELETE_CASE_TIME_LOG_OBJECT_FAILURE = '[CASE] Delete Time Log Object Failure',

  LOAD_CASE_REQUEST = '[CASE] Load Case Request',
  LOAD_CASE_FAILURE = '[CASE] Load Case Failure',
  LOAD_CASE_SUCCESS = '[CASE] Load Case Success',

  CASE_PAGINATION_REQUEST = '[CASE] Case Pagination Request',
  CASE_PAGINATION_SUCCESS = '[CASE] Case Pagination Success',
  CASE_PAGINATION_FAILURE = '[CASE] Case Pagination Failure',

  CASE_EVENTS_PAGINATION_REQUEST = '[CASE] Case Events Pagination Request',

  CASE_TIME_LOGS_PAGINATION_REQUEST = '[CASE] Case Time Logs Pagination Request',

  UPDATE_CASE_REQUEST = '[CASE] Update Case Request',
  UPDATE_CASE_FAILURE = '[CASE] Update Case Failure',
  UPDATE_CASE_SUCCESS = '[CASE] Update Case Success',

  UPDATE_TASK_REQUEST = '[CASE] Update Task Request',
  UPDATE_TASK_FAILURE = '[CASE] Update Task Failure',
  UPDATE_TASK_SUCCESS = '[CASE] Update Task Success',

  SAVE_CASE_ADDITIONAL_FILE_REQUEST = '[CASE] Save Case Additional File Request',
  SAVE_CASE_ADDITIONAL_FILE_FAILURE = '[CASE] Save Case Additional File Failure',
  SAVE_CASE_ADDITIONAL_FILE_SUCCESS = '[CASE] Save Case Additional File Success',

  REMOVE_CASE_ADDITIONAL_FILE_REQUEST = '[CASE] Remove Case Additional File Request',
  REMOVE_CASE_ADDITIONAL_FILE_FAILURE = '[CASE] Remove Case Additional File Failure',
  REMOVE_CASE_ADDITIONAL_FILE_SUCCESS = '[CASE] Remove Case Additional File Success',

  CREATE_COMMENT_REQUEST = '[CASE] Create Comment Request',
  CREATE_COMMENT_FAILURE = '[CASE] Create Comment Failure',
  CREATE_COMMENT_SUCCESS = '[CASE] Create Comment Success',

  UPDATE_CASE_COMMENT_REQUEST = '[CASE] Update Case Comment Request',
  UPDATE_CASE_COMMENT_FAILURE = '[CASE] Update Case Comment Failure',
  UPDATE_CASE_COMMENT_SUCCESS = '[CASE] Update Case Comment Success',

  CREATE_CASE_TIME_LOG_OBJECT_REQUEST = '[CASE] Create Time Log Request',
  CREATE_CASE_TIME_LOG_OBJECT_SUCCESS = '[CASE] Create Time Log Success',
  CREATE_CASE_TIME_LOG_OBJECT_FAILURE = '[CASE] Create Time Log Failure',

  UPDATE_CASE_TIME_LOG_OBJECT_REQUEST = '[CASE] Update Time Log Request',
  UPDATE_CASE_TIME_LOG_OBJECT_SUCCESS = '[CASE] Update Time Log Success',
  UPDATE_CASE_TIME_LOG_OBJECT_FAILURE = '[CASE] Update Time Log Failure',

  ADDITIONAL_EXPENSES_PAGINATION_REQUEST = '[CASE] Additional Expenses Pagination Request',

  LOAD_ADDITIONAL_EXPENSES_LIST_REQUEST = '[CASE] Load Additional Expenses List Request',
  LOAD_ADDITIONAL_EXPENSES_LIST_SUCCESS = '[CASE] Load Additional Expenses List Success',
  LOAD_ADDITIONAL_EXPENSES_LIST_FAILURE = '[CASE] Load Additional Expenses List Failure',

  CREATE_CASE_ADDITIONAL_EXPENSE_ITEM_REQUEST = '[CASE] Create Additional Expense Item Request',
  CREATE_CASE_ADDITIONAL_EXPENSE_ITEM_SUCCESS = '[CASE] Create Additional Expense Item Success',
  CREATE_CASE_ADDITIONAL_EXPENSE_ITEM_FAILURE = '[CASE] Create Additional Expense Item Failure',

  UPDATE_CASE_ADDITIONAL_EXPENSE_ITEM_REQUEST = '[CASE] Update Additional Expense Item Request',
  UPDATE_CASE_ADDITIONAL_EXPENSE_ITEM_SUCCESS = '[CASE] Update Additional Expense Item Success',
  UPDATE_CASE_ADDITIONAL_EXPENSE_ITEM_FAILURE = '[CASE] Update Additional Expense Item Failure',

  DELETE_ADDITIONAL_EXPENSES_OBJECT_REQUEST = '[CASE] Delete Additional Expenses Object Request',
  DELETE_ADDITIONAL_EXPENSES_OBJECT_SUCCESS = '[CASE] Delete Additional Expenses Object Success',
  DELETE_ADDITIONAL_EXPENSES_OBJECT_FAILURE = '[CASE] Delete Additional Expenses Object Failure'
}
