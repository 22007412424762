import { Action } from '@ngrx/store';
import { ActionTypes } from '../action-types.enum';
import { UserView } from '@models/user.model';

export class LoadAllUsersListRequestAction implements Action {
  readonly type = ActionTypes.LOAD_ALL_USERS_LIST_REQUEST;
}

export class LoadAllUsersListSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ALL_USERS_LIST_SUCCESS;

  constructor(public payload: UserView[]) {}
}

export class LoadAllUsersListFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ALL_USERS_LIST_FAILURE;

  constructor(public payload: Error) {}
}
