import { Action } from '@ngrx/store';

import { AmplifyAuthError } from '@interfaces/amplify-auth-error.interface';
import { ActionTypes } from '../action-types.enum';

export class EmailFormSubmitRequestAction implements Action {
  readonly type = ActionTypes.EMAIL_FORM_SUBMIT_REQUEST;

  constructor(public payload: string) {}
}

export class EmailFormSubmitFailureAction implements Action {
  readonly type = ActionTypes.EMAIL_FORM_SUBMIT_FAILURE;

  constructor(public payload: Partial<AmplifyAuthError>) {}
}
