import { Action } from '@ngrx/store';

import { FileView } from '@interfaces/file-view.interface';
import { VMOCompany } from '@models/vmo-company.model';
import { ActionTypes } from '../action-types.enum';

export class GenerateW9FormRequestAction implements Action {
  readonly type = ActionTypes.GENERATE_W9_FORM_REQUEST;

  constructor(public payload: { model: Partial<VMOCompany>; saveW9?: boolean }) {}
}

export class GenerateW9FormSuccessAction implements Action {
  readonly type = ActionTypes.GENERATE_W9_FORM_SUCCESS;

  constructor(public payload: { file: FileView; loading?: boolean }) {}
}

export class GenerateW9FormFailureAction implements Action {
  readonly type = ActionTypes.GENERATE_W9_FORM_FAILURE;

  constructor(public payload: Error) {}
}
