export enum ActionTypes {
  LOAD_CURRENT_USER_REQUEST = '[USER] Load Current User Request',
  LOAD_CURRENT_USER_FAILURE = '[USER] Load Current User Failure',
  LOAD_CURRENT_USER_SUCCESS = '[USER] Load Current User Success',

  LOAD_USER_VIEW_REQUEST = '[USER] Load User View Request',
  LOAD_USER_VIEW_FAILURE = '[USER] Load User View Failure',
  LOAD_USER_VIEW_SUCCESS = '[USER] Load User View Success',

  LOAD_ALL_USERS_LIST_REQUEST = '[SURVEY] Load All Users List Request',
  LOAD_ALL_USERS_LIST_FAILURE = '[SURVEY] Load All Users List Failure',
  LOAD_ALL_USERS_LIST_SUCCESS = '[SURVEY] Load All Users List Success',

  LOAD_ALL_INVITES_LIST_REQUEST = '[SURVEY] Load All Invites List Request',
  LOAD_ALL_INVITES_LIST_FAILURE = '[SURVEY] Load All Invites List Failure',
  LOAD_ALL_INVITES_LIST_SUCCESS = '[SURVEY] Load All Invites List Success',

  LOAD_ALL_REQUESTS_LIST_REQUEST = '[SURVEY] Load All Requests List Request',
  LOAD_ALL_REQUESTS_LIST_FAILURE = '[SURVEY] Load All Requests List Failure',
  LOAD_ALL_REQUESTS_LIST_SUCCESS = '[SURVEY] Load All Requests List Success',

  CREATE_USER_REQUEST = '[USER] Create User Request',
  CREATE_USER_FAILURE = '[USER] Create User Failure',
  CREATE_USER_SUCCESS = '[USER] Create User Success',

  UPDATE_USER_REQUEST = '[USER] Update User Request',
  UPDATE_USER_FAILURE = '[USER] Update User Failure',
  UPDATE_USER_SUCCESS = '[USER] Update User Success',

  UPDATE_USER_REQUEST_REQUEST = '[USER] Update Single User Request',
  UPDATE_USER_REQUEST_FAILURE = '[USER] Update Single User Failure',
  UPDATE_USER_REQUEST_SUCCESS = '[USER] Update Single User Success',

  CREATE_USER_ADDITIONAL_FILE_REQUEST = '[USER] Create User Additional File Request',
  CREATE_USER_ADDITIONAL_FILE_FAILURE = '[USER] Create User Additional File Failure',
  CREATE_USER_ADDITIONAL_FILE_SUCCESS = '[USER] Create User Additional File Success',

  LOAD_USER_FILES_REQUEST = '[USER] Load User Files Request',
  LOAD_USER_FILES_FAILURE = '[USER] Load User Files Failure',
  LOAD_USER_FILES_SUCCESS = '[USER] Load User Files Success',

  INVITE_NEW_USER_REQUEST = '[USER] Invite New User Request',
  INVITE_NEW_USER_FAILURE = '[USER] Invite New User Failure',
  INVITE_NEW_USER_SUCCESS = '[USER] Invite New User Success',

  DISABLE_USER_REQUEST = '[USER] Disable User Request',
  DISABLE_USER_FAILURE = '[USER] Disable User Failure',
  DISABLE_USER_SUCCESS = '[USER] Disable User Success',

  REACTIVATE_USER_REQUEST = '[USER] Reactivate User Request',
  REACTIVATE_USER_FAILURE = '[USER] Reactivate User Failure',
  REACTIVATE_USER_SUCCESS = '[USER] Reactivate User Success',

  CREATE_USER_INVITE_REQUEST = '[USER] Create User Invite Request',
  CREATE_USER_INVITE_FAILURE = '[USER] Create User Invite Failure',
  CREATE_USER_INVITE_SUCCESS = '[USER] Create User Invite Success',

  UPDATE_USER_INVITE_REQUEST = '[USER] Update User Invite Request',
  UPDATE_USER_INVITE_FAILURE = '[USER] Update User Invite Failure',
  UPDATE_USER_INVITE_SUCCESS = '[USER] Update User Invite Success',

  CONFIRM_USER_INVITE_REQUEST = '[USER] Confirm User Invite Request',
  CONFIRM_USER_INVITE_FAILURE = '[USER] Confirm User Invite Failure',
  CONFIRM_USER_INVITE_SUCCESS = '[USER] Confirm User Invite Success',

  CHANGE_USER_ROLE_REQUEST = '[USER] Change User Role Request',
  CHANGE_USER_ROLE_FAILURE = '[USER] Change User Role Failure',
  CHANGE_USER_ROLE_SUCCESS = '[USER] Change User Role Success',

  CHANGE_USER_EMAIL_REQUEST = '[USER] Change User Email Request',
  CHANGE_USER_EMAIL_FAILURE = '[USER] Change User Email Failure',
  CHANGE_USER_EMAIL_SUCCESS = '[USER] Change User Email Success',

  USER_PAGINATION_REQUEST = '[USER] User Pagination Request',
  USER_PAGINATION_SUCCESS = '[USER] User Pagination Success',

  USER_AUTHLOG_PAGINATION_REQUEST = '[USER] User Auth Log Pagination Request',
  USER_AUTHLOG_PAGINATION_SUCCESS = '[USER] User Auth Log Pagination Success',

  USER_INVITE_PAGINATION_REQUEST = '[USER] User Invite Pagination Request',
  USER_INVITE_PAGINATION_SUCCESS = '[USER] User Invite Pagination Success',

  USER_REQUEST_PAGINATION_REQUEST = '[USER] User Request Pagination Request',
  USER_REQUEST_PAGINATION_SUCCESS = '[USER] User Request Pagination Success',
  USER_REQUEST_PAGINATION_FAILURE = '[USER] User Request Pagination Failure',

  CREATE_CONTACT_COMMENT_REQUEST = '[CASE] Create Contact Comment Request',
  CREATE_CONTACT_COMMENT_FAILURE = '[CASE] Create Contact Comment Failure',
  CREATE_CONTACT_COMMENT_SUCCESS = '[CASE] Create Contact Comment Success',

  UPDATE_CONTACT_CASE_COMMENT_REQUEST = '[CASE] Update Contact Comment Request',
  UPDATE_CONTACT_CASE_COMMENT_FAILURE = '[CASE] Update Contact Comment Failure',
  UPDATE_CONTACT_CASE_COMMENT_SUCCESS = '[CASE] Update Contact Comment Success',

  SAVE_USER_ADDITIONAL_FILE_REQUEST = '[USER] Save User Additional File Request',
  SAVE_USER_ADDITIONAL_FILE_FAILURE = '[USER] Save User Additional File Failure',
  SAVE_USER_ADDITIONAL_FILE_SUCCESS = '[USER] Save User Additional File Success',

  REMOVE_USER_ADDITIONAL_FILE_REQUEST = '[USER] Remove User Additional File Request',
  REMOVE_USER_ADDITIONAL_FILE_FAILURE = '[USER] Remove User Additional File Failure',
  REMOVE_USER_ADDITIONAL_FILE_SUCCESS = '[USER] Remove User Additional File Success',

  SAVE_USER_CHECKS_FILES_REQUEST = '[USER] Save User Checks Files Request',
  SAVE_USER_CHECKS_FILES_FAILURE = '[USER] Save User Checks Files Failure',
  SAVE_USER_CHECKS_FILES_SUCCESS = '[USER] Save User Checks Files Success',

  REMOVE_USER_CHECKS_FILES_REQUEST = '[USER] Remove User Checks Files Request',
  REMOVE_USER_CHECKS_FILES_FAILURE = '[USER] Remove User Checks Files Failure',
  REMOVE_USER_CHECKS_FILES_SUCCESS = '[USER] Remove User Checks Files Success',

  UPDATE_USERS_LIST_SUCCESS = '[USER] Update Users List Success'
}
