import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AuthService } from '@services/auth/auth.service';
import { AmplifyService } from '@services/amplify.service';

import { AgreementContentConfig } from '@interfaces/agreement-content-config.interface';
import { AgreementTypeEnum } from '@enums/agreement-type.enum';
import { getAgreementsList } from '@graphql/agreements/queries';
import { agreementCompanyAddressKey, agreementCompanyNameKey } from '@app/constants';

@Component({
  selector: 'app-agreement-modal',
  templateUrl: './agreement-modal.component.html',
  styleUrls: ['./agreement-modal.component.scss'],
})
export class AgreementModalComponent {
  public wasScrolledDown: boolean;
  public agreementTypes = AgreementTypeEnum;
  public config: AgreementContentConfig;
  public agreements: string;

  constructor(
    private readonly auth: AuthService,
    private readonly amplifyService: AmplifyService,
    @Inject(MAT_DIALOG_DATA) private data: AgreementContentConfig
  ) {
    this.config = data;
    this.getAgreement();
  }

  public onScroll(event: Event): void {
    const element = event.target as HTMLDivElement;
    const max = element.scrollHeight;
    const position = element.scrollTop + element.clientHeight + 20;

    if (position >= max) {
      this.wasScrolledDown = true;
    }
  }

  private async getAgreement(): Promise<void> {
    const typeFilter =
      this.config.type === AgreementTypeEnum.UserAgreement
        ? AgreementTypeEnum.UserAgreement
        : AgreementTypeEnum.ServicePartnerAgreement;

    const isUserLogged = await this.auth.isAuthenticated();

    if (!isUserLogged) {
      await this.amplifyService.loginAsAdmin();
    }

    getAgreementsList
      .execute()
      .then((res) => {
        this.agreements = res?.data?.listAgreementss?.items.filter((agreement) => agreement.type === typeFilter)[0]?.body || '';
        this.agreements = this.agreements.replace(agreementCompanyNameKey, this.config.companyName || '"Company name"');
        this.agreements = this.agreements.replace(agreementCompanyAddressKey, this.config.companyAddress || '"Company address"');
      })
      .finally(() => {
        if (!isUserLogged) {
          this.amplifyService.logout();
        }
      });
  }
}
