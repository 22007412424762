import { GraphQLResult } from '@aws-amplify/api/lib/types';

import { GraphQLApiService } from '@services/graphql/graphql-api.service';
import { defaultQueryParams } from '@app/constants';

export class GraphQLQueryList<Req, Res> {
  protected query: string;

  public execute(params?: Req): Promise<GraphQLResult<Res>> {
    const isListAgreements = this.query.includes('listAgreementss');
    const queryParams: any = Object.assign(isListAgreements ? { limit: 10 } : { ...defaultQueryParams }, params);

    if (this.query.includes('listUsersAuthEvents')) {
      delete queryParams.sort;
    }

    return GraphQLApiService.request<Req, Res>(this.query, queryParams);
  }
}

export class GraphQLQueryById<Req, Res> {
  protected query: string;

  public execute(params: Req): Promise<GraphQLResult<Res>> {
    return GraphQLApiService.request<Req, Res>(this.query, params);
  }
}

export class GraphQLCustomQueryList<Req, Res> {
  protected query: string;

  public execute(params?: Req): Promise<GraphQLResult<Res>> {
    const queryParams: any = Object.assign({ from: 0, limit: 10 }, params);

    return GraphQLApiService.request<Req, Res>(this.query, queryParams);
  }
}
