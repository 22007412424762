export enum SalesForceActionsEnum {
  case = 'Case',
  caseComment = 'CaseComment',
  caseTask = 'Task',
  surveyTemplate = 'postSurvey__c',
  attachment = 'Attachment',
  servicePartnerCompany = 'Account',
  userProfile = 'Contact',
  contactComment = 'ContactComment__c',
}
