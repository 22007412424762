import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { Pagination } from '@models/pagination.model';
import { SiteSurvey } from '@models/survey/site-survey.model';

export class QuestionsPaginationRequestAction implements Action {
  readonly type = ActionTypes.QUESTIONS_PAGINATION_REQUEST;

  constructor(public payload: Pagination) {}
}

export class SurveysPaginationRequestAction implements Action {
  readonly type = ActionTypes.SURVEYS_PAGINATION_REQUEST;

  constructor(public payload: Pagination, public staticFilter?: { [key: string]: any }) {}
}

export class SurveysPaginationSuccessAction implements Action {
  readonly type = ActionTypes.SURVEYS_PAGINATION_SUCCESS;

  constructor(public payload: { items: SiteSurvey[], total: number }) {}
}

export class SurveysManagementPaginationRequestAction implements Action {
  readonly type = ActionTypes.SURVEYS_MANAGEMENT_PAGINATION_REQUEST;

  constructor(public payload: Pagination) {}
}

export class SurveysManagementPaginationSuccessAction implements Action {
  readonly type = ActionTypes.SURVEYS_MANAGEMENT_PAGINATION_SUCCESS;

  constructor(public payload: { items: SiteSurvey[], total: number }) {}
}

export class SurveysManagementPaginationFailureAction implements Action {
  readonly type = ActionTypes.SURVEYS_MANAGEMENT_PAGINATION_FAILURE;

  constructor(public payload: Error) {}
}

export class ArchivedSurveysPaginationRequestAction implements Action {
  readonly type = ActionTypes.ARCHIVED_SURVEYS_PAGINATION_REQUEST;

  constructor(public payload: Pagination, public staticFilter?: { [key: string]: any }) {}
}

export class ArchivedSurveysPaginationSuccessAction implements Action {
  readonly type = ActionTypes.ARCHIVED_SURVEYS_PAGINATION_SUCCESS;

  constructor(public payload: { items: SiteSurvey[], total: number }) {}
}

export class ArchivedSurveysPaginationFailureAction implements Action {
  readonly type = ActionTypes.ARCHIVED_SURVEYS_PAGINATION_FAILURE;

  constructor(public payload: Error) {}
}
