import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { CaseAdditionalFile } from '@models/additional-file.model';

class CreateCaseAttachment extends GraphQLMutation<CaseAdditionalFile, { createCaseAdditionalFiles: Partial<CaseAdditionalFile> }> {
  protected query = `mutation CreateCaseAdditionalFiles(
    $input: CreateCaseAdditionalFilesInput!
    $condition: ModelCaseAdditionalFilesConditionInput
  ) {
    createCaseAdditionalFiles(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateCaseAttachment extends GraphQLMutation<Required<Partial<CaseAdditionalFile>, 'id'>, { updateCaseAdditionalFiles: Partial<CaseAdditionalFile> }> {
  protected query = `mutation UpdateCaseAdditionalFiles(
    $input: UpdateCaseAdditionalFilesInput!
    $condition: ModelCaseAdditionalFilesConditionInput
  ) {
    updateCaseAdditionalFiles(input: $input, condition: $condition) {
      id
    }
  }`;
}

class RemoveCaseAttachment extends GraphQLMutation<Required<Partial<CaseAdditionalFile>, 'id'>, { deleteCaseAdditionalFiles: Partial<CaseAdditionalFile> }> {
  protected query = `mutation DeleteCaseAdditionalFiles(
    $input: DeleteCaseAdditionalFilesInput!
    $condition: ModelCaseAdditionalFilesConditionInput
  ) {
    deleteCaseAdditionalFiles(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createCaseAttachment = new CreateCaseAttachment();
export const updateCaseAttachment = new UpdateCaseAttachment();
export const removeCaseAttachment = new RemoveCaseAttachment();
