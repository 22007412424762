import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, combineLatest, EMPTY, from } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, repeat, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '@services/api.service';
import { FileService } from '@services/file.service';
import { DialogService } from '@services/dialog.service';
import { SnackBarService } from '@services/snackbar.service';
import { HelperService } from '@services/helpers/helper.service';
import { UserHelperService } from '@services/helpers/user-helper.service';
import { CompanyHelperService } from '@services/helpers/company-helper.service';

import { Email } from '@models/email.model';
import { VMOCompany } from '@models/vmo-company.model';
import { S3FileObject } from '@models/s3-file-object.model';
import { GraphQLFilter } from '@models/graphql/graphql-filter.model';
import { W9FormRequest } from '@models/w9-from-request.model';
import { UserRolesEnum } from '@enums/user-roles.enum';
import { StorageFileKeyPrefixEnum } from '@enums/storage-file-key-prefix.enum';
import { UserInvite } from '@interfaces/user-invite.interface';
import { UserInviteStatusEnum } from '@enums/user-invite-status.enum';
import { EntityStatusEnum } from '@enums/entity-status.enum';
import { environment } from './../../../environments/environment';
import { TableOfReactivateUsersComponent } from './../../dashboard/vmo-company/table-of-reactivate-users/table-of-reactivate-users.component';

import { getTeamFilesList } from '@graphql/company-files/queries';
import { getCompanyById, searchCompanies } from '@graphql/company/queries';
import { getUserInviteList, searchUserInvites } from '@graphql/user-invites/queries';
import { getUserList } from '@graphql/user/queries';
import { removeTeamFile } from '@graphql/company-files/mutations';
import { createCompany, updateCompany } from '@graphql/company/mutations';
import { updateUserInvite } from '@graphql/user-invites/mutations';

import * as CompanyActions from './company-actions';
import * as CompanySelectors from './selectors';
import { ActionTypes } from './action-types.enum';
import { UserActions, UserSelectors } from '../user-store';
import { RootStore } from '..';
import * as AuthActions from '../auth-store/auth-actions';
import { SalesForceService } from '@services/sales-force.service';
import { SalesForceActionsEnum } from '@enums/sales-force-actions.enum';

@Injectable()
export class CompanyEffects {
  constructor(
    private readonly router: Router,
    private readonly salesForceService: SalesForceService,
    private readonly store$: Store<RootStore.AppState>,
    private readonly actions$: Actions,
    private readonly snackbar: SnackBarService,
    private readonly fileService: FileService,
    private readonly apiService: ApiService,
    private readonly companyHelper: CompanyHelperService,
    private readonly helper: HelperService,
    private readonly userHelper: UserHelperService,
    private readonly dialog: DialogService
  ) {}

  @Effect()
  loadCompanyEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.LoadCompanyRequestAction>(ActionTypes.LOAD_COMPANY_REQUEST),
    switchMap(({ payload }) => getCompanyById.execute({ id: payload }).then(res => res?.data?.getTeam)),
    switchMap((company) => {
      const key = company?.W9Form?.key;

      if (key) {
        return this.fileService.getFile(key).then((link) => {
          this.store$.dispatch(new CompanyActions.GenerateW9FormSuccessAction({ file: { name: company.W9Form.name, link, key }}));
          return company;
        });
      }

      return of(company);
    }),
    map((company) => {
      if (!company) {
        this.snackbar.error('Service company not found');
        this.router.navigate(['service-companies']);
      } else {
        company.TaxID = this.companyHelper.getFormattedTaxId(company);
        this.store$.dispatch(new CompanyActions.LoadCompanyAdditionalFileRequestAction(company.id));
        this.store$.dispatch(new CompanyActions.LoadCompanyInvitationsRequestAction(company.id));
      }

      if (company?.CertificateInsurance) {
        this.store$.dispatch(
          new CompanyActions.LoadCompanyInsuranceRequestAction(company.CertificateInsurance)
        );
      }

      return new CompanyActions.LoadCompanySuccessAction(company);
    }),
    catchError((error) => of(new CompanyActions.LoadCompanyFailureAction(error))),
    repeat()
  );

  @Effect()
  createCompanyEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.CreateCompanyRequestAction>(ActionTypes.CREATE_COMPANY_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) =>
      combineLatest([
        createCompany
          .execute({
            input: {
              ...payload.model,
              createdBy: user.id,
              TaxID: Number(payload.model.TaxID.toString().replace(/[-]/g, ''))
            }
          })
          .then(
            (response) => {
              return ({
                ...payload.model,
                id: response?.data?.createTeam?.id
              } as VMOCompany);
            }
          ),
          of(payload),
          of(user)
      ])
    ),
    switchMap(([company, payload, user]) =>
      combineLatest([
        Promise.resolve(this.companyHelper.createNewCompanySF(payload.model, company.id, user.id)),
        of(company),
        of(payload),
        of(user)
      ])
    ),
    map(([salesforceID, company, payload, user]) => {
      if (company?.signed && company?.signValue) {
        this.store$.dispatch(new CompanyActions.GenerateW9FormRequestAction({ model: company, saveW9: true }));
      }
      if (user) {
        const sfUpdateModel = {
          AccountId: salesforceID
        };
        this.salesForceService.patchSFInfo(SalesForceActionsEnum.userProfile, user.salesforceId, sfUpdateModel);
      }

      if (payload.files?.length) {
        payload.files.map((file) => {
            this.salesForceService.uploadSFAttachment(salesforceID, file.CertificateInsurance.name, file.CertificateInsurance);
          }
        );
        this.store$.dispatch(
          new CompanyActions.UpdateCompanyRequestAction({
            company: { id: company.id },
            files: payload.files,
            type: 'newCompany'
          })
        );
      }

      if (payload.additionalFiles?.length) {
        this.store$.dispatch(
          new CompanyActions.SaveCompanyAdditionalFileRequestAction({
            files: payload.additionalFiles, id: company.id, salesforceId: salesforceID
          })
        );
      }

      if (user.role.includes(UserRolesEnum.ServicePartnerAdmin)) {
        const updateUserModel = {
          model: { user: { id: user.id, teamId: company.id, userTeamIDId: company.id } }
        };

        const userInviteModel: Partial<UserInvite> = {
          email: user.email,
          firstName: user.firstname,
          lastName: user.lastname,
          userId: user.id,
          teamId: company.id,
          usersInviteTeamIDId: company.id,
          usersInviteUserIDId: user.id,
          role: user.role,
          status: UserInviteStatusEnum.InviteAccepted
        };

        this.store$.dispatch(new UserActions.CreateUserInviteRequestAction(userInviteModel));
        this.store$.dispatch(new UserActions.UpdateUserRequestAction(updateUserModel));
      }

      this.snackbar.success('Company was created successfully');
      this.store$.dispatch(new CompanyActions.LoadCompanySuccessAction(company));

      return new CompanyActions.CreateCompanySuccessAction();
    }),
    catchError((error) => of(new CompanyActions.CreateCompanyFailureAction(error))),
    repeat()
  );

  @Effect()
  updateCompanyEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.UpdateCompanyRequestAction>(ActionTypes.UPDATE_COMPANY_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([action, user]) =>
      combineLatest([
        this.fileService
          .save(action.payload.files, StorageFileKeyPrefixEnum.CompanyInsurance, action.payload.company.id)
          .then((savedFiles) => {
            const updatedCompany = { ...action.payload.company };
            if (action.payload.type === 'existingCompany') {
              this.companyHelper.updateNewCompanySF(updatedCompany);
              action.payload.files.map((file) => {
                this.salesForceService
                  .uploadSFAttachment(updatedCompany.salesforceId, file.CertificateInsurance.name, file.CertificateInsurance);
              });
            }
            const model = Object.assign(updatedCompany, ...savedFiles);

            if (model.TaxID) {
              model.TaxID = Number(model.TaxID.toString().replace(/[-]/g, ''));
            }

            return updateCompany.execute({
              input: {
                ...model,
                updatedBy: user.id
              }
            });
          }),
        of(action.payload)
      ])
    ),
    map(([response, payload]) => {
      this.store$.dispatch(new CompanyActions.LoadCompanyRequestAction(response?.data?.updateTeam?.id));
      payload.params?.callback?.();
      return new CompanyActions.UpdateCompanySuccessAction();
    }),
    catchError((error) => of(new CompanyActions.UpdateCompanyFailureAction(error))),
    repeat()
  );

  @Effect()
  loadCompanyListEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.LoadCompanyListRequestAction>(ActionTypes.LOAD_COMPANY_LIST_REQUEST),
    switchMap(() => searchCompanies.execute().then(res => res?.data?.searchTeams?.items)),
    map((response) => new CompanyActions.LoadCompanyListSuccessAction(response)),
    catchError((error) => of(new CompanyActions.LoadCompanyListFailureAction(error))),
    repeat()
  );

  @Effect()
  loadCompanyInsuranceEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.LoadCompanyInsuranceRequestAction>(ActionTypes.LOAD_COMPANY_INSURANCE_REQUEST),
    switchMap(({ payload }) => combineLatest([this.fileService.getFile(payload.key), of(payload)])),
    map(([link, payload]) => {
      const insurance = {
        name: payload.key.split('/').pop(),
        link
      };

      return new CompanyActions.LoadCompanyInsuranceSuccessAction(insurance);
    }),
    catchError((error) => of(new CompanyActions.LoadCompanyInsuranceFailureAction(error))),
    repeat()
  );

  @Effect()
  loadCompanyAdditionalFilesEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.LoadCompanyAdditionalFileRequestAction>(
      ActionTypes.LOAD_COMPANY_ADDITIONAL_FILE_REQUEST
    ),
    switchMap(({ payload }) => getTeamFilesList.execute(new GraphQLFilter('userId', payload))),
    map((response) =>
      response?.data?.listTeamAdditionalFiless?.items?.map((item) => ({
        ...item.image,
        id: item.id
      }))
    ),
    switchMap((files) => Promise.all(files.map((file) => this.fileService.createFileView(file)))),
    map((files) => new CompanyActions.LoadCompanyAdditionalFileSuccessAction(files)),
    catchError((error) => of(new CompanyActions.LoadCompanyAdditionalFileFailureAction(error))),
    repeat()
  );

  @Effect()
  saveCompanyAdditionalFilesEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.SaveCompanyAdditionalFileRequestAction>(ActionTypes.SAVE_COMPANY_ADDITIONAL_FILE_REQUEST),
    switchMap(({ payload }) =>
      Promise.all(payload.files.map((file) => this.companyHelper.saveFile(file, payload.id, payload.salesforceId)))
    ),
    switchMap((files) => Promise.all(files.map((file) => this.fileService.createFileView(file)))),
    map((files) => new CompanyActions.SaveCompanyAdditionalFileSuccessAction(files)),
    catchError((error) => of(new CompanyActions.SaveCompanyAdditionalFileRequestAction(error))),
    repeat()
  );

  @Effect()
  removeCompanyAdditionalFilesEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.RemoveCompanyAdditionalFileRequestAction>(
      ActionTypes.REMOVE_COMPANY_ADDITIONAL_FILE_REQUEST
    ),
    switchMap(({ payload }) =>
      this.fileService
        .removeFile(payload.file.key)
        .then(() => {
          this.salesForceService.deleteSFAttachment(SalesForceActionsEnum.attachment, payload.salesforceId);
          return removeTeamFile.execute({ input: { id: payload.file.id } });
        })
    ),
    withLatestFrom(this.store$.select(CompanySelectors.selectCompanyAdditionalFiles)),
    map(([response, files]) => {
      const newFiles = files.filter((file) => file.id !== response?.data?.deleteTeamAdditionalFiles?.id);

      return new CompanyActions.RemoveCompanyAdditionalFileSuccessAction(newFiles);
    }),
    catchError((error) => of(new CompanyActions.RemoveCompanyAdditionalFileFailureAction(error))),
    repeat()
  );

  @Effect()
  inviteUserEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.InviteUserRequestAction>(ActionTypes.INVITE_USER_REQUEST),
    switchMap(({ payload }) => {
      const searchFilter = new GraphQLFilter('email', payload.user.email);
      return combineLatest([
        getUserList.execute(searchFilter).then((response) => response?.data?.listUsers?.items[0]),
        of(payload)
      ]);
    }),
    switchMap(([user, payload]) => {
      if (!user) {
        this.snackbar.warning('User not found. Please, create new user');
        this.store$.dispatch(new CompanyActions.InviteUserSuccessAction(true));
        return EMPTY;
      } else {
        return combineLatest([
          getUserInviteList
            .execute(new GraphQLFilter('email', user.email))
            .then((response) => response?.data?.listUsersInvites?.items),
          of(payload),
          of(user)
        ]);
      }
    }),
    switchMap(([userInvites, payload, user]) => {
      const isUserInvitedToThisCompany = userInvites.some((invite) => invite.teamId === payload.company.id);
      if (user?.teamId && user.teamId !== payload.company.id) {
        this.snackbar.error('This user has already joined another company');
        this.store$.dispatch(new CompanyActions.InviteUserSuccessAction());
        return EMPTY;
      } else if (user?.teamId && user.teamId === payload.company.id) {
        this.snackbar.error('This user has already joined this company');
        this.store$.dispatch(new CompanyActions.InviteUserSuccessAction());
        return EMPTY;
      } else if (isUserInvitedToThisCompany) {
        this.snackbar.error('This user has already been invited to this company');
        this.store$.dispatch(new CompanyActions.InviteUserSuccessAction());
        return EMPTY;
      }

      this.companyHelper.checkInvitedUserRole(user);
      const emailTemplate = this.companyHelper.createUserInviteEmailTemplate(payload.company);
      const userInviteModel: Partial<UserInvite> = {
        email: payload.user.email,
        firstName: user.firstname,
        lastName: user.lastname,
        userId: user.id,
        teamId: payload.company.id,
        usersInviteTeamIDId: payload.company.id,
        usersInviteUserIDId: user.id,
        role: payload.user.role,
        status: UserInviteStatusEnum.InvitePending
      };

      this.store$.dispatch(new UserActions.CreateUserInviteRequestAction(userInviteModel));

      return this.apiService
        .sendEmail(
          new Email({
            to: user.email,
            text: emailTemplate
          })
        )
        .then(() => {
          payload?.params?.callback();
          this.snackbar.success('Invitation to join the partner successfully sent');
        });
    }),
    map(() => new CompanyActions.InviteUserSuccessAction()),
    catchError((error) => of(new CompanyActions.InviteUserFailureAction(error))),
    repeat()
  );

  @Effect()
  generateW9FormEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.GenerateW9FormRequestAction>(ActionTypes.GENERATE_W9_FORM_REQUEST),
    switchMap(({ payload }) => from(this.apiService.generateW9Form(new W9FormRequest(payload.model))).pipe(
      switchMap(({ key }) => this.fileService.getFile(key).then((url) => ({ url, key }))),
      withLatestFrom(
        this.store$.select(CompanySelectors.selectW9Form),
        this.store$.select(CompanySelectors.selectCompanyInfo),
        this.store$.select(UserSelectors.selectUser)
        ),
        switchMap(([newForm, oldForm, company, user]) => {
        const requests = [];
        if (oldForm?.key) {
          requests.push(this.fileService.removeFile(oldForm.key));
        }

        if (payload.saveW9) {
          if (company?.W9Form?.key) {
            requests.push(this.fileService.removeFile(company.W9Form.key));
          }
          const s3Object = new S3FileObject(newForm.key, 'W9 Form');
          requests.push(updateCompany.execute({ input: { id: company.id, updatedBy: user.id, W9Form: s3Object } }));
        }

        return Promise.all(requests).then(() => ({ name: 'W9 Form', link: newForm.url, key: newForm.key }));
      })
    )),
    map((w9Form) => new CompanyActions.GenerateW9FormSuccessAction({ file: w9Form, loading: false })),
    catchError((error) => of(new CompanyActions.GenerateW9FormFailureAction(error))),
    repeat()
  );

  @Effect()
  removeW9FormEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.RemoveW9FormRequestAction>(ActionTypes.REMOVE_W9_FORM_REQUEST),
    switchMap(({ payload }) => this.fileService.removeFile(payload)),
    map(() => new CompanyActions.RemoveW9FormSuccessAction()),
    catchError((error) => of(new CompanyActions.RemoveW9FormFailureAction(error))),
    repeat()
  );

  @Effect()
  loadCompanyInvitationsEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.LoadCompanyInvitationsRequestAction>(ActionTypes.LOAD_COMPANY_INVITATIONS_REQUEST),
    switchMap(({ payload }) => searchUserInvites.execute(new GraphQLFilter('teamId', payload))),
    map((response) => {
      const invites = response?.data?.searchUsersInvites?.items || [];

      invites.forEach((invite) => {
        invite.firstName = invite.userID?.firstname || invite.firstName;
        invite.lastName = invite.userID?.lastname || invite.lastName;

        if (
          invite.status !== UserInviteStatusEnum.RegistrationPending &&
          invite.status !== UserInviteStatusEnum.InviteResent
        ) {
          return;
        }
        const expireAt = +new Date(invite.inviteDatetime) + environment.linkExpirationTime;
        if (expireAt < +new Date()) {
          invite.status = UserInviteStatusEnum.Expired;
          updateUserInvite
            .execute({ input: { id: invite.id, status: UserInviteStatusEnum.Expired, statusSearch: UserInviteStatusEnum.Expired } });
        }
      });
      const sorted = this.helper.sortByCreationDate(invites);

      return new CompanyActions.LoadCompanyInvitationsSuccessAction(sorted);
    }),
    catchError((error) => of(new CompanyActions.LoadCompanyInvitationsFailureAction(error))),
    repeat()
  );

  @Effect()
  activateCompanyEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.ActivateCompanyRequestAction>(ActionTypes.ACTIVATE_COMPANY_REQUEST),
    switchMap(({ payload }) => combineLatest([getUserInviteList.execute(new GraphQLFilter('teamId', payload)), of(payload)])),
    switchMap(([response, teamId]) => {
      const invites = response?.data?.listUsersInvites?.items || [];
      const userList = invites.filter(invite => invite.status === UserInviteStatusEnum.Disabled);

      return combineLatest([
        this.dialog.open<TableOfReactivateUsersComponent, UserInvite[]>(TableOfReactivateUsersComponent, { data: userList }).afterClosed(),
        of(teamId)
      ]);
    }),
    switchMap(([users, teamId]) => {
      if (!users) {
        this.store$.dispatch(new CompanyActions.ActivateCompanySuccessAction());
        return EMPTY;
      }

      const requests = users.map(
        (user) => this.userHelper.enableUser(user).then(() => this.userHelper.setNewUserPassword(user.email, user.userID.cognitoId))
      );

      return combineLatest([
        ...requests,
        updateCompany.execute({ input: { id: teamId, status: EntityStatusEnum.Active, statusSearch: EntityStatusEnum.Active } })
      ]).pipe(map(() => teamId));
    }),
    withLatestFrom(this.store$.select(CompanySelectors.selectCompanies)),
    map(([teamId, companyList]) => {
      const companies = JSON.parse(JSON.stringify(companyList));
      const company = companies.find(item => item.id === teamId);
      if (company) {
        company.status = EntityStatusEnum.Active;
        this.store$.dispatch(new CompanyActions.LoadCompanyListSuccessAction(companies));
      }
      this.snackbar.success('Company has been reactivated successfully');
      return new CompanyActions.ActivateCompanySuccessAction();
    }),
    catchError((error) => of(new CompanyActions.ActivateCompanyFailureAction(error))),
    repeat()
  );

  @Effect()
  deactivateCompanyEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.DeactivateCompanyRequestAction>(ActionTypes.DEACTIVATE_COMPANY_REQUEST),
    switchMap(({ payload }) => this.dialog.confirm(payload.message).afterClosed().pipe(filter(value => {
      if (!value) {
        this.store$.dispatch(new CompanyActions.ActivateCompanySuccessAction());
      }
      return value;
    }), map(() => payload))),
    switchMap(( payload ) => combineLatest([getUserInviteList.execute(new GraphQLFilter('teamId', payload.model.id)), of(payload)])),
    switchMap(([response, team]) => {
      const invites = response?.data?.listUsersInvites?.items || [];
      const statusesToDisable = [
        UserInviteStatusEnum.InviteAccepted,
        UserInviteStatusEnum.RegistrationComplete,
        UserInviteStatusEnum.Reactivated
      ];
      const usersToDisable = invites.filter((user) => statusesToDisable.includes(user.status));
      const requests = usersToDisable.map((user) => this.userHelper.disableUser(user));
      return combineLatest([
        ...requests,
        updateCompany.execute({ input: { id: team.model.id, status: EntityStatusEnum.Disabled, statusSearch: EntityStatusEnum.Disabled } })
      ]).pipe(map(() => team));
    }),
    withLatestFrom(this.store$.select(CompanySelectors.selectCompanies)),
    map(([team, companyList]) => {
      const companies = JSON.parse(JSON.stringify(companyList));
      const company = companies.find(item => item.id === team.model.id);
      if (company) {
        company.status = EntityStatusEnum.Disabled;
        this.store$.dispatch(new CompanyActions.LoadCompanyListSuccessAction(companies));
      }
      this.snackbar.success('Company has been deactivated successfully');
      if (team.type === 'byOwner') {
        this.store$.dispatch(new AuthActions.LogoutRequestAction(true));
      }
      return new CompanyActions.DeactivateCompanySuccessAction();
    }),
    catchError((error) => of(new CompanyActions.DeactivateCompanyFailureAction(error))),
    repeat()
  );

  @Effect()
  companyPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<CompanyActions.CompanyPaginationRequestAction>(ActionTypes.COMPANY_PAGINATION_REQUEST),
    switchMap(({ payload }) => combineLatest([
      searchCompanies.execute(payload).then(res => res?.data?.searchTeams?.items),
      payload.reset ? of([] as VMOCompany[]) : this.store$.select(CompanySelectors.selectCompanies).pipe(take(1))
    ])),
    map(([response, companies]) => new CompanyActions.LoadCompanyListSuccessAction([...companies, ...response])),
    catchError((error) => of(new CompanyActions.LoadCompanyListFailureAction(error))),
    repeat()
  );

  @Effect({ dispatch: false })
  companyErrorsEffect$: Observable<void> = this.actions$.pipe(
    ofType<Action & { payload: Error }>(
      ActionTypes.LOAD_COMPANY_FAILURE,
      ActionTypes.LOAD_COMPANY_LIST_FAILURE,
      ActionTypes.CREATE_COMPANY_FAILURE,
      ActionTypes.UPDATE_COMPANY_FAILURE,
      ActionTypes.INVITE_USER_FAILURE,
      ActionTypes.LOAD_COMPANY_INSURANCE_FAILURE,
      ActionTypes.GENERATE_W9_FORM_FAILURE,
      ActionTypes.LOAD_COMPANY_ADDITIONAL_FILE_FAILURE,
      ActionTypes.SAVE_COMPANY_ADDITIONAL_FILE_FAILURE,
      ActionTypes.REMOVE_COMPANY_ADDITIONAL_FILE_FAILURE,
      ActionTypes.DEACTIVATE_COMPANY_FAILURE,
      ActionTypes.ACTIVATE_COMPANY_FAILURE,
      ActionTypes.REMOVE_W9_FORM_FAILURE
    ),
    map((action) => {
      console.log('action: ', action);
      this.snackbar.error(action.payload.message);
    })
  );
}
