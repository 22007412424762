import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { CompanyState, ShownCompany } from './state';
import { FileView } from '@interfaces/file-view.interface';
import { UserInvite } from '@interfaces/user-invite.interface';
import { VMOCompany, VMOCompanyView } from '@models/vmo-company.model';

const getIsLoading = (state: CompanyState): boolean => state.isLoading;

const getW9IsLoading = (state: CompanyState): boolean => state.w9IsLoading;

const getCompanyInfo = (state: CompanyState): VMOCompany => state.shownCompany.companyInfo;

const getCompanyInsurance = (state: CompanyState): { CertificateInsurance: FileView } =>
  state.shownCompany.insurance;

const getCompanyAdditionalFiles = (state: CompanyState): FileView[] => state.shownCompany.additionalFiles;

const getW9Form = (state: CompanyState): FileView => state.shownCompany.w9Form;

const getInvitedUsersList = (state: CompanyState): UserInvite[] => state.shownCompany.invitedUsers;

const getShownCompany = (state: CompanyState): ShownCompany => state.shownCompany;

const getCompanies = (state: CompanyState): VMOCompany[] => state.companies;

const getCreateNewUser = (state: CompanyState): boolean => state.createNewUser;

export const selectCompanyState: MemoizedSelector<object, CompanyState> = createFeatureSelector<CompanyState>(
  'vmo-company'
);

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectCompanyState,
  getIsLoading
);

export const selectW9IsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectCompanyState,
  getW9IsLoading
);

export const selectCompanyInfo: MemoizedSelector<object, VMOCompanyView> = createSelector(
  selectCompanyState,
  getCompanyInfo
);

export const selectCompanies: MemoizedSelector<object, VMOCompany[]> = createSelector(
  selectCompanyState,
  getCompanies
);

export const selectCreateNewUser: MemoizedSelector<object, boolean> = createSelector(
  selectCompanyState,
  getCreateNewUser
);

export const selectShownCompany: MemoizedSelector<object, ShownCompany> = createSelector(
  selectCompanyState,
  getShownCompany
);

export const selectCompanyInsurance: MemoizedSelector<
  object,
  { CertificateInsurance: FileView }
> = createSelector(selectCompanyState, getCompanyInsurance);

export const selectCompanyAdditionalFiles: MemoizedSelector<object, FileView[]> = createSelector(
  selectCompanyState,
  getCompanyAdditionalFiles
);

export const selectW9Form: MemoizedSelector<object, FileView> = createSelector(selectCompanyState, getW9Form);

export const selectInvitedUsersList: MemoizedSelector<object, UserInvite[]> = createSelector(selectCompanyState, getInvitedUsersList);
