import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { VMOCompany } from '@models/vmo-company.model';
import { FileField } from '@interfaces/file-field.interface';
import { ActionParams } from '@models/action-params.model';

export class DeactivateCompanyRequestAction implements Action {
  readonly type = ActionTypes.DEACTIVATE_COMPANY_REQUEST;

  constructor(public payload: { model: VMOCompany; type?; message?: string; }) {}
}

export class DeactivateCompanySuccessAction implements Action {
  readonly type = ActionTypes.DEACTIVATE_COMPANY_SUCCESS;
}

export class DeactivateCompanyFailureAction implements Action {
  readonly type = ActionTypes.DEACTIVATE_COMPANY_FAILURE;

  constructor(public payload: Error) {}
}
