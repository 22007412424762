import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { UserInviteStatusEnum } from '@enums/user-invite-status.enum';

export class ConfirmUserInviteRequestAction implements Action {
  readonly type = ActionTypes.CONFIRM_USER_INVITE_REQUEST;

  constructor(public payload: { status: UserInviteStatusEnum, companyName: string }) {}
}

export class ConfirmUserInviteSuccessAction implements Action {
  readonly type = ActionTypes.CONFIRM_USER_INVITE_SUCCESS;
}

export class ConfirmUserInviteFailureAction implements Action {
  readonly type = ActionTypes.CONFIRM_USER_INVITE_FAILURE;

  constructor(public payload: Error) {}
}
