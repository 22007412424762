import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { combineLatest, EMPTY, from, Observable, of } from 'rxjs';
import { catchError, concatMap, map, repeat, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { UserHelperService } from '@services/helpers/user-helper.service';
import { SnackBarService } from '@services/snackbar.service';
import { AmplifyService } from '@services/amplify.service';
import { ApiService } from '@services/api.service';
import { CompanyHelperService } from '@services/helpers/company-helper.service';

import { Email } from '@models/email.model';
import { UserView } from '@models/user.model';
import { GraphQLFilter } from '@models/graphql/graphql-filter.model';
import { UserInvite } from '@interfaces/user-invite.interface';
import { AmplifyAuthError } from '@interfaces/amplify-auth-error.interface';
import { UserRegistrationStatusEnum } from '@enums/user-registration-status.enum';
import { AmplifyErrorCodesEnum } from '@enums/amplify-error-codes.enum';
import { UserInviteStatusEnum } from '@enums/user-invite-status.enum';
import { UserRolesEnum } from '@enums/user-roles.enum';
import { deactivatedAccountMessage, rememberMeLabel } from '@app/constants';
import { getCognitoUser, searchUsers } from '@graphql/user/queries';
import { getCompanyById } from '@graphql/company/queries';
import { searchUserInvites } from '@graphql/user-invites/queries';
import { updateUser } from '@graphql/user/mutations';
import { createUserRequest } from '@graphql/user-request/mutations';
import { resendUserInvite, updateUserInvite } from '@graphql/user-invites/mutations';

import * as AuthActions from './auth-actions';
import { NoOpAction } from './../actions';
import { RootStore } from '../';
import { UserActions, UserSelectors } from '../user-store';
import { ActionTypes } from './action-types.enum';
import { CompanyActions } from '@store/company-store';
import { HelperService } from '@services/helpers/helper.service';
import { EntityStatusEnum } from '@enums/entity-status.enum';
import { AuthService } from '@services/auth/auth.service';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly router: Router,
    private readonly store$: Store<RootStore.AppState>,
    private readonly actions$: Actions,
    private readonly amplifyService: AmplifyService,
    private readonly snackbar: SnackBarService,
    private readonly userHelper: UserHelperService,
    private readonly apiService: ApiService,
    private readonly companyHelper: CompanyHelperService,
    private readonly authService: AuthService
  ) {}

  @Effect()
  loginRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.LoginRequestAction>(ActionTypes.LOGIN_REQUEST),
    switchMap((action) =>
      from(this.amplifyService.singIn(action.payload.model)).pipe(
        map(() => {
          let returnUrl = action.payload.returnUrl || '/';
          if (this.authService.role === UserRolesEnum.Customer) {
            if (!returnUrl.includes('survey-management')) {
              returnUrl = 'survey-management';
            }
          }
          localStorage.removeItem('signOut');
          this.router.navigateByUrl(returnUrl);

          return new NoOpAction();
        }),
        catchError((error: AmplifyAuthError) => {
          let newError: Error = error;
          if (error.message === 'User is disabled.') {
            newError = new Error(deactivatedAccountMessage);
          } else if (error.code === AmplifyErrorCodesEnum.NotAuthorizedException) {
            newError = new Error('Incorrect email or password.');
          }

          return of(new AuthActions.LoginFailureAction(newError));
        })
      )
    )
  );

  @Effect()
  registerRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.RegistrationRequestAction>(ActionTypes.REGISTRATION_REQUEST),
    map(({ payload }) => {
      this.store$.dispatch(
        new UserActions.UpdateUserRequestAction({
          model: payload,
          params: { redirectTo: 'auth/login', message: 'Registration was successful', needLogout: true },
        })
      );
      return new AuthActions.RegistrationSuccessAction();
    }),
    catchError((error) => of(new AuthActions.RegistrationFailureAction(error)))
  );

  @Effect()
  logoutRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.LogoutRequestAction>(ActionTypes.LOGOUT_REQUEST),
    switchMap((action) => from(this.amplifyService.logout()).pipe(map(() => action.payload))),
    map((redirectToLoginPage) => {
      localStorage.setItem(rememberMeLabel, 'false');
      if (!JSON.parse(localStorage.getItem('signOut'))) {
        window.dispatchEvent(new Event('storage'));
        localStorage.setItem('signOut', 'true');
      } else {
        localStorage.setItem('signOut', 'false');
      }
      if (redirectToLoginPage) {
        this.router.navigate(['auth/login']);
      }
      this.store$.dispatch(new UserActions.LoadCurrentUserSuccessAction(null));

      return new AuthActions.LogoutSuccessAction();
    }),
    catchError((error) => of(new AuthActions.LogoutFailureAction(error)))
  );

  @Effect()
  emailFormSubmitEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.EmailFormSubmitRequestAction>(ActionTypes.EMAIL_FORM_SUBMIT_REQUEST),
    switchMap(({ payload }) => {
      const filter = new GraphQLFilter('email', payload);
      return combineLatest([
        from(this.amplifyService.loginAsAdmin()).pipe(concatMap(() => this.userHelper.fetchUser(filter))),
        of(payload),
      ]);
    }),
    switchMap(([user, payload]) => {
      if (!user) {
        this.store$.dispatch(new AuthActions.EmailFormSubmitFailureAction({ message: 'User not found' }));
        return EMPTY;
      }
      if (user.registrationStatus === UserRegistrationStatusEnum.Complete) {
        this.store$.dispatch(new AuthActions.ResetPasswordRequestAction(payload));
        return EMPTY;
      } else {
        // If user in Cognito is Confirmed then allow to reset password & update user & userInvite models
        const userInCognito = getCognitoUser.execute({ cognitoId: user.cognitoId });
        userInCognito.then((res) => {
          if (res?.data?.getCognitoUser.UserStatus === 'CONFIRMED') {
            this.store$.dispatch(new AuthActions.ResetPasswordRequestAction(payload));
            updateUser.execute({
              input: {
                id: user.id,
                status: EntityStatusEnum.Active,
                statusSearch: EntityStatusEnum.Active,
                registrationStatus: UserRegistrationStatusEnum.Complete
              }
            })
            .then(() => searchUserInvites.execute(new GraphQLFilter('email', user.email)))
            .then((userInvites) => {
              const userInvite = userInvites?.data?.searchUsersInvites?.items[0];
              if (
                userInvite?.status === UserInviteStatusEnum.RegistrationPending ||
                userInvite?.status === UserInviteStatusEnum.Expired ||
                userInvite?.status === UserInviteStatusEnum.InviteResent
              ) {
                return updateUserInvite.execute({
                  input: {
                    id: userInvite.id,
                    status: UserInviteStatusEnum.RegistrationComplete,
                    statusSearch: UserInviteStatusEnum.RegistrationComplete
                  }
                });
              }
            });
          }
        });
        return userInCognito;
      }
    }),
    map((response) => {
      if (response.data.getCognitoUser.Username) {
        this.router.navigate(['auth/registration'], {
          queryParams: { username: response.data.getCognitoUser.Username },
        });
        return new NoOpAction();
      } else {
        return new AuthActions.EmailFormSubmitFailureAction({ message: 'User not found' });
      }
    }),
    tap(() => this.store$.dispatch(new AuthActions.LogoutRequestAction(false))),
    catchError((error) => of(new AuthActions.EmailFormSubmitFailureAction(error))),
    repeat()
  );

  @Effect()
  resetPasswordEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.ResetPasswordRequestAction>(ActionTypes.RESET_PASSWORD_REQUEST),
    switchMap((action) =>
      from(this.amplifyService.resetPassword(action.payload)).pipe(
        map(() => {
          this.router.navigateByUrl('auth/new-password', {
            state: { user: { username: action.payload } },
          });
          return new AuthActions.ResetPasswordSuccessAction();
        }),
        catchError((error) => of(new AuthActions.ResetPasswordFailureAction(error)))
      )
    )
  );

  @Effect()
  requestResendSignUpEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.RequestResendSignUpRequestAction>(ActionTypes.REQUEST_RESEND_SIGN_UP_REQUEST),
    switchMap(({ payload }) =>
      this.amplifyService
        .loginAsAdmin()
        .then(() => resendUserInvite.execute({ input: { email: payload.email, requestType: 'requestResendSignUp' } }))
        .then((response) => {
          const success = response?.data?.resendUserInvite?.success || false;
          const message = response?.data?.resendUserInvite?.message || '';
          if (!success && message) {
            throw new Error(message);
          }
          return response;
        })
    ),
    map(() => {
      this.router.navigate(['auth/login']);
      this.snackbar.success('A request for a new invitation link has been sent to the administrator');
      return new AuthActions.RequestResendSignUpSuccessAction();
    }),
    catchError((error) => of(new AuthActions.RequestResendSignUpFailureAction(error))),
    tap(() => this.store$.dispatch(new AuthActions.LogoutRequestAction(false))),
    repeat()
  );

  @Effect()
  resendSignUpEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.ResendSignUpRequestAction>(ActionTypes.RESEND_SIGN_UP_REQUEST),
    switchMap(({ payload }) =>
      searchUsers
        .execute(new GraphQLFilter('email', payload.email))
        .then((res) => [res?.data?.searchUsers?.items[0], payload])
    ),
    switchMap(([user, payload]: [UserView, UserInvite]) => {
      const updateInviteModel = {
        id: payload.id,
        status: UserInviteStatusEnum.InviteResent,
        statusSearch: UserInviteStatusEnum.InviteResent,
        inviteDatetime: HelperService.getDateTimeUTCString(),
      };

      if (user.registrationStatus === UserRegistrationStatusEnum.NotComplete) {
        return this.amplifyService
          .resendSignUp(payload.email)
          .then(() => {
            return Promise.all([
              updateUserInvite.execute({ input: updateInviteModel }),
              updateUser.execute({
                input: { id: user.id, inviteDatetime: HelperService.getDateTimeUTCString() },
              }),
            ]);
          })
          .then(() => ({ ...payload, ...updateInviteModel }));
      } else {
        if (!!payload.teamId) {
          return getCompanyById.execute({ id: payload.teamId })
            .then((res) => {
              const emailTemplate = this.companyHelper.createUserInviteEmailTemplate(res?.data?.getTeam);
              return updateUserInvite
                .execute({ input: updateInviteModel })
                .then(() =>
                  this.apiService.sendEmail(
                    new Email({
                      to: user.email,
                      text: emailTemplate,
                    })
                  )
                )
                .then(() => ({ ...payload, ...updateInviteModel }));
            });
        } else {
          updateInviteModel.status = UserInviteStatusEnum.RegistrationComplete;
          updateInviteModel.statusSearch = UserInviteStatusEnum.RegistrationComplete;
          return updateUserInvite
            .execute({ input: updateInviteModel })
            .then(() => ({ ...payload, ...updateInviteModel }));
        }

      }
    }),
    withLatestFrom(this.store$.select(UserSelectors.selectInvitedUsersList)),
    map(([payload, inviteList]) => {
      const invites = [...inviteList];
      const idx = invites.findIndex(item => item.id === payload.id);

      if (idx > -1) {
        invites.splice(idx, 1, payload);
        this.store$.dispatch(new UserActions.LoadAllInvitesListSuccessAction(invites));
      }

      this.snackbar.success('The invitation has been re-sent successfully');
      this.store$.dispatch(new CompanyActions.LoadCompanyInvitationsRequestAction(payload.teamId));
      return new AuthActions.ResendSignUpSuccessAction();
    }),
    catchError((error) => of(new AuthActions.ResendSignUpFailureAction(error))),
    repeat()
  );

  @Effect()
  sendRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.SendRequestRequestAction>(ActionTypes.SEND_REQUEST_REQUEST),
    switchMap((action) =>
      from(
        this.amplifyService.loginAsAdmin().then(() => createUserRequest.execute({ input: action.payload }))
      ).pipe(catchError((error) => of(new AuthActions.SendRequestFailureAction(error))))
    ),
    map(() => {
      this.snackbar.success('The request has been sent successfully');
      this.router.navigate(['auth/login']);

      return new AuthActions.SendRequestSuccessAction();
    }),
    tap(() => this.store$.dispatch(new AuthActions.LogoutRequestAction()))
  );

  @Effect()
  setNewPasswordEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.SetNewPasswordRequestAction>(ActionTypes.SET_NEW_PASSWORD_REQUEST),
    switchMap((action) =>
      this.amplifyService.loginAsAdmin().then(() => this.amplifyService.confirmForgotPassword(action.payload))
    ),
    map(() => {
      this.router.navigate(['auth/login']);
      this.snackbar.success('New password successfully set');
      return new AuthActions.SetNewPasswordSuccessAction();
    }),
    catchError((error) => of(new AuthActions.SetNewPasswordFailureAction(error))),
    tap(() => this.store$.dispatch(new AuthActions.LogoutRequestAction(false))),
    repeat()
  );

  @Effect()
  setPasswordAsAdminEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.SetPasswordAsAdminRequestAction>(ActionTypes.SET_PASSWORD_AS_ADMIN_REQUEST),
    switchMap(({ payload }) =>
      this.userHelper.setNewUserPassword('', payload.cognitoId, false, payload.password)
        .then()
        .catch((error) => of(new AuthActions.SetPasswordAsAdminFailureAction(error)))
    ),
    map(() => new AuthActions.SetPasswordAsAdminSuccessAction())
  );

  @Effect()
  changeUserPasswordEffect$: Observable<Action> = this.actions$.pipe(
    ofType<AuthActions.ChangePasswordRequestAction>(ActionTypes.CHANGE_PASSWORD_REQUEST),
    switchMap(({ payload }) => this.amplifyService.changePassword(payload.oldPassword, payload.newPassword).then(() => payload)),
    map((payload) => {
      payload?.params?.callback && payload.params.callback();
      return new AuthActions.ChangePasswordSuccessAction();
    }),
    catchError((error) => {
      console.log('error: ', error);
      return of(new AuthActions.ChangePasswordFailureAction(error));
    }),
    repeat()
  );

  @Effect({ dispatch: false })
  authErrorsEffect$: Observable<void> = this.actions$.pipe(
    ofType<Action & { payload: AmplifyAuthError }>(
      ActionTypes.LOGIN_FAILURE,
      ActionTypes.SET_NEW_PASSWORD_FAILURE,
      ActionTypes.RESET_PASSWORD_FAILURE,
      ActionTypes.EMAIL_FORM_SUBMIT_FAILURE,
      ActionTypes.SEND_REQUEST_FAILURE,
      ActionTypes.REQUEST_RESEND_SIGN_UP_FAILURE,
      ActionTypes.RESEND_SIGN_UP_FAILURE,
      ActionTypes.LOGOUT_FAILURE,
      ActionTypes.SET_PASSWORD_AS_ADMIN_FAILURE,
      ActionTypes.CHANGE_PASSWORD_FAILURE,
    ),
    map((action) => this.snackbar.error(action.payload.message))
  );
}
