import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { UserInvite } from '@interfaces/user-invite.interface';

export class DisableUserRequestAction implements Action {
  readonly type = ActionTypes.DISABLE_USER_REQUEST;

  constructor(public payload: UserInvite) {}
}

export class DisableUserSuccessAction implements Action {
  readonly type = ActionTypes.DISABLE_USER_SUCCESS;
}

export class DisableUserFailureAction implements Action {
  readonly type = ActionTypes.DISABLE_USER_FAILURE;

  constructor(public payload: Error) {}
}
