import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { UserView } from '../../core/models/user.model';
import { UserState } from './state';
import { UserInvite } from '@interfaces/user-invite.interface';
import { UserRequest } from '@interfaces/user-request.interface';
import { UserAuthLog } from '@interfaces/user-authlog.interface';

const getIsLoading = (state: UserState): boolean => state.isLoading;

const getActiveUser = (state: UserState): UserView => state.activeUser;

const getCurrentUserView = (state: UserState): UserView => state.currentUserView;

const getUserRole = (state: UserState): string => state.activeUser.role;

const getExistingInvitedUsers = (state: UserState): string[] => state.userInvites.existingUsers;

const getInvitationsSent = (state: UserState): boolean => state.userInvites.invitationsSent;

const getAllUserList = (state: UserState): UserView[] => state.usersPagination.items;

const getInvitedUsersList = (state: UserState): UserInvite[] => state.invitedUsers;

const getInvitesPagination = (state: UserState): { items: UserInvite[], total: number } => state.invitesPagination;

const getInvitesPaginationLoading = (state: UserState): boolean => state.invitesPaginationLoading;

const getUserRequestsList = (state: UserState): { items: UserRequest[], total: number } => state.userRequests;

const getUserRequestsListLoading = (state: UserState): boolean => state.userRequestsLoading;

const getUsersPagination = (state: UserState): { items: UserView[], total: number } => state.usersPagination;

const getUsersPaginationLoading = (state: UserState): boolean => state.usersPaginationLoading;

const getUsersAuthLogPagination = (state: UserState): { items: UserAuthLog[], total: number } => state.userAuthLogPagination;

const getUsersAuthLogPaginationLoading = (state: UserState): boolean => state.userAuthLogPaginationLoading;

export const selectUserState: MemoizedSelector<object, UserState> = createFeatureSelector<UserState>('user-management');

export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(selectUserState, getIsLoading);

export const selectUser: MemoizedSelector<object, UserView> = createSelector(selectUserState, getActiveUser);

export const selectUserRole: MemoizedSelector<object, string> = createSelector(selectUserState, getUserRole);

export const selectExistingInvitedUsers: MemoizedSelector<object, string[]> = createSelector(selectUserState, getExistingInvitedUsers);

export const selectInvitationsSent: MemoizedSelector<object, boolean> = createSelector(selectUserState, getInvitationsSent);

export const selectCurrentUserView: MemoizedSelector<object, UserView> = createSelector(selectUserState, getCurrentUserView);

export const selectAllUsersList: MemoizedSelector<object, UserView[]> = createSelector(selectUserState, getAllUserList);

export const selectInvitedUsersList: MemoizedSelector<object, UserInvite[]> = createSelector(selectUserState, getInvitedUsersList);

export const selectInvitesPagination: MemoizedSelector<object, { items: UserInvite[], total: number }> = createSelector(selectUserState, getInvitesPagination);

export const selectInvitesPaginationLoading: MemoizedSelector<object, boolean> = createSelector(selectUserState, getInvitesPaginationLoading);

export const selectUserRequestsList: MemoizedSelector<object, { items: UserRequest[], total: number }> = createSelector(selectUserState, getUserRequestsList);

export const selectUserRequestsListLoading: MemoizedSelector<object, boolean> = createSelector(selectUserState, getUserRequestsListLoading);

export const selectUsersPagination: MemoizedSelector<object, { items: UserView[], total: number }> = createSelector(selectUserState, getUsersPagination);

export const selectUsersPaginationLoading: MemoizedSelector<object, boolean> = createSelector(selectUserState, getUsersPaginationLoading);

export const selectUserAuthLogPagination: MemoizedSelector<object, { items: UserAuthLog[], total: number }> = createSelector(selectUserState, getUsersAuthLogPagination);

export const selectUserAuthLogPaginationLoading: MemoizedSelector<object, boolean> = createSelector(selectUserState, getUsersAuthLogPaginationLoading);
