import { Injectable } from '@angular/core';

import { FileService } from '@services/file.service';

import { QuestionResponseEnum } from '@enums/survey/question-responses.enum';
import { StorageFileKeyPrefixEnum } from '@enums/storage-file-key-prefix.enum';
import { QuestionAnswerTypeEnum } from '@enums/survey/question-answer-type.enum';
import { AnswerValue, QuestionsAnswer, QuestionsBody, SurveyBody } from '@interfaces/survey-template-json.interface';
import { IDBSurveyObject } from '@interfaces/indexed-db/idb-survey-object.interface';
import { SurveyFillingView } from '@interfaces/survey-filling-view.interface';
import { SiteSurvey } from '@models/survey/site-survey.model';
import { acceptedImagesFormats } from '@app/constants';

@Injectable({ providedIn: 'root' })
export class SurveyHelperService {
  constructor(private readonly fileService: FileService) {}

  public mapSurveyFields(surveys: SiteSurvey[]): SiteSurvey[] {
    return surveys.map((survey) => {
      const createdByUser = `${survey?.createdByID?.firstname || ''} ${survey?.createdByID?.lastname || ''}`.trim();

      return {
        ...survey,
        client: survey.clientID?.name,
        project: survey.projectID?.name,
        case: survey.caseID?.name,
        caseNumber: survey.caseID?.caseNumber,
        createdByUser
      };
    });
  }

  public updateSurveyBody(list: SurveyBody[], answers: IDBSurveyObject[]): SurveyBody[] {
    list.forEach((obj) => {
      const objAnswers = answers.find((item) => item.id === obj.id);

      if (objAnswers?.answers) {
        const questions: QuestionsBody[] = JSON.parse(obj.questionsBody || null) || [];
        obj.questionsBody = this.updateQuestionBody(questions, objAnswers);
        obj.label = objAnswers.label || obj.label;
      }

      if (obj.children?.length) {
        this.updateSurveyBody(obj.children, answers);
      }
    });

    return list;
  }

  public setObjectLabel(list: SurveyBody[], answers: IDBSurveyObject[]): SurveyBody[] {
    list.forEach((obj) => {
      const objAnswers = answers.find((item) => item.id === obj.id);
      obj.label = objAnswers?.label || obj.label;

      if (obj.children?.length) {
        this.updateSurveyBody(obj.children, answers);
      }
    });

    return list;
  }

  public updateObjectBody(list: SurveyBody[], answers: IDBSurveyObject): SurveyBody[] {
    if (!answers?.answers) {
      throw new Error('No changes found');
    }
    const breakException = new Error('Object found');
    const selectedObjectId = answers.id;

    try {
      list.forEach((obj) => {
        if (selectedObjectId === obj.id) {
          const questions: QuestionsBody[] = JSON.parse(obj.questionsBody || null) || [];
          obj.questionsBody = this.updateQuestionBody(questions, answers);
          obj.label = answers.label || obj.label;

          throw breakException;
        }

        if (obj.children?.length) {
          this.updateObjectBody(obj.children, answers);
        }
      });
    } catch (error) {}

    return list;
  }

  public isSurveyValid(data: SurveyBody[], surveyAnswers: IDBSurveyObject[]): {
    invalidIds: string[];
    nodeList: SurveyBody[];
} {
    const invalidIds: string[] = [];
    const findInvalidIds = (list: SurveyBody[]): void => {
      list.forEach((obj) => {
        let isObjectValid = true;
        const answers = surveyAnswers.find((objAnswers) => objAnswers.id === obj.id);
        const questions: QuestionsBody[] = JSON.parse(obj.questionsBody || null) || [];
        const requiredQuestions = questions.filter((question: QuestionsBody) => !question?.optional);

        if (requiredQuestions.length) {
          isObjectValid = !!answers && requiredQuestions.every((question) => !!answers.answers[question.id]?.length);
        }

        if (!isObjectValid) {
          invalidIds.push(obj.id);
          obj.invalid = true;
        }

        if (obj.children?.length) {
          findInvalidIds(obj.children);
        }
      });
    };

    findInvalidIds(data);

    return { invalidIds, nodeList: data };
  }

  public async mapQuestionList(body: SurveyBody[], surveyAnswers: IDBSurveyObject[], surveyId: string): Promise<IDBSurveyObject[]> {
    const result: IDBSurveyObject[] = [];

    const mapper = async (data: SurveyBody[], answers: IDBSurveyObject[], id: string) => {
      await Promise.all(
        data.map(async (obj) => {
          const objAnswers = answers.find((item) => item.id === obj.id);

          if (objAnswers?.answers) {
            const questions: QuestionsBody[] = JSON.parse(obj.questionsBody || null) || [];
            result.push(await this.mapQuestions(questions, objAnswers, id));
          }

          if (obj.children?.length) {
            await mapper(obj.children, answers, id);
          }
        })
      );
    };
    await mapper(body, surveyAnswers, surveyId);

    return result;
  }

  public async mapQuestions(questions: QuestionsBody[], answers: IDBSurveyObject, surveyId: string): Promise<IDBSurveyObject> {
    const result: IDBSurveyObject = {
      id: answers.id,
      surveyKeyPath: answers.surveyKeyPath,
      answers: {},
    };
    const requests = questions.map(async (question) => {
      let answerValue: QuestionsAnswer[] = answers.answers[question.id];

      if (answerValue?.[0]?.type === QuestionAnswerTypeEnum.String) {
        answerValue = JSON.parse(JSON.stringify(answerValue));
        answerValue[0].value = (answerValue[0].value as string).replace(/('|")/g, ' ');
      }
      
      if (!answerValue?.length) {
        result.answers[question.id] = answerValue;
        return;
      }

      if (
        answerValue[0].type === QuestionAnswerTypeEnum.Array ||
        answerValue[0].type === QuestionAnswerTypeEnum.String
      ) {
        result.answers[question.id] = answerValue;
      } else {
        const savedValues = answerValue.filter((item) => !(item.value instanceof File));
        const files: File[] = answerValue.filter((item) => item.value instanceof File).map((item) => item.value as File);
        result.answers[question.id] = [...savedValues, ...(await this.saveFiles(files, surveyId))];
      }
    });

    await Promise.all(requests);

    return result;
  }

  public createViewConfig(questions: QuestionsBody[] = [], answers: IDBSurveyObject): Promise<SurveyFillingView[]> {
    return Promise.all(
      questions.map(async (question) => {
        const answer: QuestionsAnswer[] = answers?.answers?.[question.id] || question.answer;
        const config = {
          title: question.title,
          id: question.id,
          responseType: question.responseType,
          responses: question.responses,
          value: null,
          files: [],
          galleryImages: [],
          optional: question.optional || false
        };

        if (!answer?.length && question.responseType !== QuestionResponseEnum.Instruction) {
          config.value = question.responseType === QuestionResponseEnum.Checkbox ? false : null;
          return config;
        }

        switch (question.responseType) {
          case QuestionResponseEnum.Instruction:
            config.value = new QuestionsAnswer({ type: QuestionAnswerTypeEnum.String, value: null });
            break;

          case QuestionResponseEnum.Text:
          case QuestionResponseEnum.Select:
          case QuestionResponseEnum.GeoLocation:
          case QuestionResponseEnum.MultiSelectCheckbox:
            config.value = answer[0].value;
            break;

          case QuestionResponseEnum.Checkbox:
            config.value = !!answer[0].value;
            break;

          case QuestionResponseEnum.FileUpload:
          case QuestionResponseEnum.FloorPlan:
            config.files = await this.loadFiles(answer);
            config.value = answer;
            break;

          case QuestionResponseEnum.SelectFromGallery:
            config.value = answer;
            config.files = await this.loadFiles(answer);
            config.galleryImages = config.files.map(({ url = '' }) => ({
              small: url,
              medium: url,
              big: url,
            }));

            config.galleryImages = await Promise.all(config.files.map(async (file: AnswerValue | File) => {
              let url: string | ArrayBuffer;
              if (file instanceof File) {
                url = await this.fileService.getPreviewUrl(file);
              } else {
                url = await this.fileService.getFile(file.key);
              }

              return {
                small: url,
                medium: url,
                big: url
              };
            }));
            break;
        }

        return config;
      })
    );
  }

  public createIDBObject(data: { [key: string]: any }): { [key: string]: any } {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const isValid = !!value || typeof value === 'boolean';
      const answer: QuestionsAnswer[] = this.createAnswer(value);

      if (isValid) {
        acc[key] = answer;
      } else {
        acc[key] = null;
      }

      return acc;
    }, {});
  }

  private createAnswer(value): QuestionsAnswer[] {
    const answer: QuestionsAnswer[] = [];
    const isArray: boolean = Array.isArray(value) && !!value?.length;

    if (typeof value === 'string') {
      answer.push({
        type: QuestionAnswerTypeEnum.String,
        value
      });
    } else if (typeof value === 'boolean') {
      answer.push({
        type: QuestionAnswerTypeEnum.Array,
        value: [value]
      });
    } else if (value instanceof QuestionsAnswer) {
      answer.push(value);
    } else if (isArray && value.every((i) => typeof i === 'string')) {
      answer.push({
        type: QuestionAnswerTypeEnum.Array,
        value
      });
    } else if (isArray) {
      const uploadedFiles = value.filter((item) => !(item instanceof File));
      const files = value.filter((item) => item instanceof File).map((file: File) => {
        const type = file.type.includes('image') || this.isImageFile(file.name)
          ? QuestionAnswerTypeEnum.Image
          : QuestionAnswerTypeEnum.File;
        return {
          type,
          value: file
        };
      });

      answer.push(...uploadedFiles, ...files);
    }

    return answer;
  }

  private async loadFiles(answer: QuestionsAnswer[]): Promise<AnswerValue[]> {
    return Promise.all(
      answer.map(async (item) => {
        const value = (item.value || item) as AnswerValue | File;
        if (value instanceof File) {
          return value;
        }

        const url = await this.fileService.getFile(value.key);
        const file: AnswerValue = {
          name: value.name,
          key: value.key,
          url
        };
        return file;
      })
    );
  }

  private async saveFiles(files: File[], id: string): Promise<QuestionsAnswer[]> {
    const requests: Promise<QuestionsAnswer>[] = files.map(async (file) => {
      const type = file.type.includes('image') || this.isImageFile(file.name)
        ? QuestionAnswerTypeEnum.Image
        : QuestionAnswerTypeEnum.File;
      const prefix = `${StorageFileKeyPrefixEnum.SurveyFiles}/${id}`;
      const key = (await this.fileService.saveFile(file, prefix)).key;

      return {
        type,
        value: {
          name: file.name,
          key
        }
      };
    });

    return await Promise.all(requests);
  }

  private updateQuestionBody(questions: QuestionsBody[], objAnswers: IDBSurveyObject): string {
    questions.forEach((question) => (question.answer = objAnswers.answers[question.id] || []));

    return JSON.stringify(questions);
  }

  private isImageFile(filename: string): boolean {
    const imageFormatsArray = acceptedImagesFormats.replace(/\./g, '').split(',');
    const fileFormat = filename.split('.').pop().toLowerCase();
    const res = imageFormatsArray.includes(fileFormat);

    return res;
  }
}
