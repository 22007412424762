import { Injectable } from '@angular/core';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api/lib/types';
import { Observable } from 'zen-observable-ts';
import gql from 'graphql-tag';

import { GraphQLSubscriptionResponse } from '@interfaces/graphql/graphql-subscription-response.interface';

@Injectable({ providedIn: 'root' })
export class GraphQLApiService {
  public static async request<T, K>(query: string, params: T): Promise<GraphQLResult<K>> {
    try {
      const fetchData = await API.graphql(graphqlOperation(gql(query), params));
      console.log('fetchData: ', fetchData);
      return fetchData as GraphQLResult<K>;
    } catch (error) {
      console.log('error: ', error);
      if (Array.isArray(error?.errors) && error?.errors?.length) {
        throw new Error(error.errors[0].message);
      }

      throw new Error(error);
    }
  }

  public static subscription<T>(query: string, name: string): Observable<T> {
    return ((API.graphql(graphqlOperation(gql(query))) as unknown) as Observable<
      GraphQLSubscriptionResponse<T>
    >).map((response) => {
      if (response.value.errors?.length) {
        throw new Error((response.value.errors[0] as Error).message);
      }

      return response.value.data[name];
    });
  }
}
