import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { UserView } from '@models/user.model';
import { GraphQLFilter } from '@models/graphql/graphql-filter.model';

export class LoadUserViewRequestAction implements Action {
  readonly type = ActionTypes.LOAD_USER_VIEW_REQUEST;

  constructor(public payload: string | GraphQLFilter) {}
}

export class LoadUserViewSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_USER_VIEW_SUCCESS;

  constructor(public payload: UserView) {}
}

export class LoadUserViewFailureAction implements Action {
  readonly type = ActionTypes.LOAD_USER_VIEW_FAILURE;

  constructor(public payload: Error) {}
}
