import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { BackEndRole } from '@models/roles/backend-role.model';

export class LoadBackEndRoleListRequestAction implements Action {
  readonly type = ActionTypes.LOAD_BACKEND_ROLE_LIST_REQUEST;
}

export class LoadBackEndRoleListSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_BACKEND_ROLE_LIST_SUCCESS;

  constructor(public payload: BackEndRole[]) {}
}

export class LoadBackEndRoleListFailureAction implements Action {
  readonly type = ActionTypes.LOAD_BACKEND_ROLE_LIST_FAILURE;

  constructor(public payload: Error) {}
}
