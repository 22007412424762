import { Action } from '@ngrx/store';

import { UserCreationRequest } from './../../../core/interfaces/user/user-creation-form-submit.interface';
import { ActionTypes } from '../action-types.enum';

export class CreateUserRequestAction implements Action {
  readonly type = ActionTypes.CREATE_USER_REQUEST;

  constructor(public payload: UserCreationRequest) {}
}

export class CreateUserSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_USER_SUCCESS;
}

export class CreateUserFailureAction implements Action {
  readonly type = ActionTypes.CREATE_USER_FAILURE;

  constructor(public payload: Error) {}
}
