import { EntityCreationStepEnum } from '@enums/entity-creation-step.enum';

// General
export const projectTitle = 'Pivital, Inc';
export const acceptedFiles = '.heic,.jpg,.jpeg,.png,.tif,.bmp,.gif,.pdf,.xls,.xslx,.doc,.docx,.ppt,.pptx';
export const acceptedImagesFormats = '.heic,.jpg,.jpeg,.png,.tif,.bmp,.gif';
export const maxFileSize = 20971520; // 20mb in bytes (binary)
export const organizationId = 'be3ddaf0-0e26-4cf2-9b84-dcc4245e3da4';

// Form validation
export const numberRegExp = new RegExp(/\d/);
export const capitalCaseRegExp = new RegExp(/[A-Z]/);
export const lowerCaseRegExp = new RegExp(/[a-z]/);
export const onlyNumbersRegExp = new RegExp(/^\d+$/);
export const phoneSpecialCharactersRegExp = new RegExp(/^[^a-zA-Z`~!@#$%\^&*_={}|[\]\\:;"<>?,./]*$/);
export const specialCharactersRegExp = new RegExp(/^[^`~!@#$%\^&*()_+={}|[\]\\:;"<>?,./]*$/);
export const emailSpecialCharactersRegExp = new RegExp(/^[^`~!#$%\^&*()={}|[\]\\:;'"<>?,/]*$/);
export const phoneNumberRegExp = new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);
export const urlRegExp = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
export const phoneNumberMinLength = 10;
export const passwordMinLength = 8;
export const passwordMaxLength = 30;
export const confirmationCodeLength = 6;

// SnackBar config
export const horizontalPosition = 'right';
export const verticalPosition = 'top';
export const snackBarDuration = 5000; // 5 second

// MatDialog config
export const matDialogWidth = '600';

// Auth
export const rememberMeLabel = 'RememberMe';
export const routePermissionsDataName = 'permissions';
export const spcAdditionalRole = 'ServicePartnerAdmin, ServicePartnerTechnician';
export const defaultRoleTemplateName = 'Default';
export const deactivatedAccountMessage = 'Call Pivital to reactivate your account';
export const appFilters = 'appFilters';

export const timezones: { label: string; value: string }[] = [
  { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
  { label: '(GMT-08:00) Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
  { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
  { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
  { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' }
];

export const reCaptchaURL = 'https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit';

export const angularMaterialIconPath = '/assets/icons/angular-material-icons';
export const materialIcons = [
  { name: 'add', path: `${angularMaterialIconPath}/add.svg` },
  { name: 'clear', path: `${angularMaterialIconPath}/clear.svg` },
  { name: 'visibility', path: `${angularMaterialIconPath}/visibility.svg` },
  { name: 'visibility_off', path: `${angularMaterialIconPath}/visibility_off.svg` }
];

export const userRoles = [
  { frontRoleName: 'Admin', roleCognito: 'Admin' },
  { frontRoleName: 'Service Partner Admin', roleCognito: 'ServicePartnerAdmin' },
  { frontRoleName: 'Customer', roleCognito: 'Customer' },
  { frontRoleName: 'Pivital Technician', roleCognito: 'OrgTech' },
  { frontRoleName: 'Platform Technician', roleCognito: 'FnTech' },
  { frontRoleName: 'Project Coordinator', roleCognito: 'ProjectCoordinator' },
  { frontRoleName: 'Vendor Manager', roleCognito: 'VendorManager' },
  { frontRoleName: 'Service Partner Technician', roleCognito: 'Technician' }
];

// VMO Company
export const countryForExtendedForm = 'United States';
export const milesToMetersCoefficient = 1609.344;
export const extendedVMOCompanyFormFields = {
  [EntityCreationStepEnum.First]: ['taxType', 'TaxID', 'IsUnion', 'type'],
  [EntityCreationStepEnum.Fourth]: ['file', 'InsuranceRenewalDate'],
  [EntityCreationStepEnum.Fifth]: ['BankName', 'RoutingNumber', 'AccountNumber', 'AccountName', 'signValue', 'signDate']
};
export const W9GeneratorRequiredFields = ['name', 'type', 'TaxID', 'Address1', 'City', 'State', 'ZIPCode'];

export const VMOCompanyTypes = [
  'Individual/sole proprietor or single-member LLC',
  'C Corporation',
  'S Corporation',
  'Partnership',
  'Trust/estate',
  'LLC C',
  'LLC S',
  'LLC P'
];

export const toolsList = [
  'Cable Certifier w Output',
  'Certified Weight Kit',
  'Fiber ODTR',
  'Fiber Termination Kit',
  'Trenching / Boring Equipment',
  'Wifi Survey Software'
];

export const servicesList = [
  'Break / Fix On-site',
  'Demark Extensions',
  'Electrical',
  'Fiber Cabling',
  'ITAD IT Asset Disposal',
  'IT Hardware Deployments',
  'Logistics / Supply Chain',
  'Low-Voltage Cabling',
  'Scale Calibration',
  'Structured Cabling',
  'Trenching / Boring',
  'Wireless Design',
  'Wireless Services'
];

export const DatePickerFormat = {
  parse: {
    dateInput: 'input'
  },
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'll',
    monthYearA11yLabel: 'MMMM YYYY'
  },
  useUtc: false
};

// app table
export const appTable = {
  lazyLoadRows: 100,
  paginationOptions: [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ]
};

// graphQL
export const defaultQueryParams = { from: 0, limit: 10 };
export const defaultSubscriptionDuration = 300000; // 5 minutes

// agreements keys
export const agreementCompanyNameKey = 'companyNameKey';
export const agreementCompanyAddressKey = 'companyAddressKey';
