import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { SnackBarService } from '@services/snackbar.service';

import { RootStore } from '@store/index';
import { UserSelectors } from '@store/user-store';
import * as NotificationActions from './notification-actions';
import * as NotificationSelectors from './selectors';

import { getBellNotificationsList } from '@graphql/notification/queries';

import { ActionTypes } from './action-types.enum';
import { BellNotification } from '@models/bell-notification.model';
import { updateProfileNotification } from '@graphql/notification/mutations';

@Injectable()
export class NotificationEffects {
  constructor(
    private readonly store$: Store<RootStore.AppState>,
    private readonly actions$: Actions,
    private readonly snackbar: SnackBarService
  ) {}

  @Effect()
  loadBellNotificationsRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType<NotificationActions.LoadBellNotificationsRequestAction>(ActionTypes.LOAD_BELL_NOTIFICATIONS_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([_, user]) => {
      const filter = {
        targetUserId: { eq: user.id },
        isViewed: { eq: false }
      };

      return getBellNotificationsList.execute({ filter, sort: { field: 'updatedDate', direction: 'desc' } }).then(
        (res) => res?.data?.searchProfileNotifications?.items
      );
    }),
    map((notifications) => new NotificationActions.LoadBellNotificationsSuccessAction(notifications)),
    catchError((error) => of(new NotificationActions.LoadBellNotificationsFailureAction(error)))
  );

  @Effect()
  notificationsPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<NotificationActions.NotificationsPaginationRequestAction>(ActionTypes.NOTIFICATION_PAGINATION_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      let filter = {
        targetUserId: { eq: user.id },
        isActive: { eq: true }
      };
      if (payload.filter) {
        filter = {...filter, ...payload.filter};
      }

      return combineLatest([
        getBellNotificationsList.execute({ ...payload, filter, sort: { field: 'createdDate', direction: 'desc' } })
          .then((res) => res?.data?.searchProfileNotifications?.items.map(
            (notification) => {
              notification.createdDate = notification.createdDate * 1000;
              return notification;
            }
          )),
        payload.reset ? of([] as BellNotification[]) : this.store$.select(NotificationSelectors.selectNotifications).pipe(take(1))
      ]);
    }),
    map(([response, notifications]) => new NotificationActions.NotificationsPaginationSuccessAction([...notifications, ...response])),
    catchError((error) => of(new NotificationActions.NotificationsPaginationFailureAction(error)))
  );

  @Effect()
  notificationUpdateEffect$: Observable<Action> = this.actions$.pipe(
    ofType<NotificationActions.NotificationsUpdateRequestAction>(ActionTypes.NOTIFICATION_UPDATE_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      return combineLatest([
        Promise.all(
          payload.updateData.map((notification) => {
            return updateProfileNotification.execute({ input: { ...notification, updatedBy: user.id }});
          })
        ).then(() => payload),
        this.store$.select(NotificationSelectors.selectNotifications).pipe(take(1))
      ]);
    }),
    map(([payload, notifications]) => {
      const notificationList: BellNotification[] = JSON.parse(JSON.stringify(notifications));

      payload.updateData.forEach((updatedNotification) => {
        const targetNotificationIndex = notificationList.findIndex((notification) => notification.id === updatedNotification.id);
        if (targetNotificationIndex >= 0) {
          notificationList[targetNotificationIndex] = Object.assign(notificationList[targetNotificationIndex], updatedNotification);
        }
      });
      payload.params?.callback?.();

      return new NotificationActions.NotificationsUpdateSuccessAction(notificationList);
    }),
    catchError((error) => of(new NotificationActions.NotificationsUpdateFailureAction(error)))
  );

  @Effect({ dispatch: false })
  notificationErrorsEffect$: Observable<void> = this.actions$.pipe(
    ofType<Action & { payload: Error }>(
      ActionTypes.LOAD_BELL_NOTIFICATIONS_FAILURE
    ),
    map((action) => {
      console.log('action: ', action);
      this.snackbar.error(action.payload.message);
    })
  );
}
