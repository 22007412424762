<h2 mat-dialog-title class="text-center">Reactivate company</h2>

<table *ngIf="dataSource?.data?.length; else noValue" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
  </ng-container>

  <!-- Role Column -->
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef>Role</th>
    <td mat-cell *matCellDef="let element">{{ element.role }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
</table>

<ng-template #noValue>
  <div class="no-value">User list is empty</div>
</ng-template>

<mat-dialog-actions align="end" class="pt-3 pb-3">
  <button mat-raised-button class="cancel-btn" (click)="close()">Cancel</button>
  <button mat-raised-button class="save-btn" (click)="save()">Reactivate</button>
</mat-dialog-actions>
