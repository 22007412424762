import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { CaseTimeLog } from '@models/case/case-timelog.model';

class CreateCaseTimeLog extends GraphQLMutation<CaseTimeLog, { createTimeLog: Partial<CaseTimeLog> }> {
  protected query = `mutation CreateTimeLog(
    $input: CreateTimeLogInput!
    $condition: ModelTimeLogConditionInput
  ) {
    createTimeLog(input: $input, condition: $condition) {
      id
    }
  }
  `;
}

class UpdateCaseTimeLog extends GraphQLMutation<Required<Partial<CaseTimeLog>, 'id'>, { updateCaseTimeLog: Partial<CaseTimeLog> }> {
  protected query = `mutation UpdateTimeLog(
    $input: UpdateTimeLogInput!
    $condition: ModelTimeLogConditionInput
  ) {
    updateTimeLog(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const createCaseTimeLog = new CreateCaseTimeLog();
export const updateCaseTimeLog = new UpdateCaseTimeLog();
