import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SurveyTemplate } from '@models/survey/survey-template.model';
import { ActionParams } from '@models/action-params.model';

export class DeleteSurveyTemplateRequestAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_TEMPLATE_REQUEST;

  constructor(public payload: { data: SurveyTemplate, params?: ActionParams }) {}
}

export class DeleteSurveyTemplateSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_TEMPLATE_SUCCESS;

  constructor(public payload: SurveyTemplate[]) {}
}

export class DeleteSurveyTemplateFailureAction implements Action {
  readonly type = ActionTypes.DELETE_SURVEY_TEMPLATE_FAILURE;

  constructor(public payload: Error) {}
}
