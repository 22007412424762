import { Injectable } from '@angular/core';

import { FileService } from '@services/file.service';
import { SnackBarService } from '@services/snackbar.service';

import { UserView } from '@models/user.model';
import { VMOCompany, VMOCompanyView } from '@models/vmo-company.model';
import { EmailTemplate } from '@models/email-template.model';
import { FileView } from '@interfaces/file-view.interface';
import { UserRolesEnum } from '@enums/user-roles.enum';
import { StorageFileKeyPrefixEnum } from '@enums/storage-file-key-prefix.enum';
import { W9GeneratorRequiredFields } from '@app/constants';
import { createTeamFile, updateTeamFile } from '@graphql/company-files/mutations';
import { SalesForceService } from '@services/sales-force.service';
import { SalesForceActionsEnum } from '@enums/sales-force-actions.enum';
import { updateCompany } from '@graphql/company/mutations';

@Injectable({ providedIn: 'root' })
export class CompanyHelperService {
  constructor(private readonly fileService: FileService,
              private readonly salesForceService: SalesForceService,
              private readonly snackbar: SnackBarService) {}

  public checkInvitedUserRole(user: UserView): void {
    const userRoles = user?.role?.split(',');

    if (
      userRoles &&
      !userRoles.includes(UserRolesEnum.PlatformTechnician) &&
      !userRoles.includes(UserRolesEnum.ServicePartnerTechnician) &&
      !userRoles.includes(UserRolesEnum.ServicePartnerAdmin)
    ) {
      throw new Error('The user does not have the required role for this action');
    }
  }

  public createUserInviteEmailTemplate(company: Partial<VMOCompany>): string {
    return EmailTemplate.createEmailTemplate(
      `You have been invited to the ${company.name} partner. Follow the link for confirmation`,
      `${location.origin}/user-management/confirm-invite?name=${company.name}`,
      'Confirm invitation'
    );
  }

  public restorePasswordEmailTemplate(): string {
    return EmailTemplate.createEmailTemplate(
      `Your invitation link has expired. Follow the link to reset password:`,
      `${location.origin}/auth/reset-password`,
      'Reset password'
    );
  }

  public async createNewCompanySF(companyDTO: VMOCompany, compnanyId, userId): Promise<any> {
    const updatedCompany = { ...companyDTO };
    const sfUpdateModel = {
      Name: updatedCompany.name,
      Country__c: updatedCompany.Country,
      post_address_1__c: updatedCompany.Address1,
      post_address_2__c: updatedCompany.Address2,
      post_address_city__c: updatedCompany.City,
      post_address_state__c: updatedCompany.State,
      post_address_zip__c: updatedCompany.ZIPCode,
      Phone: updatedCompany.Phone,
      Website: updatedCompany.Website,
      Primary_Contact_Email__c: updatedCompany.MainEmail,
      Primary_Contact__c: updatedCompany.AccountsPayableContactFirstName + ' ' + updatedCompany.AccountsPayableContactLastName,
      Primary_Contact_Phone__c: updatedCompany.AccountsPayableContactPhone,
      AP_Contact__c: updatedCompany.AccountsPayableContactPhone,
      AP_Email__c: updatedCompany.AccountsPayableContactEmail,
      post_companyInsurance__c: updatedCompany.ProvideInsurance,
      Insurance_Exp_Date__c: updatedCompany.InsuranceRenewalDate,
      VMO_Status__c: 'active',
      Type: 'Partner',
      POST_ID__c: compnanyId,
      ...(updatedCompany.SWIFTCode) && { post_payment_swift_code__c: updatedCompany.SWIFTCode },
      ...(updatedCompany.IBANNumber) && { post_iban_number__c: updatedCompany.IBANNumber },
      ...(updatedCompany.SortCode) && { post_sort_code__c: updatedCompany.SortCode },
      ...(updatedCompany.TaxID) && { postTaxIdentifier__c: updatedCompany.TaxID },
      ...(updatedCompany.PaymentMethod) && { post_payment_method__c: updatedCompany.PaymentMethod },
      ...(updatedCompany.RoutingNumber) && { post_routing_number__c: updatedCompany.RoutingNumber },
      ...(updatedCompany.AccountName) && { post_payment_account_name__c: updatedCompany.AccountName },
      ...(updatedCompany.AccountNumber) && { post_bank_account_number__c: updatedCompany.AccountNumber }
    };
    return this.salesForceService.postSFInfo(SalesForceActionsEnum.servicePartnerCompany, sfUpdateModel).then((res) => {
      const model = { id: compnanyId, salesforceId: res.id };
      updateCompany.execute({ input: { ...model, updatedBy: userId } });
      return res.id;
    });
  }

  public updateNewCompanySF(updatedCompany: Partial<VMOCompany>): void {
    const sfUpdateModel = {
      ...(updatedCompany.name) && { Name: updatedCompany.name },
      ...(updatedCompany.Country) && { Country__c: updatedCompany.Country },
      ...(updatedCompany.Address1) && { post_address_1__c: updatedCompany.Address1 },
      ...(updatedCompany.Address2) && { post_address_2__c: updatedCompany.Address2 },
      ...(updatedCompany.City) && { post_address_city__c: updatedCompany.City },
      ...(updatedCompany.State) && { post_address_state__c: updatedCompany.State },
      ...(updatedCompany.ZIPCode) && { post_address_zip__c: updatedCompany.ZIPCode },
      ...(updatedCompany.Phone) && { Phone: updatedCompany.Phone },
      ...(updatedCompany.Website) && { Website: updatedCompany.Website },
      ...(updatedCompany.MainEmail) && { Primary_Contact_Email__c: updatedCompany.MainEmail },
      ...(updatedCompany.AccountsPayableContactFirstName) && { Primary_Contact__c: updatedCompany.AccountsPayableContactFirstName + ' ' + updatedCompany.AccountsPayableContactLastName },
      ...(updatedCompany.AccountsPayableContactPhone) && { Primary_Contact_Phone__c: updatedCompany.AccountsPayableContactPhone },
      ...(updatedCompany.AccountsPayableContactPhone) && { AP_Contact__c: updatedCompany.AccountsPayableContactPhone },
      ...(updatedCompany.AccountsPayableContactEmail) && { AP_Email__c: updatedCompany.AccountsPayableContactEmail },
      ...(updatedCompany.InsuranceRenewalDate) && { Insurance_Exp_Date__c: updatedCompany.InsuranceRenewalDate },
      ...(updatedCompany.SWIFTCode) && { post_payment_swift_code__c: updatedCompany.SWIFTCode },
      ...(updatedCompany.IBANNumber) && { post_iban_number__c: updatedCompany.IBANNumber },
      ...(updatedCompany.SortCode) && { post_sort_code__c: updatedCompany.SortCode },
      ...(updatedCompany.TaxID) && { postTaxIdentifier__c: updatedCompany.TaxID },
      ...(updatedCompany.PaymentMethod) && { post_payment_method__c: updatedCompany.PaymentMethod },
      ...(updatedCompany.RoutingNumber) && { post_routing_number__c: updatedCompany.RoutingNumber },
      ...(updatedCompany.AccountName) && { post_payment_account_name__c: updatedCompany.AccountName },
      ...(updatedCompany.AccountNumber) && { post_bank_account_number__c: updatedCompany.AccountNumber },
      ...(updatedCompany.BankName) && { post_bank_name__c: updatedCompany.BankName },
      ...(updatedCompany.BankAddress) && { post_bank_address__c: updatedCompany.BankAddress }

    };
    this.salesForceService.patchSFInfo(SalesForceActionsEnum.servicePartnerCompany, updatedCompany.salesforceId, sfUpdateModel);
    // payload.files.map((file) => {
    //     this.salesForceService.uploadSFAttachment(salesforceID, file.CertificateInsurance.name, file.CertificateInsurance);
    //   }
    // );
  }

  public async saveFile(file: File, id: string, salesforceId: string): Promise<Partial<FileView>> {
    return this.fileService
      .save(file, StorageFileKeyPrefixEnum.CompanyAdditionalFiles, id)
      .then((s3Object) =>
        createTeamFile.execute({
          input: { image: s3Object, userId: id, teamAdditionalFilesUserIDId: id },
        })
      )
      .then((result) => {
        this.salesForceService.uploadSFAttachment(salesforceId, file.name, file).then((response) => {
          updateTeamFile.execute({
            input: { id: result?.data?.createTeamAdditionalFiles?.id, salesforceId: response},
          });
        });
        const resultFileInfo = {
          key: result?.data?.createTeamAdditionalFiles?.image?.key,
          name: result?.data?.createTeamAdditionalFiles?.image?.name,
          id: result?.data?.createTeamAdditionalFiles?.id,
        };
        return resultFileInfo;
      });
  }

  public isW9FieldsValid(data: VMOCompany): boolean {
    const isInvalid = Object.entries(data)
      .filter(([key]) => W9GeneratorRequiredFields.includes(key))
      .some(([_, value]) => !value);

    if (isInvalid) {
      this.snackbar.error(
        'You need to fill in fields Company Name, Company Address, Company Type and Tax ID to have the ability to Generate the W9 form'
      );
    }

    return !isInvalid;
  }

  public getFormattedTaxId(companyData: VMOCompanyView): string {
    if (companyData?.taxType && companyData?.TaxID) {
      const taxId = String(companyData.TaxID).split('');

      if (companyData.taxType === 'employerId') {
        taxId.splice(2, 0, '-');
      } else if (companyData.taxType === 'socialId') {
        taxId.splice(5, 0, '-');
        taxId.splice(3, 0, '-');
      }

      return taxId.join('');
    } else {
      return '';
    }
  }
}
