import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { UserView } from './../../../core/models/user.model';

export class LoadUserFilesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_USER_FILES_REQUEST;

  constructor(public payload: UserView) {}
}

export class LoadUserFilesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_USER_FILES_SUCCESS;

  constructor(public payload: UserView) {}
}

export class LoadUserFilesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_USER_FILES_FAILURE;

  constructor(public payload: Error) {}
}
