import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { SurveyObject } from '@models/survey/survey-object.model';

export class LoadObjectBankRequestAction implements Action {
  readonly type = ActionTypes.LOAD_OBJECT_BANK_REQUEST;
}

export class LoadObjectBankSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_OBJECT_BANK_SUCCESS;

  constructor(public payload: SurveyObject[]) {}
}

export class LoadObjectBankFailureAction implements Action {
  readonly type = ActionTypes.LOAD_OBJECT_BANK_FAILURE;

  constructor(public payload: Error) {}
}
