import { GraphQLResult } from '@aws-amplify/api/lib/types';
import { Injectable } from '@angular/core';
import { EMPTY, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SnackBarService } from '@services/snackbar.service';
import { FileService } from '@services/file.service';
import { ApiService } from '@services/api.service';
import { SalesForceService } from '@services/sales-force.service';

import { Email } from '@models/email.model';
import { User, UserView } from '@models/user.model';
import { EmailTemplate } from '@models/email-template.model';
import { GraphQLFilter } from '@models/graphql/graphql-filter.model';
import { FileView } from '@interfaces/file-view.interface';
import { UserInvite } from '@interfaces/user-invite.interface';
import { UserRequest } from '@interfaces/user-request.interface';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { UserInviteStatusEnum } from '@enums/user-invite-status.enum';
import { EntityStatusEnum } from '@enums/entity-status.enum';
import { StorageFileKeyPrefixEnum } from '@enums/storage-file-key-prefix.enum';
import { SalesForceActionsEnum } from '@enums/sales-force-actions.enum';

import { getUserById, searchUsers } from '@graphql/user/queries';
import { getUserFilesList } from '@graphql/user-files/queries';
import { updateUserInvite } from '@graphql/user-invites/mutations';
import { cognitoDisableUser, cognitoEnableUser, cognitoSetPassword, updateUser } from '@graphql/user/mutations';
import { getCompanyList } from '@graphql/company/queries';
import { createUserFile } from '@graphql/user-files/mutations';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserHelperService {
  constructor(
    private readonly fileService: FileService,
    private readonly salesForceService: SalesForceService,
    private readonly snackbar: SnackBarService,
    private readonly apiService: ApiService,
  ) {}

  public fetchUser(filter: GraphQLQueryRequest<User> | string): Observable<UserView> {
    let request: Promise<UserView>;

    if (typeof filter === 'string') {
      request = getUserById.execute({ id: filter }).then((res) => res?.data?.getUser);
    } else {
      request = searchUsers
        .execute(filter)
        .then((res) => res?.data?.searchUsers?.items?.[0]);
    }

    return from(request).pipe(
      map((user) => {
        if (user) {
          const commentItems = user.comments.items || [];

          commentItems.forEach((comment) => {
            comment.commentBody = comment.commentBody.replace(/\<br\>/g, '\n').replace(/<[^>]*>/g, '');
          });
          return {
            ...user,
            companyName: user.teamID?.name,
            comments: { items: commentItems }
          };
        } else {
          throw new Error('User not found');
        }
      })
    );
  }

  public async fetchUserAdditionalFiles(user: UserView): Promise<FileView[]> {
    const files: FileView[] = [];

    if (user.additionalFiles?.items?.length) {
      const result = await getUserFilesList.execute(new GraphQLFilter('userId', user.id));
      const additionalFiles = result?.data?.listUserAdditionalFiless?.items?.map((item) => ({
        ...item.image,
        id: item.id
      }));

      for (const file of additionalFiles) {
        const fileView = await this.fileService.createFileView(file);
        files.push(fileView);
      }
    }

    return files;
  }

  public async fetchUserChecks(file): Promise<FileView> {
    const fileView = await this.fileService.createFileView(file);

    return fileView;
  }

  public updateUserInvite(inviteModel: UserInvite, status: UserInviteStatusEnum): Promise<GraphQLResult<{
    updateUsersInvite: Partial<UserInvite>;
  }>> | Observable<never> {
    if (!inviteModel) {
      this.snackbar.error('Invitation not found');
      return EMPTY;
    }

    return updateUserInvite.execute({
      input: {
        id: inviteModel.id,
        status,
        statusSearch: status
      }
    });
  }

  public disableUser(user: UserInvite): Promise<Partial<UserInvite>> {
    return cognitoDisableUser.execute({ input: { cognitoUserId: user.userID.cognitoId } })
      .then(() => updateUser.execute({ input: { id: user.userId, status: EntityStatusEnum.Disabled, statusSearch: EntityStatusEnum.Disabled }}))
      .then(() => updateUserInvite.execute({ input: { id: user.id, status: UserInviteStatusEnum.Disabled, statusSearch: UserInviteStatusEnum.Disabled } }))
      .then(() => user);
  }

  public enableUser(user: UserInvite): Promise<Partial<UserInvite>> {
    return cognitoEnableUser.execute({ input: { cognitoUserId: user.userID.cognitoId } })
      .then(() => updateUser.execute({ input: { id: user.userId, status: EntityStatusEnum.Reactivated, statusSearch: EntityStatusEnum.Reactivated  }}))
      .then(() => updateUserInvite.execute({ input: { id: user.id, status: UserInviteStatusEnum.Reactivated, statusSearch: UserInviteStatusEnum.Reactivated } }))
      .then(() => user);
  }

  public setNewUserPassword(email: string, cognitoUserId: string, sendMail = false, password?: string): Promise<{ message: string }> {
    const newPassword = Math.random().toString(36).slice(2);
    const emailTemplate = EmailTemplate.createEmailTemplate(
      `Your account has been reactivated. You can log in with new password <span style="background-color: #e3e3e3;display:inline-block; padding:1px 4px;">${newPassword}</span>`
    );

    return cognitoSetPassword.execute({ input: { cognitoUserId, password: password || newPassword } })
      .then(() => sendMail ? this.apiService.sendEmail(new Email({ to: email, text: emailTemplate })) : { message: 'success' });
  }

  public updateInviteStatuses(invites: UserInvite[]): UserInvite[] {
    invites.forEach((invite) => {
      invite = { ...invite };
      if (!invite.hasOwnProperty('username')) {
        invite.username = `${invite.firstName || ''} ${invite.lastName || ''}`.trim();
      }
      if (!invite.hasOwnProperty('companyName')) {
        invite.companyName = invite.teamID?.name;
      }

      if (
        invite.status !== UserInviteStatusEnum.RegistrationPending &&
        invite.status !== UserInviteStatusEnum.InviteResent
      ) {
        return;
      }
      const expireAt = +new Date(invite.inviteDatetime) + environment.linkExpirationTime;
      if (expireAt < +new Date()) {
        invite.status = UserInviteStatusEnum.Expired;
        invite.statusSearch = UserInviteStatusEnum.Expired;
        updateUserInvite.execute({
          input: { id: invite.id, status: UserInviteStatusEnum.Expired, statusSearch: UserInviteStatusEnum.Expired }
        });
      }
    });

    return invites;
  }

  public mapRequestFields(requests: UserRequest[]): UserRequest[] {
    return requests.map((req) => {
      let createdDateInMilliseconds;

      if (req.createdDate && String(req.createdDate).length === 10) {
        createdDateInMilliseconds = req.createdDate * 1000;
      }

      return {
        ...req,
        createdDate: createdDateInMilliseconds || req.createdDate,
        username: `${req.firstName} ${req.lastName}`,
        rejectedByName: req.rejectedBy ? `${req.rejectedBy.firstname} ${req.rejectedBy.lastname}` : null,
        approvedByName: req.approvedBy ? `${req.approvedBy.firstname} ${req.approvedBy.lastname}` : null
      };
    });
  }

  public associateUserSF(user, teamId) {
    getCompanyList.execute(new GraphQLFilter('id', teamId)).then((response) => {
      const userInvitedCompany = response.data.listTeams.items;
      if (userInvitedCompany) {
        const sfUpdateModel = {
          AccountId: userInvitedCompany[0].salesforceId
        };
        this.salesForceService.patchSFInfo(SalesForceActionsEnum.userProfile, user.salesforceId, sfUpdateModel);
      }
    });
  }

  public async saveFile(file: File, id: string, salesforceId: string): Promise<Partial<FileView>> {
    return this.fileService
      .save(file, StorageFileKeyPrefixEnum.UserFiles, id)
      .then((s3Object) =>
        createUserFile.execute({
          input: { image: s3Object, userId: id, userAdditionalFilesUserIDId: id }
        })
      )
      .then((result) => {
        const resultFileInfo = {
          key: result?.data?.createUserAdditionalFiles?.image?.key,
          name: result?.data?.createUserAdditionalFiles?.image?.name,
          id: result?.data?.createUserAdditionalFiles?.id
        };

        return resultFileInfo;
      });
  }
}
