import { Action } from '@ngrx/store';
import { ActionTypes } from '../action-types.enum';
import { UserRequest } from '@interfaces/user-request.interface';
import { ActionParams } from '@models/action-params.model';

export class UpdateUserRequestSingleRequestAction  implements Action {
  readonly type = ActionTypes.UPDATE_USER_REQUEST_REQUEST;

  constructor(public payload: { model: UserRequest, status?: string, params?: ActionParams }) {}
}

export class UpdateUserRequestSingleSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_USER_REQUEST_SUCCESS;
}

export class UpdateUserRequestSingleFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_USER_REQUEST_FAILURE;

  constructor(public payload: Error) {}
}
