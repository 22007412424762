import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { IDBSurveyObject } from '@interfaces/indexed-db/idb-survey-object.interface';

export class LoadSurveyDataFromIndexedDBRequestAction implements Action {
  readonly type = ActionTypes.LOAD_SURVEY_DATA_FROM_INDEXED_DB_REQUEST;

  constructor(public payload?: string) {}
}

export class LoadSurveyDataFromIndexedDBSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SURVEY_DATA_FROM_INDEXED_DB_SUCCESS;

  constructor(public payload: IDBSurveyObject[]) {}
}

export class LoadSurveyDataFromIndexedDBFailureAction implements Action {
  readonly type = ActionTypes.LOAD_SURVEY_DATA_FROM_INDEXED_DB_FAILURE;

  constructor(public payload: Error) {}
}
