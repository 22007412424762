import { Actions } from './actions';
import { initialState, AuthState } from './state';
import { ActionTypes } from './action-types.enum';

export function authReducer(state = initialState, action: Actions): AuthState {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
      };

    case ActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };

    case ActionTypes.LOGIN_REQUEST:
    case ActionTypes.EMAIL_FORM_SUBMIT_REQUEST:
    case ActionTypes.SET_NEW_PASSWORD_REQUEST:
    case ActionTypes.RESET_PASSWORD_REQUEST:
    case ActionTypes.REQUEST_RESEND_SIGN_UP_REQUEST:
    case ActionTypes.RESEND_SIGN_UP_REQUEST:
    case ActionTypes.SEND_REQUEST_REQUEST:
    case ActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.LOGIN_FAILURE:
    case ActionTypes.EMAIL_FORM_SUBMIT_FAILURE:
    case ActionTypes.SET_NEW_PASSWORD_SUCCESS:
    case ActionTypes.SET_NEW_PASSWORD_FAILURE:
    case ActionTypes.RESET_PASSWORD_SUCCESS:
    case ActionTypes.RESET_PASSWORD_FAILURE:
    case ActionTypes.REQUEST_RESEND_SIGN_UP_SUCCESS:
    case ActionTypes.REQUEST_RESEND_SIGN_UP_FAILURE:
    case ActionTypes.RESEND_SIGN_UP_SUCCESS:
    case ActionTypes.RESEND_SIGN_UP_FAILURE:
    case ActionTypes.SEND_REQUEST_SUCCESS:
    case ActionTypes.SEND_REQUEST_FAILURE:
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
    case ActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default: {
      return state;
    }
  }
}
