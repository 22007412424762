import { Required } from 'utility-types';

import { GraphQLMutation } from '@models/graphql/graphql-mutation.model';
import { Task } from '@models/case/task.model';

class UpdateTask extends GraphQLMutation<Required<Partial<Task>, 'id'>, { updateTask: Partial<Task> }> {
  protected query = `mutation updateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
    }
  }`;
}

export const updateTask = new UpdateTask();

