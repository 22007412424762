import { GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';
import { CaseEvent } from '@models/case/case-event.model';

class GetCaseEventsList extends GraphQLQueryList<GraphQLQueryRequest<CaseEvent>, { searchCaseEvents: { items: CaseEvent[] } }> {
  protected query = `query ListCaseEvents(
    $filter: SearchableCaseEventFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableCaseEventSortInput
  ) {
    searchCaseEvents(filter: $filter, from: $from, limit: $limit, nextToken: $nextToken, sort: $sort) {
      items {
        id
        body
        caseId
        eventDate
        eventTime
        eventType
        createdAt
        updatedAt
        createdDate
        updatedDate
        toUserId
        byUserID {
          firstname
          lastname
        }
      }
      nextToken
    }
  }`;
}

export const getCaseEventsList = new GetCaseEventsList();
