import { GraphQLQueryById, GraphQLQueryList } from '@models/graphql/graphql-query.model';
import { TeamAdditionalFile } from '@models/additional-file.model';
import { GraphQLQueryRequest } from '@interfaces/graphql/graphql-query-request.interface';

class GetTeamFileById extends GraphQLQueryById<{ id: string }, { getTeamAdditionalFiles: TeamAdditionalFile }> {
  protected query = `query GetTeamAdditionalFiles($id: ID!) {
    getTeamAdditionalFiles(id: $id) {
      createdAt
      salesforceId
      id
      image {
        region
        name
        key
        bucket
      }
      updatedAt
      userId
    }
  }`;
}

class GetTeamFilesList extends GraphQLQueryList<GraphQLQueryRequest<TeamAdditionalFile>, { listTeamAdditionalFiless: { items: TeamAdditionalFile[] } }> {
  protected query = `query ListTeamAdditionalFiless(
    $filter: ModelTeamAdditionalFilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamAdditionalFiless(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        salesforceId
        id
        image {
          region
          name
          key
          bucket
        }
        updatedAt
        userId
      }
      nextToken
    }
  }`;
}

export const getTeamFileById = new GetTeamFileById();
export const getTeamFilesList = new GetTeamFilesList();
