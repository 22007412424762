import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SnackBarService } from '@services/snackbar.service';
import { ActionTypes } from './action-types.enum';
import * as RootActions from './actions';

@Injectable()
export class RootEffects {
  constructor(private readonly actions$: Actions, private readonly snackbar: SnackBarService) {}

  @Effect({ dispatch: false })
  rootErrorEffect$: Observable<void> = this.actions$.pipe(
    ofType<RootActions.EffectErrorAction>(ActionTypes.EFFECT_ERROR),
    map((action) => {
      console.log('action: ', action);
      this.snackbar.error(action.payload.message);
    })
  );
}
