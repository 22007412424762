import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { AmplifyAuthError } from './../../../core/interfaces/amplify-auth-error.interface';
import { SetNewPassword } from './../../../core/interfaces/set-new-password.interface';

export class SetNewPasswordRequestAction implements Action {
  readonly type = ActionTypes.SET_NEW_PASSWORD_REQUEST;

  constructor(public payload: SetNewPassword) {}
}

export class SetNewPasswordSuccessAction implements Action {
  readonly type = ActionTypes.SET_NEW_PASSWORD_SUCCESS;
}

export class SetNewPasswordFailureAction implements Action {
  readonly type = ActionTypes.SET_NEW_PASSWORD_FAILURE;

  constructor(public payload: AmplifyAuthError) {}
}
