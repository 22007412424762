import { Injectable } from '@angular/core';
import { base64ToArrayBuffer, fileToBase64, imageProcessor, rotate } from 'ts-image-processor';

import { AmplifyStorageService } from './amplify-storage.service';
import { S3FileObject } from '../models/s3-file-object.model';
import { FileField } from '../interfaces/file-field.interface';
import { StorageFileKeyPrefixEnum } from '../enums/storage-file-key-prefix.enum';
import { FileView } from '../interfaces/file-view.interface';

@Injectable({ providedIn: 'root' })
export class FileService extends AmplifyStorageService {

  public async save(data: File, filePrefix: StorageFileKeyPrefixEnum | string, entityId?: string): Promise<S3FileObject>;
  public async save(data: FileField<File>, filePrefix: StorageFileKeyPrefixEnum, entityId: string): Promise<FileField<S3FileObject>>;
  public async save(data: FileField<File>[], filePrefix: StorageFileKeyPrefixEnum, entityId: string ): Promise<FileField<S3FileObject>[]>;
  public async save(data: FileField<File>[] | FileField<File> | File, filePrefix: StorageFileKeyPrefixEnum, entityId?: string): Promise<FileField<S3FileObject>[] | FileField<S3FileObject> | S3FileObject> {
    try {
      if (!data) return null;

      if (data instanceof File) {
        const result = await this.createS3Object({ data }, filePrefix, entityId);
        return Object.values(result)[0];
      } else if (Array.isArray(data)) {
        const s3ObjectFiles: FileField<S3FileObject>[] = [];

        for (const item of data) {
            s3ObjectFiles.push(await this.createS3Object(item, filePrefix, entityId));
        }

        return s3ObjectFiles;
      } else {
        return await this.createS3Object(data, filePrefix, entityId);
      }

    } catch (error) {
      console.log(error);
    }
  }

  public async getFile(key: string): Promise<string> {
    return String(await this.get(key));
  }

  public async copyFile(keyFrom: string): Promise< File > {
    const result = String(await this.get(keyFrom));
    const resultBlob = await this.get(keyFrom, true) as { Body: Blob };
    const name = keyFrom.split('/').pop();
    let response = await fetch(result);
    let data = await response.blob();
    let metadata = {
      type: resultBlob.Body.type
    };
    let file = new File([data], name, metadata);
    return file;
  }

  public async downloadFile(key: string): Promise<void> {
    return await this.download(key);
  }

  public async removeFile(key: string): Promise<void> {
    return await this.remove(key);
  }

  public renameFile(file: File, newName: string): File {
    const blob = file.slice(0, file.size, file.type);
    const name = `${newName}.${file.name.split('.').pop()}`;
    return new File([blob], name, { type: file.type });
  }

  public async createFileView(file: Partial<FileView>): Promise<FileView> {
    if(!file?.key) return null;

    const link = await this.getFile(file.key);

    return {
      link,
      name: file.name,
      key: file.key,
      id: file.id
    };
  }

  public saveFile(file: File, prefix: string): Promise<{ key: string }> {
    return this.put(file, `${prefix}/${file.name}`);
  }

  public getPreviewUrl(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
    });
  }

  public async rotateImage(img: File, degrees: number) {
    const sourceBase64 = await fileToBase64(img);

    return imageProcessor
      .src(sourceBase64, { jpgQuality: 1 })
      .pipe(rotate({ degree: degrees }))
      .then((resultBase64) => new File([base64ToArrayBuffer(resultBase64)], img.name, { type: img.type }));
  }

  private async createS3Object(
    object: FileField<File>, prefix: StorageFileKeyPrefixEnum | string, entityId?: string
  ): Promise<FileField<S3FileObject>> {

    let keyPrefix: string;
    const [field, file] = Object.entries(object)[0];

    if (entityId) {
      keyPrefix = `${prefix}/${entityId}/${file.name}`;
    } else {
      keyPrefix = prefix;
    }

    await this.put(file, keyPrefix);

    return ({ [field]: new S3FileObject(keyPrefix, file.name) });
  }
}
