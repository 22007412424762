import { Action } from '@ngrx/store';

import { AmplifyAuthError } from './../../../core/interfaces/amplify-auth-error.interface';
import { LoginModel } from './../../../core/models/login.model';
import { ActionTypes } from '../action-types.enum';

export class LoginRequestAction implements Action {
  readonly type = ActionTypes.LOGIN_REQUEST;

  constructor(public payload: { model: LoginModel; returnUrl?: string }) {}
}

export class LoginSuccessAction implements Action {
  readonly type = ActionTypes.LOGIN_SUCCESS;
}

export class LoginFailureAction implements Action {
  readonly type = ActionTypes.LOGIN_FAILURE;

  constructor(public payload: Error) {}
}
