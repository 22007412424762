import { Injectable } from '@angular/core';
import { Storage } from 'aws-amplify';
import { saveAs } from 'file-saver';

import { SnackBarService } from './snackbar.service';

@Injectable({ providedIn: 'root' })
export class AmplifyStorageService {
  constructor(private readonly snackbar: SnackBarService) {}

  protected async put(file: File, key: string): Promise<{ key: string }> {
    try {
      return (await Storage.put(key, file, {
        contentType: file.type,
      })) as { key: string };
    } catch (error) {
      this.snackbar.error(error?.message);
      throw new Error(error?.message);
    }
  }

  protected async get(key: string, download: boolean = false): Promise<object | string> {
    try {
      return await Storage.get(key, { download, expires: 3600 });
    } catch (error) {
      this.snackbar.error(error?.message);
      throw new Error(error?.message);
    }
  }

  protected async download(key: string): Promise<void> {
    const result = await this.get(key, true) as { Body: Blob };
    const name = key.split('/').pop();

    saveAs(result.Body, name);
  }

  protected async remove(key: string): Promise<void> {
    try {
      await Storage.remove(key);
    } catch (error) {
      this.snackbar.error(error?.message);
      throw new Error(error?.message);
    }
  }
}
