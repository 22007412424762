import { UserRegistrationStatusEnum } from '@enums/user-registration-status.enum';
import { EntityStatusEnum } from '@enums/entity-status.enum';
import { S3FileObject } from '@models/s3-file-object.model';
import { VMOCompany } from '@models/vmo-company.model';
import { FileView } from '@interfaces/file-view.interface';
import { ContactComment } from '@models/contact-comment.model';
import { UserStatusEnum } from '@enums/user-status.enum';

export class User {
  email: string;
  status: EntityStatusEnum;
  registrationStatus: UserRegistrationStatusEnum;
  role: string;
  orgId: string;
  cognitoId: string;

  firstname?: string;
  lastname?: string;
  country?: string;
  Region?: string;
  teamId?: string;
  timezone?: string;
  document?: S3FileObject;
  id?: string;
  deletedAt?: string;
  deleteStatus?: number;
  phone?: string;
  dateOfBirth?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  serviceArea?: string;
  backgroundCheck?: S3FileObject;
  drugScreenCheck?: S3FileObject;
  backgroundCheckDate?: string;
  drugScreenCheckDate?: string;
  tools?: string;
  services?: string;
  inviteDatetime?: string;
  ipAddress?: string;
  agreementDate?: string;
  notificationTypes?: string;
  createdAt?: string;
  updatedAt?: string;
  createdDate?: number;
  statusSearch?: string;
  userLogo?: S3FileObject;
  salesforceId?: string;
  userClientIDId?: string;
  clientId?: string;
}

export class UserDTO extends User {
  parentUserId?: string;
  userTeamIDId?: string;
  userOrgIDId: string;
  teamID?: Partial<VMOCompany>;

  constructor(source: Partial<UserDTO> = {}) {
    super();
    Object.assign(this, source);
  }
}

export class UserView extends User {
  teamID: Partial<VMOCompany>;
  additionalFiles: {
    items: { image: S3FileObject }[];
  };
  userLogoView?: FileView;
  backgroundCheckFileView?: FileView;
  drugScreenCheckFileView?: FileView;
  additionalFilesViews?: FileView[];
  companyName?: string;
  comments: {
    items: ContactComment[];
  };
}

export class Technician {
  id?: string;
  salesforceId?: string;
  teamId?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  role?: string;
  status?: UserStatusEnum;
  name?: string;
}
