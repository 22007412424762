import { Action } from '@ngrx/store';
import { ActionTypes } from './action-types.enum';

export class EffectErrorAction implements Action {
  readonly type = ActionTypes.EFFECT_ERROR;

  constructor(public payload: Error) {}
}

export class NoOpAction implements Action {
  readonly type = ActionTypes.NO_OP_ACTION;
}

export type Actions = EffectErrorAction | NoOpAction;
