import { Actions } from './actions';
import { CaseState, initialState } from './state';
import { ActionTypes } from './action-types.enum';

export function caseReducer(state = initialState, action: Actions): CaseState {
  switch (action.type) {
    case ActionTypes.UPDATE_CASE_SUCCESS:
    case ActionTypes.UPDATE_CASE_FAILURE:
    case ActionTypes.UPDATE_TASK_REQUEST:
    case ActionTypes.UPDATE_TASK_SUCCESS:
    case ActionTypes.UPDATE_TASK_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.CASE_PAGINATION_SUCCESS:
      return {
        ...state,
        casePagination: action.payload,
        casePaginationLoading: false
      };

    case ActionTypes.LOAD_CASE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        singleCase: action.payload
      };

    case ActionTypes.CASE_PAGINATION_REQUEST:
      return {
        ...state,
        casePaginationLoading: true
      };

    case ActionTypes.UPDATE_CASE_REQUEST:
    case ActionTypes.LOAD_CASE_REQUEST:
    case ActionTypes.UPDATE_CASE_COMMENT_REQUEST:
    case ActionTypes.CREATE_COMMENT_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.CASE_EVENTS_PAGINATION_REQUEST:
    case ActionTypes.LOAD_CASE_EVENTS_LIST_REQUEST:
      return {
        ...state,
        isHistoryLoading: true
      };

    case ActionTypes.LOAD_CASE_TIME_LOGS_LIST_REQUEST:
    case ActionTypes.CASE_TIME_LOGS_PAGINATION_REQUEST:
    case ActionTypes.CREATE_CASE_TIME_LOG_OBJECT_REQUEST:
    case ActionTypes.UPDATE_CASE_TIME_LOG_OBJECT_REQUEST:
    case ActionTypes.DELETE_CASE_TIME_LOG_OBJECT_REQUEST:
      return {
        ...state,
        isTimeLogsLoading: true
      };

    case ActionTypes.ADDITIONAL_EXPENSES_PAGINATION_REQUEST:
    case ActionTypes.CREATE_CASE_ADDITIONAL_EXPENSE_ITEM_REQUEST:
    case ActionTypes.UPDATE_CASE_ADDITIONAL_EXPENSE_ITEM_REQUEST:
    case ActionTypes.DELETE_ADDITIONAL_EXPENSES_OBJECT_REQUEST:
      return {
        ...state,
        isExpensesLoading: true
      };

    case ActionTypes.CASE_PAGINATION_FAILURE:
    case ActionTypes.LOAD_CASE_FAILURE:
    case ActionTypes.LOAD_CASE_EVENTS_LIST_FAILURE:
    case ActionTypes.CREATE_COMMENT_SUCCESS:
    case ActionTypes.CREATE_COMMENT_FAILURE:
    case ActionTypes.UPDATE_CASE_COMMENT_SUCCESS:
    case ActionTypes.UPDATE_CASE_COMMENT_FAILURE:
    case ActionTypes.CREATE_CASE_TIME_LOG_OBJECT_FAILURE:
    case ActionTypes.UPDATE_CASE_TIME_LOG_OBJECT_FAILURE:
    case ActionTypes.DELETE_CASE_TIME_LOG_OBJECT_FAILURE:
    case ActionTypes.DELETE_ADDITIONAL_EXPENSES_OBJECT_FAILURE:
    case ActionTypes.CREATE_CASE_ADDITIONAL_EXPENSE_ITEM_FAILURE:
    case ActionTypes.UPDATE_CASE_ADDITIONAL_EXPENSE_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        casePaginationLoading: false
      };

    case ActionTypes.LOAD_CASE_EVENTS_LIST_SUCCESS:
      return {
        ...state,
        caseEvents: [...action.payload],
        isHistoryLoading: false
      };

    case ActionTypes.LOAD_CASE_TIME_LOGS_LIST_SUCCESS:
    case ActionTypes.DELETE_CASE_TIME_LOG_OBJECT_SUCCESS:
      return {
        ...state,
        caseTimeLogs: [...action.payload],
        isTimeLogsLoading: false
      };

    case ActionTypes.LOAD_ADDITIONAL_EXPENSES_LIST_SUCCESS:
    case ActionTypes.DELETE_ADDITIONAL_EXPENSES_OBJECT_SUCCESS:
      return {
        ...state,
        additionalExpenses: [...action.payload],
        isExpensesLoading: false
      };

    default: {
      return state;
    }
  }
};
