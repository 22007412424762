import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-copy-confirmation',
  templateUrl: './copy-confirmation.component.html',
  styleUrls: ['./copy-confirmation.component.scss']
})
export class CopyConfirmationComponent {
  public includeNested = false;

  constructor(
    private readonly dialogRef: MatDialogRef<CopyConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { objectName: string, hasNestedObjects: boolean }
  ) {}

  public onConfirm(): void {
    this.dialogRef.close({ confirm: true, includeNested: this.includeNested });
  }
}
