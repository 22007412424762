import awsConfig from '../../../../amplify-src/aws-exports';

export class S3FileObject {
  bucket: string;
  region: string;

  constructor(public key: string, public name?: string) {
    this.bucket = awsConfig.aws_user_files_s3_bucket;
    this.region = awsConfig.aws_user_files_s3_bucket_region;
  }
}
