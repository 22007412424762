import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { CaseTimeLog } from '@models/case/case-timelog.model';
import { ActionParams } from '@models/action-params.model';
import { Required } from 'utility-types';


export class DeleteTimeLogObjectRequestAction implements Action {
  readonly type = ActionTypes.DELETE_CASE_TIME_LOG_OBJECT_REQUEST;

  constructor(public payload: { id: string, params?: ActionParams }) {}
}

export class DeleteTimeLogObjectSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_CASE_TIME_LOG_OBJECT_SUCCESS;

  constructor(public payload: CaseTimeLog[]) {}
}

export class DeleteTimeLogObjectFailureAction implements Action {
  readonly type = ActionTypes.DELETE_CASE_TIME_LOG_OBJECT_FAILURE;

  constructor(public payload: Error) {}
}

export class CreateTimeLogObjectRequestAction implements Action {
  readonly type = ActionTypes.CREATE_CASE_TIME_LOG_OBJECT_REQUEST;

  constructor(public payload: { model: CaseTimeLog; params?: ActionParams }) {}
}

export class CreateTimeLogObjectSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_CASE_TIME_LOG_OBJECT_SUCCESS;
}

export class CreateTimeLogObjectFailureAction implements Action {
  readonly type = ActionTypes.CREATE_CASE_TIME_LOG_OBJECT_FAILURE;

  constructor(public payload: Error) {}
}

export class UpdateTimeLogObjectRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_TIME_LOG_OBJECT_REQUEST;

  constructor(public payload: { model: Required<Partial<CaseTimeLog>, 'id'>; params?: ActionParams }) {}
}

export class UpdateTimeLogObjectSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_TIME_LOG_OBJECT_SUCCESS;
}

export class UpdateTimeLogObjectFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_CASE_TIME_LOG_OBJECT_FAILURE;

  constructor(public payload: Error) {}
}
