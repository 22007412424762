import { Action } from '@ngrx/store';

import { ActionTypes } from '../action-types.enum';
import { AmplifyAuthError } from './../../../core/interfaces/amplify-auth-error.interface';

export class SetPasswordAsAdminRequestAction implements Action {
  readonly type = ActionTypes.SET_PASSWORD_AS_ADMIN_REQUEST;

  constructor(public payload: { cognitoId: string; password: string }) {}
}

export class SetPasswordAsAdminSuccessAction implements Action {
  readonly type = ActionTypes.SET_PASSWORD_AS_ADMIN_SUCCESS;
}

export class SetPasswordAsAdminFailureAction implements Action {
  readonly type = ActionTypes.SET_PASSWORD_AS_ADMIN_FAILURE;

  constructor(public payload: AmplifyAuthError) {}
}
