import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, map, repeat, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, EMPTY, from, Observable, of } from 'rxjs';

import { FilterHelperService } from '@services/helpers/filter-helper.service';
import { UserHelperService } from '@services/helpers/user-helper.service';
import { HelperService } from '@services/helpers/helper.service';
import { SnackBarService } from '@services/snackbar.service';
import { AmplifyService } from '@services/amplify.service';
import { AuthService } from '@services/auth/auth.service';
import { FileService } from '@services/file.service';
import { ApiService } from '@services/api.service';

import { UserRequest } from '@interfaces/user-request.interface';
import { organizationId, spcAdditionalRole } from '@app/constants';
import { UserInvite } from '@interfaces/user-invite.interface';
import { AmplifyAuthCustomAttributesEnum } from '@enums/amplify-auth-custom-attributes.enum';
import { UserRegistrationStatusEnum } from '@enums/user-registration-status.enum';
import { StorageFileKeyPrefixEnum } from '@enums/storage-file-key-prefix.enum';
import { UserRequestStatusEnum } from '@enums/user-request-status.enum';
import { NotificationTypesEnum } from '@enums/notification-types.enum';
import { UserInviteStatusEnum } from '@enums/user-invite-status.enum';
import { EntityStatusEnum } from '@enums/entity-status.enum';
import { UserRolesEnum } from '@enums/user-roles.enum';
import { GraphQLFilter } from '@models/graphql/graphql-filter.model';
import { EmailTemplate } from '@models/email-template.model';
import { UserDTO, UserView } from '@models/user.model';
import { Email } from '@models/email.model';
import { createUserInvite } from '@graphql/user-invites/mutations';
import { updateUserRequest } from '@graphql/user-request/mutations';
import { createUserFile, removeUserFile } from '@graphql/user-files/mutations';
import { cognitoAddToGroup, cognitoRemoveFromGroup, cognitoUpdateUserAttribute, createUser, updateUser } from '@graphql/user/mutations';
import { getUserById, searchUsers, searchUsersCustom } from '@graphql/user/queries';
import { getUserInviteList, searchUserInvites, searchUserInvitesByEmail } from '@graphql/user-invites/queries';
import { searchUserRequests, searchUserRequestsByEmail} from '@graphql/user-request/queries';

import { ActionTypes } from './action-types.enum';
import * as CompanySelectors from '@store/company-store/selectors';
import * as CompanyActions from '@store/company-store/company-actions';
import * as UserActions from './user-actions';
import * as UserSelectors from './selectors';
import * as AuthActions from '../auth-store/auth-actions';
import { RootStore } from '..';
import { SalesForceService } from '@services/sales-force.service';
import { SalesForceActionsEnum } from '@enums/sales-force-actions.enum';
import { createContactComment, updateContactComment } from '@graphql/contact-comments/mutations';
import { ContactComment } from '@models/contact-comment.model';
import { S3FileObject } from '@models/s3-file-object.model';
import { AdditionalFile } from '@models/additional-file.model';
import { listUsersAuthEvents } from '@graphql/user-authlog/queries';

@Injectable()
export class UserEffects {
  constructor(
    private readonly router: Router,
    private readonly store$: Store<RootStore.AppState>,
    private readonly actions$: Actions,
    private readonly amplifyService: AmplifyService,
    private readonly salesForceService: SalesForceService,
    private readonly snackbar: SnackBarService,
    private readonly userHelper: UserHelperService,
    private readonly authService: AuthService,
    private readonly fileService: FileService,
    private readonly filterHelper: FilterHelperService,
    private readonly apiService: ApiService
  ) {}

  // User Invites
  @Effect()
  loadAllInvitesListEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.LoadAllInvitesListRequestAction>(ActionTypes.LOAD_ALL_INVITES_LIST_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([_, user]) => {
      const filter = this.filterHelper.getUserInvitesSearchFilter(user);
      return searchUserInvites.execute({ filter, sort: null }).then((res) => res?.data?.searchUsersInvites?.items);
    }),
    map((invites) => new UserActions.LoadAllInvitesListSuccessAction(this.userHelper.updateInviteStatuses(invites))),
    catchError((error) => of(new UserActions.LoadAllInvitesListFailureAction(error))),
    repeat()
  );

  // User Requests
  @Effect()
  loadAllUsersListRequestAction$: Observable<Action> = this.actions$.pipe(
      ofType<UserActions.LoadAllUsersListRequestAction>(ActionTypes.LOAD_ALL_USERS_LIST_REQUEST),
      withLatestFrom(this.store$.select(UserSelectors.selectUser)),
      switchMap(([_, user]) => {
        const filter = this.filterHelper.getUserSearchFilter(user);
        return searchUsers.execute({ filter }).then((res) => res?.data?.searchUsers?.items);
      }),
      map((items) => {
        const users = items.map(item => ({
          ...item,
          name: `${item.firstname ? item.firstname + ' ' : ''}${item.lastname || ''}`,
          companyName: item.teamID?.name
        }));
        return new UserActions.LoadAllUsersListSuccessAction(users);
      }),
      catchError((error) => of(new UserActions.LoadAllUsersListFailureAction(error))),
      repeat()
  );

  @Effect()
  loadCurrentUserEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.LoadCurrentUserRequestAction>(ActionTypes.LOAD_CURRENT_USER_REQUEST),
    switchMap((action) => {
      if (!action.payload) {
        return from(this.amplifyService.getCurrentUserInfo()).pipe(
          switchMap((user) => {
            if (!user?.username) {
              this.store$.dispatch(new AuthActions.LogoutRequestAction(true));
              return EMPTY;
            }

            return this.userHelper.fetchUser(new GraphQLFilter('cognitoId', user.username));
          })
        );
      } else if (typeof action.payload === 'string') {
        return getUserById.execute({ id: action.payload }).then((user) => user.data.getUser);
      } else {
        return this.userHelper.fetchUser(action.payload);
      }
    }),
    map((user) => {
      this.authService.role = user.role;
      return new UserActions.LoadCurrentUserSuccessAction({ ...user, companyName: user?.teamID?.name });
    }),
    catchError((error) => of(new UserActions.LoadCurrentUserFailureAction(error))),
    repeat()
  );

  @Effect()
  loadUserViewEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.LoadUserViewRequestAction>(ActionTypes.LOAD_USER_VIEW_REQUEST),
    switchMap(({ payload }) => this.userHelper.fetchUser(payload)),
    map((user) => {
      if (user) {
        return new UserActions.LoadUserFilesRequestAction(user);
      } else {
        return new UserActions.LoadUserViewSuccessAction(null);
      }
    }),
    catchError((error) => of(new UserActions.LoadUserViewFailureAction(error))),
    repeat()
  );

  @Effect()
  loadUserFilesEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.LoadUserFilesRequestAction>(ActionTypes.LOAD_USER_FILES_REQUEST),
    switchMap(({ payload }) => {
      if (!payload) {
        return EMPTY;
      }

      return combineLatest([
        of(payload),
        this.fileService.createFileView({
           key: payload.userLogo?.key,
           name: payload.userLogo?.name
        }),
        this.fileService.createFileView({
          key: payload.backgroundCheck?.key,
          name: payload.backgroundCheck?.name
        }),
        this.fileService.createFileView({
          key: payload.drugScreenCheck?.key,
          name: payload.drugScreenCheck?.name
        }),
        this.userHelper.fetchUserAdditionalFiles(payload)
      ]);
    }),
    map(([user, userLogoFileView, backgroundCheckFileView, drugScreenCheckFileView, additionalFilesViews]) => {
      const userView = Object.assign({}, user, {
        userLogoFileView,
        backgroundCheckFileView,
        drugScreenCheckFileView,
        additionalFilesViews
      });

      return new UserActions.LoadUserFilesSuccessAction(userView);
    }),
    catchError((error) => of(new UserActions.LoadUserFilesFailureAction(error))),
    repeat()
  );

  @Effect()
  inviteNewUserEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.InviteNewUserRequestAction>(ActionTypes.INVITE_NEW_USER_REQUEST),
    switchMap(({ payload }) => {
      const emails = payload.map((item) => item.email);

      return combineLatest([
        searchUsers
          .execute({
            filter: { or: [...emails.map((email) => ({ email: { eq: email } }))] }
          })
          .then((response) => response?.data?.searchUsers?.items?.map((user) => user.email)),
        of(payload)
      ]);
    }),
    switchMap(([existingUsers, payload]) => {
      if (existingUsers?.length) {
        this.store$.dispatch(
          new UserActions.InviteNewUserSuccessAction({
            existingUsers,
            invitationsSent: false
          })
        );
        return EMPTY;
      } else {
        const requests = payload.map((user) =>
          this.amplifyService
            .signUp({
              username: user.email,
              password: Math.random().toString(36).slice(2),
              attributes: {
                [AmplifyAuthCustomAttributesEnum.Role]: user.role
              }
            })
            .then((cognitoUser) => {
              const userModel = new UserDTO({
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
                phone: user.phone,
                status: EntityStatusEnum.Pending,
                statusSearch: EntityStatusEnum.Pending,
                registrationStatus: UserRegistrationStatusEnum.NotComplete,
                role: user.role,
                cognitoId: cognitoUser.userSub,
                orgId: organizationId,
                userOrgIDId: organizationId,
                inviteDatetime: HelperService.getDateTimeUTCString(),
                notificationTypes: `${NotificationTypesEnum.Text},${NotificationTypesEnum.Push}`,
                createdDate: HelperService.timestamp
              });
              if (user.client) {
                userModel.clientId = user.client;
                userModel.userClientIDId = user.client;
              }
              createUser.execute({
                input: userModel
              });
              const userInviteModel: Partial<UserInvite> = {
                email: user.email,
                role: user.role,
                status: UserInviteStatusEnum.RegistrationPending
              };
              this.store$.dispatch(new UserActions.CreateUserInviteRequestAction(userInviteModel));
            })
        );

        return Promise.all(requests);
      }
    }),
    map(() => {
      this.snackbar.success('Invitations sent successfully');
      return new UserActions.InviteNewUserSuccessAction({
        existingUsers: [],
        invitationsSent: true
      });
    }),
    catchError((error) => of(new UserActions.InviteNewUserFailureAction(error))),
    repeat()
  );

  @Effect()
  createUserEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.CreateUserRequestAction>(ActionTypes.CREATE_USER_REQUEST),
    switchMap(({ payload }) => {
      let userRole = payload.user.role;
      if (userRole === spcAdditionalRole) {
        userRole = UserRolesEnum.ServicePartnerAdmin;
      }
      const signUpParams = {
        username: payload.user.email,
        password: Math.random().toString(36).slice(2),
        attributes: {
          [AmplifyAuthCustomAttributesEnum.Role]: userRole
        }
      };

      return combineLatest([
        from(
          this.amplifyService.signUp(signUpParams).then((response) =>
            createUser
              .execute({
                input: {
                  ...payload.user,
                  cognitoId: response.userSub,
                  teamId: payload.user.teamId,
                  userTeamIDId: payload.user.teamId,
                  inviteDatetime: HelperService.getDateTimeUTCString(),
                  notificationTypes: payload.user.notificationTypes || `${NotificationTypesEnum.Text},${NotificationTypesEnum.Push}`,
                  createdDate: HelperService.timestamp
                }
              })
              .then((res) => new UserDTO(Object.assign({}, payload.user, res?.data?.createUser)))
          )
        ),
        of(payload),
      ]);
    }),
    map(([user, payload]) => {
      const { files = [], additionalFiles = [] } = payload;

      const userInviteModel: Partial<UserInvite> = {
        email: user.email,
        firstName: user.firstname,
        lastName: user.lastname,
        userId: user.id,
        teamId: user.teamId,
        usersInviteTeamIDId: user.teamId,
        usersInviteUserIDId: user.id,
        role: user.role,
        status: UserInviteStatusEnum.RegistrationPending
      };

      this.store$.dispatch(new UserActions.CreateUserInviteRequestAction(userInviteModel));

      if (files?.length || additionalFiles?.length) {
        this.store$.dispatch(
          new UserActions.UpdateUserRequestAction({
            model: { user: { id: user.id }, files, additionalFiles },
            params: payload.params
          })
        );
      } else {
        if (payload.params?.hasOwnProperty('redirectTo')) {
          this.router.navigate([payload.params.redirectTo]);
        }
        const message = payload.params?.message || 'User has been created successfully';
        this.snackbar.success(message);
        payload.params?.callback?.();
      }

      return new UserActions.CreateUserSuccessAction();
    }),
    catchError((error) => of(new UserActions.CreateUserFailureAction(error))),
    repeat()
  );

  @Effect()
  updateUserEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.UpdateUserRequestAction>(ActionTypes.UPDATE_USER_REQUEST),
    switchMap((action) => {
      let userImage;
      const { user, files, additionalFiles, teamID } = action.payload.model;

      if (additionalFiles?.length) {
        this.store$.dispatch(
          new UserActions.CreateUserAdditionalFileRequestAction({
            files: additionalFiles,
            userId: user.id
          })
        );
      }

      if (action.payload.params?.hasOwnProperty('redirectTo')) {
        if (action.payload.params.redirectTo === 'auth/login') {
          const sfUpdateModel = {
            ...(action.payload.model.user.country) && { Country__c: action.payload.model.user.country },
            ...(action.payload.model.user.firstname) && { FirstName: action.payload.model.user.firstname },
            ...(action.payload.model.user.lastname) && { LastName: action.payload.model.user.lastname },
            ...(action.payload.model.user.email) && { Email: action.payload.model.user.email },
            ...(action.payload.model.user.phone) && { Phone: action.payload.model.user.phone },
            ...(action.payload.model.user.address1) && { post_address_1__c: action.payload.model.user.address1 },
            ...(action.payload.model.user.address2) && { post_address_2__c: action.payload.model.user.address2 },
            ...(action.payload.model.user.city) && { post_address_city__c: action.payload.model.user.city },
            ...(action.payload.model.user.state) && { post_address_state__c: action.payload.model.user.state },
            ...(action.payload.model.user.zipCode) && { post_address_zip__c: action.payload.model.user.zipCode },
            ...(action.payload.model.user.dateOfBirth) && { Date_of_Birth__c: action.payload.model.user.dateOfBirth },
            ...(action.payload.model.user.id) && { POST_ID__c: action.payload.model.user.id },
            EmailEnabled__c: false,
            SmsEnabled__c: true,
            AppMsgEnabled__c: true,
            VMO_Status__c: 'active'
          };
          if (action.payload.model.user.teamId) {
            sfUpdateModel['AccountId'] = action.payload.model.teamID.salesforceId;
          }
          this.salesForceService.postSFInfo(SalesForceActionsEnum.userProfile, sfUpdateModel).then((res) => {
              const model = { id: action.payload.model.user.id, salesforceId: res.id };
              updateUser.execute({ input: { ...model } }).then((response) => {});
              if (files?.length) {
                files.map((file) => {
                  if (file.hasOwnProperty('backgroundCheck')) {
                    this.salesForceService.uploadSFAttachment(res.id, file.backgroundCheck.name, file.backgroundCheck);
                  }
                  if (file.hasOwnProperty('drugScreenCheck')) {
                    this.salesForceService.uploadSFAttachment(res.id, file.drugScreenCheck.name, file.drugScreenCheck);
                  }
                });
              }
              if (additionalFiles?.length) {
                additionalFiles.map((file) => {
                  this.salesForceService.uploadSFAttachment(res.id, file.name, file);
                });
              }
          });
        }
      } else {
        const userPhoto = action.payload?.userPhoto;
        let sfModel = {};
        sfModel = {
          ...(action.payload.model.user.country) && { Country__c: action.payload.model.user.country },
          ...(action.payload.model.user.firstname) && { FirstName: action.payload.model.user.firstname },
          ...(action.payload.model.user.lastname) && { LastName: action.payload.model.user.lastname },
          ...(action.payload.model.user.email) && { Email: action.payload.model.user.email },
          ...(action.payload.model.user.phone) && { Phone: action.payload.model.user.phone },
          ...(action.payload.model.user.address1) && { post_address_1__c: action.payload.model.user.address1 },
          ...(action.payload.model.user.address2) && { post_address_2__c: action.payload.model.user.address2 },
          ...(action.payload.model.user.city) && { post_address_city__c: action.payload.model.user.city },
          ...(action.payload.model.user.state) && { post_address_state__c: action.payload.model.user.state },
          ...(action.payload.model.user.zipCode) && { post_address_zip__c: action.payload.model.user.zipCode },
          ...(action.payload.model.user.dateOfBirth) && { Date_of_Birth__c: action.payload.model.user.dateOfBirth },
        };
        if (action.payload.model.user.notificationTypes) {
          sfModel['EmailEnabled__c'] = !!action.payload.model.user.notificationTypes.includes('Email');
          sfModel['SmsEnabled__c'] = !!action.payload.model.user.notificationTypes.includes('Text');
          sfModel['AppMsgEnabled__c'] = !!action.payload.model.user.notificationTypes.includes('Push');
        }
        if (Object.keys(sfModel).length !== 0) {
          this.salesForceService.patchSFInfo(SalesForceActionsEnum.userProfile, action.payload.model.user.salesforceId, sfModel);
        }

        if (userPhoto && !userPhoto.bucket) {
          const file = userPhoto;
          const userId = user.id;
          const keyTo = `${StorageFileKeyPrefixEnum.UserLogo}/${userId}`;
          const s3Object = new S3FileObject(`${keyTo}/${file[0].name}`, file[0].name);
          const attachment: AdditionalFile = {
            image: s3Object
          };
          this.fileService
            .saveFile(file[0], keyTo)
            .then(() => {
              const model = { id: userId, userLogo: attachment.image };
            });
          userImage = attachment.image;
        } else if (!userPhoto) {
          userImage = null;
        } else if (userPhoto) {
          userImage = userPhoto;
        }
      }
      return this.fileService
        .save(files, StorageFileKeyPrefixEnum.UserFiles, user.id)
        .then((savedFiles) => {
          updateUser.execute({ input: { ...user, ...Object.assign({}, ...(savedFiles || []), { userLogo: userImage}) } });
        }).then(() => action.payload);
    }),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    map(([payload, currentUser]) => {
      if (payload.params?.hasOwnProperty('redirectTo')) {
        this.router.navigate([payload.params.redirectTo]);
      }
      payload.params?.needLogout && this.store$.dispatch(new AuthActions.LogoutRequestAction());
      payload.params?.callback?.();
      payload.params?.message && this.snackbar.success(payload.params?.message);

      if (payload.model.user.id === currentUser?.id) {
        this.store$.dispatch(new UserActions.UpdateUserSuccessAction());
        return new UserActions.LoadCurrentUserRequestAction(currentUser.id);
      } else {
        return new UserActions.UpdateUserSuccessAction();
      }
    }),
    catchError((error) => of(new UserActions.UpdateUserFailureAction(error))),
    repeat()
  );

  @Effect()
  UpdateUserRequestSingleEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.UpdateUserRequestSingleRequestAction>(ActionTypes.UPDATE_USER_REQUEST_REQUEST),
    switchMap(({ payload }) =>
      combineLatest([
        searchUsers.execute(new GraphQLFilter('email', payload.model.email)).then((res) => res?.data?.searchUsers?.items[0]),
        of(payload)
      ])
    ),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([[userExist, { model: { id, email, firstName = '', lastName = '', phone = '' }, status, params }], userBy]) => {
      if (userExist || status === UserRequestStatusEnum.Reject) {
        return updateUserRequest
          .execute({ input: { id, userRequestRejectedById: userBy.id, status: UserRequestStatusEnum.Reject, statusSearch: UserRequestStatusEnum.Reject } })
          .then(() => {
            const message = userExist ? 'This user is already in the system.' : 'User Request has been rejected successfully';

            this.snackbar.success(message);
            return params;
          });
      }

      return updateUserRequest
        .execute({ input: { id, userRequestApprovedById: userBy.id, status: UserRequestStatusEnum.Approved, statusSearch: UserRequestStatusEnum.Approved } })
        .then(() => this.store$.dispatch(new UserActions.InviteNewUserRequestAction([{
          email,
          role: UserRolesEnum.PlatformTechnician,
          firstname: firstName,
          lastname: lastName,
          phone
        }])))
        .then(() => {
          const message = 'User Request has been approved and invite has been send successfully';

          this.snackbar.success(message);
          return params;
        });
    }),
    map((params) => {
      params?.callback?.();

      return new UserActions.UpdateUserRequestSingleSuccessAction();
    }),
    catchError((error) => of(new UserActions.UpdateUserRequestSingleFailureAction(error))),
    repeat()
);

  @Effect()
  createUserAdditionalFileEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.CreateUserAdditionalFileRequestAction>(
      ActionTypes.CREATE_USER_ADDITIONAL_FILE_REQUEST
    ),
    switchMap((action) => {
      const requests = action.payload.files.map((file) =>
        this.fileService
          .save(file, StorageFileKeyPrefixEnum.UserFiles, action.payload.userId)
          .then((s3Object) =>
            createUserFile.execute({
              input: {
                image: s3Object,
                userAdditionalFilesUserIDId: action.payload.userId,
                userId: action.payload.userId
              }
            })
          )
      );

      return Promise.all(requests);
    }),
    map(() => {
      this.snackbar.success('User has been created successfully');
      return new UserActions.CreateUserAdditionalFileSuccessAction();
    }),
    catchError((error) => of(new UserActions.CreateUserAdditionalFileFailureAction(error))),
    repeat()
  );

  @Effect()
  disableUserEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.DisableUserRequestAction>(ActionTypes.DISABLE_USER_REQUEST),
    switchMap(({ payload }) => this.userHelper.disableUser(payload)),
    withLatestFrom(
      this.store$.select(CompanySelectors.selectInvitedUsersList),
      this.store$.select(UserSelectors.selectAllUsersList)
    ),
    map(([response, usersInvites, userList]) => {
      const invites = [...usersInvites];
      const inviteIndex = invites.findIndex((item) => item.id === response?.id);

      if (inviteIndex > -1) {
        const user: UserInvite = { ...invites[inviteIndex], status: UserInviteStatusEnum.Disabled };
        invites.splice(inviteIndex, 1, user);
      }

      const users = [...userList];
      const userIndex = users.findIndex((item) => item.id === response?.userId);

      if (userIndex > -1) {
        const user: UserView = { ...users[userIndex], status: EntityStatusEnum.Disabled };
        users.splice(userIndex, 1, user);
        this.store$.dispatch(new UserActions.UpdateUsersInListSuccessAction([...users]));
      }

      this.snackbar.success('User has been disabled successfully');
      this.store$.dispatch(new CompanyActions.LoadCompanyInvitationsSuccessAction(invites));
      return new UserActions.DisableUserSuccessAction();
    }),
    catchError((error) => of(new UserActions.DisableUserFailureAction(error))),
    repeat()
  );

  @Effect()
  reactivateUserEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.ReactivateUserRequestAction>(ActionTypes.REACTIVATE_USER_REQUEST),
    switchMap(({ payload }) => this.userHelper.enableUser(payload)),
    withLatestFrom(
      this.store$.select(CompanySelectors.selectInvitedUsersList),
      this.store$.select(UserSelectors.selectAllUsersList)
    ),
    switchMap(([response, usersInvites, userList]) => {
      const invites = [...usersInvites];
      const inviteIndex = invites.findIndex((item) => item.id === response?.id);

      if (inviteIndex > -1) {
        const user: UserInvite = { ...invites[inviteIndex], status: UserInviteStatusEnum.Reactivated };
        invites.splice(inviteIndex, 1, user);
      }

      const users = [...userList];
      const userIndex = users.findIndex((item) => item.id === response?.userId);

      if (userIndex > -1) {
        const user: UserView = { ...users[userIndex], status: EntityStatusEnum.Reactivated };
        users.splice(userIndex, 1, user);
        this.store$.dispatch(new UserActions.UpdateUsersInListSuccessAction([...users]));
      }

      return this.userHelper.setNewUserPassword(response.email, response.userID.cognitoId, true).then(() => invites);
    }),
    map((users) => {
      this.snackbar.success('User has been reactivated successfully');
      this.store$.dispatch(new CompanyActions.LoadCompanyInvitationsSuccessAction(users));
      return new UserActions.ReactivateUserSuccessAction();
    }),
    catchError((error) => of(new UserActions.ReactivateUserFailureAction(error))),
    repeat()
  );

  @Effect()
  createUserInviteEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.CreateUserInviteRequestAction>(ActionTypes.CREATE_USER_INVITE_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, activeUser]) => {
      const userInviteModel: UserInvite = {
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        userId: payload.userId,
        teamId: payload.teamId,
        usersInviteTeamIDId: payload.teamId,
        usersInviteUserIDId: payload.userId,
        role: payload.role,
        status: payload.status,
        statusSearch: payload.status,
        createdBy: activeUser.id,
        updatedBy: activeUser.id,
        inviteDatetime: HelperService.getDateTimeUTCString(),
        createdDate: HelperService.timestamp
      };

      return createUserInvite.execute({ input: userInviteModel });
    }),
    map(() => new UserActions.CreateUserInviteSuccessAction()),
    catchError((error) => of(new UserActions.CreateUserInviteFailureAction(error))),
    repeat()
  );

  @Effect()
  updateUserInviteEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.UpdateUserInviteRequestAction>(ActionTypes.UPDATE_USER_INVITE_REQUEST),
    switchMap(({ payload }) => {
      return combineLatest([
        this.amplifyService
          .loginAsAdmin()
          .then(() =>
            searchUserInvites
              .execute(new GraphQLFilter('email', payload.email))
              .then((res) => res?.data?.searchUsersInvites?.items?.length && res.data.searchUsersInvites.items[0])
          ),
        of(payload)
      ]);
    }),
    switchMap(([inviteModel, payload]) => this.userHelper.updateUserInvite(inviteModel, payload.status)),
    map(() => new UserActions.UpdateUserInviteSuccessAction()),
    catchError((error) => of(new UserActions.UpdateUserInviteFailureAction(error))),
    tap(() => this.store$.dispatch(new AuthActions.LogoutRequestAction(false))),
    repeat()
  );

  @Effect()
  confirmUserInviteEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.ConfirmUserInviteRequestAction>(ActionTypes.CONFIRM_USER_INVITE_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser).pipe(take(1))),
    switchMap(([{payload}, activeUser]) => {
      return combineLatest([
        getUserInviteList
          .execute(new GraphQLFilter('email', activeUser.email))
          .then((res) => {
            return res?.data?.listUsersInvites?.items?.length
              && res.data.listUsersInvites.items.filter((invite) => invite?.teamID?.name === payload.companyName)[0];
          }),
        of(payload),
        of(activeUser),
      ]);
    }),
    switchMap(([inviteModel, payload, activeUser]) => {
      if (activeUser.role !== inviteModel.role && payload.status === UserInviteStatusEnum.InviteAccepted) {
        this.store$.dispatch(
          new UserActions.ChangeUserRoleRequestAction({ user: activeUser, newRole: inviteModel.role, type: 'userInvite' })
        );
      }

      if (payload.status === UserInviteStatusEnum.InviteRejected) {
        return this.userHelper.updateUserInvite(inviteModel, payload.status);
      } else if (payload.status === UserInviteStatusEnum.InviteAccepted) {
        return combineLatest([
          this.userHelper.updateUserInvite(inviteModel, payload.status),
          updateUser.execute({
            input: {
              id: activeUser.id,
              role: inviteModel.role,
              teamId: inviteModel.teamId,
              userTeamIDId: inviteModel.teamId,
            },
          }),
          this.userHelper.associateUserSF(activeUser, inviteModel.teamId)
        ]).pipe(
          map(() => {
            this.snackbar.success('You have successfully joined the SP Company');
          })
        );
      }
    }),
    map(() => {
      this.router.navigate(['']);
      return new UserActions.ConfirmUserInviteSuccessAction();
    }),
    catchError((error) => of(new UserActions.ConfirmUserInviteFailureAction(error))),
    repeat()
  );

  @Effect()
  changeUserRoleEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.ChangeUserRoleRequestAction>(ActionTypes.CHANGE_USER_ROLE_REQUEST),
    switchMap(({ payload }) => {
      const oldRole = payload.user.role.split(', ')[0];
      const newRole = payload.newRole.split(', ')[0];
      cognitoUpdateUserAttribute.execute({
        input: {
          cognitoUserId: payload.user.cognitoId,
          attributeName: AmplifyAuthCustomAttributesEnum.Role,
          attributeValue: newRole
        }
      })
      .then(() => cognitoRemoveFromGroup.execute({ input: { cognitoUserId: payload.user.cognitoId, group: oldRole } }))
      .then(() => cognitoAddToGroup.execute({ input: { cognitoUserId: payload.user.cognitoId, group: newRole } }));

      return of(payload);
    }),
    map((payload) => {
      if (payload.type === 'userInvite') {
        this.snackbar.warning('Your role has changed. Please log in again to apply the new permissions');
      }
      return new UserActions.ChangeUserRoleSuccessAction();
    }),
    catchError((error) => of(new UserActions.ChangeUserRoleFailureAction(error))),
    repeat()
  );

  @Effect()
  changeUserEmailEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.ChangeUserEmailRequestAction>(ActionTypes.CHANGE_USER_EMAIL_REQUEST),
    switchMap(({ payload }) => {
      const password = Math.random().toString(36).slice(2);
      const emailTemplate = EmailTemplate.createEmailTemplate(
        `<div>Your POST credentials has been changed.</div>
        ${EmailTemplate.highlight('Email', payload.newEmail)}
        ${EmailTemplate.highlight('Password', password)}`
      );

      return cognitoUpdateUserAttribute.execute({
        input: {
          cognitoUserId: payload.cognitoId,
          attributeName: AmplifyAuthCustomAttributesEnum.Email,
          attributeValue: payload.newEmail
        }
      })
      .then(() => cognitoUpdateUserAttribute.execute({
        input: {
          cognitoUserId: payload.cognitoId,
          attributeName: AmplifyAuthCustomAttributesEnum.EmailVerified,
          attributeValue: String(true)
        }
      }))
      .then(() => this.userHelper.setNewUserPassword(payload.newEmail, payload.cognitoId, false, password))
      .then(() => Promise.all([
        this.apiService.sendEmail(new Email({ to: payload.oldEmail, text: emailTemplate })),
        this.apiService.sendEmail(new Email({ to: payload.newEmail, text: emailTemplate }))
      ]))
      .then(() => payload.params?.callback && payload.params.callback());
    }),
    map(() => new UserActions.ChangeUserEmailSuccessAction()),
    catchError((error) => of(new UserActions.ChangeUserEmailFailureAction(error))),
    repeat()
  );

  @Effect()
  userPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.UserPaginationRequestAction>(ActionTypes.USER_PAGINATION_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) => {
      const queryParams = {
        from: payload.from,
        limit: payload.limit
      };

      if (payload.filter) {
        queryParams['filter'] = { ...payload.filter };

        if (queryParams['filter']['role']) {
          queryParams['filter'].role = { eq: queryParams['filter']['role'] };
        }
      }

      if (payload.sort) {
        queryParams['sort'] = { ...payload.sort };

        if (payload.sort.field === 'status') {
          queryParams['sort'] = { field: 'statusSearch', direction: payload.sort['direction'] };
        }
      }

      if (user.role === UserRolesEnum.ProjectCoordinator || user.role === UserRolesEnum.VendorManager) {
        const restrictedRolesFilter = {
          ne: [
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.Admin,
            UserRolesEnum.ProjectCoordinator,
            UserRolesEnum.VendorManager
          ].join(',')
        };
        queryParams['filter'].role = queryParams['filter'].role && typeof queryParams['filter'].role === 'object'
          ? { ...queryParams['filter'].role, ...restrictedRolesFilter }
          : restrictedRolesFilter;
      }

      return searchUsersCustom.execute(queryParams).then((res) => res?.data?.searchUsersCustom);
    }),
    map((response) => {
      response.items = response.items.map((item) => ({
        ...item,
        username: `${item.firstname ? item.firstname + ' ' : ''}${item.lastname || ''}`,
        companyName: item.teamID?.name
      }));
      return new UserActions.UserPaginationSuccessAction({ ...response });
    }),
    catchError((error) => of(new UserActions.LoadAllUsersListFailureAction(error)))
  );

  @Effect()
  userAuthLogPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.UserAuthLogPaginationRequestAction>(ActionTypes.USER_AUTHLOG_PAGINATION_REQUEST),
    switchMap(({ payload, cognitoId }) => {
      const queryParams = {
        cognitoId,
        from: payload.from,
        limit: payload.limit
      };
      return listUsersAuthEvents
        .execute(queryParams)
        .then((res) => res?.data?.listUsersAuthEvents);
    }),
    map((response) => {
      return new UserActions.UserAuthLogPaginationSuccessAction(response);
    }),
    catchError((error) => of(new UserActions.LoadAllUsersListFailureAction(error)))
  );

  @Effect()
  userInvitesPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.UserInvitePaginationRequestAction>(ActionTypes.USER_INVITE_PAGINATION_REQUEST),
    switchMap(({ payload }) => {
      const queryParams = Object.assign({ sort: { field: 'inviteDatetime', direction: 'desc' }}, payload);

      if (!payload.filter) {
        delete queryParams.filter;
      }

      return searchUserInvitesByEmail.execute(queryParams).then((res) => res?.data?.searchUserInvitesByEmail);
    }),
    map((response) => {
      return new UserActions.UserInvitePaginationSuccessAction({
        items: this.userHelper.updateInviteStatuses([...response.items]),
        total: response.total
      });
    }),
    catchError((error) => of(new UserActions.LoadAllInvitesListFailureAction(error)))
  );

  @Effect()
  userRequestPaginationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.UserRequestPaginationRequestAction>(ActionTypes.USER_REQUEST_PAGINATION_REQUEST),
    switchMap(({ payload }) => {
      const queryParams = {
        from: payload.from,
        limit: payload.limit,
        sort: { direction: 'desc', field: 'createdDate' }
      };

      if (payload?.filter) {
        queryParams['filter'] = payload.filter;
      }
      if (payload?.sort) {
        queryParams.sort = payload.sort;
      }

      return searchUserRequestsByEmail.execute(queryParams).then((res) => res?.data?.searchUserRequestsByEmail);
    }),
    map((response) => {
      const requests = { ...response, items: this.userHelper.mapRequestFields(response.items) };

      return new UserActions.UserRequestPaginationSuccessAction(requests);
    }),
    catchError((error) => of(new UserActions.UserRequestPaginationFailureAction(error)))
  );

  @Effect()
  createContactCommentEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.CreateContactCommentRequestAction>(ActionTypes.CREATE_CONTACT_COMMENT_REQUEST),
    withLatestFrom(this.store$.select(UserSelectors.selectUser)),
    switchMap(([{ payload }, user]) =>
      combineLatest([
        createContactComment.execute({
          input: { ...payload.model, createdBy: user.id, contactCommentContactIDId: user.id },
        }).then(
          (response) =>
            ({
              ...payload.model,
              id: response?.data?.createContactComment?.id,
            } as ContactComment)
        ),
        of(user)
      ])
    ),
    map(([payload, user]) => {
      const sfUpdateModel = {
       // IsDeleted: false,
       // CreatedById: user.salesforceId,
        Contact__c: payload.salesforceContactId,
        isPublished__c: true,
        isActive__c: true,
        CommentBody__c: payload.commentBody
      };
      this.salesForceService.postSFInfo(SalesForceActionsEnum.contactComment, sfUpdateModel).then((res) => {
        const model = {id: payload.id, salesforceId: res.id};
        updateContactComment.execute({ input: model}).then(() => payload);
      });
      this.snackbar.success('Comment have been sent');
      return new UserActions.CreateContactCommentSuccessAction();
    }),
    catchError((error) => of(new UserActions.CreateContactCommentFailureAction(error))),
    repeat()
  );

  @Effect()
  saveUserAdditionalFilesEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.SaveUserAdditionalFileRequestAction>(ActionTypes.SAVE_USER_ADDITIONAL_FILE_REQUEST),
    switchMap(({ payload }) =>
      Promise.all(payload.files.map((file) => this.userHelper.saveFile(file, payload.id, payload.salesforceId)))
        .then(() => payload)
    ),
    switchMap((payload) => Promise.all(payload.files.map((file) => this.fileService.createFileView(file)))
    .then(() => payload)),
    map((payload) => {
      payload.params?.callback && payload.params.callback();
      const files = [];

      return new UserActions.SaveUserAdditionalFileSuccessAction(files);
    }),
    catchError((error) => of(new UserActions.SaveUserAdditionalFileFailureAction(error))),
    repeat()
  );

  @Effect()
  removeUserAdditionalFilesEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.RemoveUserAdditionalFileRequestAction>(
      ActionTypes.REMOVE_USER_ADDITIONAL_FILE_REQUEST
    ),
    switchMap(({ payload }) =>
      this.fileService
        .removeFile(payload.file.key)
        .then(() => {
          //this.salesForceService.deleteSFAttachment(SalesForceActionsEnum.attachment, payload.salesforceId);
          return removeUserFile.execute({ input: { id: payload.file.id } });
        })
        .then(() => payload)
    ),
    map((payload) => {
      const newFiles = [];
      payload.params?.callback && payload.params.callback();

      return new UserActions.RemoveUserAdditionalFileSuccessAction(newFiles);
    }),
    catchError((error) => of(new UserActions.RemoveUserAdditionalFileFailureAction(error))),
    repeat()
  );

  @Effect()
  saveUserChecksFilesEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserActions.SaveUserChecksFilesRequestAction>(ActionTypes.SAVE_USER_CHECKS_FILES_REQUEST),
    switchMap(({ payload }) => {
      const files = payload.files[0];
      const userInfo = payload.userInfo;
      return this.fileService
        .save(files, StorageFileKeyPrefixEnum.UserFiles, payload.userInfo.id)
        .then((savedFiles) => {
            // this.salesForceService.deleteSFAttachment(SalesForceActionsEnum.attachment, payload.salesforceId);
          if (payload.type === 'drugScreenCheck') {
            updateUser.execute({ input: { ...userInfo, ...Object.assign({}, { drugScreenCheck: savedFiles })} });
          } else {
            updateUser.execute({ input: { ...userInfo, ...Object.assign({}, { backgroundCheck: savedFiles })} });
          }
        })
        .then(() => payload);
      }
    ),
    map((payload) => {
      console.log(payload);
      payload.params?.callback && payload.params.callback();
      const files = [];

      return new UserActions.SaveUserChecksFilesSuccessAction(files);
    }),
    catchError((error) => of(new UserActions.SaveUserChecksFilesFailureAction(error))),
    repeat()
  );

  @Effect({ dispatch: false })
  userErrorsEffect$: Observable<void> = this.actions$.pipe(
    ofType<Action & { payload: Error }>(
      ActionTypes.LOAD_ALL_USERS_LIST_FAILURE,
      ActionTypes.LOAD_ALL_INVITES_LIST_FAILURE,
      ActionTypes.LOAD_ALL_REQUESTS_LIST_FAILURE,
      ActionTypes.LOAD_CURRENT_USER_FAILURE,
      ActionTypes.CREATE_USER_FAILURE,
      ActionTypes.UPDATE_USER_FAILURE,
      ActionTypes.CREATE_USER_ADDITIONAL_FILE_FAILURE,
      ActionTypes.LOAD_USER_FILES_FAILURE,
      ActionTypes.LOAD_USER_VIEW_FAILURE,
      ActionTypes.DISABLE_USER_FAILURE,
      ActionTypes.REACTIVATE_USER_FAILURE,
      ActionTypes.CHANGE_USER_EMAIL_FAILURE,
      ActionTypes.CREATE_CONTACT_COMMENT_FAILURE,
      ActionTypes.REMOVE_USER_ADDITIONAL_FILE_FAILURE,
      ActionTypes.SAVE_USER_ADDITIONAL_FILE_FAILURE,
      ActionTypes.SAVE_USER_CHECKS_FILES_FAILURE,
    ),
    map((action) => {
      console.log('action: ', action);
      this.snackbar.error(action.payload.message);
    })
  );
}
