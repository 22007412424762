import { Action } from '@ngrx/store';

import { ActionTypes } from '../../action-types.enum';
import { ExtraObjectsTemplate } from '@interfaces/indexed-db/extra-objects-template.interface';


export class SaveExtraObjectsInIndexedDBRequestAction implements Action {
  readonly type = ActionTypes.SAVE_EXTRA_OBJECTS_IN_INDEXED_DB_REQUEST;

  constructor(public payload: ExtraObjectsTemplate) {}
}

export class SaveExtraObjectsInIndexedDBSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_EXTRA_OBJECTS_IN_INDEXED_DB_SUCCESS;

  constructor(public payload: string) {}
}

export class SaveExtraObjectsInIndexedDBFailureAction implements Action {
  readonly type = ActionTypes.SAVE_EXTRA_OBJECTS_IN_INDEXED_DB_FAILURE;

  constructor(public payload: Error) {}
}
